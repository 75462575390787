import { replaceParams, endpoints } from "@utils/axios";
import React, { Fragment, useEffect, useState } from "react";
import { Branch, Convert } from "../types/Branch";
import useAxios from "@routes/hooks/use-axios";
import { z } from "zod";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { InputField, ValidationError } from "@components/hook-form";
import { Button } from "@components/button";
import { ButtonVariant } from "@components/button/button";
import { toast } from "react-toastify";
import Applabel from "@components/hook-form/applabel";

import CountryInputSelect from "@components/hook-form/country-input-select";
import TimezoneData from "@assets/timezones";
import tzmoment from "moment-timezone";
import { Dialog, Transition } from "@headlessui/react";

import PhoneInput, {
	formatPhoneNumber,
	getCountryCallingCode,
	isPossiblePhoneNumber,
	isValidPhoneNumber,
} from "react-phone-number-input";
import { CountryCode, E164Number } from "libphonenumber-js";
import "react-phone-number-input/style.css";
import { convertPhoneNumberAndCodeToNumber, convertPhoneNumberAndCodeToString } from "@utils/phoneUtils";

interface DummyProfilePictureContainerProps {
	getPatientData: () => Promise<void>;
	selectedImage: string;
	setSelectedImage: React.Dispatch<React.SetStateAction<string>>;
	patientId: string;
}

function DummyProfilePictureContainer({
	getPatientData,
	selectedImage,
	setSelectedImage,
	patientId,
}: DummyProfilePictureContainerProps) {
	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const [displayImage, setDisplayImage] = React.useState<string>("");

	useEffect(() => {
		setDisplayImage(selectedImage);
	}, [selectedImage]);

	const openModal = () => setIsModalOpen(true);
	const closeModal = () => setIsModalOpen(false);

	return (
		<div className="flex flex-col w-24">
			{displayImage != "" ? (
				<div className="w-24 h-24 bg-gray-300 rounded-lg overflow-hidden border border-gray-300 mt-2">
					<img src={displayImage} alt="Profile" className="w-full h-full object-cover" onClick={openModal} />
				</div>
			) : (
				<div className="w-24 h-24 flex  justify-center bg-gray-300 rounded-lg p-3 border border-gray-300 mt-2">
					<svg xmlns="http://www.w3.org/2000/svg" height="100%" viewBox="0 0 448 512" className="text-gray-500">
						<path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464H398.7c-8.9-63.3-63.3-112-129-112H178.3c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z" />
					</svg>
				</div>
			)}

			<Transition appear show={isModalOpen} as={Fragment}>
				<Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto " onClose={closeModal}>
					<div className="min-h-screen px-4 text-center">
						<Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

						<div className="inline-block align-middle my-16 p-4 w-full max-w-lg text-left overflow-hidden shadow-xl transform transition-all bg-white">
							<img src={displayImage} alt="Profile" className="w-full h-full object-cover rounded-lg" />

							<button className="absolute top-0 right-1 m-4 text-xl cursor-pointer" onClick={closeModal}>
								&times;
							</button>
						</div>
					</div>
				</Dialog>
			</Transition>
		</div>
	);
}

interface Timezone {
	value: string;
	abbr: string;
	text: string;
	utc: string[];
}

// TO DO  : Change Country state city from optional to required with respective messages
export const BranchAddressSchema = z.object({
	address: z.string().min(1, { message: "Address is required" }),
	city: z.string().optional(),
	state: z.string().optional(),
	country: z.string().optional(),
	postalCode: z.string().min(1, { message: "Postal Code/zip is required" }),
});
export type BranchAddress = z.infer<typeof BranchAddressSchema>;

// Add this phone validation function at the top level
const phoneValidation = (val: string, ctx: any) => {
	if (!val || val.trim() === "") {
		ctx.addIssue({
			code: z.ZodIssueCode.custom,
			message: `Please enter Phone`,
			fatal: true,
		});
		return z.NEVER;
	}
	if (!isValidPhoneNumber(val)) {
		ctx.addIssue({
			code: z.ZodIssueCode.custom,
			fatal: true,
			message: "Invalid phone number format",
		});
		return z.NEVER;
	}
	if (!isPossiblePhoneNumber(val)) {
		ctx.addIssue({
			code: z.ZodIssueCode.custom,
			message: `Invalid phone number length`,
			fatal: true,
		});
		return z.NEVER;
	}
};

// Update the BranchSchema to use the reusable phoneValidation
export const BranchSchema = z.object({
	entityId: z.string(),
	name: z.string().min(1, { message: "Branch name is required" }),
	adminSalutation: z.string().min(1, { message: "Title is required" }),
	adminFirstName: z.string().min(1, { message: "First name is required" }),
	adminMiddleName: z.string(),
	adminLastName: z.string().min(1, { message: "Last name is required" }),
	email: z.string().min(1, { message: "Email is required" }).email({ message: "Must be a valid email" }),
	phoneCode: z.string().min(1, { message: "Phone Code is required" }),
	phone: z.string().superRefine(phoneValidation),
	branchphone: z.string().superRefine(phoneValidation).optional(),
	isActive: z.boolean().optional(),
	timezone: z.string().optional(),
	address: BranchAddressSchema,
});

export type BranchSchemaType = z.infer<typeof BranchSchema>;

function BranchInformationComponent(props: {
	branchId: string;
	entityId: string;
	setBranchTimeZone: React.Dispatch<React.SetStateAction<string>>;
	hasTimeZoneChanged: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const [branch, setBranch] = React.useState<Branch | null>(null);
	const [loading, setLoading] = React.useState(true);
	const [formData, setFormData] = useState({}); // Store form data here
	const [error, setApiError] = React.useState("");
	const [isEditable, setIsEditable] = React.useState(false);
	const [phoneNumber, setPhoneNumber] = useState("91");
	const [selectedImage, setSelectedImage] = React.useState<string>("");

	const axios = useAxios();

	const {
		register,
		handleSubmit,
		watch,
		reset,
		control,
		setValue,
		setError,
		clearErrors,
		trigger,
		formState: { errors },
	} = useForm<BranchSchemaType>({
		resolver: zodResolver(BranchSchema),
	});

	const handlePhoneNumberChange = (value: E164Number | undefined) => {
		setValue("phone", value?.toString() ?? "");
	};

	const ressetWorkingHours = async (timeZone: string) => {
		try {
			const paramsMap = new Map<string, string>([["id", props.branchId]]);
			const filteredEndpoint = replaceParams(endpoints.branch.updateworkinghours, paramsMap);

			const response = await axios.put(
				filteredEndpoint,
				{ workingHours: [] },
				{
					headers: {
						"Content-Type": "application/json",
						timezone: timeZone, // Add the timezone string to the headers
					},
				}
			);
			if (response.data.status === 200)
				toast.success(response.data.message === "success" ? "Working reset successfully" : response.data.message);
		} catch (error) {
			toast.error(error.response.data["data"].toString());
			setLoading(false);
		}
	};

	useEffect(() => {
		setIsEditable(false);

		const getEntityData = async () => {
			try {
				const paramsMap = new Map<string, string>([
					["entityId", props.entityId],
					["branchId", props.branchId],
				]);
				const filteredEndpoint = replaceParams(endpoints.branch.view, paramsMap);
				const response = await axios.get(filteredEndpoint);
				const data = response.data["data"];

				setPhoneNumber(data.phoneCode.toString().toString() + data.phone.toString().toString());

				const convertedBranch = Convert.toBranch(JSON.stringify(data));
				setBranch(convertedBranch);
				props.setBranchTimeZone(convertedBranch.timezone ?? ""); // Important for setting in next tab working hours

				const phoneNumberDataString = convertPhoneNumberAndCodeToString({
					phoneNumber: convertedBranch.phone ?? 0,
					phoneCode: convertedBranch.phoneCode ?? 0,
				});

				const branchPhoneNumber =
					convertedBranch.branchphone && convertedBranch.branchphone !== 0
						? convertedBranch.branchphone
						: convertedBranch.phone;

				const branchPhoneDataString = convertPhoneNumberAndCodeToString({
					phoneNumber: branchPhoneNumber,
					phoneCode: convertedBranch.phoneCode ?? 0,
				});

				console.log("Initial phone data:", {
					adminPhone: phoneNumberDataString.phoneNumber,
					branchPhone: branchPhoneDataString.phoneNumber,
					rawBranchPhone: branchPhoneNumber,
				});

				reset({
					...convertedBranch,
					phone: phoneNumberDataString.phoneNumber,
					phoneCode: phoneNumberDataString.phoneCode,
					branchphone: branchPhoneDataString.phoneNumber,
				});
				setIsEditable(false);

				setLoading(false);
			} catch (error) {
				console.error("Error fetching branch data:", error);
				toast.error(error.message);

				setApiError(error.message);
				setLoading(false);
			}
		};
		getEntityData();
	}, []);

	const handleToggleEdit = () => {
		setIsEditable((prevState) => !prevState);
	};

	const onSubmit: SubmitHandler<BranchSchemaType> = async (data) => {
		setLoading(true);

		try {
			const combinedData = { ...formData, ...data };

			const phoneData = convertPhoneNumberAndCodeToNumber({
				phoneNumber: combinedData.phone,
				phoneCode: combinedData.phoneCode,
			});

			const branchPhoneData = convertPhoneNumberAndCodeToNumber({
				phoneNumber: combinedData.branchphone || combinedData.phone,
				phoneCode: combinedData.phoneCode,
			});

			console.log("Phone numbers before request:", {
				adminPhone: phoneData.phoneNumber,
				branchPhone: branchPhoneData.phoneNumber,
				rawBranchPhone: combinedData.branchphone,
			});

			const requestPayload = {
				...combinedData,
				phone: phoneData.phoneNumber,
				phoneCode: phoneData.phoneCode,
				branchphone: branchPhoneData.phoneNumber || phoneData.phoneNumber, // Fallback to admin phone
			};

			console.log("Final request payload:", requestPayload);

			const paramsMap = new Map<string, string>([
				["entityId", props.entityId],
				["branchId", props.branchId],
			]);
			const filteredEndpoint = replaceParams(endpoints.branch.update, paramsMap);

			const response = await axios.put(filteredEndpoint, requestPayload);
			if (response.data.status === 200)
				toast.success(response.data.message === "success" ? "Branch updated successfully" : response.data.message);

			props.setBranchTimeZone((prevState) => {
				if (prevState !== data.timezone) {
					ressetWorkingHours(data.timezone!);
					props.hasTimeZoneChanged(true);

					return data.timezone ?? "";
				}
				return prevState;
			}); // Important for setting in next tab working hours
			setLoading(false);
			setIsEditable(false);
		} catch (error) {
			toast.error(error.message);

			setApiError(error.response.data["data"].toString());
			setLoading(false);
			setIsEditable(true);
			console.error("Error submitting form:", error);
		}
	};

	function checkDuplicateEmail() {
		var email = watch("email");

		if (branch?.email !== email && email.trim() !== "") {
			if (email) {
				const paramsMap = new Map<string, string>([["email", watch("email")]]);
				const filteredEndpoint = replaceParams(endpoints.staff.checkStaffEmail, paramsMap);
				axios
					.get(filteredEndpoint)
					.then((res) => {
						clearErrors("email");
					})
					.catch((error) => {
						if (error && error.response && error.response.data && error.response.data.status === 500) {
							setError("email", {
								type: "custom",
								message: "Account with this email already exists. Please contact support.",
							});
							setValue("email", "");
						} else {
							toast.error(error.message);
							setValue("email", "");
						}
					});
			}
		}
	}

	return (
		<>
			{loading && <p>Loading...</p>}

			{!loading && (
				<form onSubmit={handleSubmit(onSubmit)} noValidate>
					<div className="">
						{/* <DummyProfilePictureContainer
              getPatientData={async () => {}}
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
              patientId={''}
            /> */}
						<div className="flex flex-col w-full md:w-2/3 pr-2">
							<Applabel label="Timezone" mandatory />
							<select
								{...register("timezone")}
								value={watch("timezone")}
								disabled={!isEditable}
								placeholder="Timezone"
								className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
							>
								{TimezoneData.timezones.map((timezone: Timezone, index: number) =>
									timezone.utc.map((tz) => (
										<option key={tz} value={tz}>
											{tz} -{timezone.text}
										</option>
									))
								)}
							</select>
						</div>
						{/* Entity Name */}
						<div className="flex flex-col w-full md:w-2/3 pr-2">
							<Applabel label="Branch Name" mandatory />

							<input
								type="text"
								disabled={!isEditable}
								key="name"
								{...register("name")}
								value={watch("name")}
								placeholder="Branch Name"
								className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
							/>
							{errors.name && <ValidationError message={errors.name?.message?.toString() ?? ""} />}
						</div>

						{/* Entity Owner */}
						<div className="flex flex-col md:flex-row w-full justify-start items-start mt-0 md:space-x-4">
							<div className="flex flex-col w-full md:w-1/3">
								<div className="flex flex-row w-full justify-start space-x-2">
									<div className="flex flex-col ">
										<Applabel label="Title" mandatory />
										<select
											{...register("adminSalutation")}
											placeholder="Salutation"
											disabled={!isEditable}
											className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
											value={watch("adminSalutation")}
										>
											<option key="" value="">
												Select
											</option>
											{["Mr", "Mrs", "Ms", "Miss", "Dr", "Prof", "Rev", "Hon", "Capt"].map((salutation) => {
												return (
													<option key={salutation} value={salutation}>
														{salutation}
													</option>
												);
											})}
										</select>
										{errors.adminSalutation && (
											<ValidationError message={errors.adminSalutation?.message?.toString() ?? ""} />
										)}
									</div>
									<div className="flex flex-col grow">
										<Applabel label="First Name" mandatory />
										<input
											{...register("adminFirstName")}
											disabled={!isEditable}
											placeholder="Branch Admin First Name"
											value={watch("adminFirstName")}
											type="text"
											className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
										/>
										{errors.adminFirstName && (
											<ValidationError message={errors.adminFirstName?.message?.toString() ?? ""} />
										)}
									</div>
								</div>
							</div>
							<div className="flex flex-col w-full md:w-1/3">
								<Applabel label="Middle Name" />
								<input
									{...register("adminMiddleName")}
									disabled={!isEditable}
									placeholder="Branch Admin Middle Name"
									value={watch("adminMiddleName")}
									className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
								/>
								{errors.adminMiddleName && (
									<ValidationError message={errors.adminMiddleName?.message?.toString() ?? ""} />
								)}
							</div>
							<div className="flex flex-col w-full md:w-1/3">
								<Applabel label="Last Name" mandatory />
								<input
									{...register("adminLastName")}
									disabled={!isEditable}
									className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
									placeholder="Branch Admin Last Name"
									value={watch("adminLastName")}
								/>
								{errors.adminLastName && <ValidationError message={errors.adminLastName?.message?.toString() ?? ""} />}
							</div>
						</div>

						<div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4">
							<div className="flex flex-col md:w-1/3">
								<Applabel label="Email" mandatory />
								<input
									{...register("email")}
									disabled={!isEditable}
									placeholder="Branch Admin Email"
									className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
									value={watch("email")}
									onBlur={checkDuplicateEmail}
									required
								/>
								{errors.email && <ValidationError message={errors.email?.message?.toString() ?? ""} />}
							</div>

							<div className="flex flex-col md:w-1/4">
								<Applabel label="Mobile Number" mandatory />
								<div className="flex flex-row">
									<PhoneInput
										onCountryChange={(country?: CountryCode | undefined) => {
											if (country) {
												const countryCode = getCountryCallingCode(country);
												setValue("phoneCode", countryCode?.toString() ?? "");
											}
										}}
										{...register("phone")}
										international={true}
										disabled={!isEditable}
										defaultCountry="IN"
										countryCallingCodeEditable={false}
										placeholder="Enter phone number"
										value={watch("phone")}
										onChange={(value?: E164Number | undefined) => {
											clearErrors("phone");
											handlePhoneNumberChange(value);
										}}
										onBlur={() => {
											trigger("phone");
										}}
										className="w-full"
									/>
								</div>
								{errors.phone && <ValidationError message={errors.phone?.message?.toString() ?? ""} />}
							</div>

							{/* New Branch Contact Number Field */}
							<div className="flex flex-col md:w-1/4">
								<Applabel label="Branch Contact Number" mandatory />
								<div className="flex flex-row">
									<PhoneInput
										onCountryChange={(country?: CountryCode | undefined) => {
											if (country) {
												const countryCode = getCountryCallingCode(country);
												setValue("branchphone", "");
												setValue("phoneCode", countryCode?.toString() ?? "");
											}
										}}
										{...register("branchphone")}
										international={true}
										disabled={!isEditable}
										defaultCountry="IN"
										countryCallingCodeEditable={false}
										placeholder="Enter branch contact number"
										value={watch("branchphone")}
										onChange={(value?: E164Number | undefined) => {
											clearErrors("branchphone");
											if (value) {
												console.log("Setting branch phone value:", value);
												setValue("branchphone", value.toString());
											} else {
												// If no value, use the admin phone number
												const adminPhone = watch("phone");
												console.log("Using admin phone as fallback:", adminPhone);
												setValue("branchphone", adminPhone);
											}
										}}
										onBlur={() => {
											const currentValue = watch("branchphone");
											console.log("Branch phone on blur:", currentValue);
											if (!currentValue) {
												const adminPhone = watch("phone");
												setValue("branchphone", adminPhone);
											}
											trigger("branchphone");
										}}
										className="w-full"
									/>
								</div>
								{errors.branchphone && <ValidationError message={errors.branchphone?.message?.toString() ?? ""} />}
							</div>

							<div className="flex flex-col md:w-1/3"></div>
						</div>

						{/* Entity Address */}
						<hr className="my-2" />

						<span className="flex-none text-lg font-medium w-1/6 mb-0">Address</span>

						<hr className="my-2" />

						<div className="flex flex-col md:flex-row md:w-full md:space-x-4">
							<div className="flex flex-col w-full md:w-2/3">
								<Applabel label="Address" mandatory />

								<input
									{...register("address.address")}
									placeholder="Branch Address"
									disabled={!isEditable}
									className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
									required
								/>
								{errors.address && <ValidationError message={errors.address?.address?.message?.toString() ?? ""} />}
							</div>
							<div className="flex flex-col w-full md:w-1/3">
								<Applabel label="Postal/Zip code" mandatory />

								<input
									{...register("address.postalCode")}
									disabled={!isEditable}
									placeholder="Branch Zip Code"
									className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
									required
									value={watch("address.postalCode")}
								/>
								{errors.address?.postalCode && (
									<ValidationError message={errors.address.postalCode?.message?.toString() ?? ""} />
								)}
							</div>
						</div>

						<CountryInputSelect
							isDisabled={!isEditable}
							register={register}
							setValue={setValue}
							watch={watch}
							country="address.country"
							state="address.state"
							city="address.city"
						/>
						<div className="fixed items-center w-full justify-end bottom-0 h-16 z-50 shrink-0 mt-4 p-4 -ml-8 shadow bg-gray-100">
							<div className="flex flex-row ">
								<div>
									<Button
										variant={ButtonVariant.PRIMARY}
										type="submit"
										className="ml-3"
										onClick={handleSubmit(onSubmit)}
									>
										Save
									</Button>
								</div>
							</div>
						</div>
					</div>
					<div className="h-20 mt-10"></div>
					<div className="fixed items-start w-full justify-end bottom-0 h-16 z-50 shrink-0 mt-4 p-4 -ml-4 md:-ml-6 lg:-ml-8 shadow bg-gray-100">
						<Button
							variant={ButtonVariant.PRIMARY}
							type="button"
							className="lg:ml-3"
							onClick={isEditable ? handleSubmit(onSubmit) : handleToggleEdit}
						>
							{isEditable ? "Save" : "Edit"}
						</Button>
					</div>
				</form>
			)}
		</>
	);
}

export default BranchInformationComponent;
