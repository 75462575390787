import { Button } from "@components/button";
import { ButtonVariant } from "@components/button/button";
import { Switch } from "@headlessui/react";
import { endpoints, replaceParams } from "@utils/axios";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
interface Notification {
  SendAppointmentClientEmail: boolean;
  SendClientOnBoardingEmail: boolean;
  SendDailyDigestEmailToOwner: boolean;
  SendDailyDigestEmailToPractitioner: boolean;
}

export default function NotificationComponent(props: { entityId: string }) {
  const [enabled, setEnabled] = React.useState<Notification>({
    SendAppointmentClientEmail: true,
    SendClientOnBoardingEmail: true,
    SendDailyDigestEmailToOwner: true,
    SendDailyDigestEmailToPractitioner: true,
  });

  const [loading, setLoading] = React.useState(true);

  //Get api call to fetch the notification
  useEffect(() => {
    const getEntityData = async () => {
      try {
        const paramsMap = new Map<string, string>([["id", props.entityId]]);
        const filteredEndpoint = replaceParams(
          endpoints.entity.fetchClientNotification,
          paramsMap
        );
        const response = await axios.get(filteredEndpoint);
        const data: Notification = response.data["data"];

        setEnabled({
          SendAppointmentClientEmail: data.SendAppointmentClientEmail,
          SendClientOnBoardingEmail: data.SendClientOnBoardingEmail,
          SendDailyDigestEmailToOwner: data.SendDailyDigestEmailToOwner,
          SendDailyDigestEmailToPractitioner:
            data.SendDailyDigestEmailToPractitioner,
        });
        setLoading(false);
      } catch (error) {
        toast.error(error.message);

        console.error("Error fetching entity data:", error);

        setLoading(false);
      }
    };
    getEntityData();
  }, []);

  const handleSave = async () => {
    setLoading(true);
    try {
      const paramsMap = new Map<string, string>([["id", props.entityId]]);
      const filteredEndpoint = replaceParams(
        endpoints.entity.updateClientNotification,
        paramsMap
      );
      setEnabled((prevEnabled) => {
        const newEnabled = {
          ...prevEnabled,
        };

        axios.put(filteredEndpoint, newEnabled).then((response) => {
          if (response.data.status === 200) {
            toast.success(
              response.data.message === "success"
                ? "Notification setting updated successfully"
                : response.data.message
            );
          }

          setLoading(false);
        });
        return newEnabled;
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      setLoading(false);
    }
  };
  //Toggle the button on change function

  const handleNotificationOnboarding = async () => {
    setEnabled((prevEnabled) => ({
      ...prevEnabled,
      SendClientOnBoardingEmail: !prevEnabled?.SendClientOnBoardingEmail,
    }));
    await handleSave();
  };
  const handleNotificationForAppointment = async () => {
    setEnabled((prevEnabled) => ({
      ...prevEnabled,
      SendAppointmentClientEmail: !prevEnabled?.SendAppointmentClientEmail,
    }));
    await handleSave();
  };

  const handleNotificationForDailyDigestToOwner = async () => {
    setEnabled((prevEnabled) => ({
      ...prevEnabled,
      SendDailyDigestEmailToOwner: !prevEnabled?.SendDailyDigestEmailToOwner,
    }));
    await handleSave();
  };

  const handleNotificationForDailyDigestToPractitioner = async () => {
    setEnabled((prevEnabled) => ({
      ...prevEnabled,
      SendDailyDigestEmailToPractitioner:
        !prevEnabled?.SendDailyDigestEmailToPractitioner,
    }));
    await handleSave();
  };

  return (
    <>
      <div className="flex flex-row mt-10">
        <div className="flex flex-col md:flex-row lg:flex-row items-center justify-start mt-2">
          <span className="">
            Notification email / sms / WhatsApp for onboarding Patient
          </span>
          <Switch
            onChange={handleNotificationOnboarding}
            checked={enabled?.SendClientOnBoardingEmail}
            className={`${
              enabled?.SendClientOnBoardingEmail
                ? "bg-secondary-600"
                : "bg-secondary-50"
            }
                                relative inline-flex h-[38px] w-[74px] flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 ml-20`}
          >
            <span
              aria-hidden="true"
              className={`${
                enabled?.SendClientOnBoardingEmail
                  ? "translate-x-9"
                  : "translate-x-0"
              }
                                pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>
          {enabled?.SendClientOnBoardingEmail ? (
            <span className="text-gray-500 flex-shrink ml-5">Enabled</span>
          ) : (
            <span className="text-gray-500 flex-shrink ml-5">Disabled</span>
          )}
        </div>
      </div>
      <div>
        <div className="flex flex-col md:flex-row lg:flex-row items-center justify-start mt-2">
          <span className="">
            Notification email / sms / WhatsApp for Appointment
          </span>
          <Switch
            onChange={handleNotificationForAppointment}
            checked={enabled?.SendAppointmentClientEmail}
            className={`${
              enabled?.SendAppointmentClientEmail
                ? "bg-secondary-600"
                : "bg-secondary-50"
            }
                                relative inline-flex h-[38px] w-[74px] flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 ml-32`}
          >
            <span
              aria-hidden="true"
              className={`${
                enabled?.SendAppointmentClientEmail
                  ? "translate-x-9"
                  : "translate-x-0"
              }
                                pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>
          {enabled?.SendAppointmentClientEmail ? (
            <span className="text-gray-500 flex-shrink ml-5">Enabled</span>
          ) : (
            <span className="text-gray-500 flex-shrink ml-5">Disabled</span>
          )}
        </div>
      </div>
      <div>
        <div className="flex flex-col md:flex-row lg:flex-row items-center justify-start mt-2">
          <span className="">Send daily email digest to owner</span>
          <Switch
            onChange={handleNotificationForDailyDigestToOwner}
            checked={enabled?.SendDailyDigestEmailToOwner}
            className={`${
              enabled?.SendDailyDigestEmailToOwner
                ? "bg-secondary-600"
                : "bg-secondary-50"
            }
                                relative inline-flex h-[38px] w-[74px] flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 ml-32`}
          >
            <span
              aria-hidden="true"
              className={`${
                enabled?.SendDailyDigestEmailToOwner
                  ? "translate-x-9"
                  : "translate-x-0"
              }
                                pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>
          {enabled?.SendDailyDigestEmailToOwner ? (
            <span className="text-gray-500 flex-shrink ml-5">Enabled</span>
          ) : (
            <span className="text-gray-500 flex-shrink ml-5">Disabled</span>
          )}
        </div>
      </div>
      <div>
        <div className="flex flex-col md:flex-row lg:flex-row items-center justify-start mt-2">
          <span className="">Send daily email digest to practitioner</span>
          <Switch
            onChange={handleNotificationForDailyDigestToPractitioner}
            checked={enabled?.SendDailyDigestEmailToPractitioner}
            className={`${
              enabled?.SendDailyDigestEmailToPractitioner
                ? "bg-secondary-600"
                : "bg-secondary-50"
            }
                                relative inline-flex h-[38px] w-[74px] flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 ml-32`}
          >
            <span
              aria-hidden="true"
              className={`${
                enabled?.SendDailyDigestEmailToPractitioner
                  ? "translate-x-9"
                  : "translate-x-0"
              }
                                pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>
          {enabled?.SendDailyDigestEmailToPractitioner ? (
            <span className="text-gray-500 flex-shrink ml-5">Enabled</span>
          ) : (
            <span className="text-gray-500 flex-shrink ml-5">Disabled</span>
          )}
        </div>
      </div>
    </>
  );
}
