import React, { Suspense, lazy } from "react";
import { Navigate, Outlet, Route } from "react-router-dom";

// layouts
import DashboardLayout from "@layouts/dashboard";

// components
import { getCurrentRouteMetadata, paths } from "@routes/paths";
import { ViewEntityPage } from "@pages/entities";

import ViewBranchPage from "@pages/branches/view-branch/ViewBranchPage";
import ViewStaffPage from "@pages/staff/view-staff/ViewStaffPage";
import { AddEntityPage } from "@pages/add-entity";
import ViewMasterAssessment from "@pages/view-master-assessment/view/ViewMasterAssessment";

import AddBranchPage from "@pages/add-branch/view/AddBranchPage";
import AddStaffPage from "@pages/add-staff/view/AddStaffPage";
import AddPatientPage from "@pages/add-patient/view/AddPatientPage";
import AddExercisePage from "@pages/add-exercise/view/AddExercisePage";
import AssistantDashboard from "@pages/dashboard/components/assistant-dashboard";
import PhysicalExamination from "@pages/manage-patient-new/view/PhysicalExamination";
import RoleBasedGuard from "@authentication/guard/role-based-guard";
import { UserRole } from "@authentication/types";
import { key } from "localforage";
import StaffDashboard from "@pages/dashboard/components/staff-dashboard";
import ExpenseIndexPage from "@pages/finances/view/ExpensesIndex";
import AddBillPage from "@pages/finances/view/AddBill";
import BillingIndexPage from "@pages/finances/view/BillingIndex";
import ReferralPayout from "@pages/referral-payout/ReferralPayout";
import Credits from "@pages/credits/Credits";
import MySchedule from "@pages/my-schedule/MySchedule";
import PhysicalExaminationV2 from "@pages/manage-patient-new-v2/view/PhysicalExaminationV2";
import PatientRecord from "@pages/manage-patient-new-v2/view/PatientRecord";
import BookAppointmentPageV2 from "@pages/v2/book-appointment";
import BookAppointmentPageV3 from "@pages/appointments/book-appointment/BookAppointmentPageV3";
import StaffCalander from "@pages/appointments/calender/MultiStaffSchedule";
import SingleCalander from "@pages/appointments/calender/StaffSchedule";
import AssistantDashboardV2 from "@pages/dashboard/components/assistant-dashboardV2";

// ----------------------------------------------------------------------
const EntitiesIndexPage = lazy(() => import("@pages/entities"));
const DashboardPage = lazy(() => import("@pages/dashboard"));
const StaffIndexPage = lazy(() => import("@pages/staff"));
const ExerciseIndexPage = lazy(() => import("@pages/exercises"));
const GritupLibraryPage = lazy(() => import("@pages/gritup-library"));

const BranchIndexPage = lazy(() => import("@pages/branches"));
const PatientsIndexPage = lazy(() => import("@pages/patients"));

const ManagePatientPage = lazy(() => import("@pages/manage-patient"));
const ManagePatientNewPage = lazy(() => import("@pages/manage-patient-new"));
const AppointmentIndexPage = lazy(() => import("@pages/appointments"));

// ----------------------------------------------------------------------

type RouteData = {
  path: string;
  permissions: UserRole[];
  element: React.ReactNode;
};

const routesData: RouteData[] = [
	{
		permissions: paths.dashboard.permissions,
		path: paths.dashboard.route,
		element: <DashboardPage />,
	},
	{
		permissions: paths.mySchedule.permissions,
		path: paths.mySchedule.route,
		element: <MySchedule />,
	},
	{
		permissions: paths.assistantDashboard.permissions,
		path: paths.assistantDashboard.route,
		element: <AssistantDashboard />,
	},
	{
		permissions: paths.assistantDashboardV2.permissions,
		path: paths.assistantDashboardV2.route,
		element: <AssistantDashboardV2 />,
	},
	{
		permissions: paths.staffDashboard.permissions,
		path: paths.staffDashboard.route,
		element: <StaffDashboard />,
	},
	{
		element: <EntitiesIndexPage />,
		path: paths.entity.index.route,
		permissions: paths.entity.index.permissions,
	},
	{
		element: <ViewEntityPage />,
		path: paths.entity.manage.route,
		permissions: paths.entity.manage.permissions,
	},
	{
		element: <ViewEntityPage />,
		path: paths.staff.entityManage.route,
		permissions: paths.staff.entityManage.permissions,
	},
	{
		element: <AddEntityPage />,
		path: paths.entity.onboard.route,
		permissions: paths.entity.onboard.permissions,
	},
	{
		permissions: paths.appointment.index.permissions,
		path: paths.appointment.index.route,
		element: <AppointmentIndexPage />,
	},
	{
		permissions: paths.calander.staff.permissions,
		path: paths.calander.staff.route,
		element: <StaffCalander />,
	},
	{
		permissions: paths.appointment.single.permissions,
		path: paths.appointment.single.route,
		element: <SingleCalander />,
	},
	{
		permissions: paths.staff.index.permissions,
		path: paths.staff.index.route,
		element: <StaffIndexPage />,
	},
	{
		permissions: paths.staff.view.permissions,
		path: paths.staff.view.route,
		element: <ViewStaffPage />,
	},
	{
		permissions: paths.staff.profileView.permissions,
		path: paths.staff.profileView.route,
		element: <ViewStaffPage />,
	},
	{
		permissions: paths.staff.onboard.permissions,
		path: paths.staff.onboard.route,
		element: <AddStaffPage />,
	},
	{
		permissions: paths.branch.index.permissions,
		path: paths.branch.index.route,
		element: <BranchIndexPage />,
	},
	{
		permissions: paths.branch.view.permissions,
		path: paths.branch.view.route,
		element: <ViewBranchPage />,
	},
	{
		permissions: paths.staff.branchManage.permissions,
		path: paths.staff.branchManage.route,
		element: <ViewBranchPage />,
	},
	{
		permissions: paths.branch.onboard.permissions,
		path: paths.branch.onboard.route,
		element: <AddBranchPage />,
	},
	{
		permissions: paths.exercise.index.permissions,
		path: paths.exercise.index.route,
		element: <ExerciseIndexPage />,
	},
	{
		permissions: paths.exercise.onboard.permissions,
		path: paths.exercise.onboard.route,
		element: <AddExercisePage />,
	},
	{
		permissions: paths.exercise.view.permissions,
		path: paths.exercise.view.route,
		element: <AddExercisePage />,
	},
	{
		permissions: paths.patient.index.permissions,
		path: paths.patient.index.route,
		element: <PatientsIndexPage />,
	},
	{
		permissions: paths.patient.manage.permissions,
		path: paths.patient.manage.route,
		element: <PhysicalExamination />,
	},

	{
		permissions: paths.patient.manageV2.permissions,
		path: paths.patient.manageV2.route,
		element: <PatientRecord />,
	},

	{
		permissions: paths.patient.physicalExamination.permissions,
		path: paths.patient.physicalExamination.route,
		element: <ManagePatientNewPage assessmentId={""} />,
	},

	{
		permissions: paths.patient.onboard.permissions,
		path: paths.patient.onboard.route,
		element: <AddPatientPage />,
	},
	{
		permissions: paths.patient.masterAssessment.permissions,
		path: paths.patient.masterAssessment.route,
		element: <ViewMasterAssessment />,
	},
	{
		permissions: paths.grituplibrary.index.permissions,
		path: paths.grituplibrary.index.route,
		element: <GritupLibraryPage />,
	},
	{
		permissions: paths.grituplibrary.view.permissions,
		path: paths.grituplibrary.view.route,
		element: <AddExercisePage />,
	},
	{
		permissions: paths.expense.index.permissions,
		path: paths.expense.index.route,
		element: <ExpenseIndexPage />,
	},
	{
		permissions: paths.invoices.index.permissions,
		path: paths.invoices.index.route,
		element: <AddBillPage />,
	},
	{
		permissions: paths.billing.index.permissions,
		path: paths.billing.index.route,
		element: <BillingIndexPage />,
	},
	{
		permissions: paths.referralBilling.index.permissions,
		path: paths.referralBilling.index.route,
		element: <ReferralPayout />,
	},
	{
		permissions: paths.credits.index.permissions,
		path: paths.credits.index.route,
		element: <Credits />,
	},
];

export const securedRoutes = (
  <Route element={<DashboardLayout />}>
    {routesData.map((route) => (
      <Route
        key={route.path}
        path={route.path}
        element={
          <RoleBasedGuard roles={route.permissions}>
            {route.element}
          </RoleBasedGuard>
        }
      />
    ))}
  </Route>
);
