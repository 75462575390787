"use client";
import Button, { ButtonVariant } from "@components/button/button";
import { endpoints, replaceParams } from "@utils/axios";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import DetailsStep from "./DetailsStep";
import { useNavigate } from "react-router-dom";
import EConvert, { type Entity } from "@pages/add-staff/types/EntityType";
// import BConvert, { type Branch } from "@pages/add-staff/types/BranchType";
import BConvert, { type Branch } from "@pages/staff/types/BranchType";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import { toast } from "react-toastify";
import { ValidationError } from "@components/hook-form";
import Applabel from "@components/hook-form/applabel";
import CountryInputSelect from "@components/hook-form/country-input-select";
import TimezoneData from "@assets/timezones";
import tzmoment from "moment-timezone";
import PhoneInput, { getCountryCallingCode, isPossiblePhoneNumber, isValidPhoneNumber } from "react-phone-number-input";
import type { E164Number } from "libphonenumber-js";
import "react-phone-number-input/style.css";
import { convertPhoneNumberAndCodeToNumber } from "@utils/phoneUtils";

export const RoleSchema = z.object({
	role: z.string().min(1, { message: "Role is required" }),
});
export type Role = z.infer<typeof RoleSchema>;

export const AddressSchema = z.object({
	address: z.string().min(1, { message: "Address is required" }),
	city: z.string().optional(),
	state: z.string().optional(),
	country: z.string().optional(),
	postalCode: z.string().optional(),
});
export type Address = z.infer<typeof AddressSchema>;

export const StaffSchema = z.object({
	salutation: z.string().min(1, { message: "Title is required" }),
	entityBranchId: z.string(),
	entityId: z.string(),
	firstName: z.string().min(1, { message: "First name is required" }),
	lastName: z.string().min(1, { message: "Last name is required" }),
	password: z
		.string()
		.min(1, { message: "Password is required" })
		.min(8, { message: "Password must be at least 8 characters long" })
		.refine((val) => /[A-Z]/.test(val), {
			message: "Password must contain at least one uppercase letter",
		})
		.refine((val) => /[a-z]/.test(val), {
			message: "Password must contain at least one lowercase letter",
		})
		.refine((val) => /\d/.test(val), {
			message: "Password must contain at least one digit",
		})
		.refine((val) => /[!@#$%^&*(),.?":{}|<>]/.test(val), {
			message: "Password must contain at least one special character",
		}),
	gender: z.string(),
	email: z.string().min(1, { message: "Email is required" }).email({ message: "Must be a valid email" }),
	phoneCode: z.string().min(1, { message: "Phone Code is required" }),
	phone: z.string().superRefine((val, ctx) => {
		//
		if (!val || val.trim() === "") {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: `Please enter Phone`,
				fatal: true,
			});
			return z.NEVER;
		}
		if (!isValidPhoneNumber(val)) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				fatal: true,
				message: "Invalid phone number format",
			});

			return z.NEVER;
		}

		if (!isPossiblePhoneNumber(val)) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: `Invalid phone number length`,
				fatal: true,
			});
			return z.NEVER;
		}
	}),

	address: AddressSchema,
	role: RoleSchema.required({ role: true }),
	timezone: z.string().refine(
		() => {
			return tzmoment.tz.guess();
		},
		{ message: "Please add Timezone" }
	),
	dob: z.string(),
	designation: z.string().min(1, { message: "Designation is required" }),
});
export type StaffSchemaType = z.infer<typeof StaffSchema>;

// Infer the TS type according to the zod schema.
// const steps = [DetailsStep, ConfigurationStep, DeclarationStep];
const steps = [DetailsStep];
interface Timezone {
	value: string;
	abbr: string;
	text: string;
	utc: string[];
}

function AddStaffPage() {
	const [currentStep, setCurrentStep] = useState(0);
	const [formData, setFormData] = useState({}); // Store form data here

	const navigator = useNavigate();
	const [entities, setEntities] = React.useState<Entity[] | null>(null); // this is the list of entities [{}]
	const [branches, setBranches] = React.useState<Branch[] | null>(null); // this is the list of entities [{}]
	const [branchId, setBranchId] = React.useState<string | null>(null);

	const [loading, setLoading] = React.useState(true);
	const [error, setApiError] = React.useState("");
	const [isPlatformAdmin, setIsPlatformAdmin] = React.useState(false);
	const [entityId, setEntityId] = React.useState<string | null>(null);

	const [phoneNumber, setPhoneNumber] = useState("91");
	const [emergencyPhoneNumber, setEmergencyPhoneNumber] = useState("91");

	const [isEntityOwner, setIsEntityOwner] = React.useState(false);
	const [utcTime, setUtcTime] = useState("");

	const Roles = new Map<string, string>([
		["Entity Owner", "ENTITY_OWNER"],
		["Branch Admin", "BRANCH_ADMIN"],
		["Practitioner", "PRACTITIONER"],
		["Assistant", "ASSISTANT"],
	]);

	const handlePhoneNumberChange = (value: E164Number | undefined) => {
		setValue("phone", value?.toString() ?? "");
	};

	const getBranches = async () => {
		try {
			const paramsMap = new Map<string, string>([["entityId", entityId ?? ""]]);
			const filteredEndpoint = replaceParams(endpoints.branch.list, paramsMap);

			const response = await axios.get(filteredEndpoint);
			const data = response.data["data"];
			const convertedBranchList = data.map((entity: any) => BConvert.toBranch(JSON.stringify(entity)));

			setBranches(convertedBranchList);

			setLoading(false);
		} catch (error) {
			console.error("Error fetching entity list:", error);
			setApiError(error.message);
			setLoading(false);
		}
	};

	useEffect(() => {
		if (entityId && !branchId) {
			getBranches(); // This will be called whenever entityId has a value
		} // This will be called whenever entityId changes
	}, [entityId]);

	const userData = getUserPersistedOnLocalStorage();
	useEffect(() => {
		const getEntities = async () => {
			try {
				const response = await axios.get(endpoints.entity.list);
				const data = response.data["data"];
				const convertedEntityList = data.map((entity: any) => EConvert.toEntity(JSON.stringify(entity)));

				setEntities(convertedEntityList);

				setLoading(false);
			} catch (error) {
				console.error("Error fetching entity list:", error);
				setApiError(error.message);
				setLoading(false);
			}
		};

		if (userData?.user_role === "PLATFORM_ADMIN") {
			getEntities();

			setIsPlatformAdmin(true);
		} else if (userData?.user_role === "ENTITY_OWNER") {
			setEntityId(userData?.entity_id ?? "");
			setIsEntityOwner(true);
		} else {
			setEntityId(userData?.entity_id ?? "");
			setBranchId(userData?.branch_id ?? "");
			setLoading(false);
		}

		// setLoading(false);
		TimezoneData.timezones.map((timezone: Timezone, index: number) =>
			timezone.utc.map((tz) => {
				if (tz === tzmoment.tz.guess()) {
					setUtcTime(timezone.text);
				}
			})
		);
	}, []);

	const handleEntitySelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setEntityId(event.target.value);
		setBranchId(null);
		// getBranches(); // Update the state with the selected value
	};

	const handleBranchSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setBranchId(event.target.value);
		// getBranches(); // Update the state with the selected value
	};
	const {
		register,
		handleSubmit,
		watch,
		reset,
		control,
		setValue,
		setError,
		clearErrors,
		trigger,
		formState: { errors },
	} = useForm<StaffSchemaType>({ resolver: zodResolver(StaffSchema) });

	useEffect(() => {
		const countryCode = getCountryCallingCode("IN");
		setValue("phoneCode", countryCode?.toString() ?? "");
		setValue("phone", "");
	}, []);

	const onSubmit: SubmitHandler<StaffSchemaType> = async (data) => {
		setLoading(true);
		try {
			const combinedData = { ...formData, ...data };

			const phoneData = convertPhoneNumberAndCodeToNumber({
				phoneNumber: combinedData.phone,
				phoneCode: combinedData.phoneCode,
			});

			StaffSchema.parse(combinedData);

			const response = await axios.post(endpoints.staff.onboard, {
				...combinedData,
				phone: phoneData.phoneNumber,
				phoneCode: phoneData.phoneCode,
			});
			if (response.data.status === 200)
				toast.success(response.data.message === "success" ? "Staff updated successfully" : response.data.message);

			setLoading(false);

			navigator("/staff");
		} catch (error) {
			setLoading(false);
			toast.error(error.response?.data.data);
			console.error("Error submitting form:", error.response.data.data);
		}
	};

	const StepComponent = steps[currentStep];

	// const StepTitles = ['Details', 'Configuration', 'Declaration'];
	const StepTitles = ["Details"];

	function checkDuplicateEmail() {
		var email = watch("email");

		if (email.trim() !== "")
			if (email) {
				const paramsMap = new Map<string, string>([["email", watch("email")]]);
				const filteredEndpoint = replaceParams(endpoints.staff.checkStaffEmail, paramsMap);
				axios
					.get(filteredEndpoint)
					.then((res) => {
						clearErrors("email");
					})
					.catch((error) => {
						if (error && error.response && error.response.data && error.response.data.status === 500) {
							setError("email", {
								type: "custom",
								message: "Account with this email already exists. Please contact support.",
							});
							setValue("email", "");
						} else {
							toast.error(error.message);
							setValue("email", "");
						}
					});
			}
	}

	return (
		<>
			<div className="flex flex-col w-full h-full">
				<div className="flex flex-col justify-start">
					<div className="flex flex-row justify-between">
						<h1 className="text-2xl font-bold">Add Staff</h1>
						<Button
							variant={ButtonVariant.PRIMARY}
							type="submit"
							className="ml-3"
							onClick={() => {
								navigator("/staff");
							}}
						>
							Back
						</Button>
					</div>
					<span className="text-gray-500">Add a new staff to the system</span>
				</div>

				<hr className="my-4" />

				<div className="flex flex-row justify-between md:justify-start items-center w-full">
					{StepTitles.map((title, index) => (
						<div
							key={index}
							className={`flex flex-row py-2 px-3 mx-2 items-center justify-center rounded-xl ${
								index === currentStep ? "bg-primary-100 text-primary" : "bg-gray-200 text-gray-500"
							}`}
						>
							<div className="flex flex-col mr-2 h-6 w-6 rounded-full bg-primary-600 text-white text-sm text-primary justify-center items-center">
								<span>{index + 1}</span>
							</div>
							<span>{title}</span>
						</div>
					))}
				</div>

				<hr className="my-4" />

				<form onSubmit={handleSubmit(onSubmit)} noValidate>
					<div className="mb-32">
						<div>
							{loading && <p>Loading...</p>}
							{error && <p>Error: {error}</p>}
							<div className="flex flex-col w-full space-y-4">
								{/* Show entity selection only for Platform Admin */}
								{!loading && !error && entities && isPlatformAdmin && (
									<div className="flex flex-col">
										<Applabel label="Select Entity" mandatory />
										<select
											{...register("entityId")}
											onChange={handleEntitySelectChange}
											name="entityId"
											className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
										>
											<option key="" value="">
												Select
											</option>
											{entities.map((entity) => (
												<option key={entity.id} value={entity.id}>
													{entity.name}
												</option>
											))}
										</select>
										{errors.entityId && <ValidationError message={errors.entityId?.message?.toString() ?? ""} />}
									</div>
								)}

								{/* Location Details card - shown for all roles */}
								{!loading && !error && (
									<div className="p-4 border rounded-lg shadow-md w-full mb-4">
										<span className="text-lg font-medium">Location Details</span>

										<div className="flex flex-col md:flex-row w-full mt-2 md:space-x-4">
											{/* Branch Selection - Only for Entity Owner */}
											{isEntityOwner && branches && (
												<div className="flex flex-col w-full md:w-1/2">
													<Applabel label="Select Branch" mandatory />
													<select
														{...register("entityBranchId")}
														onChange={handleBranchSelectChange}
														name="entityBranchId"
														className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
													>
														<option key="" value="">
															Select
														</option>
														{branches.map((branch) => (
															<option key={branch.id} value={branch.id}>
																{branch.name}
															</option>
														))}
													</select>
													{errors.entityBranchId && (
														<ValidationError message={errors.entityBranchId?.message?.toString() ?? ""} />
													)}
												</div>
											)}

											{/* Timezone - Shown for all roles */}
											<div className="flex flex-col w-full md:w-1/2">
												<Applabel label="Timezone" mandatory />
												<select
													{...register("timezone")}
													value={watch("timezone")}
													className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
												>
													<option value={tzmoment.tz.guess()}>
														{tzmoment.tz.guess()} - {utcTime}
													</option>
													{TimezoneData.timezones.map((timezone: Timezone, index: number) =>
														timezone.utc.map((tz) => (
															<option key={tz} value={tz}>
																{tz} - {timezone.text}
															</option>
														))
													)}
												</select>
											</div>
										</div>

										{/* Hidden inputs for non-platform admin users */}
										{!isPlatformAdmin && <input {...register("entityId")} type="hidden" value={userData?.entity_id} />}
										{!isPlatformAdmin && !isEntityOwner && (
											<input {...register("entityBranchId")} type="hidden" value={userData?.branch_id} />
										)}
									</div>
								)}
							</div>
							{!loading && !error && entityId && branchId && (
								<div className={entityId === "" ? "invisible" : branchId === "" ? "invisible" : "visible"}>
									<div className="flex flex-row md:flex-row w-full justify-start items-start mt-0 md:space-x-4">
										<div className="flex flex-col w-full">
											<div className="p-4 border rounded-lg shadow-md w-full mb-5">
												<span className="text-lg font-medium">Personal Information</span>
												<div className="flex flex-col md:flex-row w-full mt-2 md:space-x-4">
													<div className="flex flex-col w-full md:w-1/5">
														<Applabel label="Title" mandatory />
														<select
															{...register("salutation")}
															placeholder="Salutation"
															className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
															value={watch("salutation")}
														>
															<option key="" value="">
																Select
															</option>
															{["Mr", "Mrs", "Ms", "Miss", "Dr", "Prof", "Rev", "Hon", "Capt"].map((salutation) => (
																<option key={salutation} value={salutation}>
																	{salutation}
																</option>
															))}
														</select>
														{errors.salutation && (
															<ValidationError message={errors.salutation?.message?.toString() ?? ""} />
														)}
													</div>

													<div className="flex flex-col w-full md:w-1/5">
														<Applabel label="First Name" mandatory />
														<input
															{...register("firstName")}
															placeholder="Staff First Name"
															value={watch("firstName")}
															type="text"
															className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
														/>
														{errors.firstName && (
															<ValidationError message={errors.firstName?.message?.toString() ?? ""} />
														)}
													</div>
													<div className="flex flex-col w-full md:w-1/5">
														<Applabel label="Last Name" mandatory />
														<input
															{...register("lastName")}
															className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
															placeholder="Staff Last Name"
															value={watch("lastName")}
														/>
														{errors.lastName && (
															<ValidationError message={errors.lastName?.message?.toString() ?? ""} />
														)}
													</div>

													<div className="flex flex-col w-full md:w-1/5">
														<Applabel label="Date of Birth" />
														<input
															{...register("dob")}
															className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
															type="date"
															value={watch("dob")}
														/>
														{errors.dob && <ValidationError message={errors.dob?.message?.toString() ?? ""} />}
													</div>
													<div className="flex flex-col w-full md:w-1/5">
														<Applabel label="Gender" />
														<select
															{...register("gender")}
															className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
															value={watch("gender")}
														>
															<option key="" value="">
																Select
															</option>
															{["Male", "Female", "Other"].map((gender) => (
																<option key={gender} value={gender}>
																	{gender}
																</option>
															))}
														</select>
														{errors.gender && <ValidationError message={errors.gender?.message?.toString() ?? ""} />}
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4">
										<div className="flex flex-col w-full">
											<div className="p-4 border rounded-lg shadow-md w-full mb-5">
												<span className="text-lg font-medium">Contact Details</span>
												<div className="flex flex-col md:flex-row w-full mt-2 md:space-x-4">
													<div className="flex flex-col w-full md:w-1/2">
														<Applabel label="Email" mandatory />
														<input
															{...register("email")}
															placeholder="Staff Email"
															onBlur={checkDuplicateEmail}
															className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
														/>
														{errors.email && <ValidationError message={errors.email?.message?.toString() ?? ""} />}
													</div>
													<div className="flex flex-col w-full md:w-1/2">
														<Applabel label="Mobile Number" />
														<PhoneInput
															{...register("phone")}
															international={true}
															defaultCountry="IN"
															placeholder="Enter phone number"
															className="w-full"
															onChange={(value) => {
																clearErrors("phone");
																handlePhoneNumberChange(value);
															}}
														/>
														{errors.phone && <ValidationError message={errors.phone?.message?.toString() ?? ""} />}
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="p-4 border rounded-lg shadow-md w-full mb-5">
										<span className="flex-none text-lg font-medium w-1/6 mb-0">Address</span>

										<div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4">
											<div className="flex flex-col grow">
												<Applabel label="Address" mandatory />
												<input
													{...register("address.address")}
													placeholder="Staff Address"
													className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
													required
												/>
												{errors.address?.address && (
													<ValidationError message={errors.address?.address?.message?.toString() ?? ""} />
												)}
											</div>
											<div className="flex flex-col grow">
												<Applabel label="Postal/Zip code" />
												<input
													{...register("address.postalCode")}
													placeholder="Staff Zip Code"
													className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
													required
													value={watch("address.postalCode")}
												/>
												{errors.address?.postalCode && (
													<ValidationError message={errors.address.postalCode?.message?.toString() ?? ""} />
												)}
											</div>
										</div>
										<CountryInputSelect
											isDisabled={false}
											register={register}
											setValue={setValue}
											watch={watch}
											country="address.country"
											state="address.state"
											city="address.city"
										/>
									</div>

									<div className="p-4 border rounded-lg shadow-md w-full mb-5">
										<span className="text-lg font-medium">Role Information</span>
										<div className="flex flex-col md:flex-row w-full mt-2 md:space-x-4">
											<div className="flex flex-col w-full md:w-1/3">
												<Applabel label="Select Role" mandatory />
												<select
													{...register("role.role")}
													name="role.role"
													className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
												>
													<option key="" value="">
														Select
													</option>
													{Array.from(Roles.entries()).map(([key, value]) => (
														<option key={value} value={value}>
															{key}
														</option>
													))}
												</select>
												{errors.role && <ValidationError message={errors.role?.role?.message?.toString() ?? ""} />}
											</div>

											<div className="flex flex-col w-full md:w-1/3">
												<Applabel label="Designation" mandatory />
												<input
													{...register("designation")}
													placeholder="Staff Designation"
													className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
												/>
												{errors.designation && (
													<ValidationError message={errors.designation?.message?.toString() ?? ""} />
												)}
											</div>
											<div className="flex flex-col w-full md:w-1/3">
												<Applabel label="Password" mandatory />
												<input
													{...register("password")}
													placeholder="Staff password"
													className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
													value={watch("password")}
													required
												/>
												{errors.password && <ValidationError message={errors.password?.message?.toString() ?? ""} />}
											</div>
										</div>
									</div>

									<div className="h-20"></div>
									<div className="fixed items-center w-full justify-end bottom-0 h-16 z-50 shrink-0 mt-4 p-4 -ml-8 shadow bg-gray-100">
										<div className="flex flex-row ">
											<div>
												<Button
													variant={ButtonVariant.PRIMARY}
													type="submit"
													className="ml-3"
													onClick={handleSubmit(onSubmit)}
												>
													Save
												</Button>
											</div>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</form>
			</div>
		</>
	);
}

export default AddStaffPage;
