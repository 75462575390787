import React, { useCallback, useEffect, useState } from "react";
import { Convert, PatientStatistics } from "../types/PatientStats";
import { endpoints, replaceParams } from "@utils/axios";
import { Conversion, AppointmentStatistics } from "../types/AppointmentStats";
import AppointmentDashboardCards from "./AppointmentDashboardCards";
import moment from "moment";
import useAxios from "@routes/hooks/use-axios";
import OutlinedButton, { ButtonVariant } from "@components/button/outline-button";

import BookAppointmentPage from "@pages/appointments/book-appointment/BookAppointmentPage";
import { QuickPatientAddDialog } from "@pages/appointments/book-appointment/QuickPatientAddDialog";
import { useNavigate } from "react-router-dom";
import { paths } from "@routes/paths";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import { Tab, Switch } from "@headlessui/react";
import { EntityBranch, EntityBranchConvert } from "../types/EntityBranch";
import { CurrencyRupeeIcon, SquaresPlusIcon } from "@heroicons/react/24/solid";
import {
	ActivityIcon,
	Banknote,
	BanknoteIcon,
	CheckCircle,
	ClockIcon,
	HourglassIcon,
	TrendingUp,
	UserCheckIcon,
} from "lucide-react";
import RightOffcanvas from "@components/right-offcanvas/right-offcanvas";
import InvoicePage from "@pages/finances/view/InvoicePage";

import { Calendar, Clock, UserCheck, XCircle, UserX, Users, PlusCircle, ArrowRightCircle } from "lucide-react";
import StatCard, { StatCardMini } from "./StatCard";
import { PageHeader } from "@components/ui/PageHeader";
import CheckInPage from "@pages/appointments/walkin-appointment/CheckIn";
import { toast } from "react-toastify";
import AppointmentTableRow from "./AppointmentTableRow";
import { AppointmentStatus } from "../types/AppointmentStatus";
import { Appointment, Convert as AppointmentConvert } from "@pages/appointments/types/Appointment";
import { Button } from "@components/button";

const AssistantDashboardV2 = () => {
	const [patientStat, setPatientStats] = React.useState<PatientStatistics | null>(null);
	const [AppointmentStat, setAppointmentStats] = React.useState<AppointmentStatistics | null>(null);
	const axios = useAxios();

	const [scheduledAppointmentList, setScheduledAppointmentList] = React.useState<Appointment[]>([]);
	const [waitingAppointmentList, setWaitingAppointmentList] = React.useState<Appointment[]>([]);
	const [ongoingAppointmentList, setOngoingAppointmentList] = React.useState<Appointment[]>([]);
	const [completedAppointmentList, setCompletedAppointmentList] = React.useState<Appointment[]>([]);
	const [quickPatientAddDialog, setQuickPatientAddDialog] = React.useState(false);
	const userData = getUserPersistedOnLocalStorage();
	const [branches, setBranches] = React.useState<EntityBranch[]>([]);
	const [selectedBranchId, setSelectedBranchId] = React.useState<string>(userData?.branch_id ?? "0");

	const [selectedDate, setSelectedDate] = useState(moment(new Date()).format("DD-MM-YYYY"));

	const naviagtor = useNavigate();

	const [trackWaitingTime, setTrackWaitingTime] = useState(false);
	const [cardView, setCardView] = useState(false);
	const navigateBilling = () => {
		naviagtor(paths.billing.index.route, {});
	};

	const getScheduledAppointmentList = async () => {
		let paramsMap: Map<string, string>;

		paramsMap = new Map<string, string>([["id", userData?.branch_id ?? ""]]);

		const filteredEndpoint = replaceParams(endpoints.assistantDashboard.appointmentListByStatus, paramsMap);

		const response = await axios.get(filteredEndpoint, {
			params: {
				date: selectedDate,
				status: "Scheduled",
			},
		});
		const data: Appointment[] = response.data["data"];
		const toSet: Appointment[] = [];
		if (data != null)
			for (const appointment of data) {
				const converted = AppointmentConvert.toAppointment(JSON.stringify(appointment));
				let duration = appointment.endTimeMinutes - appointment.startTimeMinutes;

				converted.apptdate = new Date(moment(converted.apptdate).toLocaleString());

				converted.startTime = moment(converted.apptdate).format("hh:mm A");
				converted.endTime = moment(appointment.startTime, "hh:mm A").add(duration, "minutes").format("hh:mm A");
				converted.startTimeMinutes = converted.apptdate.getHours() * 60 + converted.apptdate.getMinutes();
				converted.endTimeMinutes = converted.apptdate.getHours() * 60 + converted.apptdate.getMinutes() + duration;
				toSet.push(converted);
			}
		setScheduledAppointmentList(toSet);
	};

	const getScheduledAppointmentListForEntityOwner = async () => {
		let paramsMap: Map<string, string>;
		paramsMap = new Map<string, string>([["id", selectedBranchId]]);

		const filteredEndpoint = replaceParams(endpoints.assistantDashboard.appointmentListByStatus, paramsMap);

		const response = await axios.get(filteredEndpoint, {
			params: {
				date: selectedDate,
				status: "Scheduled",
			},
		});
		const data: Appointment[] = response.data["data"];
		const toSet: Appointment[] = [];
		if (data != null)
			for (const appointment of data) {
				const converted = AppointmentConvert.toAppointment(JSON.stringify(appointment));
				let duration = appointment.endTimeMinutes - appointment.startTimeMinutes;

				converted.apptdate = new Date(moment(converted.apptdate).toLocaleString());

				converted.startTime = moment(converted.apptdate).format("hh:mm A");
				converted.endTime = moment(appointment.startTime, "hh:mm A").add(duration, "minutes").format("hh:mm A");
				converted.startTimeMinutes = converted.apptdate.getHours() * 60 + converted.apptdate.getMinutes();
				converted.endTimeMinutes = converted.apptdate.getHours() * 60 + converted.apptdate.getMinutes() + duration;
				toSet.push(converted);
			}
		setScheduledAppointmentList(toSet);
	};

	const getWaitingAppointmentList = async () => {
		let paramsMap: Map<string, string>;

		paramsMap = new Map<string, string>([["id", userData?.branch_id ?? ""]]);

		const filteredEndpoint = replaceParams(endpoints.assistantDashboard.appointmentListByStatus, paramsMap);

		const response = await axios.get(filteredEndpoint, {
			params: {
				date: selectedDate,
				status: "Waiting",
			},
		});
		const data: Appointment[] = response.data["data"];
		const toSet: Appointment[] = [];
		if (data != null)
			for (const appointment of data) {
				const converted = AppointmentConvert.toAppointment(JSON.stringify(appointment));
				let duration = appointment.endTimeMinutes - appointment.startTimeMinutes;

				converted.apptdate = new Date(moment(converted.apptdate).toLocaleString());

				converted.startTime = moment(converted.apptdate).format("hh:mm A");
				converted.endTime = moment(appointment.startTime, "hh:mm A").add(duration, "minutes").format("hh:mm A");
				converted.startTimeMinutes = converted.apptdate.getHours() * 60 + converted.apptdate.getMinutes();
				converted.endTimeMinutes = converted.apptdate.getHours() * 60 + converted.apptdate.getMinutes() + duration;
				toSet.push(converted);
			}
		setWaitingAppointmentList(toSet);
	};

	const getWaitingAppointmentListForEntityOwner = async () => {
		let paramsMap: Map<string, string>;

		paramsMap = new Map<string, string>([["id", selectedBranchId]]);

		const filteredEndpoint = replaceParams(endpoints.assistantDashboard.appointmentListByStatus, paramsMap);

		const response = await axios.get(filteredEndpoint, {
			params: {
				date: selectedDate,
				status: "Waiting",
			},
		});
		const data: Appointment[] = response.data["data"];
		const toSet: Appointment[] = [];
		if (data != null)
			for (const appointment of data) {
				const converted = AppointmentConvert.toAppointment(JSON.stringify(appointment));
				let duration = appointment.endTimeMinutes - appointment.startTimeMinutes;

				converted.apptdate = new Date(moment(converted.apptdate).toLocaleString());

				converted.startTime = moment(converted.apptdate).format("hh:mm A");
				converted.endTime = moment(appointment.startTime, "hh:mm A").add(duration, "minutes").format("hh:mm A");
				converted.startTimeMinutes = converted.apptdate.getHours() * 60 + converted.apptdate.getMinutes();
				converted.endTimeMinutes = converted.apptdate.getHours() * 60 + converted.apptdate.getMinutes() + duration;
				toSet.push(converted);
			}
		setWaitingAppointmentList(toSet);
	};

	const getOngoingAppointmentList = async () => {
		let paramsMap: Map<string, string>;

		paramsMap = new Map<string, string>([["id", userData?.branch_id ?? ""]]);

		const filteredEndpoint = replaceParams(endpoints.assistantDashboard.appointmentListByStatus, paramsMap);

		const response = await axios.get(filteredEndpoint, {
			params: {
				date: selectedDate,
				status: "InSession",
			},
		});
		const data: Appointment[] = response.data["data"];
		const toSet: Appointment[] = [];
		if (data != null)
			for (const appointment of data) {
				const converted = AppointmentConvert.toAppointment(JSON.stringify(appointment));
				let duration = appointment.endTimeMinutes - appointment.startTimeMinutes;

				converted.apptdate = new Date(moment(converted.apptdate).toLocaleString());

				converted.startTime = moment(converted.apptdate).format("hh:mm A");
				converted.endTime = moment(appointment.startTime, "hh:mm A").add(duration, "minutes").format("hh:mm A");
				converted.startTimeMinutes = converted.apptdate.getHours() * 60 + converted.apptdate.getMinutes();
				converted.endTimeMinutes = converted.apptdate.getHours() * 60 + converted.apptdate.getMinutes() + duration;
				toSet.push(converted);
			}
		setOngoingAppointmentList(toSet);
	};

	const getOngoingAppointmentListForEntityOwner = async () => {
		let paramsMap: Map<string, string>;

		paramsMap = new Map<string, string>([["id", selectedBranchId]]);

		const filteredEndpoint = replaceParams(endpoints.assistantDashboard.appointmentListByStatus, paramsMap);

		const response = await axios.get(filteredEndpoint, {
			params: {
				date: selectedDate,
				status: "InSession",
			},
		});
		const data: Appointment[] = response.data["data"];
		const toSet: Appointment[] = [];
		if (data != null)
			for (const appointment of data) {
				const converted = AppointmentConvert.toAppointment(JSON.stringify(appointment));
				let duration = appointment.endTimeMinutes - appointment.startTimeMinutes;

				converted.apptdate = new Date(moment(converted.apptdate).toLocaleString());

				converted.startTime = moment(converted.apptdate).format("hh:mm A");
				converted.endTime = moment(appointment.startTime, "hh:mm A").add(duration, "minutes").format("hh:mm A");
				converted.startTimeMinutes = converted.apptdate.getHours() * 60 + converted.apptdate.getMinutes();
				converted.endTimeMinutes = converted.apptdate.getHours() * 60 + converted.apptdate.getMinutes() + duration;
				toSet.push(converted);
			}
		setOngoingAppointmentList(toSet);
	};

	const getCompletedAppointmentList = async () => {
		let paramsMap: Map<string, string>;

		paramsMap = new Map<string, string>([["id", userData?.branch_id ?? ""]]);

		const filteredEndpoint = replaceParams(endpoints.assistantDashboard.appointmentListByStatus, paramsMap);

		const response = await axios.get(filteredEndpoint, {
			params: {
				date: selectedDate,
				status: "Completed",
			},
		});
		const data: Appointment[] = response.data["data"];
		const toSet: Appointment[] = [];
		if (data != null)
			for (const appointment of data) {
				const converted = AppointmentConvert.toAppointment(JSON.stringify(appointment));
				let duration = appointment.endTimeMinutes - appointment.startTimeMinutes;

				converted.apptdate = new Date(moment(converted.apptdate).toLocaleString());

				converted.startTime = moment(converted.apptdate).format("hh:mm A");
				converted.endTime = moment(appointment.startTime, "hh:mm A").add(duration, "minutes").format("hh:mm A");
				converted.startTimeMinutes = converted.apptdate.getHours() * 60 + converted.apptdate.getMinutes();
				converted.endTimeMinutes = converted.apptdate.getHours() * 60 + converted.apptdate.getMinutes() + duration;
				toSet.push(converted);
			}
		setCompletedAppointmentList(toSet);
	};

	const getCompletedAppointmentListForEntityOwner = async () => {
		let paramsMap: Map<string, string>;

		paramsMap = new Map<string, string>([["id", selectedBranchId]]);

		const filteredEndpoint = replaceParams(endpoints.assistantDashboard.appointmentListByStatus, paramsMap);

		const response = await axios.get(filteredEndpoint, {
			params: {
				date: selectedDate,
				status: "Completed",
			},
		});
		const data: Appointment[] = response.data["data"];
		const toSet: Appointment[] = [];
		if (data != null)
			for (const appointment of data) {
				const converted = AppointmentConvert.toAppointment(JSON.stringify(appointment));
				let duration = appointment.endTimeMinutes - appointment.startTimeMinutes;

				converted.apptdate = new Date(moment(converted.apptdate).toLocaleString());

				converted.startTime = moment(converted.apptdate).format("hh:mm A");
				converted.endTime = moment(appointment.startTime, "hh:mm A").add(duration, "minutes").format("hh:mm A");
				converted.startTimeMinutes = converted.apptdate.getHours() * 60 + converted.apptdate.getMinutes();
				converted.endTimeMinutes = converted.apptdate.getHours() * 60 + converted.apptdate.getMinutes() + duration;
				toSet.push(converted);
			}
		setCompletedAppointmentList(toSet);
	};

	const getPatientStatus = async () => {
		let paramsMap: Map<string, string>;

		paramsMap = new Map<string, string>([["id", userData?.branch_id ?? ""]]);

		const filteredEndpoint = replaceParams(endpoints.assistantDashboard.patientsCount, paramsMap);
		try {
			const response = await axios.get(filteredEndpoint, {
				params: {
					requestedDate: selectedDate,
				},
			});
			const data = response.data["data"];

			const converted = Convert.toPatientStatistics(JSON.stringify(data));
			const patientStats = converted;
			setPatientStats(patientStats);
		} catch (error) {
			console.error(error);
		}
	};

	const getPatientStatusForEntityOwner = async () => {
		let paramsMap: Map<string, string>;

		paramsMap = new Map<string, string>([["id", selectedBranchId]]);

		const filteredEndpoint = replaceParams(endpoints.assistantDashboard.patientsCount, paramsMap);
		try {
			const response = await axios.get(filteredEndpoint, {
				params: {
					requestedDate: selectedDate,
				},
			});
			const data = response.data["data"];

			const converted = Convert.toPatientStatistics(JSON.stringify(data));
			const patientStats = converted;
			setPatientStats(patientStats);
		} catch (error) {
			console.error(error);
		}
	};

	const getAppointmentStatus = async () => {
		try {
			let paramsMap: Map<string, string>;

			paramsMap = new Map<string, string>([["id", userData?.branch_id ?? ""]]);

			const filteredEndpoint = replaceParams(endpoints.assistantDashboard.appointmentsCount, paramsMap);

			const response = await axios.get(filteredEndpoint, {
				params: {
					requestedDate: selectedDate,
				},
			});
			const data = response.data["data"];

			const converted = Conversion.toAppointmentStatistics(JSON.stringify(data));
			const appointmentStats = converted;
			setAppointmentStats(appointmentStats);
		} catch (error) {}
	};

	const getAppointmentStatusForEntityOwner = async () => {
		try {
			let paramsMap: Map<string, string>;

			paramsMap = new Map<string, string>([["id", selectedBranchId]]);

			const filteredEndpoint = replaceParams(endpoints.assistantDashboard.appointmentsCount, paramsMap);

			const response = await axios.get(filteredEndpoint, {
				params: {
					requestedDate: selectedDate,
				},
			});
			const data = response.data["data"];

			const converted = Conversion.toAppointmentStatistics(JSON.stringify(data));
			const appointmentStats = converted;
			setAppointmentStats(appointmentStats);
		} catch (error) {}
	};

	const checkIn = () => {
		if (userData?.user_role === "ENTITY_OWNER") {
			getScheduledAppointmentListForEntityOwner();
			getWaitingAppointmentListForEntityOwner();
		} else {
			getScheduledAppointmentList();
			getWaitingAppointmentList();
		}
	};

	const inSession = () => {
		if (userData?.user_role === "ENTITY_OWNER") {
			getWaitingAppointmentListForEntityOwner();
			getOngoingAppointmentListForEntityOwner();
		} else {
			getWaitingAppointmentList();
			getOngoingAppointmentList();
		}
	};

	const completed = () => {
		if (userData?.user_role === "ENTITY_OWNER") {
			getOngoingAppointmentListForEntityOwner();
			getCompletedAppointmentListForEntityOwner();
			getAppointmentStatusForEntityOwner();
		} else {
			getOngoingAppointmentList();
			getCompletedAppointmentList();
			getAppointmentStatus();
		}
	};

	const callBackCompleted = () => {
		if (userData?.user_role === "ENTITY_OWNER") {
			getScheduledAppointmentListForEntityOwner();
			getWaitingAppointmentListForEntityOwner();
			getOngoingAppointmentListForEntityOwner();
			getCompletedAppointmentListForEntityOwner();
			getAppointmentStatusForEntityOwner();
		} else {
			getScheduledAppointmentList();
			getWaitingAppointmentList();
			getOngoingAppointmentList();
			getCompletedAppointmentList();
			getAppointmentStatus();
		}
	};

	// below is the api call to get the appointmentstatus and patient status in a react hook
	useEffect(() => {
		if (userData?.user_role === "ENTITY_OWNER") {
			getAppointmentStatusForEntityOwner();
			getPatientStatusForEntityOwner();
			getScheduledAppointmentListForEntityOwner();
			getWaitingAppointmentListForEntityOwner();
			getOngoingAppointmentListForEntityOwner();
			getCompletedAppointmentListForEntityOwner();
		} else {
			getAppointmentStatus();
			getPatientStatus();
			getScheduledAppointmentList();
			getWaitingAppointmentList();
			getOngoingAppointmentList();
			getCompletedAppointmentList();
		}
	}, [selectedDate, selectedBranchId]);

	const appointmentData = AppointmentStat;
	const [bookAppointmentDialog, setBookAppointmentDialog] = useState(false);
	const [checkInDialog, setCheckInDialog] = useState(false);

	function classNames(...classes: string[]) {
		return classes.filter(Boolean).join(" ");
	}

	const getBranches = useCallback(async () => {
		const paramsMap = new Map<string, string>([["entityId", userData?.entity_id ?? ""]]);
		const filteredEndpoint = replaceParams(endpoints.ownerDashboard.branches, paramsMap);
		const response = await axios.get(filteredEndpoint);
		const data = response.data["data"];
		const converted = [];

		for (const branch of data) {
			const temp = EntityBranchConvert.toEntityBranch(JSON.stringify(branch));
			/* 			if (temp.id === userData?.branch_id) {
				setTrackWaitingTime(temp.sessionTimeTracker);
			} */
			converted.push(temp);
		}

		setBranches(converted);
	}, [axios]);

	useEffect(() => {
		getBranches();

		  const handleResize = () => {
				// Set to card view when screen width is less than 768px (md breakpoint)
				setCardView(window.innerWidth < 768);
			};

			// Initial check
			handleResize();

			// Add event listener for window resize
			window.addEventListener("resize", handleResize);

			// Clean up event listener on component unmount
			return () => {
				window.removeEventListener("resize", handleResize);
			};
		
	}, []);

	const handleSelectChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
		const selectedBranchId = event.target.value;
		for (const branch of branches) {
			/* 			if (branch.id === selectedBranchId) {
				setTrackWaitingTime(branch.sessionTimeTracker);
			} */
		}
		setSelectedBranchId(selectedBranchId);
	};

	const [newBill, setNewBill] = React.useState(false);
	function createNewBill(): void {
		setNewBill(true);
	}

	const navigate = useNavigate();

	// Function to update the session time tracker status
	/* 	const updateSessionTimeTracker = async (status: boolean) => {
		try {
			const userData = getUserPersistedOnLocalStorage();
			const paramsMap = new Map<string, string>([
				["entityId", userData?.entity_id!],
				["branchId", selectedBranchId!],
				["sessionTimeTracker", status.toString()],
			]);
			const filteredEndpoint = replaceParams(endpoints.branch.updateSessionTimeTracker, paramsMap);
			const response = await axios.put(filteredEndpoint);
			if (response.status === 200) {
				toast.success("Waiting/In-Session time tracking updated successfully");
			}
		} catch (error) {
			console.error("Error updating session time tracker:", error);
		}
	}; */

	// Handle toggle change
	/* 	const handleToggleChange = (checked: boolean) => {
		setTrackWaitingTime(checked);
		updateSessionTimeTracker(checked); // Call the API to update the status
	}; */

	return (
		<div className="flex flex-col mx-2">
			<div className="flex flex-col sm:flex-row justify-between mb-2 	">
				<div className="flex flex-row w-full md:w-1/4">
					<PageHeader title="Appointment Queue" />
				</div>
				<div className="flex flex-wrap md:gap-2 w-full md:w-3/4 md:justify-end gap-y-2 items-center justify-between">
					{/*               <OutlinedButton
              variant={ButtonVariant.PRIMARY}
              type="button"
              onClick={() => setBookAppointmentDialog(true)}
              className="w-full justify-center"
            >
              <SquaresPlusIcon className="w-5 h-5 text-primary-500" />
              <span className="pl-1 text-xs"> Book Appointment </span>
            </OutlinedButton> */}

					<OutlinedButton
						variant={ButtonVariant.PRIMARY}
						className="justify-center sm:justify-normal w-1/2 md:w-fit"
						onClick={() => {
							navigate(paths.calander.single.route);
						}}
					>
						<SquaresPlusIcon className="w-5 h-5 text-primary-500" />
						<span className="pl-1 text-xs"> Book Appointment </span>
					</OutlinedButton>

					<OutlinedButton
						variant={ButtonVariant.SECONDARY}
						type="button"
						onClick={navigateBilling}
						className="justify-center sm:justify-normal w-1/2 md:w-fit"
					>
						<CurrencyRupeeIcon className="w-5 h-5 text-secondary-500" />
						<span className="pl-1 text-xs"> Billing </span>
					</OutlinedButton>
					<OutlinedButton
						variant={ButtonVariant.TERTIARY}
						type="button"
						onClick={() => createNewBill()}
						className="justify-center sm:justify-normal w-1/2 md:w-fit"
					>
						<BanknoteIcon className="w-5 h-5 text-secondary-500" />
						<span className="pl-1 text-xs"> New Bill </span>
					</OutlinedButton>

					{userData?.user_role === "ENTITY_OWNER" ? (
						<div className="w-full md:w-fit">
							<select
								className="w-full h-full border border-1 rounded-md cursor-pointer text-sm"
								disabled={false}
								onChange={handleSelectChange}
							>
								<option key={"All"} value={"0"}>
									All Branches
								</option>
								{branches.map((branch) => (
									<option key={branch.id} value={branch.id}>
										{branch.name}
									</option>
								))}
							</select>
						</div>
					) : (
						<div></div>
					)}
				</div>
			</div>
			<hr className="my-2 border-gray-100 shadow-sm"></hr>
			<div className="flex flex-col">
				{/* Statistics Grid */}
				<div className="grid grid-cols-3 md:grid-cols-5 gap-4">
					<StatCardMini
						icon={TrendingUp}
						label="Total"
						value={`${
							(scheduledAppointmentList.length ?? 0) +
							(waitingAppointmentList.length ?? 0) +
							(ongoingAppointmentList.length ?? 0) +
							(completedAppointmentList.length ?? 0)
						}`}
						color="bg-slate-700"
						textColor="text-slate-700"
					/>
					<StatCardMini
						icon={ClockIcon}
						label="Scheduled"
						value={`${scheduledAppointmentList.length ?? 0}`}
						color="bg-blue-500"
						textColor="text-blue-500"
					/>
					<StatCardMini
						icon={HourglassIcon}
						label="Waiting"
						value={`${waitingAppointmentList.length ?? 0}`}
						color="bg-amber-500"
						textColor="text-amber-500"
					/>
					<StatCardMini
						icon={ActivityIcon}
						label="In-Session"
						value={`${ongoingAppointmentList.length ?? 0}`}
						color="bg-purple-500"
						textColor="text-purple-500"
					/>
					<StatCardMini
						icon={TrendingUp}
						label="Completed"
						value={`${completedAppointmentList.length ?? 0}`}
						color="bg-emerald-500"
						textColor="text-emerald-500"
					/>
				</div>

				<div className="flex flex-col md:flex-row w-full justify-between bg-gray-50 pt-4 px-2 rounded-lg my-4 gap-2">
					<div className="flex flex-row font-semibold text-sm text-gray-600 gap-4">
						<Tab.Group>
							<Tab.List className="flex space-x-1 rounded-xl lg:ml-2">
								<Tab
									key={"Today"}
									onClick={() => {
										setSelectedDate(moment(new Date()).format("DD-MM-YYYY"));
									}}
									className={({ selected }) =>
										classNames(
											"w-full px-2 py-2 mb-4 text-sm text-gray-900 rounded-md border border-gray-300 focus:ring-0 focus:border-primary-500",
											selected ? "bg-white text-primary-700 shadow border-primary-500" : "text-primary-300"
										)
									}
								>
									Today
								</Tab>
								<Tab
									key={"Tomorrow"}
									onClick={() => {
										const currentDate = moment(new Date(), "DD-MM-YYYY");

										const nextDate = currentDate.add(1, "days");

										setSelectedDate(nextDate.format("DD-MM-YYYY"));
									}}
									className={({ selected }) =>
										classNames(
											"w-full px-2 py-2 mb-4 text-sm text-gray-900 rounded-md border border-gray-300 focus:ring-0 focus:border-primary-500",
											selected ? "bg-white text-primary-700 shadow border-primary-500" : "text-primary-300"
										)
									}
								>
									Tomorrow
								</Tab>
							</Tab.List>
						</Tab.Group>
						<span className="text-xs font-medium mt-2">
							{moment(selectedDate, "DD-MM-YYYY").format("dddd, DD-MMM-YYYY")}
						</span>
					</div>
					<div className="text-sm text-primary-800 font-semibold items-center justify-center">
						** Click on a row to open client details **
					</div>
					<div className="flex items-center justify-between  gap-4">
						<Button variant={ButtonVariant.PRIMARY} type="button" onClick={() => setCheckInDialog(true)} className="">
							<UserCheckIcon className="w-5 h-5 0" />
							<span className="pl-1 text-sm">Add Walk-Ins & Attendance</span>
						</Button>

						<span className="text-sm font-sem text-gray-600">Card View</span>
						<Switch
							checked={cardView}
							onChange={() => setCardView(!cardView)}
							className={`${
								cardView ? "bg-primary-600" : "bg-gray-200"
							} relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200 ease-in-out`}
						>
							<span
								className={`${
									cardView ? "translate-x-6" : "translate-x-1"
								} inline-block h-4 w-4 transform rounded-full bg-white transition duration-200 ease-in-out`}
							/>
						</Switch>
					</div>
					{/* Toggle for tracking waiting time */}
					{/* 					{(userData?.user_role === "ENTITY_OWNER" || userData?.user_role === "BRANCH_ADMIN") && (
						<div className="flex items-center justify-between mb-4 gap-4">
							<span className="text-sm font-normal text-gray-600">
								Enable Advanced Tracking - Waiting & In Session Stages
							</span>
							<Switch
								checked={trackWaitingTime}
								onChange={handleToggleChange}
								className={`${
									trackWaitingTime ? "bg-primary-600" : "bg-gray-200"
								} relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200 ease-in-out`}
							>
								<span
									className={`${
										trackWaitingTime ? "translate-x-6" : "translate-x-1"
									} inline-block h-4 w-4 transform rounded-full bg-white transition duration-200 ease-in-out`}
								/>
							</Switch>
						</div>
					)} */}
					{/* 					<div className="">
						<span className="text-gray-600 text-xs">**Click on the card to see Patient information</span>
					</div> */}
				</div>

				<div className="space-y-4">
					{/* Appointment Cards */}
					{cardView ? (
						<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
							<AppointmentDashboardCards
								appointmentStatus="Scheduled"
								buttonName="Check-in"
								appointmentList={scheduledAppointmentList}
								callback={checkIn}
								dateSwitcher={selectedDate}
								icon={ClockIcon}
								iconColor="text-primary-500"
							/>
							<AppointmentDashboardCards
								appointmentStatus="Waiting"
								buttonName="Start Session"
								appointmentList={waitingAppointmentList}
								callback={inSession}
								dateSwitcher={selectedDate}
								icon={HourglassIcon}
								iconColor="text-orange-500"
							/>
							<AppointmentDashboardCards
								appointmentStatus="InSession"
								buttonName="Mark Complete"
								appointmentList={ongoingAppointmentList}
								callback={completed}
								dateSwitcher={selectedDate}
								icon={ActivityIcon}
								iconColor="text-blue-800"
							/>
							<AppointmentDashboardCards
								appointmentStatus="Completed"
								buttonName="Bill Details"
								appointmentList={completedAppointmentList}
								callback={callBackCompleted}
								dateSwitcher={selectedDate}
								icon={CheckCircle}
								iconColor="text-green-800"
							/>
						</div>
					) : (
						<div className="grid grid-cols-1 md:grid-cols-1 gap-4 overflow-x-auto">
							<table className="min-w-full divide-y divide-gray-200">
								<thead className="bg-primary-50 py-2">
									<tr>
										<th className="px-4 py-2 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
											Client Name
										</th>
										<th className="px-4 py-2 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
											Scheduled Time
										</th>
										<th className="px-4 py-2 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
											Service
										</th>
										<th className="px-4 py-2 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
											Scheduled With
										</th>
										<th className="px-4 py-2 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
											Status
										</th>
										<th className="px-4 py-2 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
											Time Info
										</th>
										<th className="px-6 py-2 text-xs font-medium text-gray-700 uppercase tracking-wider items-center text-left">
											Actions
										</th>
									</tr>
								</thead>
								<AppointmentTableRow
									appointmentStatus={AppointmentStatus.Waiting}
									buttonName="Start Session"
									appointmentList={waitingAppointmentList}
									callback={inSession}
									callbackCompleted={callBackCompleted}
									dateSwitcher={selectedDate}
									icon={HourglassIcon}
									iconColor="text-orange-500"
								/>
								<AppointmentTableRow
									appointmentStatus={AppointmentStatus.InSession}
									buttonName="Mark Complete"
									appointmentList={ongoingAppointmentList}
									callback={completed}
									callbackCompleted={callBackCompleted}
									dateSwitcher={selectedDate}
									icon={ActivityIcon}
									iconColor="text-blue-800"
								/>
								<AppointmentTableRow
									appointmentStatus={AppointmentStatus.Scheduled}
									buttonName="Check-in"
									appointmentList={scheduledAppointmentList}
									callback={checkIn}
									callbackCompleted={callBackCompleted}
									dateSwitcher={selectedDate}
									icon={ClockIcon}
									iconColor="text-primary-500"
								/>
								<AppointmentTableRow
									appointmentStatus={AppointmentStatus.Completed}
									buttonName="Bill Details"
									appointmentList={completedAppointmentList}
									callback={callBackCompleted}
									callbackCompleted={callBackCompleted}
									dateSwitcher={selectedDate}
									icon={CheckCircle}
									iconColor="text-green-800"
								/>
							</table>
						</div>
					)}
				</div>
				{bookAppointmentDialog && (
					<BookAppointmentPage
						setBookAppointmentDialog={setBookAppointmentDialog}
						setQuickPatientAddDialog={setQuickPatientAddDialog}
						getAppointments={
							userData?.user_role === "ENTITY_OWNER"
								? getScheduledAppointmentListForEntityOwner
								: getScheduledAppointmentList
						}
					/>
				)}

				{checkInDialog && (
					<CheckInPage
						setCheckInDialog={setCheckInDialog}
						setQuickPatientAddDialog={setQuickPatientAddDialog}
						branchId={selectedBranchId}
						getAppointments={
							userData?.user_role === "ENTITY_OWNER"
								? getScheduledAppointmentListForEntityOwner
								: getScheduledAppointmentList
						}
					/>
				)}

				{quickPatientAddDialog && <QuickPatientAddDialog setQuickPatientAddDialog={setQuickPatientAddDialog} />}

				<RightOffcanvas
					isOpen={newBill}
					onClose={() => {
						setNewBill(false);
					}}
				>
					{newBill && (
						<>
							<InvoicePage
								clientName=""
								invoiceDetails={{
									invoiceNumber: "",
									invoiceDate: new Date(),
									dueDate: new Date(),
									entityId: userData?.entity_id,
									entityBranchId: userData?.branch_id,
									clientName: "",
									clientId: "",
									items: [],
									totalAmount: 0,
									status: "pending",
									billForAppointments: false,
									totalPaid: 0,
									tax: 0,
									outStandingAmount: 0,
									paymentDetails: [],
									discount: 0,
									subtotal: 0,
									paymentMode: "",
									notes: "",
									taxes: [],
								}}
								onClose={() => setNewBill(false)}
							/>
						</>
					)}
				</RightOffcanvas>
			</div>
		</div>
	);
};

export default AssistantDashboardV2;
