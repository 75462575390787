import React from 'react';
import { NewMasterAssessmentData } from '../types/NewMasterAssessment';
import { endpoints, replaceParams } from '@utils/axios';
import axios from 'axios';
import { Convert, PrescriptionDetails } from '../types/PrescriptionDetails';
import moment from 'moment';

const GoalsTab = (props: {
  patientId: string;
  masterAssessmentData: NewMasterAssessmentData | null;
}) => {
  const [goals, setGoals] = React.useState({
    advice: '',
    precautions: '',
    type: '',
    prescribedThrapeuticProcedures: '',
    shortTermGoals: '',
    midTermGoals: '',
    longTermGoals: '',
    sessionCount: '',
    sessionFrequency: '',
    sessionFrequencyUnit: '',
    nextPrescribedDate: new Date(),
  });

  React.useEffect(() => {
    const getTreatmentPlanData = async () => {
      try {
        const paramsMap = new Map<string, string>([
          ['id', props.masterAssessmentData?.treatmentPlanId ?? ''],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.treatmentPlan.view,
          paramsMap,
        );
        const response = await axios.get(filteredEndpoint);
        const data = response.data['data'];

        setGoals({
          advice: data.advice,
          precautions: data.precautions,
          type: data.type,
          prescribedThrapeuticProcedures: data.prescribedThrapeuticProcedures,
          shortTermGoals: data.shortTermGoals,
          midTermGoals: data.midTermGoals,
          longTermGoals: data.longTermGoals,
          sessionCount: data.sessionCount,
          sessionFrequency: data.sessionFrequency,
          sessionFrequencyUnit: data.sessionFrequencyUnit,
          nextPrescribedDate: new Date(),
        });
      } catch (error) {}
    };

    getTreatmentPlanData();
  }, []);

  const onSubmit = async () => {
    if (
      props.masterAssessmentData?.treatmentPlanId === '000000000000000000000000'
    ) {
      try {
        const paramsMap = new Map<string, string>([
          ['mid', props.masterAssessmentData?.id!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.treatmentPlan.createPrescription,
          paramsMap,
        );

        await axios.post(filteredEndpoint, {
          advice: goals.advice,
          precautions: goals.precautions,
          type: goals.type,
          prescribedThrapeuticProcedures: goals.prescribedThrapeuticProcedures,
          shortTermGoals: goals.shortTermGoals,
          midTermGoals: goals.midTermGoals,
          longTermGoals: goals.longTermGoals,
          sessionCount: goals.sessionCount,
          sessionFrequency: goals.sessionFrequency,
          sessionFrequencyUnit: goals.sessionFrequencyUnit,
          nextPrescribedDate: new Date(),
        });
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    } else {
      try {
        const paramsMap = new Map<string, string>([
          ['id', props.masterAssessmentData?.treatmentPlanId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.treatmentPlan.update,
          paramsMap,
        );
        const response = await axios.put(filteredEndpoint, {
          advice: goals.advice,
          precautions: goals.precautions,
          type: goals.type,
          prescribedThrapeuticProcedures: goals.prescribedThrapeuticProcedures,
          shortTermGoals: goals.shortTermGoals,
          midTermGoals: goals.midTermGoals,
          longTermGoals: goals.longTermGoals,
          sessionCount: goals.sessionCount,
          sessionFrequency: goals.sessionFrequency,
          sessionFrequencyUnit: goals.sessionFrequencyUnit,
          nextPrescribedDate: new Date(),
        });
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    }
  };


  return (
    <div className="flex flex-col p-2">
      <div className="flex flex-col md:flex-row w-full mt-4 md:space-x-4">
        <div className="flex flex-col  w-full ">
          <span className="flex-none font-medium text-sm">Short Term Goal</span>
          <div className="flex flex-row w-full mt-1">
            <textarea
              key="shortTermGoals"
              name="shortTermGoals"
              placeholder="Enter short term goals"
              rows={3}
              value={goals.shortTermGoals}
              onBlur={onSubmit}
              onChange={(e) => {
                setGoals({ ...goals, shortTermGoals: e.target.value });
              }}
              className="block w-full rounded-lg border-0 py-2 ring-1 placeholder:text-gray-400 sm:text-sm sm:leading-6"
            />
            {/* {errors.shortTermGoals && (
              <ValidationError
                message={errors.shortTermGoals?.message?.toString() ?? ''}
              />
            )} */}
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row w-full mt-4 md:space-x-4">
        <div className="flex flex-col  w-full ">
          <span className="flex-none font-medium text-sm">Mid Term Goal</span>
          <div className="flex flex-row w-full mt-1">
            <textarea
              key="midTermGoals"
              name="midTermGoals"
              placeholder="Enter mid term goals"
              rows={3}
              value={goals.midTermGoals}
              onBlur={onSubmit}
              onChange={(e) => {
                setGoals({ ...goals, midTermGoals: e.target.value });
              }}
              className="block w-full rounded-lg border-0 py-2 ring-1 placeholder:text-gray-400 sm:text-sm sm:leading-6"
            />
            {/* {errors.midTermGoals && (
              <ValidationError
                message={errors.midTermGoals?.message?.toString() ?? ''}
              />
            )} */}
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row w-full mt-4 md:space-x-4">
        <div className="flex flex-col  w-full ">
          <span className="flex-none font-medium text-sm">Long Term Goal</span>
          <div className="flex flex-row w-full mt-1">
            <textarea
              key="longTermGoals"
              name="longTermGoals"
              placeholder="Enter long term goals"
              rows={3}
              value={goals.longTermGoals}
              onBlur={onSubmit}
              onChange={(e) => {
                setGoals({ ...goals, longTermGoals: e.target.value });
              }}
              className="block w-full rounded-lg border-0 py-2 ring-1 placeholder:text-gray-400 sm:text-sm sm:leading-6"
            />
            {/* {errors.longTermGoals && (
              <ValidationError
                message={errors.longTermGoals?.message?.toString() ?? ''}
              />
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoalsTab;
