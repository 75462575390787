import React from "react";
export default function StatCard({
	icon: Icon,
	label,
	value,
	color,
}: {
	icon: any;
	label: string;
	value: string;
	color: string;
}) {
	return (
		<div className="bg-white rounded-xl shadow-sm p-4 flex items-center space-x-4 border border-gray-50">
			<div className={`p-3 rounded-lg ${color}`}>
				<Icon className="w-6 h-6 text-white" />
			</div>
			<div>
				<p className="text-gray-600 text-sm">{label}</p>
				<p className="text-2xl font-semibold">{value}</p>
			</div>
		</div>
	);
}

export function StatCardMini({
	icon: Icon,
	label,
	value,
	color,
	textColor,
}: {
	icon: any;
	label: string;
	value: string;
	color: string;
	textColor: string;
}) {
	return (
		<div className="bg-white rounded-xl  p-2 flex items-center space-x-4 ">
			<div className={`p-1 rounded-lg ${color}`}>
				<Icon className="w-4 h-4 text-white" />
			</div>
			<div className="flex flex-row items-center space-x-2">
				<span className="text-gray-800 text-sm font-normal">{label}</span>
				<span className={`text-gray-800 text-xl font-semibold`}>{value}</span>
			</div>
		</div>
	);
}

export function StatCardTiny({
	icon: Icon,
	label,
	value,
	color,
	textColor,
}: {
	icon: any;
	label: string;
		value: string;
		color: string;
		textColor: string;
}) {
	return (
		<div className="bg-white rounded-xl  p-4 flex items-center space-x-4 border border-gray-100">
			<div className={`p-1 rounded-lg ${color}`}>
				<Icon className="w-4 h-4 text-white" />
			</div>
			<div>
				<p className={`text-gray-600 text-xs ${textColor}`}>{label}</p>
				<p className={`text-sm font-semibold ${textColor}`}>{value}</p>
			</div>
		</div>
	);
}
