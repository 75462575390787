import { Therapist, TimeSlot } from './types';

export const therapists: Therapist[] = [
  {
    id: '1',
    name: 'Dr. Sarah Johnson',
    specialty: 'Cognitive Behavioral Therapy',
    imageUrl: 'https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?auto=format&fit=crop&q=80&w=200&h=200'
  },
  {
    id: '2',
    name: 'Dr. Michael Chen',
    specialty: 'Family Therapy',
    imageUrl: 'https://images.unsplash.com/photo-1612349317150-e413f6a5b16d?auto=format&fit=crop&q=80&w=200&h=200'
  },
  {
    id: '3',
    name: 'Dr. Emily Rodriguez',
    specialty: 'Anxiety & Depression',
    imageUrl: 'https://images.unsplash.com/photo-1559839734-2b71ea197ec2?auto=format&fit=crop&q=80&w=200&h=200'
  },
  {
    id: '4',
    name: 'Dr. James Wilson',
    specialty: 'Trauma Therapy',
    imageUrl: 'https://images.unsplash.com/photo-1537368910025-700350fe46c7?auto=format&fit=crop&q=80&w=200&h=200'
  },
  {
    id: '5',
    name: 'Dr. Maria Garcia',
    specialty: 'Child Psychology',
    imageUrl: 'https://images.unsplash.com/photo-1551836022-d5d88e9218df?auto=format&fit=crop&q=80&w=200&h=200'
  },
  {
    id: '6',
    name: 'Dr. David Kim',
    specialty: 'Couples Therapy',
    imageUrl: 'https://images.unsplash.com/photo-1622253692010-333f2da6031d?auto=format&fit=crop&q=80&w=200&h=200'
  },
  {
    id: '7',
    name: 'Dr. Rachel Thompson',
    specialty: 'Eating Disorders',
    imageUrl: 'https://images.unsplash.com/photo-1594824476967-48c8b964273f?auto=format&fit=crop&q=80&w=200&h=200'
  },
  {
    id: '8',
    name: 'Dr. Marcus Brown',
    specialty: 'Addiction Recovery',
    imageUrl: 'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?auto=format&fit=crop&q=80&w=200&h=200'
  },
  {
    id: '9',
    name: 'Dr. Sofia Patel',
    specialty: 'PTSD Specialist',
    imageUrl: 'https://images.unsplash.com/photo-1587614382346-4ec70e388b28?auto=format&fit=crop&q=80&w=200&h=200'
  },
  {
    id: '10',
    name: 'Dr. Thomas Anderson',
    specialty: 'Grief Counseling',
    imageUrl: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?auto=format&fit=crop&q=80&w=200&h=200'
  }
];

export const bookingDurations = [
  { label: '15 minutes', minutes: 15 },
  { label: '20 minutes', minutes: 20 },
  { label: '25 minutes', minutes: 25 },
  { label: '30 minutes', minutes: 30 },
  { label: '35 minutes', minutes: 35 },
  { label: '40 minutes', minutes: 40 },
  { label: '45 minutes', minutes: 45 },
  { label: '50 minutes', minutes: 50 },
  { label: '55 minutes', minutes: 55 },
  { label: '60 minutes (1 hour)', minutes: 60 },
  { label: '75 minutes (1 hour 15 mins)', minutes: 75 },
  { label: '90 minutes (1 hour 30 mins)', minutes: 90 },
  { label: '105 minutes (1 hour 45 mins)', minutes: 105 },
  { label: '120 minutes (2 hours)', minutes: 120 },
  { label: '135 minutes (2 hours 15 mins)', minutes: 135 },
  { label: '150 minutes (2 hours 30 mins)', minutes: 150 },
  { label: '165 minutes (2 hours 45 mins)', minutes: 165 },
  { label: '180 minutes (3 hours)', minutes: 180 },
];

const formatTime = (hour: number, minute: number): string => {
  const period = hour >= 12 ? 'PM' : 'AM';
  const displayHour = hour % 12 || 12;
  return `${displayHour}:${minute.toString().padStart(2, '0')} ${period}`;
};

export const generateTimeSlots = (startTime: string = '08:00', endTime: string = '20:00') => {
  const slots: TimeSlot[] = [];
  const [startHour, startMinute] = startTime.split(':').map(Number);
  const [endHour, endMinute] = endTime.split(':').map(Number);

  const startMinutes = startHour * 60 + startMinute;
  const endMinutes = endHour * 60 + endMinute;

  for (let minutes = startMinutes; minutes < endMinutes; minutes += 15) {
    const hour = Math.floor(minutes / 60);
    const minute = minutes % 60;
    const time = formatTime(hour, minute);
    slots.push({
      time,
       isAvailable: false,
      isBooked: false, 
      minutesFromMidnight: minutes
    });
  }
  return slots;
};