import React from "react";

// -------------------------------------------------
export enum ButtonVariant {
	PRIMARY = "primary",
	SECONDARY = "secondary",
	TERTIARY = "tertiary",
	GRAY = "gray",
	GHOST = "ghost",
	WHITEPRIMARY = "whiteprimary",
	CUSTOM = "custom",
}

const VARIANT_MAPS: Record<ButtonVariant, string> = {
	[ButtonVariant.WHITEPRIMARY]:
		"bg-white hover:bg-gray-100 focus:ring-primary-500 focus:ring-offset-primary-200 text-primary shadow-sm border border-primary-400",
	[ButtonVariant.PRIMARY]:
		"bg-primary-50 hover:bg-primary-100 focus:ring-primary-500 focus:ring-offset-primary-200 text-primary shadow-sm border border-primary-400",
	[ButtonVariant.SECONDARY]:
		"bg-secondary-50 hover:bg-secondary-100 focus:ring-secondary-500 focus:ring-offset-secondary-200 text-secondary shadow-sm border border-secondary-400",
	[ButtonVariant.TERTIARY]:
		"bg-tertiary-50 hover:bg-tertiary-100 focus:ring-tertiary-500 focus:ring-offset-tertiary-200 text-tertiary shadow-sm border border-tertiary-400",
	[ButtonVariant.GRAY]:
		"bg-gray-50 hover:bg-gray-100 focus:ring-gray-500 focus:ring-offset-gray-200 text-gray shadow-sm border border-gray-400",
	[ButtonVariant.GHOST]: "border hover:bg-accent hover:text-accent-foreground",
	[ButtonVariant.CUSTOM]: "",
};

type ButtonProps = {
	children: React.ReactNode;
	variant: ButtonVariant;
	type?: "button" | "submit" | "reset";
	isDisabled?: boolean;
	isLoading?: boolean;
	className?: string;
	onClick: () => void;
};

// -------------------------------------------------

export default function Button(props: ButtonProps) {
	const bgClass = VARIANT_MAPS[props.variant];
	const className =
		`inline-flex items-center rounded-lg text-sm px-4 py-2 font-medium focus:outline-none transition ease-in-out duration-150 hover:shadow ${bgClass} ${props.className}`.trim();
	const onClick = props.isLoading ? () => {} : props.onClick;
	const disabled = props.isLoading || props.isDisabled;
	const children = props.isLoading ? "Loading..." : props.children;

	return (
		<button className={className} onClick={onClick} disabled={disabled} type={props.type}>
			{children}
		</button>
	);
}
