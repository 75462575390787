import { endpoints, replaceParams } from "@utils/axios";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { Appointments } from "@pages/appointments/types/Appointments";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Divider from "@components/divider/divider";

import useAxios from "@routes/hooks/use-axios";
import ViewPaymentDetails from "./ViewPaymentDetails";
import { CancelAppointmentDialog } from "./CancelAppointmentDialog";
import AppointmentDetails from "./AppointmentDetails";
import BillingAndPaymentDetails from "../../payments/BillingAndPaymentDetails";
import Button, { ButtonVariant } from "@components/button/outline-button";
import { ConvertSessionCredit, SessionCredits } from "@pages/manage-patient-new/types/SessionCredits";
import { useNavigate } from "react-router-dom";
import { Clock, LucideIcon, Sidebar } from "lucide-react";
import { Sheet, SheetClose, SheetContent, SheetHeader, SheetTrigger } from "@components/ui/sheet";
import PatientRecord from "@pages/manage-patient-new-v2/view/PatientRecord";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { getUIVersion, getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import Modal from "@components/modal";
import RightOffcanvas from "@components/right-offcanvas/right-offcanvas";

export default function AppointmentDashboardCards(props: {
	appointmentStatus: string;
	buttonName: string;
	appointmentList: Appointments[];
	callback: () => void;
	dateSwitcher: string;
	icon: LucideIcon;
	iconColor: string;
}) {
	const [searchQuery, setSearchQuery] = React.useState("");
	const [addPaymentDetails, setAddPaymentDetails] = useState(false);
	const [cancelDialog, setCancelDialog] = useState(false);
	const [showPaymentDetails, setShowAddPaymentDetails] = useState(false);
	const [showAppointmentDetails, setShowAppointmentDetails] = useState(false);
	const [isRightOffcanvasPatient, setIsRightOffcanvasPatient] = useState(false);

	const [selectedAppointmentId, setSelectedAppointmentId] = useState("");
	const [appointmentDetails, setAppointmentDetails] = useState<Appointments | null>(null);

	const axios = useAxios();

	const navigate = useNavigate();

	const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchQuery(event.target.value);
	};

	const filteredAppointments = props.appointmentList?.filter((appointment) => {
		return appointment.clientName.toLowerCase().includes(searchQuery.toLowerCase());
	});

	const checkStatus = async (appStatus: string, appId: string, appointmentDetails: Appointments) => {
		type Status = "Scheduled" | "Waiting" | "InSession" | "Cancelled" | "Completed" | "Complete";
		const statusTransitions: Record<Status, Status> = {
			Scheduled: "Waiting",
			Waiting: "InSession",
			InSession: "Completed",
			Cancelled: "Cancelled",
			Completed: "Complete",
			Complete: "Complete",
		};

		if (statusTransitions.hasOwnProperty(appStatus)) {
			const nextStatus = statusTransitions[appStatus as Status];

			if (nextStatus === "Complete") {
				setAppointmentDetails(appointmentDetails);
				setSelectedAppointmentId(appId);
				setShowAddPaymentDetails(true);
			} else if (nextStatus === "Cancelled") {
				setSelectedAppointmentId(appId);
				setCancelDialog(true);
			} else {
				handleStatusChange(appId, nextStatus);
			}
		}
	};

	const handleStatusChange = async (appointmentId: string, appointmentStatus: string) => {
		try {
			const paramsMap = new Map<string, string>([
				["id", appointmentId],
				["status", appointmentStatus],
			]);
			const filteredEndpoint = replaceParams(endpoints.assistantDashboard.updateAppointmentStatus, paramsMap);
			await axios.put(filteredEndpoint);
			props.callback();
		} catch (error) {
			console.error(error);
		}
	};

	const confirmPaymentAndChangeStatus = async () => {
		try {
			setAddPaymentDetails(false);
			props.callback();
			// handleStatusChange(selectedAppointmentId, 'Completed');
		} catch (error) {
			console.error(error);
		}
	};

	const handleJoinNowButton = (meetLink: string) => {
		window.open(meetLink, "_blank");
	};

	const navigateToPatientScreen = (patientId: string) => {
		navigate(`/patient/manage/${patientId}`);
	};

	const uiVersion = getUIVersion();

	return (
		<div className="rounded-md  border border-primary-50 shadow-sm">
			<div className="">
				<div className="flex items-center justify-between p-2 border-b border-primary-100 bg-gray-50">
					<props.icon className={`w-5 h-5 ${props.iconColor}`} />

					<span className="font-semibold text-gray-800 text-sm">
						{props.appointmentStatus === "Ongoing" || props.appointmentStatus === "InSession" ? (
							<span className="text-sm font-medium px-2.5 py-0.5">In&nbsp;Session</span>
						) : (
							<span className="text-sm font-medium px-2.5 py-0.5">{props.appointmentStatus}</span>
						)}
					</span>
					<span className="text-sm font-medium px-2.5 py-0.5 rounded-full bg-primary-100">
						{props.appointmentList.length}
					</span>
				</div>

				<div className="mt-2 px-4">
					<label htmlFor="search" className="sr-only">
						Search
					</label>
					<div className="flex rounded-md shadow-sm">
						<div className="relative flex-grow focus-within:z-10">
							<div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
								{/* Heroicon name: search */}
								<FontAwesomeIcon icon={["fas", "search"]} className="h-4 w-4 text-primary-500" aria-hidden="true" />
							</div>
							<input
								type="text"
								name="search"
								id="search"
								className="focus:ring-primary-500 focus:border-primary-500 block w-full border-b sm:text-sm border-gray-100"
								placeholder="Search"
								value={searchQuery}
								onChange={handleSearch}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className="mt-2 overflow-auto  min-h-[300px] 2xl:min-h-[600px] p-2">
				{filteredAppointments.length != 0 ? (
					<></>
				) : (
					<div className="flex flex-col justify-center items-center y-2">
						<div className="flex-shrink">
							<span className="font-medium text-primary-500 text-sm">No Patient is {props.appointmentStatus}</span>
						</div>
					</div>
				)}
				{filteredAppointments.map((appointment: Appointments) => (
					<div className="flex flex-col justify-between rounded-2xl shadow-md bg-white mb-2 mx-2 hover:cursor-pointer border border-primary-200">
						{/* ...content of the staff card... */}
						<div
							className="flex flex-row rounded-xl bg-white-300 items-center justify-between p-4 pb-0 "
							onClick={() => {
								setSelectedAppointmentId(appointment.id);
								setShowAppointmentDetails(true);
							}}
						>
							<div className="flex flex-col w-3/4">
								<span className="text-sm text-primary-800 font-semibold truncate">{appointment.clientName}</span>
								<span className="flex-initial text-xs text-gray-800 font-semibold">{appointment.startTime}</span>
								<span className="flex-initial text-xs text-gray-800 ">
									{appointment.appointmentType}, {appointment.modeOfConsultation}
								</span>
								<hr className="mt-2 h-0.5 bg-white-300 opacity-100 dark:opacity-50" />
							</div>
							<div className="p-3 bg-gray-200 rounded-xl text-xs">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									fill="currentColor"
									className="w-6 h-6 text-primary-500"
								>
									<path
										fill-rule="evenodd"
										d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z"
										clip-rule="evenodd"
									/>
								</svg>
							</div>
						</div>
						<div
							className="flex flex-row items-center justify-between pb-0"
							onClick={() => {
								setSelectedAppointmentId(appointment.id);
								setShowAppointmentDetails(true);
							}}
						>
							<div className="flex flex-col items-start px-4 mt-2 w-full">
								<span className="flex-initial  text-xs ">Appointment With</span>
								<span className="flex-initial truncate text-xs font-medium w-full">{appointment.entityUserName}</span>
							</div>
							{props.appointmentStatus === "Waiting" && (
								<div className="flex flex-col items-center pr-4 font-semibold">
									<span className="text-xs">Waiting</span>
									<WaitingTimeCounter t={appointment.checkInTime} />
								</div>
							)}
							{(props.appointmentStatus === "Ongoing" || props.appointmentStatus === "InSession") && (
								<div className="flex flex-col items-center pr-4 font-semibold">
									<span className="text-xs text-center">In Session</span>
									<WaitingTimeCounter t={appointment.sessionStartTime} />
								</div>
							)}
						</div>
						<div className="flex flex-row rounded-bl-2xl rounded-br-2xl bg-gray-100  p-2 mt-2  justify-between">
							<div className="flex flex-row w-full justify-between gap-2">
								{(props.appointmentStatus === "Ongoing" || props.appointmentStatus === "InSession") && (
									<>
										{uiVersion === "v3" ? (
											<>
												<Button
													variant={ButtonVariant.SECONDARY}
													type="button"
													onClick={() => {
														navigateToPatientScreen(appointment.clientId);
													}}
													className="text-xs"
												>
													Manage Patient
												</Button>
											</>
										) : (
											<>
												<Button
													variant={ButtonVariant.SECONDARY}
													type="button"
													onClick={() => {
														setIsRightOffcanvasPatient(true);
													}}
													className="text-xs"
												>
													<span className="pl-1 text-xs"> Patient Records </span>
												</Button>
												{isRightOffcanvasPatient && (
													<RightOffcanvas
														isOpen={isRightOffcanvasPatient}
														onClose={() => {
															setIsRightOffcanvasPatient(false);
														}}
														sizeClasses="w-full"
													>
														<div className="flex flex-col w-full">
															<div className="flex justify-end items-end w-full mb-2">
																<Button
																	variant={ButtonVariant.SECONDARY}
																	type="button"
																	onClick={() => {
																		setIsRightOffcanvasPatient(false);
																	}}
																>
																	Close
																</Button>
															</div>
															<div className="">
																<PatientRecord inputPatientId={appointment.clientId} defaultTab="pe" />
															</div>
														</div>
													</RightOffcanvas>
												)}
											</>
											/*                       <Sheet>
                                              <SheetTrigger>
                                                <div>
                                                  <Button
                                                    variant={ButtonVariant.SECONDARY}
                                                    type="button"
                                                    onClick={() => {}}
                                                    children="Patient Records"
                                                  />
                                                </div>
                                              </SheetTrigger>
                                              <SheetContent className="w-full md:w-3/4 justify-end ">
                                                <div className="flex flex-col">
                                                  <div className="flex justify-end items-end w-full mb-2">
                                                    <SheetClose className="border-2 rounded-md p-1 bg-primary-200 font-medium">
                                                      Close
                                                    </SheetClose>
                                                  </div>
                                                  <div className="">
                                                    <PatientRecord
                                                      inputPatientId={appointment.clientId}
                                                      defaultTab="pe"
                                                    />
                                                  </div>
                                                </div>
                                              </SheetContent>
                                              </Sheet> */
										)}
									</>
								)}
								{props.appointmentStatus === "Scheduled" ? (
									<div>
										<Button
											variant={ButtonVariant.GHOST}
											type="button"
											onClick={() => checkStatus("Cancelled", appointment.id, appointment)}
											className="mr-2 w-1/2 sm:w-fit justify-center sm:justify-normal"
										>
											<span className="pl-1 text-xs"> Cancel </span>
										</Button>
									</div>
								) : (
									<></>
								)}
								{/* If status is completed then show primary - payment details button only if payment status is paid  */}
								{props.appointmentStatus !== "Completed" && (
									<div>
										<Button
											variant={ButtonVariant.SECONDARY}
											type="button"
											className=""
											isDisabled={!(props.dateSwitcher === moment(new Date()).format("DD-MM-YYYY"))}
											onClick={() => checkStatus(props.appointmentStatus, appointment.id, appointment)}
										>
											<span className="pl-1 text-xs"> {props.buttonName} </span>
										</Button>
									</div>
								)}

								{props.appointmentStatus !== "Completed" && appointment.meetLink && (
									<div>
										<button
											className="self-end bg-secondary-300 hover:bg-secondary-500 text-white font-bold py-2 px-4 rounded-xl focus:outline-none focus:shadow-outline mr-3"
											onClick={() => handleJoinNowButton(appointment.meetLink)}
										>
											Join
										</button>
									</div>
								)}

								{props.appointmentStatus === "Completed" && (
									<>
										{appointment.paymentStatus !== "" ? (
											<div>
												<Button
													variant={ButtonVariant.SECONDARY}
													className="self-end"
													onClick={() => {
														setAppointmentDetails(appointment);
														setAddPaymentDetails(true);
													}}
												>
													Bill Paid
												</Button>
											</div>
										) : (
											<>
												{appointment.billAmount > 0 ? (
													<div>
														<Button
															variant={ButtonVariant.SECONDARY}
															className="self-end"
															onClick={() => {
																setAppointmentDetails(appointment);
																setAddPaymentDetails(true);
															}}
														>
															View Bill Details
														</Button>
													</div>
												) : (
													<div>
														<Button
															variant={ButtonVariant.PRIMARY}
															className="self-end text-xs"
															onClick={() => {
																setAppointmentDetails(appointment);
																setAddPaymentDetails(true);
															}}
														>
															Add Bill Details
														</Button>
													</div>
												)}
											</>
										)}
									</>
								)}
							</div>
						</div>
					</div>
				))}
			</div>

			{addPaymentDetails && (
				<BillingAndPaymentDetails
					appointmentDetails={appointmentDetails!}
					onClose={() => setAddPaymentDetails(false)}
					onConfirm={confirmPaymentAndChangeStatus}
				/>
			)}
			{showPaymentDetails && (
				<ViewPaymentDetails
					onClose={() => setShowAddPaymentDetails(false)}
					onConfirm={confirmPaymentAndChangeStatus}
					appointmentId={selectedAppointmentId}
					appointmentDetails={appointmentDetails!}
					setAddPaymentDetails={setAddPaymentDetails}
				/>
			)}
			{cancelDialog && (
				<CancelAppointmentDialog
					onClose={() => setCancelDialog(false)}
					appId={selectedAppointmentId}
					callback={props.callback}
				/>
			)}
			{showAppointmentDetails && (
				<AppointmentDetails onClose={() => setShowAppointmentDetails(false)} appId={selectedAppointmentId} />
			)}
		</div>
	);
}
function WaitingTimeCounter(props: { t: Date }) {
	const [waitingTime, setWaitingTime] = useState<string>("");

	useEffect(() => {
		const updateWaitingTime = () => {
			const duration = moment.duration(moment().diff(moment(props.t)));
			const hours = duration.hours();
			const minutes = duration.minutes();

			let timeString = "";
			if (hours > 0) {
				timeString += `${hours}h `;
			}
			timeString += `${minutes}m`;

			setWaitingTime(timeString);
		};

		updateWaitingTime();
		const intervalId = setInterval(updateWaitingTime, 60000);

		return () => clearInterval(intervalId);
	}, [props.t]);

	return <span className="text-xs">{waitingTime}</span>;
}
