import { replaceParams, endpoints } from "@utils/axios";
import React, { useEffect, useState } from "react";
import { Convert, StaffData } from "../types/StaffData";
import useAxios from "@routes/hooks/use-axios";
import { FormProvider, SubmitHandler, useForm, useFormContext } from "react-hook-form";
import { Button } from "@components/button";
import { ButtonVariant } from "@components/button/button";
import { zodResolver } from "@hookform/resolvers/zod";

import { z } from "zod";
import { InputField, ValidationError } from "@components/hook-form";
import moment from "moment";

import { toast } from "react-toastify";
import { paths } from "@routes/paths";
import { useNavigate } from "react-router-dom";
import Applabel from "@components/hook-form/applabel";
import BConvert, { Branch } from "../types/BranchType";

import CountryInputSelect from "@components/hook-form/country-input-select";
import TimezoneData from "@assets/timezones";
import tzmoment from "moment-timezone";
import PhoneInput, {
	formatPhoneNumber,
	getCountryCallingCode,
	isPossiblePhoneNumber,
	isValidPhoneNumber,
} from "react-phone-number-input";
import { CountryCode, E164Number } from "libphonenumber-js";
import "react-phone-number-input/style.css";
import { convertPhoneNumberAndCodeToNumber, convertPhoneNumberAndCodeToString } from "@utils/phoneUtils";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import BranchChangePopUp from "./BranchChangePopUp";

interface Timezone {
	value: string;
	abbr: string;
	text: string;
	utc: string[];
}

export const RoleSchema = z.object({
	role: z.string().min(1, { message: "Role is required" }),
});
export type Role = z.infer<typeof RoleSchema>;

export const AddressSchema = z.object({
	address: z.string().min(1, { message: "Address is required" }),
	city: z.string().optional(),
	state: z.string().optional(),
	country: z.string().optional(),
	postalCode: z.string().optional(),
});
export type Address = z.infer<typeof AddressSchema>;

export const StaffSchema = z.object({
	salutation: z.string().min(1, { message: "Title is required" }),
	entityBranchId: z.string().min(1, { message: "Please Select a Branch" }),
	entityId: z.string(),
	firstName: z.string().min(1, { message: "First name is required" }),
	middleName: z.string(),
	lastName: z.string().min(1, { message: "Last name is required" }),
	bloodGroup: z.string(),
	gender: z.string(),
	email: z.string().min(1, { message: "Email is required" }).email({ message: "Must be a valid email" }),
	phoneCode: z.string().min(1, { message: "Phone Code is required" }),
	phone: z.string().superRefine((val, ctx) => {
		//
		if (!val || val.trim() === "") {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: `Please enter Phone`,
				fatal: true,
			});
			return z.NEVER;
		}
		if (!isValidPhoneNumber(val)) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				fatal: true,
				message: "Invalid phone number format",
			});

			return z.NEVER;
		}

		if (!isPossiblePhoneNumber(val)) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: `Invalid phone number length`,
				fatal: true,
			});
			return z.NEVER;
		}
	}),

	address: AddressSchema,
	role: RoleSchema.required({ role: true }),
	timezone: z.string().optional(),
	dob: z.string(),
	designation: z.string().min(1, { message: "Designation is required" }),
});
export type StaffSchemaType = z.infer<typeof StaffSchema>;

function StaffInformationComponent(props: {
	staffId: string;
	setStaffTimeZone: React.Dispatch<React.SetStateAction<string>>;
}) {
	const [staff, setStaff] = React.useState<StaffData | null>(null);
	const [loading, setLoading] = React.useState(true);
	const [formData, setFormData] = useState({}); // Store form data here
	const [isEditable, setIsEditable] = React.useState(false);
	const [phoneNumber, setPhoneNumber] = useState("91");
	const [emergencyPhoneNumber, setEmergencyPhoneNumber] = useState("");
	const [apiError, setApiError] = React.useState("");
	const [branchId, setBranchId] = React.useState<string | null>(null);
	const [branches, setBranches] = React.useState<Branch[] | null>(null);
	const [branchChangeDialog, setBranchChangeDialog] = useState(false);
	const navigator = useNavigate();
	const [checkFirstName, setCheckFirstName] = useState("");
	const [checkMiddleName, setCheckMiddleName] = useState("");
	const [checkLastName, setCheckLastName] = useState("");
	const [checkSalutation, setCheckSalutation] = useState("");
	const [isPlatformAdmin, setIsPlatformAdmin] = React.useState(false);
	const [isEntityOwner, setIsEntityOwner] = React.useState(false);

	const axios = useAxios();

	const Roles = new Map<string, string>([
		["Entity Owner", "ENTITY_OWNER"],
		["Branch Admin", "BRANCH_ADMIN"],
		["Practitioner", "PRACTITIONER"],
		["Assistant", "ASSISTANT"],
	]);

	const {
		register,
		handleSubmit,
		watch,
		reset,
		control,
		setValue,
		setError,
		clearErrors,
		trigger,
		formState: { errors },
	} = useForm<StaffSchemaType>({
		resolver: zodResolver(StaffSchema),
	});

	const handlePhoneNumberChange = (value: E164Number | undefined) => {
		setValue("phone", value?.toString() ?? "");
	};
	const handleBranchSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setBranchId(event.target.value);
		// getBranches(); // Update the state with the selected value
	};

	const getStaff = async () => {
		try {
			const paramsMap = new Map<string, string>([["staffId", props.staffId]]);
			const filteredEndpoint = replaceParams(endpoints.staff.view, paramsMap);
			const response = await axios.get(filteredEndpoint);
			const data = response.data["data"];

			setPhoneNumber(data.phoneCode.toString().toString() + data.phone.toString().toString());
			const convertedStaff = Convert.toStaffData(JSON.stringify(data));
			setStaff(convertedStaff);
			props.setStaffTimeZone(convertedStaff.timezone ?? "");
			const phoneNumberDataString = convertPhoneNumberAndCodeToString({
				phoneNumber: convertedStaff.phone ?? 0,
				phoneCode: convertedStaff.phoneCode ?? 0,
			});
			setCheckFirstName(data.firstName);
			setCheckMiddleName(data.middleName);
			setCheckLastName(data.lastName);
			setCheckSalutation(data.salutation);
			reset({
				...convertedStaff,
				phone: phoneNumberDataString.phoneNumber,
				phoneCode: phoneNumberDataString.phoneCode,
			});
			setIsEditable(false);

			setLoading(false);
		} catch (error) {
			toast.error(error.message);

			console.error("Error fetching staff data:", error);

			setLoading(false);
		}
	};
	useEffect(() => {
		setIsEditable(false);

		getStaff();
	}, []);

	useEffect(() => {
		if (staff) getBranches();
	}, [staff]);

	useEffect(() => {
		if (userData?.user_role === "PLATFORM_ADMIN") {
			setIsPlatformAdmin(true);
		} else if (userData?.user_role === "ENTITY_OWNER") {
			setIsEntityOwner(true);
		}
	}, []);

	const userData = getUserPersistedOnLocalStorage();
	const getBranches = async () => {
		try {
			const paramsMap = new Map<string, string>([["entityId", staff?.entityId ?? ""]]);
			const filteredEndpoint = replaceParams(endpoints.branch.list, paramsMap);

			const response = await axios.get(filteredEndpoint);
			const data = response.data["data"];

			const convertedBranchList = data.map((entity: any) => BConvert.toBranch(JSON.stringify(entity)));

			setBranches(convertedBranchList);

			setLoading(false);
		} catch (error) {
			console.error("Error fetching entity list:", error);
			setApiError(error.message);
			setLoading(false);
		}
	};

	const handleToggleEdit = () => {
		setIsEditable((prevState) => !prevState);
	};

	const onSubmit: SubmitHandler<StaffSchemaType> = async (data) => {
		setLoading(true);
		try {
			const combinedData = { ...formData, ...data };
			const phoneData = convertPhoneNumberAndCodeToNumber({
				phoneNumber: combinedData.phone,
				phoneCode: combinedData.phoneCode,
			});
			StaffSchema.parse(combinedData);

			const paramsMap = new Map<string, string>([["id", props.staffId]]);
			const filteredEndpoint = replaceParams(endpoints.staff.update, paramsMap);

			let nameChange = false;

			if (
				checkFirstName !== data.firstName ||
				checkMiddleName !== data.middleName ||
				checkLastName !== data.lastName ||
				checkSalutation !== data.salutation
			) {
				nameChange = true;
			}

			const response = await axios.put(
				filteredEndpoint,
				{
					...combinedData,
					phone: phoneData.phoneNumber,
					phoneCode: phoneData.phoneCode,
				},
				{
					params: {
						isUserNameChanged: nameChange,
					},
				}
			);
			if (response.data.status === 200)
				toast.success(response.data.message === "success" ? "Staff updated successfully" : response.data.message);
			setIsEditable(false);
			setLoading(false);
			await getStaff();
		} catch (error) {
			setApiError(error.response.data["data"].toString());
			setLoading(false);
			setIsEditable(true);
			console.error("Error submitting form:", error);
		}
	};

	function checkDuplicateEmail() {
		var email = watch("email");

		if (staff?.email !== email && email.trim() !== "") {
			if (email) {
				const paramsMap = new Map<string, string>([["email", watch("email")]]);
				const filteredEndpoint = replaceParams(endpoints.staff.checkStaffEmail, paramsMap);
				axios
					.get(filteredEndpoint)
					.then((res) => {
						clearErrors("email");
					})
					.catch((error) => {
						if (error && error.response && error.response.data && error.response.data.status === 500) {
							setError("email", {
								type: "custom",
								message: "Account with this email already exists. Please contact support.",
							});
							setValue("email", "");
						} else {
							toast.error(error.message);
							setValue("email", "");
						}
					});
			}
		}
	}

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)} noValidate>
				<div>
					{!loading && !apiError && (
						<div className="p-4 border rounded-lg shadow-md w-full mb-4 mt-4">
							<span className="text-lg font-medium">Location Details</span>
							<div className="flex flex-col md:flex-row w-full mt-2 md:space-x-4">
								{(isPlatformAdmin || isEntityOwner) && branches && (
									<div className="flex flex-col w-full md:w-1/2">
										<Applabel label="Select Branch" mandatory />
										<select
											{...register("entityBranchId")}
											onChange={handleBranchSelectChange}
											name="entityBranchId"
											disabled={!isEditable}
											className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
										>
											<option key="" value="">
												Select
											</option>
											{branches.map((branch) => (
												<option key={branch.id} value={branch.id}>
													{branch.name}
												</option>
											))}
										</select>
										{errors.entityBranchId && (
											<ValidationError message={errors.entityBranchId?.message?.toString() ?? ""} />
										)}
									</div>
								)}

								<div className="flex flex-col w-full md:w-1/2">
									<Applabel label="Timezone" mandatory />
									<select
										{...register("timezone")}
										value={watch("timezone")}
										disabled={!isEditable}
										className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
									>
										<option value={tzmoment.tz.guess()}>{tzmoment.tz.guess()}</option>
										{TimezoneData.timezones.map((timezone: Timezone, index: number) =>
											timezone.utc.map((tz) => (
												<option key={tz} value={tz}>
													{tz} - {timezone.text}
												</option>
											))
										)}
									</select>
								</div>
							</div>

							{!isPlatformAdmin && <input {...register("entityId")} type="hidden" value={userData?.entity_id} />}
							{!isPlatformAdmin && !isEntityOwner && (
								<input {...register("entityBranchId")} type="hidden" value={userData?.branch_id} />
							)}
						</div>
					)}

					<div className="p-4 border rounded-lg shadow-md w-full mb-5">
						<span className="text-lg font-medium">Personal Information</span>
						<div className="flex flex-col md:flex-row w-full mt-2 md:space-x-4">
							<div className="flex flex-col w-full md:w-1/5">
								<Applabel label="Title" mandatory />
								<select
									{...register("salutation")}
									placeholder="Salutation"
									className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
									disabled={!isEditable}
									value={watch("salutation")}
								>
									<option key="" value="">
										Select
									</option>
									{["Mr", "Mrs", "Ms", "Miss", "Dr", "Prof", "Rev", "Hon", "Capt"].map((salutation) => (
										<option key={salutation} value={salutation}>
											{salutation}
										</option>
									))}
								</select>
								{errors.salutation && <ValidationError message={errors.salutation?.message?.toString() ?? ""} />}
							</div>

							<div className="flex flex-col w-full md:w-1/5">
								<Applabel label="First Name" mandatory />
								<input
									{...register("firstName")}
									disabled={!isEditable}
									placeholder="Staff First Name"
									value={watch("firstName")}
									type="text"
									className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
								/>
								{errors.firstName && <ValidationError message={errors.firstName?.message?.toString() ?? ""} />}
							</div>

							<div className="flex flex-col w-full md:w-1/5">
								<Applabel label="Last Name" mandatory />
								<input
									{...register("lastName")}
									className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
									placeholder="Staff Last Name"
									disabled={!isEditable}
									value={watch("lastName")}
								/>
								{errors.lastName && <ValidationError message={errors.lastName?.message?.toString() ?? ""} />}
							</div>

							<div className="flex flex-col w-full md:w-1/5">
								<Applabel label="Date of Birth" />
								<input
									{...register("dob")}
									className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
									type="date"
									disabled={!isEditable}
									value={moment(watch("dob")).format("YYYY-MM-DD")}
								/>
								{errors.dob && <ValidationError message={errors.dob?.message?.toString() ?? ""} />}
							</div>

							<div className="flex flex-col w-full md:w-1/5">
								<Applabel label="Gender" />
								<select
									{...register("gender")}
									placeholder="Patient Gender"
									disabled={!isEditable}
									value={watch("gender")}
									className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
								>
									<option key="" value="">
										Select
									</option>
									{["Male", "Female", "Other"].map((Gender) => {
										return (
											<option key={Gender} value={Gender}>
												{Gender}
											</option>
										);
									})}
								</select>
								{errors.gender && <ValidationError message={errors.gender?.message?.toString() ?? ""} />}
							</div>
						</div>
					</div>

					<div className="p-4 border rounded-lg shadow-md w-full mb-5">
						<span className="text-lg font-medium">Contact Details</span>
						<div className="flex flex-col md:flex-row w-full mt-2 md:space-x-4">
							<div className="flex flex-col w-full md:w-1/2">
								<Applabel label="Email" mandatory />
								<input
									{...register("email")}
									placeholder="Staff Email"
									disabled={!isEditable}
									onBlur={checkDuplicateEmail}
									value={watch("email")}
									required
									className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
								/>

								{errors.email && <ValidationError message={errors.email?.message?.toString() ?? ""} />}
							</div>
							<div className="flex flex-col w-full md:w-1/2">
								<Applabel label="Mobile Number" />
								<PhoneInput
									onCountryChange={(country?: CountryCode | undefined) => {
										if (country) {
											const countryCode = getCountryCallingCode(country);
											setValue("phoneCode", countryCode?.toString() ?? "");
										}
									}}
									{...register("phone")}
									international={true}
									disabled={!isEditable}
									defaultCountry="IN"
									countryCallingCodeEditable={false}
									placeholder="Enter phone number"
									value={watch("phone")}
									onChange={(value?: E164Number | undefined) => {
										clearErrors("phone");
										handlePhoneNumberChange(value);
									}}
									onBlur={() => {
										trigger("phone");
									}}
									className="w-full"
								/>
								{errors.phone && <ValidationError message={errors.phone?.message?.toString() ?? ""} />}
							</div>
						</div>
					</div>

					<div className="p-4 border rounded-lg shadow-md w-full mb-5">
						<span className="text-lg font-medium w-1/6 ">Address</span>
						<div className="flex flex-col md:flex-row w-full mt-2 md:space-x-4">
							<div className="flex flex-col w-full">
								<Applabel label="Address" mandatory />
								<input
									{...register("address.address")}
									placeholder="Staff Address"
									disabled={!isEditable}
									className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
									required
								/>
								{errors.address?.address && (
									<ValidationError message={errors.address?.address?.message?.toString() ?? ""} />
								)}
							</div>
							<div className="flex flex-col w-full">
								<Applabel label="Postal/Zip Code" />
								<input
									{...register("address.postalCode")}
									placeholder="Staff Zip Code"
									disabled={!isEditable}
									className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
									required
									value={watch("address.postalCode")}
								/>
								{errors.address?.postalCode && (
									<ValidationError message={errors.address.postalCode?.message?.toString() ?? ""} />
								)}
							</div>
						</div>

						<CountryInputSelect
							isDisabled={!isEditable}
							register={register}
							setValue={setValue}
							watch={watch}
							country="address.country"
							state="address.state"
							city="address.city"
						/>
					</div>
				</div>

				<div className="flex flex-row md:flex-row w-full justify-start items-start mt-0 md:space-x-4">
					<div className="flex flex-col w-full">
						<div className="p-4 border rounded-lg shadow-md w-full mb-5">
							<span className="text-lg font-medium">Role Information</span>
							<div className="flex flex-col md:flex-row w-full mt-2 md:space-x-4">
								<div className="flex flex-col w-full md:w-1/2">
									<Applabel label="Select Role" mandatory />
									<select
										{...register("role.role")}
										name="role.role"
										className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
										disabled={!isEditable}
									>
										<option key="" value="">
											Select
										</option>
										{Array.from(Roles.entries()).map(([key, value]) => (
											<option key={value} value={value}>
												{key}
											</option>
										))}
									</select>
									{errors.role && <ValidationError message={errors.role?.role?.message?.toString() ?? ""} />}
								</div>
								<div className="flex flex-col w-full md:w-1/2">
									<Applabel label="Designation" mandatory />
									<input
										{...register("designation")}
										placeholder="Staff Designation"
										disabled={!isEditable}
										className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
									/>
									{errors.designation && <ValidationError message={errors.designation?.message?.toString() ?? ""} />}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="h-20"></div>

				<div className="fixed items-start w-full justify-end bottom-0 h-16 z-50 shrink-0 mt-4 p-4 -ml-4 md:-ml-6 lg:-ml-8 shadow bg-gray-100">
					<Button
						variant={ButtonVariant.PRIMARY}
						type="button"
						className="lg:ml-3"
						onClick={isEditable ? handleSubmit(onSubmit) : handleToggleEdit}
					>
						{isEditable ? "Save" : "Edit"}
					</Button>
				</div>
			</form>
			{branchChangeDialog && <BranchChangePopUp setBranchChangeDialog={setBranchChangeDialog}></BranchChangePopUp>}
		</>
	);
}

export default StaffInformationComponent;
