import { OutlinedButton } from "@components/button";
import Button, { ButtonVariant } from "@components/button/button";
import { endpoints, replaceParams } from "@utils/axios";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Controller, FormProvider, SubmitHandler, useForm, useFormContext } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import DetailsStep from "./DetailsStep";
import { useNavigate } from "react-router-dom";

import ValidationError from "@components/hook-form/validation-error";
import { Convert, Entity } from "@pages/add-branch/types/EntityType";

import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import { toast } from "react-toastify";
import Applabel from "@components/hook-form/applabel";

import CountryInputSelect from "@components/hook-form/country-input-select";
import TimezoneData from "@assets/timezones";
import tzmoment from "moment-timezone";
import { time } from "console";
import PhoneInput, {
	formatPhoneNumber,
	getCountryCallingCode,
	isPossiblePhoneNumber,
	isValidPhoneNumber,
} from "react-phone-number-input";
import { CountryCode, E164Number } from "libphonenumber-js";
import "react-phone-number-input/style.css";
import { convertPhoneNumberAndCodeToNumber } from "@utils/phoneUtils";

export const BranchAddressSchema = z.object({
	address: z.string().min(1, { message: "Address is required" }),
	city: z.string().optional(),
	state: z.string().optional(),
	country: z.string().optional(),
	postalCode: z.string().min(1, { message: "Postal Code/zip is required" }),
});
export type BranchAddress = z.infer<typeof BranchAddressSchema>;

const phoneValidation = (val: string, ctx: any) => {
	if (!val || val.trim() === "") {
		ctx.addIssue({
			code: z.ZodIssueCode.custom,
			message: `Please enter Phone`,
			fatal: true,
		});
		return z.NEVER;
	}
	if (!isValidPhoneNumber(val)) {
		ctx.addIssue({
			code: z.ZodIssueCode.custom,
			fatal: true,
			message: "Invalid phone number format",
		});
		return z.NEVER;
	}
	if (!isPossiblePhoneNumber(val)) {
		ctx.addIssue({
			code: z.ZodIssueCode.custom,
			message: `Invalid phone number length`,
			fatal: true,
		});
		return z.NEVER;
	}
};

export const BranchSchema = z.object({
	entityId: z.string(),
	name: z.string().min(1, { message: "Branch name is required" }),
	adminSalutation: z.string().min(1, { message: "Salutation is required" }),
	adminFirstName: z.string().min(1, { message: "First name is required" }),
	adminMiddleName: z.string(),
	adminLastName: z.string().min(1, { message: "Last name is required" }),
	email: z.string().min(1, { message: "Email is required" }).email({ message: "Must be a valid email" }),
	phoneCode: z.string().min(1, { message: "Phone Code is required" }),
	phone: z.string().superRefine(phoneValidation),
	branchphone: z.string().superRefine(phoneValidation).optional(),
	timezone: z.string().refine(
		() => {
			return tzmoment.tz.guess();
		},
		{ message: "Please add Timezone" }
	),
	address: BranchAddressSchema,
});

export type BranchSchemaType = z.infer<typeof BranchSchema>;
// Infer the TS type according to the zod schema.
// const steps = [DetailsStep, ConfigurationStep, DeclarationStep];
const steps = [DetailsStep];
interface Timezone {
	value: string;
	abbr: string;
	text: string;
	utc: string[];
}

function AddBranchPage() {
	const [currentStep, setCurrentStep] = useState(0);
	const [formData, setFormData] = useState({}); // Store form data here

	const [entities, setEntities] = React.useState<Entity[] | null>(null); // this is the list of entities [{}]
	const [loading, setLoading] = React.useState(true);
	const [error, setApiError] = React.useState("");
	const [isPlatformAdmin, setIsPlatformAdmin] = React.useState(false);
	const [entityId, setEntityId] = React.useState<string | null>(null);

	const [phoneValue, setPhoneValue] = useState<E164Number | undefined>();

	const [phoneNumber, setPhoneNumber] = useState("91");
	const [emergencyPhoneNumber, setEmergencyPhoneNumber] = useState("");
	const [utcTime, setUtcTime] = useState("");

	const handlePhoneNumberChange = (value: E164Number | undefined) => {
		setValue("phone", value?.toString() ?? "");
	};

	const userData = getUserPersistedOnLocalStorage();
	useEffect(() => {
		const getEntities = async () => {
			try {
				const response = await axios.get(endpoints.entity.list);
				const data = response.data["data"];
				const convertedEntityList = data.map((entity: any) => Convert.toEntity(JSON.stringify(entity)));

				setEntities(convertedEntityList);

				setLoading(false);
			} catch (error) {
				console.error("Error fetching entity list:", error);
				setApiError(error.message);
				setLoading(false);
			}
		};

		if (userData?.user_role === "PLATFORM_ADMIN") {
			getEntities();

			setIsPlatformAdmin(true);
			setEntityId("");
		} else {
			setEntityId(userData?.entity_id ?? "");

			setLoading(false);
		}

		TimezoneData.timezones.map((timezone: Timezone, index: number) =>
			timezone.utc.map((tz) => {
				if (tz === tzmoment.tz.guess()) {
					setUtcTime(timezone.text);
				}
			})
		);
	}, []);

	const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setEntityId(event.target.value); // Update the state with the selected value
	};

	const navigator = useNavigate();

	const {
		register,
		handleSubmit,
		watch,
		reset,
		control,
		setValue,
		setError,
		clearErrors,
		trigger,
		formState: { errors },
	} = useForm<BranchSchemaType>({
		resolver: zodResolver(BranchSchema),
	});

	useEffect(() => {
		const countryCode = getCountryCallingCode("IN");
		setValue("phoneCode", countryCode?.toString() ?? "");
		setValue("phone", "");
	}, []);

	const onSubmit: SubmitHandler<BranchSchemaType> = async (data) => {
		setLoading(true);
		try {
			const combinedData = { ...formData, ...data };

			// Convert both phone numbers
			const adminPhoneData = convertPhoneNumberAndCodeToNumber({
				phoneNumber: combinedData.phone,
				phoneCode: combinedData.phoneCode,
			});

			const branchPhoneData = convertPhoneNumberAndCodeToNumber({
				phoneNumber: combinedData.branchphone,
				phoneCode: combinedData.phoneCode,
			});

			BranchSchema.parse(combinedData);

			const paramsMap = new Map<string, string>([["entityId", combinedData.entityId]]);
			const filteredEndpoint = replaceParams(endpoints.branch.onboard, paramsMap);
			const response = await axios.post(filteredEndpoint, {
				...combinedData,
				phone: adminPhoneData.phoneNumber,
				phoneCode: adminPhoneData.phoneCode,
				branchphone: branchPhoneData.phoneNumber,
			});

			if (response.data.status === 200) {
				toast.success(response.data.message === "success" ? "Branch onboarded successfully" : response.data.message);
			}
			setLoading(false);
			navigator("/branch");
		} catch (error) {
			toast.error(error["response"]["data"]["data"]);
			setApiError(error.response.data["data"].toString());
			setLoading(false);
			console.error("Error submitting form:", error);
		}
	};

	const StepComponent = steps[currentStep];

	// const StepTitles = ['Details', 'Configuration', 'Declaration'];
	const StepTitles = ["Details"];

	function checkDuplicateEmail() {
		var email = watch("email");

		if (email.trim() !== "")
			if (email) {
				const paramsMap = new Map<string, string>([["email", watch("email")]]);
				const filteredEndpoint = replaceParams(endpoints.staff.checkStaffEmail, paramsMap);
				axios
					.get(filteredEndpoint)
					.then((res) => {
						clearErrors("email");
					})
					.catch((error) => {
						if (error && error.response && error.response.data && error.response.data.status === 500) {
							setError("email", {
								type: "custom",
								message: "Account with this email already exists. Please contact support.",
							});
							setValue("email", "");
						} else {
							toast.error(error.message);
							setValue("email", "");
						}
					});
			}
	}

	return (
		<>
			<div className="flex flex-col w-full h-full">
				<div className="flex flex-col justify-start">
					<div className="flex flex-row justify-between">
						<h1 className="text-2xl font-bold">Add Branch</h1>
						<Button
							variant={ButtonVariant.PRIMARY}
							type="submit"
							className="ml-3"
							onClick={() => {
								navigator("/branch");
							}}
						>
							Back
						</Button>
					</div>
					<span className="text-gray-500">Add a new business branch to the system</span>
				</div>

				<hr className="my-4" />

				<div className="flex flex-row justify-between md:justify-start items-center w-full">
					{StepTitles.map((title, index) => (
						<div
							key={index}
							className={`flex flex-row py-2 px-3 mx-2 items-center justify-center rounded-xl ${
								index === currentStep ? "bg-primary-100 text-primary" : "bg-gray-200 text-gray-500"
							}`}
						>
							<div className="flex flex-col mr-2 h-6 w-6 rounded-full bg-primary-600 text-white text-sm text-primary justify-center items-center">
								<span>{index + 1}</span>
							</div>
							<span>{title}</span>
						</div>
					))}
				</div>

				<hr className="my-4" />

				<form onSubmit={handleSubmit(onSubmit)} noValidate>
					<div className="mb-32">
						<div>
							{loading && <p>Loading...</p>}
							{error && <p>Error: {error}</p>}
							{!loading && !error && entities && (
								<div className="flex flex-col w-full space-y-4">
									<div className="flex flex-col ">
										<label className="block text-sm font-medium leading-6 text-grey-900 mt-2.5">Select Entity</label>

										<select
											{...register("entityId")}
											onChange={handleSelectChange}
											name="entityId"
											className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
										>
											<option key="" value="">
												Select
											</option>
											{entities!.map((entity) => (
												<option key={entity.id} value={entity.id}>
													{entity.name}
												</option>
											))}
										</select>
										{errors.adminSalutation && (
											<ValidationError message={errors.adminSalutation?.message?.toString() ?? ""} />
										)}
									</div>
								</div>
							)}
							{!loading && !error && !isPlatformAdmin && (
								<div className="flex flex-col w-full space-y-4">
									<div className="flex flex-col ">
										<input
											{...register("entityId")}
											name="entityId"
											className={`hidden lock w-full rounded-lg bg-gray-100 border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm `}
											value={userData?.entity_id}
										/>

										{errors.entityId && <ValidationError message={errors.entityId?.message?.toString() ?? ""} />}
									</div>
								</div>
							)}

							<div className={entityId === "" ? "invisible" : "visible"}>
								<div className="flex flex-col w-full md:w-2/3 pr-2">
									<Applabel label="Timezone" mandatory />
									<select
										{...register("timezone")}
										value={watch("timezone")}
										placeholder="Timezone"
										className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
									>
										<option value={tzmoment.tz.guess()}>
											{tzmoment.tz.guess()} - {utcTime}
										</option>
										{TimezoneData.timezones.map((timezone: Timezone, index: number) =>
											timezone.utc.map((tz) => (
												<option key={tz} value={tz}>
													{tz} -{timezone.text}
												</option>
											))
										)}
									</select>
								</div>
								{/* Entity Name */}
								<div className="flex flex-col w-full md:w-2/3 pr-2">
									<Applabel label="Branch Name" mandatory />

									<input
										type="text"
										key="name"
										{...register("name")}
										value={watch("name")}
										placeholder="Branch Name"
										className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
									/>
									{errors.name && <ValidationError message={errors.name?.message?.toString() ?? ""} />}
								</div>

								{/* Entity Owner */}
								<div className="flex flex-col md:flex-row w-full justify-start items-start mt-0 md:space-x-4">
									<div className="flex flex-col w-full md:w-1/3">
										<div className="flex flex-row w-full justify-start space-x-2">
											<div className="flex flex-col ">
												<Applabel label="Title" mandatory />
												<select
													{...register("adminSalutation")}
													placeholder="Salutation"
													className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
													value={watch("adminSalutation")}
												>
													<option key="" value="">
														Select
													</option>
													{["Mr", "Mrs", "Ms", "Miss", "Dr", "Prof", "Rev", "Hon", "Capt"].map((salutation) => {
														return (
															<option key={salutation} value={salutation}>
																{salutation}
															</option>
														);
													})}
												</select>
												{errors.adminSalutation && (
													<ValidationError message={errors.adminSalutation?.message?.toString() ?? ""} />
												)}
											</div>
											<div className="flex flex-col grow">
												<Applabel label="First Name" mandatory />
												<input
													{...register("adminFirstName")}
													placeholder="Branch Admin First Name"
													value={watch("adminFirstName")}
													type="text"
													className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
												/>
												{errors.adminFirstName && (
													<ValidationError message={errors.adminFirstName?.message?.toString() ?? ""} />
												)}
											</div>
										</div>
									</div>
									<div className="flex flex-col w-full md:w-1/3">
										<Applabel label="Middle Name" />
										<input
											{...register("adminMiddleName")}
											placeholder="Branch Admin Middle Name"
											value={watch("adminMiddleName")}
											className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
										/>
										{errors.adminMiddleName && (
											<ValidationError message={errors.adminMiddleName?.message?.toString() ?? ""} />
										)}
									</div>
									<div className="flex flex-col w-full md:w-1/3">
										<Applabel label="Last Name" mandatory />
										<input
											{...register("adminLastName")}
											className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
											placeholder="Branch Admin Last Name"
											value={watch("adminLastName")}
										/>
										{errors.adminLastName && (
											<ValidationError message={errors.adminLastName?.message?.toString() ?? ""} />
										)}
									</div>
								</div>

								<div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4">
									<div className="flex flex-col md:w-1/3">
										<Applabel label="Email" mandatory />
										<input
											{...register("email")}
											placeholder="Branch Admin Email"
											onBlur={checkDuplicateEmail}
											className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
											value={watch("email")}
											required
										/>
										{errors.email && <ValidationError message={errors.email?.message?.toString() ?? ""} />}
									</div>

									<div className="flex flex-col md:w-1/4">
										<Applabel label="Mobile Number" mandatory />
										<div className="flex flex-row">
											{" "}
											<PhoneInput
												onCountryChange={(country?: CountryCode | undefined) => {
													if (country) {
														const countryCode = getCountryCallingCode(country);
														setValue("phone", "");
														setValue("phoneCode", countryCode?.toString() ?? "");
													}
												}}
												{...register("phone")}
												international={true}
												defaultCountry="IN"
												countryCallingCodeEditable={false}
												placeholder="Enter phone number"
												value={watch("phone")}
												onChange={(value?: E164Number | undefined) => {
													clearErrors("phone");
													handlePhoneNumberChange(value);
												}}
												onBlur={() => {
													trigger("phone");
												}}
												className="w-full"
											/>
										</div>
										{errors.phone && <ValidationError message={errors.phone?.message?.toString() ?? ""} />}
									</div>

									{/* New Branch Contact Number Field */}
									<div className="flex flex-col md:w-1/4">
										<Applabel label="Branch Contact Number" />
										<div className="flex flex-row">
											<PhoneInput
												onCountryChange={(country?: CountryCode | undefined) => {
													if (country) {
														const countryCode = getCountryCallingCode(country);
														setValue("branchphone", "");
														setValue("phoneCode", countryCode?.toString() ?? "");
													}
												}}
												{...register("branchphone")}
												international={true}
												defaultCountry="IN"
												countryCallingCodeEditable={false}
												placeholder="Enter branch contact number"
												value={watch("branchphone")}
												onChange={(value?: E164Number | undefined) => {
													clearErrors("branchphone");
													setValue("branchphone", value?.toString() ?? "");
												}}
												onBlur={() => {
													trigger("branchphone");
												}}
												className="w-full"
											/>
										</div>
										{errors.branchphone && <ValidationError message={errors.branchphone?.message?.toString() ?? ""} />}
									</div>

									<div className="flex flex-col md:w-1/3"></div>
								</div>

								{/* Entity Address */}
								<hr className="my-2" />

								<span className="flex-none text-lg font-medium w-1/6 mb-0">Address</span>

								<hr className="my-2" />

								<div className="flex flex-col md:flex-row md:w-full md:space-x-4">
									<div className="flex flex-col w-full md:w-2/3">
										<Applabel label="Address" mandatory />

										<input
											{...register("address.address")}
											placeholder="Branch Address"
											className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
											required
										/>
										{errors.address && <ValidationError message={errors.address?.address?.message?.toString() ?? ""} />}
									</div>
									<div className="flex flex-col w-full md:w-1/3">
										<Applabel label="Postal/Zip code" mandatory />

										<input
											{...register("address.postalCode")}
											placeholder="Branch Zip Code"
											className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
											required
											value={watch("address.postalCode")}
										/>
										{errors.address?.postalCode && (
											<ValidationError message={errors.address.postalCode?.message?.toString() ?? ""} />
										)}
									</div>
								</div>

								<CountryInputSelect
									isDisabled={false}
									register={register}
									setValue={setValue}
									watch={watch}
									country="address.country"
									state="address.state"
									city="address.city"
								/>
								<div className="fixed items-center w-full justify-end bottom-0 h-16 z-50 shrink-0 mt-4 p-4 -ml-8 shadow bg-gray-100">
									<div className="flex flex-row ">
										<div>
											<Button
												variant={ButtonVariant.PRIMARY}
												type="submit"
												className="ml-3"
												onClick={handleSubmit(onSubmit)}
											>
												Save
											</Button>
										</div>
									</div>
								</div>
							</div>
							<div className="h-20 mt-10"></div>
						</div>
					</div>

					{/*             
            <div className="fixed items-start w-full justify-end bottom-0 h-16 z-50 shrink-0 mt-4 p-4 -ml-8 shadow bg-gray-100">
              {currentStep > 0 && (
                <OutlinedButton
                  variant={ButtonVariant.PRIMARY}
                  onClick={() => setCurrentStep(currentStep - 1)}
                  className="mr-2"
                  type="button"
                >
                  Previous
                </OutlinedButton>
              )}
              {currentStep < steps.length - 1 && (
                <OutlinedButton
                  variant={ButtonVariant.PRIMARY}
                  onClick={() => {
                    setCurrentStep(currentStep + 1);
                    setFormData({ ...formData, ...methods.getValues() });
                  }}
                  isDisabled={methods.formState.isDirty || !methods.formState.isValid}
                >
                  Next
                </OutlinedButton>
              )}
              {error && <ValidationError message={error ?? ''} />}

              {currentStep === steps.length - 1 && (
                <Button
                  variant={ButtonVariant.PRIMARY}
                  onClick={() => {
                    setFormData({ ...formData, ...methods.getValues() });
                  }}
                  type="submit"
                >
                  Submit
                </Button>
              )}
            </div> */}
				</form>
			</div>
		</>
	);
}

export default AddBranchPage;
