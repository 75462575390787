import { endpoints, replaceParams } from "@utils/axios";
import React, { Fragment, useEffect } from "react";
import { Convert, PersonalInformationDetails } from "@pages/manage-patient/types/PatientPersonalDetails";
import axios from "axios";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import EditPersonalInformation from "./EditPersonalInformation";
import SolidButton from "@components/button/button";
import Modal from "@components/modal";
import Button from "@components/button/outline-button";
import { ButtonVariant } from "@components/button/button";

import FeedbackRating from "./FeedbackRating";
import RightOffcanvas from "@components/right-offcanvas";
import Reports from "./Reports";
import ImportantNote from "./ImportantNote";
import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import PatientPaymentDetails from "./PatientPaymentDetails";
import PatientJournal from "./PatientJournal";
import ProgressTracker from "./ProgressTracker";
import { OutlinedButton } from "@components/button";
import Billing from "@pages/manage-patient-new/view/Billing";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import {
	ArrowUpOnSquareIcon,
	BanknotesIcon,
	CalendarIcon,
	ExclamationCircleIcon,
	PencilIcon,
} from "@heroicons/react/24/solid";
import PatientActivity from "./PatientActivity";
interface DummyProfilePictureContainerProps {
	selectedImage: string;
	setSelectedImage: React.Dispatch<React.SetStateAction<string>>;
	patientId: string;
}
function DummyProfilePictureContainer({
	selectedImage,
	setSelectedImage,
	patientId,
}: DummyProfilePictureContainerProps) {
	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const [displayImage, setDisplayImage] = React.useState<string>("");
	const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (file) {
			if (file.size <= 1024 * 1024) {
				const reader = new FileReader();
				reader.onload = () => {
					setSelectedImage(reader.result as string);
				};
				reader.readAsDataURL(file);
				const formData = new FormData();
				formData.append("file", file);
				try {
					const paramsMap = new Map<string, string>([["patientId", patientId]]);
					const filteredEndpoint = replaceParams(endpoints.patient.updateProfilePicture, paramsMap);
					axios.put(filteredEndpoint, formData).then(async (response) => {
						if (response.status === 200) {
							toast.success(response.data.message === "success" ? "File upload successfully" : response.data.message);
						}
					});
				} catch (e) {}
			} else {
				toast.error("File size exceeds the limit of 1MB");
			}
		}
	};
	useEffect(() => {
		setDisplayImage(selectedImage);
	}, [selectedImage]);
	const openModal = () => setIsModalOpen(true);
	const closeModal = () => setIsModalOpen(false);
	return (
		<div className="flex flex-col items-center justify-center">
			{displayImage != "" ? (
				<div className="w-full h-full rounded-lg overflow-hidden   hover:border-secondary-500 cursor-pointer items-center justify-center text-center">
					<img
						src={displayImage}
						alt="Profile"
						className="w-40 h-40 object-cover object-center justify-center items-center text-center"
						onClick={openModal}
					/>
				</div>
			) : (
				<div className="w-full h-20 flex items-center justify-center bg-gray-300 rounded-lg border border-gray-300">
					<svg xmlns="http://www.w3.org/2000/svg" height="100%" viewBox="0 0 448 512" className="text-gray-500 p-2">
						<path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464H398.7c-8.9-63.3-63.3-112-129-112H178.3c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z" />
					</svg>
				</div>
			)}

			<label
				htmlFor="imageInput"
				className="mt-2 w-full cursor-pointer text-center text-secondary-500  p-1 text-sm rounded justify-center"
			>
				<span className="underline">Change</span>
			</label>

			<input type="file" id="imageInput" accept="image/*" className="hidden" onChange={handleImageChange} />
			<Transition appear show={isModalOpen} as={Fragment}>
				<Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto " onClose={closeModal}>
					<div className="min-h-screen px-4 text-center">
						<Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
						<div className="inline-block align-middle my-16 p-4 w-full max-w-lg text-left overflow-hidden shadow-xl transform transition-all bg-white">
							<img src={displayImage} alt="Profile" className="w-full h-full object-cover rounded-lg" />
							<button className="absolute top-0 right-1 m-4 text-xl cursor-pointer" onClick={closeModal}>
								&times;
							</button>
						</div>
					</div>
				</Dialog>
			</Transition>
		</div>
	);
}
export default function PatientInfoCardV2(props: { patientId: string }) {
	const userData = getUserPersistedOnLocalStorage();
	const [patientPersonalInformation, setPatientPersonalInformation] = React.useState<PersonalInformationDetails | null>(
		null
	);
	const [editPersonalInformationDialog, setEditPersonalInformationDialog] = React.useState(false);
	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const [isRightOffcanvasOpen, setIsRightOffcanvasOpen] = React.useState(false);
	const [isRightOffcanvasOpenToFeedback, setIsRightOffcanvasOpenToFeedback] = React.useState(false);
	const [isRightOffcanvasOpenToJournal, setIsRightOffcanvasOpenToJournal] = React.useState(false);
	const [isRightOffcanvasOpenToProgressTracker, setIsRightOffcanvasOpenToProgressTracker] = React.useState(false);
	const [isRightOffcanvasOpenToReport, setIsRightOffcanvasOpenToReport] = React.useState(false);
	const [isRightOffcanvasOpenToMakePayment, setIsRightOffcanvasOpenToMakePayment] = React.useState(false);
	const [isRightOffcanvasOpenToBilling, setIsRightOffcanvasOpenToBilling] = React.useState(false);
	const [isRightOffcanvasOpenPactivity, setIsRightOffcanvasOpenPactivity] = React.useState(false);
	const [isImportantNoteModalOpen, setIsImportantNoteModalOpen] = React.useState(false);
	const handleCloseImportantNoteModal = () => setIsImportantNoteModalOpen(false);
	const handleOpenImportantNoteModal = () => setIsImportantNoteModalOpen(true);
	const [selectedImage, setSelectedImage] = React.useState<string>("");
	const getPatientData = async () => {
		try {
			const paramsMap = new Map<string, string>([["patientId", props.patientId]]);
			const filteredEndpoint = replaceParams(endpoints.patient.view, paramsMap);
			const response = await axios.get(filteredEndpoint);
			const data = response.data["data"];
			const convertedPersonalInformation = Convert.toPersonalInformation(JSON.stringify(data));
			setPatientPersonalInformation(convertedPersonalInformation);
			if (convertedPersonalInformation.profilePictureUrl === "") {
				setSelectedImage("");
			} else {
				setSelectedImage(convertedPersonalInformation.profilePictureUrl);
			}
		} catch (error) {
			console.error("Error getting patient information", error);
		}
	};
	React.useEffect(() => {
		const _init = async () => {
			await getPatientData();
		};
		//This is to get the patient data
		_init();
	}, []);

	const handleCloseRightOffCanvas = () => {
		setIsRightOffcanvasOpenToFeedback(false);
		setIsRightOffcanvasOpenToJournal(false);
		setIsRightOffcanvasOpenToProgressTracker(false);
		setIsRightOffcanvasOpenToReport(false);
		setIsRightOffcanvasOpenToMakePayment(false);
		setIsRightOffcanvasOpenToBilling(false);
		setIsRightOffcanvasOpenPactivity(false);
	};
	return (
		<div className="flex flex-col w-full  ">
			<Modal isOpen={isImportantNoteModalOpen} onClose={handleCloseImportantNoteModal}>
				<ImportantNote
					handleCloseImportatntNoteModal={handleCloseImportantNoteModal}
					notes={patientPersonalInformation?.notes ?? ""}
					patientId={props.patientId}
					getPatientData={getPatientData}
				/>
			</Modal>
			<RightOffcanvas
				isOpen={isRightOffcanvasOpenToFeedback}
				onClose={handleCloseRightOffCanvas}
				sizeClasses="w-full lg:w-[100vh]"
			>
				{isRightOffcanvasOpenToFeedback && (
					<FeedbackRating handleCloseRightOffCanvas={handleCloseRightOffCanvas} patientId={props.patientId} />
				)}
			</RightOffcanvas>
			<RightOffcanvas
				isOpen={isRightOffcanvasOpenPactivity}
				onClose={handleCloseRightOffCanvas}
				sizeClasses="w-full lg:w-[100vh]"
			>
				{isRightOffcanvasOpenPactivity && (
					<PatientActivity handleCloseRightOffCanvas={handleCloseRightOffCanvas} patientId={props.patientId} />
				)}
			</RightOffcanvas>
			<RightOffcanvas
				isOpen={isRightOffcanvasOpenToJournal}
				onClose={handleCloseRightOffCanvas}
				sizeClasses="w-full lg:w-[100vh]"
			>
				{isRightOffcanvasOpenToJournal && (
					<PatientJournal handleCloseRightOffCanvas={handleCloseRightOffCanvas} patientId={props.patientId} />
				)}
			</RightOffcanvas>
			<RightOffcanvas
				isOpen={isRightOffcanvasOpenToReport}
				onClose={handleCloseRightOffCanvas}
				sizeClasses="w-full lg:w-[100vh]"
			>
				{isRightOffcanvasOpenToReport && <Reports patientId={props.patientId} onClose={handleCloseRightOffCanvas} />}
			</RightOffcanvas>
			<RightOffcanvas
				isOpen={isRightOffcanvasOpenToMakePayment}
				onClose={handleCloseRightOffCanvas}
				sizeClasses="w-full"
			>
				{isRightOffcanvasOpenToMakePayment && (
					<PatientPaymentDetails
						patientId={props.patientId}
						onClose={handleCloseRightOffCanvas}
						patientPersonalInformation={patientPersonalInformation}
					/>
				)}
			</RightOffcanvas>
			<RightOffcanvas isOpen={isRightOffcanvasOpenToBilling} onClose={handleCloseRightOffCanvas} sizeClasses="w-full">
				{isRightOffcanvasOpenToBilling && (
					<Billing
						patientId={props.patientId}
						onClose={handleCloseRightOffCanvas}
						patientPersonalInformation={patientPersonalInformation}
					/>
				)}
			</RightOffcanvas>
			<RightOffcanvas
				isOpen={isRightOffcanvasOpenToProgressTracker}
				onClose={handleCloseRightOffCanvas}
				sizeClasses="w-full lg:w-[100vh]"
			>
				{isRightOffcanvasOpenToProgressTracker && (
					<ProgressTracker
						handleCloseRightOffCanvas={handleCloseRightOffCanvas}
						patientId={props.patientId}
						isPopup={true}
					/>
				)}
			</RightOffcanvas>
			<div className="flex lg:flex-col lg:w-full ">
				<div className="flex flex-col w-full">
					<div className="flex flex-col w-full gap-10 ">
						<div className="flex flex-col lg:flex-row  space-x-8 justify-center border rounded-md shadow-md p-4  items-center">
							<DummyProfilePictureContainer
								selectedImage={selectedImage}
								setSelectedImage={setSelectedImage}
								patientId={props.patientId}
							/>
							<div className="flex flex-col justify-center items-center text-sm ">
								<h3 className="text-xl text-gray-900 font-medium">
									{patientPersonalInformation?.firstName} {patientPersonalInformation?.lastName}
								</h3>
								<div className="flex flex-col lg:flex-row space-x-2">
									<div className="hover:underline hover:text-secondary-500 cursor-pointer">
										+{patientPersonalInformation?.phoneCode}{" "}
										{userData?.user_role === "ENTITY_OWNER" || userData?.user_role === "PLATFORM_ADMIN"
											? `${patientPersonalInformation?.phone}`
											: `${
													patientPersonalInformation?.phone != null
														? patientPersonalInformation.phone.toString().length > 4
															? patientPersonalInformation?.phone
															: `******${patientPersonalInformation?.phone}`
														: ""
											  }`}
									</div>
									<div className="hover:underline hover:text-secondary-500 cursor-pointer">
										{patientPersonalInformation?.email}
									</div>
								</div>
								<div className="mt-5 flex flex-row gap-2">
									<div className="">
										<Button variant={ButtonVariant.GRAY} onClick={() => setEditPersonalInformationDialog(true)}>
											<PencilIcon className="w-5 h-5 text-primary-500" />
											<span className="pl-1 text-xs">Edit Profile</span>
										</Button>
									</div>
									<div className="">
										<Button
											variant={ButtonVariant.PRIMARY}
											onClick={() => setIsRightOffcanvasOpenToReport(true)}
											className=""
										>
											<ArrowUpOnSquareIcon className="w-5 h-5 text-primary-500" />
											<span className="pl-1 text-xs">Upload Reports</span>
										</Button>
									</div>
								</div>
							</div>
						</div>
						<div className="flex flex-row p-4 justify-center lg:border lg:rounded-md lg:shadow-md">
							<div className="flex gap-2 flex-col lg:flex-row justify-center">
								{patientPersonalInformation?.notes !== "" ? (
									<Button
										variant={ButtonVariant.GRAY}
										className="mx-1 border border-red-700 w-44"
										onClick={handleOpenImportantNoteModal}
									>
										<ExclamationCircleIcon className="w-5 h-5 text-red-700" />
										<span className="pl-1 text-xs text-red-700"> Important Notes </span>
									</Button>
								) : (
									<Button variant={ButtonVariant.GRAY} className="mx-1 w-44" onClick={handleOpenImportantNoteModal}>
										<ExclamationCircleIcon className="w-5 h-5 text-primary-500" />
										<span className="pl-1 text-xs"> Important Notes </span>
									</Button>
								)}
								<Button
									variant={ButtonVariant.GRAY}
									className="mx-1 w-44"
									onClick={() => setIsRightOffcanvasOpenPactivity(true)}
								>
									<BanknotesIcon className="w-5 h-5 text-primary-500" />
									<span className="pl-1 text-xs"> Patient Activity</span>
								</Button>
							</div>
							<div className="flex gap-2 flex-col lg:flex-row justify-center">
{/* 								<Button
									variant={ButtonVariant.SECONDARY}
									className="mx-1 w-44"
									onClick={() => setIsRightOffcanvasOpenToBilling(true)}
								>
									<BanknotesIcon className="w-5 h-5 text-primary-500" />
									<span className="pl-1 text-xs"> Billing/Payments </span>
								</Button> */}
								<Button
									variant={ButtonVariant.SECONDARY}
									className="mx-1 w-44"
									onClick={() => setIsRightOffcanvasOpenToMakePayment(true)}
								>
									<CalendarIcon className="w-5 h-5 text-primary-500" />
									<span className="pl-1 text-xs"> Appointments </span>
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/*       <div className="lg:hidden lg:hidden xl:hidden w-full">
        <div className="flex flex-col items-center pb-10">
          <DummyProfilePictureContainer
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            patientId={props.patientId}
          />
          <h5 className="my-1 text-xl font-bold text-gray-900 dark:text-white">
            {patientPersonalInformation?.firstName}{" "}
            {patientPersonalInformation?.lastName}
          </h5>
          <span className="text-sm text-gray-500 dark:text-gray-400">
            +{patientPersonalInformation?.phoneCode}{" "}
            {userData?.user_role === "ENTITY_OWNER" ||
              userData?.user_role === "PLATFORM_ADMIN"
              ? `${patientPersonalInformation?.phone}`
              : `${patientPersonalInformation?.phone != null
                ? patientPersonalInformation.phone.toString().length > 4
                  ? patientPersonalInformation?.phone
                  : `******${patientPersonalInformation?.phone}`
                : ""
              }`}
          </span>
          <span className="text-sm text-gray-500 dark:text-gray-400">
            {patientPersonalInformation?.email}
          </span>
          <div className="flex flex-row items-center mt-4">
            <a
              className="mr-4"
              onClick={() => setIsRightOffcanvasOpenToReport(true)}
            >
              <span className="material-symbols-outlined">
                document_scanner
              </span>
            </a>

            <a
              className="mr-4"
              onClick={() => setIsRightOffcanvasOpenToFeedback(true)}
            >
              <span className="material-symbols-outlined">description</span>
            </a>
            <a
              className="mr-4"
              onClick={() => setIsRightOffcanvasOpenToJournal(true)}
            >
              <span className="material-symbols-outlined">forum</span>
            </a>
            <a
              className="mr-4"
              onClick={() => setIsRightOffcanvasOpenToProgressTracker(true)}
            >
              <span className="material-symbols-outlined">leaderboard</span>
            </a>
            <a className="mr-4" onClick={handleOpenImportantNoteModal}>
              <span className="material-symbols-outlined">clinical_notes</span>
            </a>
            <a
              className="mr-4"
              onClick={() => setIsRightOffcanvasOpenToMakePayment(true)}
            >
              <span className="material-symbols-outlined">book_online</span>
            </a>
            <a
              className="mr-4"
              onClick={() => setIsRightOffcanvasOpenToBilling(true)}
            >
              <span className="material-symbols-outlined">payments</span>
            </a>

            <a
              className="mr-4"
              onClick={() => setEditPersonalInformationDialog(true)}
            >
              <span className="material-symbols-outlined">edit</span>
            </a>
          </div>
        </div>
      </div> */}
			{/* <hr className="my-2" /> */}
			{editPersonalInformationDialog && (
				<EditPersonalInformation
					onClose={() => setEditPersonalInformationDialog(false)}
					setAddPaymentDetails={setEditPersonalInformationDialog}
					patientId={props.patientId}
					getPatientData={getPatientData}
				/>
			)}
		</div>
	);
}
