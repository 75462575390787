import axios, { AxiosRequestConfig } from "axios";
// config
import { HOST_API } from "../config-global";
import { useAuthContext } from "@authentication/hooks/use-auth-context";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import { STORAGE_KEYS } from "@constants/storage-keys";

// ----------------------------------------------------------------------

// const axiosInstance = axios.create({
//   baseURL: 'http://13.235.109.230:8081',
//   // baseURL: HOST_API,
//   headers: {
//     'Content-Type': 'application/json',
//   },
//   withCredentials: true,
// });

// // axios.interceptors.request.use(
// //   async (config) => {
// //     const accessToken = sessionStorage.getItem(STORAGE_KEYS.APP_STATE.ACCESS_TOKEN);

// //     config.headers = {
// //       'Authorization': `Bearer ${accessToken}`,
// //       'Accept': 'application/json',
// //       'Content-Type': 'application/x-www-form-urlencoded',
// //     };
// //     return config;
// //   },
// //   (error) => {
// //     Promise.reject(error);
// //   }
// // );
// // // Response interceptor for API calls
// // axios.interceptors.response.use(
// //   (response) => {
// //     return response;
// //   },
// //   async function (error) {
// //     const userData = getUserPersistedOnLocalStorage();
// //     const email = userData?.email;
// //     const refreshToken = sessionStorage.getItem(STORAGE_KEYS.APP_STATE.REFRESH_TOKEN);
// //     const originalRequest = error.config;
// //     if (error.response.status === 403 && !originalRequest._retry) {
// //       originalRequest._retry = true;
// //       const accessToken = await refresh(email ?? '', refreshToken ?? '');

// //       axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
// //       return axiosInstance(originalRequest);
// //     }
// //     return Promise.reject(error);
// //   }
// // );

// export default axiosInstance;

// ----------------------------------------------------------------------

export const getCsrfToken = () => {
	const cookie = document.cookie;
	const tokenPrefix = "csrf_=";

	if (cookie.includes(tokenPrefix)) {
		const token = cookie.slice(cookie.indexOf(tokenPrefix) + tokenPrefix.length);
		return token;
	}

	return " ";
};

export const setCookieHeader = () => {
	const csrfToken = getCsrfToken();

	if (csrfToken) {
		axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
		// axios.defaults.headers.common["X-CSRF-Token"] = "csrfToken";
	} else {
		console.warn("CSRF Token not found in cookies");
	}
};

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
	const [url, config] = Array.isArray(args) ? args : [args];

	const headers = {
		...config?.headers,
	};

	const res = await axios.get(url, { ...config, headers });

	return res.data;
};
setCookieHeader();

// ----------------------------------------------------------------------
export const replaceParams = (url: string, params: Map<string, string>, queryParams?: Map<string, string>) => {
	let tempUrl = url;
	params.forEach((value, key) => {
		const toFind: string = `:${key}`;
		tempUrl = tempUrl.replace(toFind, value.toString());
	});
	if (queryParams && queryParams.size > 0) {
		const queryString = Array.from(queryParams.entries())
			.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
			.join("&");
		tempUrl += `?${queryString}`;
	}
	return tempUrl;
};

const BASE_URL = "/core-svc"; // Define the base URL constant
const DOCUMENT_SVC_BASE_URL = "/document-svc"; // Define the base URL constant

export const endpoints = {
	themeData: `${BASE_URL}/api/v1/theme-data`,
	auth: {
		login: `${BASE_URL}/api/v1/auth/login`,
		loginWithGoogle: `${BASE_URL}/api/v1/auth/google/oauth/login`,
		logout: `${BASE_URL}/api/v1/secure/logout`,
		forgotpassword: `${BASE_URL}/api/v1/auth/forgot-password`,
		verifypassword: `${BASE_URL}/api/v1/auth/verify-password`,
		resetpassword: `${BASE_URL}/api/v1/auth/reset-password`,
		refresh: `${BASE_URL}/api/v1/auth/refresh-token`,
	},
	health: {
		healthcheck: `${BASE_URL}/api/v1/auth/healthcheck`,
	},
	entity: {
		list: `${BASE_URL}/api/v1/secure/entities/list`,
		onboard: `${BASE_URL}/api/v1/secure/entity`,
		update: `${BASE_URL}/api/v1/secure/entity/:id`,
		getworkinghours: `${BASE_URL}/api/v1/secure/entity/:entityId/working-hours`,
		updateworkinghours: `${BASE_URL}/api/v1/secure/entity/:entityId/working-hours`,
		validateWorkingHours: `${BASE_URL}/api/v1/secure/working-hours`,
		view: `${BASE_URL}/api/v1/secure/entity/:entityId`,
		branches: `${BASE_URL}/api/v1/secure/entity/:entityId/branches/list`,
		staff: `${BASE_URL}/api/v1/secure/entity/:entityId/user/list`,
		patients: `${BASE_URL}/api/v1/secure/entity/:entityId/client/list`,
		therapy: `${BASE_URL}/api/v1/secure/entity/:id/therapies`,
		addFeedbackForm: `${BASE_URL}/api/v1/secure/feedbackform`,
		updateFeedbackForm: `${BASE_URL}/api/v1/secure/feedbackform/:id`,
		fetchFeedbackForm: `${BASE_URL}/api/v1/secure/feedbackform/:entityId`,
		fetchClientConsent: `${BASE_URL}/api/v1/secure/entity/:id/consentform`,
		addClientConsent: `${BASE_URL}/api/v1/secure/entity/:id/consentform`,
		fetchClientNotification: `${BASE_URL}/api/v1/secure/entity/:id/emailsetting`,
		updateClientNotification: `${BASE_URL}/api/v1/secure/entity/:id/emailsetting`,
		fetchAppointmentSetting: `${BASE_URL}/api/v1/secure/entity/:id/appointmentsetting`,
		updateAppointmentSetting: `${BASE_URL}/api/v1/secure/entity/:id/appointmentsetting`,
		deleteAppointmentType: `${BASE_URL}/api/v1/secure/entity/:id/appointmenttypeduration/:type`,
		updateProfilePicture: `${BASE_URL}/api/v1/secure/entity/:id/logo/update`,
		getEntityPhoneNumberMask: `${BASE_URL}/api/v1/secure/entity/:id/phonenosetting`,
		updateEntityPhoneNumberMask: `${BASE_URL}/api/v1/secure/entity/:id/phonenosetting`,
		getAppointmentPaymentDetails: `${BASE_URL}/api/v1/secure/entity/:id/appointmenttypesetting`,
	},
	appointment: {
		list: `${BASE_URL}/api/v1/secure/appointments/all`,
		view: `${BASE_URL}/api/v1/secure/appointment/:id`,
		availableTimeSlots: `${BASE_URL}/api/v1/secure/users/available-slots`,
		arrayOfAvailabletimeSlots: `${BASE_URL}/api/v1/secure/user/:userId/available-slots`,
		createAppointment: `${BASE_URL}/api/v1/secure/appointment`,
		recurringAppointment: `${BASE_URL}/api/v1/secure/recurringappointments`,
		cancelAppointment: `${BASE_URL}/api/v1/secure/appointments/:id/cancel`,
		rescheduleAppointment: `${BASE_URL}/api/v1/secure/appointment/reschedule/:appointmentId`,
		quickAddPatient: `${BASE_URL}/api/v1/secure/client`,
		fetchAppointmentDuration: `${BASE_URL}/api/v1/secure/entity/:id/appointmenttypeduration`,
		transfer: `${BASE_URL}/api/v1/secure/appointment/:id/transfer`,
		getPayouts: `${BASE_URL}/api/v1/secure/appointments/entitybranch/:id/payouts`,
	},
	staff: {
		list: `${BASE_URL}/api/v1/secure/users/list`,
		onboard: `${BASE_URL}/api/v1/secure/user`,
		update: `${BASE_URL}/api/v1/secure/user/:id`,
		workingHours: `${BASE_URL}/api/v1/secure/user/:id/working-hours`,
		validateWorkingHours: `${BASE_URL}/api/v1/secure/working-hours`,
		view: `${BASE_URL}/api/v1/secure/user/:staffId`,
		appointments: `${BASE_URL}/api/v1/secure/user/:staffId/appointments`,
		checkStaffEmail: `${BASE_URL}/api/v1/secure/user/userbyemail/:email`,
		updateUserStatus: `${BASE_URL}/api/v1/secure/user/:id/:status`,
		getUserStatus: `${BASE_URL}/api/v1/secure/user/:id/activestatus`,
	},
	branch: {
		list: `${BASE_URL}/api/v1/secure/entity/:entityId/branches/list`,
		onboard: `${BASE_URL}/api/v1/secure/entity/:entityId/branch`,
		getworkinghours: `${BASE_URL}/api/v1/secure/entitybranch/:entityId/branch/:branchId/working-hours`,
		updateworkinghours: `${BASE_URL}/api/v1/secure/entitybranch/:id/working-hours`,
		validateWorkingHours: `${BASE_URL}/api/v1/secure/working-hours`,
		update: `${BASE_URL}/api/v1/secure/entity/:entityId/branch/:branchId`,
		view: `${BASE_URL}/api/v1/secure/entity/:entityId/branch/:branchId`,
		staff: `${BASE_URL}/api/v1/secure/entity/:entityId/branch/:branchId/user/list`,
		letterHead: `${BASE_URL}/api/v1/secure/entity-branch/:id/letterhead-preset`,
		services: `${BASE_URL}/api/v1/secure/services/branch/:branchId/all`,
		createService: `${BASE_URL}/api/v1/secure/services`,
		updateService: `${BASE_URL}/api/v1/secure/services/:id`,
		getAllPlans: `${BASE_URL}/api/v1/secure/plans/branch/:branchId/all`,
		createPlan: `${BASE_URL}/api/v1/secure/plans`,
		updatePlan: `${BASE_URL}/api/v1/secure/plans/:id`,
	},
	exercise: {
		list: `${BASE_URL}/api/v1/secure/exercises`,
		onboard: `${BASE_URL}/api/v1/secure/exercise`,
		view: `${BASE_URL}/api/v1/secure/exercise/:id`,
		updateExercise: `${BASE_URL}/api/v1/secure/exercise/:id`,
		addExternalLink: `${BASE_URL}/api/v1/secure/exercise/:id/external-link`,
		addMedia: `${BASE_URL}/api/v1/secure/exercise/:id/media`,
		fetchMedia: `${BASE_URL}/api/v1/secure/exercise/:id/media`,
		updateExternalLink: `${BASE_URL}/api/v1/secure/exercise/:id/external-link/:linkId`,
		deleteExternalLink: `${BASE_URL}/api/v1/secure/exercise/:id/external-link/:linkId`,
		deleteMedia: `${BASE_URL}/api/v1/secure/exercise/:id/media/:mediaId`,
		checkExerciseTitle: `${BASE_URL}/api/v1/secure/exercisebyname/:name`,
		gritupLibrary: `${BASE_URL}/api/v1/secure/exercises/PlatformLibrary`,
	},
	patient: {
		list: `${BASE_URL}/api/v1/secure/clients`,
		activeList: `${BASE_URL}/api/v1/secure/activeclients`,
		inactiveList: `${BASE_URL}/api/v1/secure/inactiveclients`,
		onboard: `${BASE_URL}/api/v1/secure/client`,
		view: `${BASE_URL}/api/v1/secure/client/:patientId/personal-information`,
		updateInformation: `${BASE_URL}/api/v1/secure/client/:patientId/personal-information`,
		checkPatientEmail: `${BASE_URL}/api/v1/secure/client/clientbyemail/:email`,
		resendOnboardFormLink: `${BASE_URL}/api/v1/secure/patient/onboard/resend-form-link`,
		updateProfilePicture: `${BASE_URL}/api/v1/secure/client/:patientId/photo/update`,
		getPaymentDetails: `${BASE_URL}/api/v1/secure/client/:patientId/appointments/payment`,
		getPaymentAppointments: `${BASE_URL}/api/v1/secure/client/:id/appointments/payments`,
		getUnbilledAppointments: `${BASE_URL}/api/v1/secure/client/:id/unbilledappointments`,
		getPatientProgress: `${BASE_URL}/api/v1/secure/client/:id/heptrackingcount`,
		getPatientRemainingCredits: `${BASE_URL}/api/v1/secure/client/:id/credit-sessions-outstanding-amt`,
		getReferrals: `${BASE_URL}/api/v1/secure/clientreferrals`,
		getBillingDetails: `${BASE_URL}/api/v1/secure/revenue/client/:id/billingdetails`,
		downloadPatientDetails: `${BASE_URL}/api/v1/secure/client/list/download-export`,
		getPatientStatus: `${BASE_URL}/api/v1/secure/client/:id/activestatus`,
		updatePatientStatus: `${BASE_URL}/api/v1/secure/client/:id/:status`,
		getMasterAssessmentPdf: `${DOCUMENT_SVC_BASE_URL}/api/v1/secured/master-assessment/branch/:branchid/client/:clientid/master-assessment-pdf/:id`,
		getMasterAssessmentHtml: `${DOCUMENT_SVC_BASE_URL}/api/v1/secured/master-assessment/branch/:branchid/client/:clientid/master-assessment-html/:id`,
		sendMasterAssessmentOnWhatsapp: `${DOCUMENT_SVC_BASE_URL}/api/v1/secured/master-assessment/branch/:branchid/client/:clientid/send-master-assessment-pdf-whatsapp/:id`,
		sendBillOnWhatsapp: `${DOCUMENT_SVC_BASE_URL}/api/v1/secured/billing/branch/:branchid/client/:clientid/whatsapp-invoice/:id`,
		getPrescription: `${DOCUMENT_SVC_BASE_URL}/api/v1/secured/prescription/:masterAssessmentId/generate-pdf`,
		sendPrescriptionOnWhatsapp: `${DOCUMENT_SVC_BASE_URL}/api/v1/secured/prescription/:masterAssessmentId/send-whatsapp`,
		addMedication: `${BASE_URL}/api/v1/secure/treatmentplan/:id/medication`,
		getMedications: `${BASE_URL}/api/v1/secure/treatmentplan/:id/medication`,
	},
	service: {
		list: `${BASE_URL}/api/v1/secure/services/branch/:branchId/all`,
	},
	medicalHistory: {
		// TODO: Create seprate end point for medical history
		view: `${BASE_URL}/api/v1/secure/medicalhistory/:id`,
		updateMedicalHistory: `${BASE_URL}/api/v1/secure/medicalhistory/:id`,
		addMedicalHistory: `${BASE_URL}/api/v1/secure/master-assessment/:mid/medicalhistory`,
	},
	diagnosis: {
		view: `${BASE_URL}/api/v1/secure/client/:patientId/diagnosis`,
		updateTreatmentCategory: `${BASE_URL}/api/v1/secure/client/:patientId/treatment-category`,
		updateProvisionalDiagnosis: `${BASE_URL}/api/v1/secure/client/:patientId/provisional-diagnosis`,
		updateChiefComplaints: `${BASE_URL}/api/v1/secure/client/:patientId/chief-complaints`,
		add: `${BASE_URL}/api/v1/secure/master-assessment/:mid/diagnosis`,
		fetch: `${BASE_URL}/api/v1/secure/diagnosis/:id`,
		fetchEntityDiagnosisSetup: `${BASE_URL}/api/v1/secure/diagnosissetup/:entityid`,
		update: `${BASE_URL}/api/v1/secure/diagnosis/:id`,
	},
	treatmentPlan: {
		view: `${BASE_URL}/api/v1/secure/treatmentplan/:id`,
		update: `${BASE_URL}/api/v1/secure/treatmentplan/:id`,
		therapyList: `${BASE_URL}/api/v1/secure/entity/:entityId/therapies`,
		//Create new Prescription
		createPrescription: `${BASE_URL}/api/v1/secure/master-assessment/:mid/treatmentplan`,
		getOutReferrals: `${BASE_URL}/api/v1/secure/outreferrals`,
	},
	treatmentProtocol: {
		view: `${BASE_URL}/api/v1/secure/treatmentprotocol/:id`,

		add: `${BASE_URL}/api/v1/secure/master-assessment/:mid/treatmentprotocol`,
		fetch: `${BASE_URL}/api/v1/secure/treatmentprotocol/:id`,
		fetchEntityTreatmentProtocolSetup: `${BASE_URL}/api/v1/secure/treatmentprotocolsetup/:entityid`,
		update: `${BASE_URL}/api/v1/secure/treatmentprotocol/:id`,
	},
	feedback: {
		view: `${BASE_URL}/api/v1/secure/client/feedback/:clientid`,
	},
	rating: {
		view: `${BASE_URL}/api/v1/secure/rating/:clientid`,
		entityUserView: `${BASE_URL}/api/v1/secure/entityuserrating/:clientid`,
	},
	journal: {
		view: `${BASE_URL}/api/v1/secure/client/:id/journal`,
	},
	sessionNotes: {
		view: `${BASE_URL}/api/v1/secure/sessionnotes/:id`,
		updateSessionNotes: `${BASE_URL}/api/v1/secure/sessionnotes/:id`,
		addSessionNotes: `${BASE_URL}/api/v1/secure/sessionnotes`,
	},
	reports: {
		view: `${BASE_URL}/api/v1/secure/client/:patientId/reports`,
		addReport: `${BASE_URL}/api/v1/secure/client/:patientId/report/upload`,
		downloadReport: `${BASE_URL}/api/v1/secure/client/:patientId/reports/:reportId`,
	},
	assessment: {
		masterAssessmentList: `${BASE_URL}/api/v1/secure/master-assessments/:patientId`,
		createMasterAssessment: `${BASE_URL}/api/v1/secure/master-assessment`,
		getMasterAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:masterAssessmentId`,
		deleteMasterAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:id`,
		assessmentSetup: `${BASE_URL}/api/v1/secure/assessmentsetup/entity/:id`,

		// VitalAssessment
		getVitalAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:masterAssessmentId/vitals-assessment`,
		createVitalAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:masterAssessmentId/vitals-assessment`,
		updateVitalAssessment: `${BASE_URL}/api/v1/secure/vitals-assessment/:vitalAssessmentId`,
		// GaitAssessment
		getGaitAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/gait-assessment`,
		createGaitAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/gait-assessment`,
		updateGaitAssessment: `${BASE_URL}/api/v1/secure/gait-assessment/:id`,
		updateActiveTillDate: `${BASE_URL}/api/v1/secure/master-assessment/:id`,

		// ROMAssessment
		getRomAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/range-of-motion-assessment`,
		createRomAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/range-of-motion-assessment`,
		updateRomAssessment: `${BASE_URL}/api/v1/secure/range-of-motion-assessment/:id`,

		// MuscleToneAssessment
		getMuscleToneAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/muscle-tone-assessment`,
		createMuscleToneAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/muscle-tone-assessment`,
		updateMuscleToneAssessment: `${BASE_URL}/api/v1/secure/muscle-tone-assessment/:id`,

		// PostureAssessment
		getPostureAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/posture-assessment`,
		createPostureAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/posture-assessment`,
		updatePostureAssessment: `${BASE_URL}/api/v1/secure/posture-assessment/:id`,

		// Pain Assessment
		getPainAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/pain-assessment`,
		createPainAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/pain-assessment`,
		updatePainAssessment: `${BASE_URL}/api/v1/secure/pain-assessment/:id`,

		//Reflex Assessment
		getReflexAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/reflex-assessment`,
		createReflexAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/reflex-assessment`,
		updateReflexAssessment: `${BASE_URL}/api/v1/secure/reflex-assessment/:id`,

		//Oedema Assessment
		getOedemaAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/oedema-assessment`,
		createOedemaAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/oedema-assessment`,
		updateOedemaAssessment: `${BASE_URL}/api/v1/secure/oedema-assessment/:id`,

		//Limb Length Assessment
		getLimbLengthAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/limblength-assessment`,
		createLimbLengthAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/limblength-assessment`,
		updateLimbLengthAssessment: `${BASE_URL}/api/v1/secure/limblength-assessment/:id`,

		//Limb Girth Assessment
		getLimbGirthAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/limbgirth-assessment`,
		createLimbGirthAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/limbgirth-assessment`,
		updateLimbGirthAssessment: `${BASE_URL}/api/v1/secure/limbgirth-assessment/:id`,

		//Muscle Wasting Assessment
		getMuscleWastingAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/musclewasting-assessment`,
		createMuscleWastingAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/musclewasting-assessment`,
		updateMuscleWastingAssessment: `${BASE_URL}/api/v1/secure/musclewasting-assessment/:id`,

		//Balance Assessment
		getBalanceAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/balance-assessment`,
		createBalanceAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/balance-assessment`,
		updateBalanceAssessment: `${BASE_URL}/api/v1/secure/balance-assessment/:id`,

		//MuscleTightness Assessment
		getMuscleTightnessAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/muscleTightness-assessment`,
		createMuscleTightnessAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/muscleTightness-assessment`,
		updateMuscleTightnessAssessment: `${BASE_URL}/api/v1/secure/muscleTightness-assessment/:id`,

		//CustomAssessment Assessment
		getCustomAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/custom-assessment`,
		createCustomAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/custom-assessment`,
		updateCustomAssessment: `${BASE_URL}/api/v1/secure/custom-assessment/:id`,
		getDropDownFormat: `${BASE_URL}/api/v1/secure/custom-assessment/entity-format/:entityId`,

		//Sensory Assessment
		getSensoryAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/sensory-assessment`,
		createSensoryAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/sensory-assessment`,
		updateSensoryAssessment: `${BASE_URL}/api/v1/secure/sensory-assessment/:id`,

		//Scar Assessment
		getScarAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/scar-assessment`,
		createScarAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/scar-assessment`,
		updateScarAssessment: `${BASE_URL}/api/v1/secure/scar-assessment/:id`,

		//MuscleStrength Assessment
		getMuscleStrengthAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/strength-assessment`,
		createMuscleStrengthAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/strength-assessment`,
		updateMuscleStrengthAssessment: `${BASE_URL}/api/v1/secure/strength-assessment/:id`,

		//Specail Test Assessment
		getSpecialTestAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/specialtests-assessment`,
		createSpecialTestAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/specialtests-assessment`,
		updateSpecialTestAssessment: `${BASE_URL}/api/v1/secure/specialtests-assessment/:id`,

		//NerveTest Assessment
		getNerveTestAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/nervetest-assessment`,
		createNerveTestAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/nervetest-assessment`,
		updateNerveTestAssessment: `${BASE_URL}/api/v1/secure/nervetest-assessment/:id`,

		//Structural Deformities Assessment
		getStructuralDeformitiesAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/structuraldeformities-assessment`,
		createStructuralDeformitiesAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/structuraldeformities-assessment`,
		updateStructuralDeformitiesAssessment: `${BASE_URL}/api/v1/secure/structuraldeformities-assessment/:id`,

		//Vertigo  Assessment
		getVertigoAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/vertigo-assessment`,
		createVertigoAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/vertigo-assessment`,
		updateVertigoAssessment: `${BASE_URL}/api/v1/secure/vertigo-assessment/:id`,

		//ADL
		getADLQuestions: `${BASE_URL}/api/v1/secure/adlassessmentsetup/entity/:id`,
		createADLAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/adlassessment`,
		updateADLAssessment: `${BASE_URL}/api/v1/secure/adlassessment/:id`,

		getADLAssessment: `${BASE_URL}/api/v1/secure/master-assessment/:mid/adlassessment`,
		getADLGraphData: `${BASE_URL}/api/v1/secure/adlassessment/client/:id/category/:category`,
	},
	ownerDashboard: {
		appointmentsCount: `${BASE_URL}/api/v1/secure/appointments/entitybranch/:id/dashboardstatuscount`,
		appointmentsCountv2: `${BASE_URL}/api/v1/secure/appointments/entitybranch/:id/appointmentstatusstatics`,
		patientsCount: `${BASE_URL}/api/v1/secure/appointments/entitybranch/:id/dashboardclientcount`,
		revenueCount: `${BASE_URL}/api/v1/secure/revenue/entitybranch/:id/getrevenuedetails`,
		branches: `${BASE_URL}/api/v1/secure/entity/:entityId/branches/list`,
		patientStats: `${BASE_URL}/api/v1/secure/revenue/entitybranch/:id/getclientcounts`,
		updatePayoutStatus: `${BASE_URL}/api/v1/secure/user/:id/payout/:status`,
		getStaffOccupancy: `${BASE_URL}/api/v1/secure/appointments/entitybranch/:id/totalappointmentsbystaff`,
		getStaffRating: `${BASE_URL}/api/v1/secure/entityuserrating/entitybranch/:id/averagerating`,
		getStaffRevenue: `${BASE_URL}/api/v1/secure/appointments/entitybranch/:id/revenuebystaff`,
	},
	practitionerDashboard: {
		appointmentsCount: `${BASE_URL}/api/v1/secure/appointments/entityuser/dashboardstatuscount`,
		patientsCount: `${BASE_URL}/api/v1/secure/appointments/entityuser/dashboardclientcount`,
		appointmentListByStatus: `${BASE_URL}/api/v1/secure/appointment/all/by-status`,
		updateAppointmentStatus: `${BASE_URL}/api/v1/secure/appointment/updatestatus/:id/:status`,
	},
	assistantDashboard: {
		appointmentsCount: `${BASE_URL}/api/v1/secure/appointments/entitybranch/:id/dashboardstatuscount`,
		patientsCount: `${BASE_URL}/api/v1/secure/appointments/entitybranch/:id/dashboardclientcount`,
		appointmentListByStatus: `${BASE_URL}/api/v1/secure/appointments/branch/:id/by-status`,
		updateAppointmentStatus: `${BASE_URL}/api/v1/secure/appointment/updatestatus/:id/:status`,
	},
	branchAdminDashboard: {
		appointmentsCount: `${BASE_URL}/api/v1/secure/appointments/entitybranch/:id/dashboardstatuscount`,
		appointmentsCountv2: `${BASE_URL}/api/v1/secure/appointments/entitybranch/:id/appointmentstatusstatics`,
		patientsCount: `${BASE_URL}/api/v1/secure/appointments/entitybranch/:id/dashboardclientcount`,
		revenueCount: `${BASE_URL}/api/v1/secure/revenue/entitybranch/:id/getrevenuedetails`,
	},
	payment: {
		add: `${BASE_URL}/api/v1/secure/payment`,
		updatePaymetDetails: `${BASE_URL}/api/v1/secure/paymentDetails`,
		view: `${BASE_URL}/api/v1/secure/payment/:appointmentId`,
		payout: `${BASE_URL}/api/v1/secure/payout`,
		refund: `${BASE_URL}/api/v1/secure/refund`,
		refundDetails: `${BASE_URL}/api/v1/secure/refund/client/:id`,
	},
	managePatient: {
		getAssignedExercises: `${BASE_URL}/api/v1/secure/treatmentplan/:id/exercise-program`,
		getSingleAssignedExercise: `${BASE_URL}/api/v1/secure/client/:patientId/exercise-program/exercise/:exerciseId`,
		getSingleExerciseMedia: `${BASE_URL}/api/v1/secure/exercise/:exerciseId/media`,
		getSingleExternalLink: `${BASE_URL}/api/v1/secure/exercise/:id/external-links`,
		deleteSingleAssignedExercice: `${BASE_URL}/api/v1/secure/treatmentplan/:id/exercise-program/exercise/:exerciseId`,
		getAvailableExercises: `${BASE_URL}/api/v1/secure/treatmentplan/:id/available-exercises`,
		updateAssignedExerciseDetails: `${BASE_URL}/api/v1/secure/treatmentplan/:id/exercise-program`,
		updateClientHomeExercisePromramme: `${BASE_URL}/api/v1/secure/treatmentplan/:id/exercise-program`,
		updateClientHomeExerciseProgrameStatus: `${BASE_URL}/api/v1/secure/treatmentplan/:id/exercise-program-status/:status`,
		updateImportantNote: `${BASE_URL}/api/v1/secure/client/:id/notes`,
	},
	// mail: {
	//   list: '/api/mail/list',
	//   details: '/api/mail/details',
	//   labels: '/api/mail/labels',
	// },
	// post: {
	//   list: '/api/post/list',
	//   details: '/api/post/details',
	//   latest: '/api/post/latest',
	//   search: '/api/post/search',
	// },
	// product: {
	//   list: '/api/product/list',
	//   details: '/api/product/details',
	//   search: '/api/product/search',
	// },
	dashboardInitialData: {
		initialData: `${BASE_URL}/api/v1/secure/entity/:userid/initialdata`,
	},
	expense: {
		addExpense: `${BASE_URL}/api/v1/secure/expense`,
		getExpenseForEntity: `${BASE_URL}/api/v1/secure/expense/entitybranch/:id`,
		getExpenseForEntityByMonth: `${BASE_URL}/api/v1/secure/expense/bymonth/entitybranch/:id`,

		deleteExpense: `${BASE_URL}/api/v1/secure/expense/:id`,
	},
	services: {
		getActiveServicesForBranch: `${BASE_URL}/api/v1/secure/services/branch/:branchId/active`,
		getActiveServicesForEntity: `${BASE_URL}/api/v1/secure/services/entity/:entityId/active`,
		getActivePlansForBranch: `${BASE_URL}/api/v1/secure/plans/branch/:branchId/active`,
		getActivePlansForEntity: `${BASE_URL}/api/v1/secure/plans/entity/:entityId/active`,
	},

	plans: {
		getActiveServicesForBranch: `${BASE_URL}/api/v1/secure/plans/branch/:branchId/active`,
	},

	invoice: {
		addExpense: `${BASE_URL}/api/v1/secure/invoice`,
		addBilling: `${BASE_URL}/api/v1/secure/billing`,
		getBillsForEntity: `${BASE_URL}/api/v1/secure/billing/entity/:entityId`,
		getBillsForBranch: `${BASE_URL}/api/v1/secure/billing/:entityId/branch/:branchId`,
		getBillsForClient: `${BASE_URL}/api/v1/secure/billing/client/:clientId`,
		deleteExpense: `${BASE_URL}/api/v1/secure/invoice/:id`,
		getBillDetails: `${BASE_URL}/api/v1/secure/billing/:id`,
		payBill: `${BASE_URL}/api/v1/secure/billing/:id/pay`,
		lineItem: `${BASE_URL}/api/v1/secure/billing/:id/lineItem`,
		removeLineItem: `${BASE_URL}/api/v1/secure/billing/:id/lineItem/:itemId`,
		addTax: `${BASE_URL}/api/v1/secure/billing/:id/tax`,
		printBillDetails: `${BASE_URL}/api/v1/secure/billing/print/:id`,
		updateBillingDate: `${BASE_URL}/api/v1/secure/billing/:id/invoiceDate`,
		updateBilling: `${BASE_URL}/api/v1/secure/billing/:id`,
		updatePaymentDetails: `${BASE_URL}/api/v1/secure/billing/:id/paymentDetails`,
		getInvoice: `${DOCUMENT_SVC_BASE_URL}/api/v1/secured/invoice/:id/generate-pdf`,
		getWhatsappInvoice: `${DOCUMENT_SVC_BASE_URL}/api/v1/secured/invoice/:id/send-whatsapp`,
	},
	referredDocsAndHospitals: {
		getReferredDocsAndHospitals: `${BASE_URL}/api/v1/secure/clientreferralswithcode`,
		getBillsForReferrals: `${BASE_URL}/api/v1/secure/revenue/referral/billingdetails`,
		getBillsExcelSheet: `${BASE_URL}/api/v1/secure/revenue/referral/downloadbillingdetails`,
	},
	credits: {
		whatsappCreditDetails: `${BASE_URL}/api/v1/secure/messagecredit/entity/:entityid`,
		whatsappRemainingCreditDetails: `${BASE_URL}/api/v1/secure/messagecredit/remainingcredits/entity/:entityid`,
		whatsappMessageDetails: `${BASE_URL}/api/v1/secure/whatsappmessage/entitybranch/:id`,
	},
};

// setCookieHeader();
