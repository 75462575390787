import useAxios from "@routes/hooks/use-axios";
import { endpoints, replaceParams } from "@utils/axios";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ValidationError } from "@components/hook-form";

import { Button, OutlinedButton } from "@components/button";
import { ButtonVariant } from "@components/button/button";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import { Staff, ConvertStaff } from "@pages/manage-patient-new/types/Staff";
import { ManagePatientNewContext } from "./ManagePatientNewV2";
import {
	NewMasterAssessmentData,
	NewMasterAssessmentDataConvert,
} from "@pages/manage-patient-new/types/NewMasterAssessment";
import { toast } from "react-toastify";
import moment from "moment";
import { watch } from "fs";
import Applabel from "@components/hook-form/applabel";

export default function NewMasterAssessment(props: {
	handleCloseModal: () => void;
	handleOnSubmit: () => void;
	masterAssessmentLedgerLength: number;
	patientId: string;
}) {
	const [staff, setStaff] = React.useState<Staff[] | null>(null); // this is the list of staff [{}]
	const [loading, setLoading] = React.useState(true);
	const [copyLatest, setCopyLatest] = React.useState(true);
	const [error, setError] = React.useState("");
	const [staffId, setStaffId] = React.useState("");
	const [staffName, setStaffName] = React.useState("");
	const [physiotherapistError, setPhysiotherapistError] = React.useState("");
	const [startDate, setStartDate] = React.useState<moment.Moment>(moment(new Date()));
	// const [endDate, setEndDate] = React.useState<moment.Moment>(
	//   moment(new Date()).add(7, 'days'),
	// );
	const [endDate, setEndDate] = React.useState<moment.Moment | null>(null);
	const [isDisabled, setIsDisabled] = React.useState(false);
	const navigate = useNavigate();

	const axios = useAxios();

	const patientId = props.patientId;

	const userData = getUserPersistedOnLocalStorage();
	const [isPractitioner, setIsPractitioner] = React.useState(false);

	// below is the api call to get the list of patients in a react hook

	const getStaff = async () => {
		try {
			// const params = new URLSearchParams([['role', 'PRACTITIONER']]);
			const response = await axios.get(endpoints.staff.list).then((response) => {
				const data = response.data["data"];
				const convertedStaffList = data.map((staff: any) => ConvertStaff.toStaff(JSON.stringify(staff)));
				setStaff(convertedStaffList);

				setLoading(false);
			});
		} catch (error) {
			console.error("Error fetching staff list:", error);
			setError(error.message);
			setLoading(false);
		}
	};
	React.useEffect(() => {
		const _init = async () => {
			await getStaff();
		};
		_init();
	}, []);

	React.useEffect(() => {
		if (userData?.user_role === "PRACTITIONER") {
			setIsPractitioner(true);

			const employee = staff?.find((staff) => staff.id === userData?.user_id);

			setStaffName(`${employee?.salutation}. ${employee?.firstName} ${employee?.middleName} ${employee?.lastName}`);
			// setStaff([]);
			setStaffId(userData?.user_id);
		}
	}, [staff]);

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (!staffId) {
			setPhysiotherapistError("Please select a Physiotherapist");
			return;
		} else {
			setPhysiotherapistError("");
		}
		setIsDisabled(true);

		try {
			const formattedEndDate = endDate ? convertToUTCWithoutTime(endDate) : null;
			const response = await axios.post(
				`${endpoints.assessment.createMasterAssessment}`,
				{
					clientId: patientId,
					entityId: userData?.entity_id,
					entityUserId: staffId,
					entityUserName: staffName,
					activetill: formattedEndDate,
					assessmentDate: startDate,
				},
				{
					params: {
						copylatest: props.masterAssessmentLedgerLength === 0 ? false : copyLatest,
					},
				}
			);

			const data = response.data["data"];

			const convertedData = NewMasterAssessmentDataConvert.toNewMasterAssessment(JSON.stringify(data));

			props.handleCloseModal();
			props.handleOnSubmit();
			toast.success("New master assessment created");
		} catch (error) {
			console.error("Error submitting form:", error);
			setError(error.response.data["data"].toString());
		}
	};

	function findEmployeeName(id: string) {
		const employee = staff?.find((staff) => staff.id === id);
		setStaffId(id);
		setStaffName(`${employee?.salutation}. ${employee?.firstName} ${employee?.middleName} ${employee?.lastName}`);
		return `${employee?.salutation}. ${employee?.firstName} ${employee?.middleName} ${employee?.lastName}`;
	}
	const convertToUTCWithoutTime = (date: moment.Moment): string => {
		// Set time to midnight (00:00:00)
		const resultDate = date.set({
			hour: 0,
			minute: 0,
			second: 0,
			millisecond: 0,
		});
		// Convert to UTC and format in '2023-12-20T00:00:00Z' format
		const formattedUTC = moment.utc(resultDate).format("YYYY-MM-DDTHH:mm:ss[Z]");
		return formattedUTC;
	};

	const getMinimumDate = () => {
		return moment().add(1, "day").format("YYYY-MM-DD");
	};

	return (
		<div className="flex flex-col w-full h-full">
			<div className="flex flex-row w-full justify-between mx-4 my-2">
				<h4 className="text-md leading-6 font-bold text-gray-900">New Physical Examination & Treatment Plan</h4>
			</div>

			<hr className="w-full" />

			<form onSubmit={(event) => handleSubmit(event)}>
				<div className="flex flex-col w-full h-full px-4 py-2 space-y-2">
					<div className="flex flex-col md:flex-row lg:flex-row justify-between">
						<div className="flex flex-col">
							<Applabel label="Assessment Date" />
							<input
								type="date"
								value={startDate.format("YYYY-MM-DD").toString()}
								className="flex lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 "
								onChange={(event) => setStartDate(moment(event.target.value))}
							/>
						</div>
						<div className="flex flex-col">
							<Applabel label="Valid Till Date (Optional)" />
							<input
								type="date"
								value={endDate ? endDate.format("YYYY-MM-DD").toString() : ""}
								min={getMinimumDate()}
								className="flex lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 "
								onChange={(e) => {
									const inputValue = e.target.value;
									if (inputValue === "") {
										// User cleared the input, consider it as an empty date
										setEndDate(null);
									} else {
										const isValidDate = moment(inputValue, "YYYY-MM-DD", true).isValid();

										if (isValidDate) {
											setEndDate(moment(inputValue));
										} else {
											// Handle invalid date input, you can choose to ignore or show an error message
											console.error("Invalid date input:", inputValue);
											setEndDate(null);
										}
									}
								}}
							/>
						</div>
					</div>
					<div className="flex flex-col w-full justify-between mt-2">
						<span className="text-sm text-gray-700">
							If the 'Valid Till Date' option is selected, the prescription will remain visible to the patient in the
							mobile app until the specified valid date.
						</span>
					</div>
					<div className="flex flex-col w-full justify-between mt-2">
						<Applabel label="Physiotherapist Name" mandatory />
						<select
							onChange={(e) => findEmployeeName(e.target.value)}
							value={staffId}
							disabled={isPractitioner}
							className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
						>
							<option value="">Consultant/Therapist</option>

							{staff?.map((staff) => {
								if (staff.isActive)
									return (
										<option key={staff.id} value={staff.id}>
											{`${staff.salutation}. ${staff.firstName} ${staff.middleName || ""} ${staff.lastName || ""}`}
										</option>
									);
							})}
						</select>
						{!staffId && physiotherapistError && <p className="text-red-500">{physiotherapistError}</p>}
					</div>
				</div>
				{props.masterAssessmentLedgerLength != 0 && (
					<div className={`flex flex-row w-full justify-start items-center p-4`}>
						<input
							type="checkbox"
							checked={copyLatest}
							onChange={() => setCopyLatest(!copyLatest)}
							className="h-4 w-4 mr-4 text-primary-600"
						/>
						<span className={`text-sm `}>Copy records from the prior physical examination and treatment plan</span>
					</div>
				)}

				{error && <ValidationError message={error} />}
				<div className="flex flex-row w-full mt-4 p-4 bg-gray-100 justify-end">
					<OutlinedButton
						variant={ButtonVariant.PRIMARY}
						type="button"
						onClick={props.handleCloseModal}
						children="Close"
						className="mr-2"
					/>
					<Button type="submit" isDisabled={isDisabled} onClick={() => {}} variant={ButtonVariant.PRIMARY}>
						Create
					</Button>
				</div>
			</form>
		</div>
	);
}
