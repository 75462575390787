import React, { useEffect, useState, useRef } from "react";
import { Trash2, X } from "lucide-react";
import { CURRENCY_SYMBOL } from "../../../config/currencyConfig";
import { endpoints, replaceParams } from "@utils/axios";
import { Patient } from "@/src/common/types/Patient";
import { Service } from "@/src/common/types/ServiceType";
import axios from "axios";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import { Plan } from "@pages/branches/types/Plan";
import { PlanService } from "@pages/branches/types/Plan";
import { AppointmentDetail, LineItem } from "../types/InvoiceDetails";
import { ConvertStaff, Staff } from "@pages/appointments/book-appointment/types/StaffType";

interface LineItemRowProps {
	item: LineItem;
	onUpdate: (id: string, field: keyof LineItem, value: any) => void;
	onDelete: (id: string) => void;
	isHeader?: boolean;
	readOnly?: boolean;
	serviceData: Service[];
	planData: Plan[];
	staffList: Staff[];
	descriptionOptionsInp: {
		[key: string]: string;
	};
	isAdvancePayment?: boolean;
}

export default function LineItemRow({
	item,
	onUpdate,
	onDelete,
	isHeader = false,
	readOnly = false,
	serviceData,
	planData,
	staffList,
	descriptionOptionsInp,
	isAdvancePayment = false,
}: LineItemRowProps) {
	const [descriptionOptions, setDescriptionOptions] = useState<{
		[key: string]: string;
	}>({});
	const [price, setPrice] = useState<{ [key: string]: string }>({});
	const [filteredOptions, setFilteredOptions] = useState<string[]>([]);
	const userData = getUserPersistedOnLocalStorage();
	// Add state for service data
	const [searchTermDesc, setSearchTermDesc] = useState(item.description); // State for search term
	const [filteredStaffList, setFilteredStaffList] = useState<Staff[]>([]); // State for filtered staff list
	const [searchTermStaff, setSearchTermStaff] = useState(item.staffName); // State for search term
	const [highlightedIndex, setHighlightedIndex] = useState(-1); // State to track highlighted index
	const [highlightedDescIndex, setHighlightedDescIndex] = useState(-1); // State to track highlighted index for description options
	const [selectedServices, setSelectedServices] = useState<PlanService[]>([]);

	const descInputRef = useRef<HTMLInputElement | null>(null);
	const descListRef = useRef<HTMLUListElement | null>(null);

	const staffInputRef = useRef<HTMLInputElement | null>(null);
	const staffListRef = useRef<HTMLUListElement | null>(null);

	/* 	useEffect(() => {
		fetchServices();
		fetchPlans();
		fetchStaffList();
	}, []); */

	useEffect(() => {
		if (selectedServices.length > 0) {
			onUpdate(item.itemId, "servicesCredits", selectedServices);
		}
	}, [selectedServices]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				descInputRef.current &&
				descListRef.current &&
				!descInputRef.current.contains(event.target as Node) &&
				!descListRef.current.contains(event.target as Node)
			) {
				setFilteredOptions([]); // Close the options
			} else if (
				staffInputRef.current &&
				staffListRef.current &&
				!staffInputRef.current.contains(event.target as Node) &&
				!staffListRef.current.contains(event.target as Node)
			) {
				setFilteredStaffList([]); // Close the options
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	// Use useEffect to update descriptionOptions when descriptionOptionsInp changes
	useEffect(() => {
		if (descriptionOptionsInp) {
			setDescriptionOptions(descriptionOptionsInp);
		}
	}, [descriptionOptionsInp]); // Dependency array ensures this runs when descriptionOptionsInp changes

	const getServicesDescription = () => {
		const options = serviceData.reduce(
			(acc, entity) => {
				acc[entity.id] = entity.name;
				return acc;
			},
			{} as { [key: string]: string }
		);
		setDescriptionOptions(options);
	};

	const getPlansDescription = () => {
		const options = planData.reduce(
			(acc, entity) => {
				acc[entity.id] = entity.name;
				return acc;
			},
			{} as { [key: string]: string }
		);
		setDescriptionOptions(options);
	};

	const handleDescriptionChange = (value: string) => {
		const selectedItemDesc = Object.entries(descriptionOptions).find(([key, name]) => name === value);
		if (selectedItemDesc) {
			const [id] = selectedItemDesc; // Get the id of the selected service
			const selectedService = serviceData.find((entity: Service) => entity.id === id); // Use serviceData here

			if (selectedService) {
				if (isAdvancePayment) {	
					setSelectedServices([{ serviceId: selectedService.id, quantity: 1, name: selectedService.name }]);
				}
				setPrice((prev) => ({
					...prev,
					[id]: selectedService.defaultCost.toString(),
				})); // Set the defaultCost in price state
				onUpdate(item.itemId, "amount", selectedService.defaultCost); // Update the amount
			} else {
				const selectedPlan = planData.find((entity: Plan) => entity.id === id);
				if (selectedPlan) {
					const services = selectedPlan?.services.map((service) => {
						const selectedService = serviceData.find((src: Service) => src.id === service.serviceId);
						return { serviceId: selectedService?.id, quantity: service.quantity, name: selectedService?.name };
					});
					if (isAdvancePayment) {
						setSelectedServices(services as PlanService[]);
					}
					setPrice((prev) => ({
						...prev,
						[id]: selectedPlan?.totalPrice.toString(),
					})); // Set the defaultCost in price state
					onUpdate(item.itemId, "amount", selectedPlan.totalPrice); // Update the amount
				}
			}
		}
		setSearchTermDesc(value);
		setFilteredOptions([]);
		//setDescriptionInput(value);
		onUpdate(item.itemId, "description", value);
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		//setDescriptionInput(value); // Update description input
		onUpdate(item.itemId, "description", value);
		setHighlightedDescIndex(-1); // Reset highlighted index on input change
		setSearchTermDesc(value); // Update the search term for description
		// Filter description options based on input
		const filtered = Object.values(descriptionOptions).filter((option) =>
			option.toLowerCase().includes(value.toLowerCase())
		);
		setFilteredOptions(filtered); // Update filtered options
	};

	const handleDescKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "ArrowDown") {
			setHighlightedDescIndex((prev) => Math.min(prev + 1, filteredOptions.length - 1));
		} else if (e.key === "ArrowUp") {
			setHighlightedDescIndex((prev) => Math.max(prev - 1, -1));
		} else if (e.key === "Enter" && highlightedDescIndex >= 0) {
			handleDescriptionChange(filteredOptions[highlightedDescIndex]); // Select highlighted staff
			setFilteredOptions([]); // Clear filtered staff list
		}
	};

	const [selectedEntityUserId, setSelectedEntityUserId] = React.useState("");
	const [branchId, setBranchId] = React.useState<string | null>(userData?.branch_id.toString() ?? "");

	function setStaffDetails(staffid: string): void {
		const staff = staffList?.find((staff) => staff.id === staffid);
		const staffName = `${staff?.salutation}. ${staff?.firstName} ${staff?.lastName}`;
		setSelectedEntityUserId(staff?.id ?? "");
		setSearchTermStaff(staffName);
		onUpdate(item.itemId, "staffId", staff?.id);
		onUpdate(item.itemId, "staffName", staffName);
	}

	const handleStaffSelect = (staff: Staff) => {
		setStaffDetails(staff.id); // Set selected staff details
		// setSearchTermSta1ff(staff);
		setFilteredStaffList([]); // Clear filtered staff list
	};

	const handleStaffInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setSearchTermStaff(value); // Update search term for staff
		setHighlightedIndex(-1); // Reset highlighted index on input change
		// Filter staff list based on input
		const filtered =
			staffList?.filter((staff) =>
				`${staff.salutation}. ${staff.firstName} ${staff.lastName}`.toLowerCase().includes(value.toLowerCase())
			) || [];
		setFilteredStaffList(filtered); // Update filtered staff list
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "ArrowDown") {
			setHighlightedIndex((prev) => Math.min(prev + 1, filteredStaffList.length - 1));
		} else if (e.key === "ArrowUp") {
			setHighlightedIndex((prev) => Math.max(prev - 1, -1));
		} else if (e.key === "Enter" && highlightedIndex >= 0) {
			handleStaffSelect(filteredStaffList[highlightedIndex]); // Select highlighted staff
			setFilteredStaffList([]); // Clear filtered staff list
		}
	};

	if (isHeader) {
		return (
			<div className="px-2 py-2 bg-primary-100 border-y border-gray-100 rounded-md">
				<div className="grid grid-cols-12 gap-2  text-xs font-medium text-gray-700 px-2 ">
					<div className="col-span-2 text-center">TYPE</div>
					<div className="col-span-3 text-center">DESCRIPTION</div>
					<div className="col-span-1 text-center">Quantity</div>
					<div className="col-span-2 text-center">PRICE</div>
					<div className="col-span-2 text-center">DISCOUNT</div>
					<div className="col-span-1 text-center">TOTAL</div>
					<div className="col-span-1 text-center"></div>
				</div>
			</div>
		);
	}

	return (
		/*  <div className="px-4 py-2 hover:bg-gray-50 transition-colors duration-200"> */
		<div className="mb-2 ">
			<div className="grid grid-cols-12 gap-2 items-center border border-gray-300 rounded-md p-4">
				{readOnly ? (
					<>
						<div className="col-span-2 text-sm">{item.type}</div>
						<div className="col-span-3 text-sm">{searchTermDesc}</div>
						<div className="col-span-1 text-sm">{item.quantity}</div>
						<div className="col-span-2 text-sm">
							{CURRENCY_SYMBOL}
							{item.amount}
						</div>
						<div className="col-span-2 text-sm">
							{CURRENCY_SYMBOL}
							{item.discount}
						</div>
						<div className="col-span-1 text-sm">
							{CURRENCY_SYMBOL}
							{item.quantity && item.amount && (item.quantity * item.amount - (item.discount || 0)).valueOf()}
						</div>
					</>
				) : (
					<>
						<select
							className="col-span-2 input-field text-sm border border-gray-300 rounded"
							value={item.type}
							onChange={(e) => {
								const newType = e.target.value;
								onUpdate(item.itemId, "type", newType);
								setDescriptionOptions({}); // Clear existing description options on type change
								setFilteredOptions([]); // Clear filtered options on type change
								if (newType === "plan") {
									getPlansDescription();
								} else if (newType === "service") {
									getServicesDescription();
								}
							}}
						>
							<option value="service">Service</option>
							<option value="plan">Plan</option>
							<option value="product">Product</option>
							<option value="other">Other</option>
						</select>

						<div className="col-span-3 relative">
							<input
								type="text"
								ref={descInputRef}
								className="input-field text-sm border border-gray-300 rounded cursor-pointer"
								value={searchTermDesc}
								onChange={handleInputChange}
								onKeyDown={handleDescKeyDown}
								placeholder="Search or select"
								onFocus={() => {
									setFilteredOptions(Object.values(descriptionOptions));
								}}
							/>
							{filteredOptions.length > 0 && (
								<ul
									ref={descListRef}
									className="input-field absolute z-10 max-h-80 overflow-y-auto border border-gray-300 bg-white shadow-2xl"
								>
									{filteredOptions.map((option, index) => (
										<li
											key={index}
											onClick={() => {
												handleDescriptionChange(option);
											}}
											className={`text-sm border-b border-b-gray-200 cursor-pointer hover:bg-gray-200 p-2 transition-colors duration-200 ${
												highlightedDescIndex === index ? "bg-gray-200" : ""
											}`}
										>
											{option}
										</li>
									))}
									<li
										onClick={() => {
											setFilteredOptions([]);
										}}
										className="text-xs cursor-pointer hover:bg-gray-200 p-2 transition-colors duration-200 text-red-500 flex items-center"
									>
										<X className="h-4 w-4 mr-2" />
										Close
									</li>
								</ul>
							)}
						</div>

						<input
							type="number"
							className="col-span-1 number-input text-sm border border-gray-300 rounded"
							value={item.quantity}
							min="1"
							onChange={(e) => {
								onUpdate(item.itemId, "quantity", parseInt(e.target.value));
								if (item.type === "service") {
									selectedServices.forEach((service) => {
										const updatedService = {
											...service,
											quantity: parseInt(e.target.value),
										};
										if (isAdvancePayment) {
											setSelectedServices((prevServices) =>
												prevServices.map((s) => (s.serviceId === service.serviceId ? updatedService : s))
											);
										}
									});
								}
							}}
						/>

						<div className="col-span-2 relative">
							<span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 font-mono">
								{CURRENCY_SYMBOL}
							</span>
							<input
								type="number"
								className="number-input pl-7 text-sm border border-gray-300 rounded"
								value={item.amount}
								min="0"
								step="1"
								onChange={(e) => onUpdate(item.itemId, "amount", parseFloat(e.target.value))}
							/>
						</div>
						<div className="col-span-2 relative">
							<span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 font-mono">
								{CURRENCY_SYMBOL}
							</span>
							<input
								type="number"
								className="number-input pl-7 text-sm border border-gray-300 rounded"
								value={item.discount}
								min="0"
								step="1"
								onChange={(e) => onUpdate(item.itemId, "discount", parseFloat(e.target.value))}
							/>
						</div>

						<div className="col-span-1 font-mono text-sm font-medium text-gray-900">
							{CURRENCY_SYMBOL}
							{item.quantity && item.amount && (item.quantity * item.amount - (item.discount || 0)).valueOf()}
						</div>

						{!readOnly && (
							<button
								onClick={() => onDelete(item.itemId)}
								className="col-span-1 text-gray-400 hover:text-red-500 flex items-center justify-center transition-colors duration-200"
								title="Delete item"
							>
								<Trash2 className="h-4 w-4" />
							</button>
						)}
					</>
				)}
				<div className="col-span-6 relative">
					<input
						type="text"
						ref={staffInputRef}
						className="input-field w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-100 placeholder:text-grey-400 focus:ring-1 focus:ring-inset focus:ring-primary-400 sm:text-sm sm:leading-6 cursor-pointer text-gray-500"
						value={searchTermStaff}
						disabled={readOnly}
						onChange={handleStaffInputChange} // Use new method for staff input change
						onKeyDown={handleKeyDown} // Handle keyboard events
						placeholder="Consultant/Therapist (Optional)"
						onFocus={() => {
							setFilteredStaffList(staffList || []); // Show all options on focus, fallback to empty array
						}}
					/>
					{filteredStaffList.length > 0 && (
						<ul
							ref={staffListRef}
							className="input-field absolute z-10 max-h-80 overflow-y-auto border border-gray-300 bg-white shadow-2xl"
						>
							{filteredStaffList.map((staff: Staff, index: number) => (
								<li
									key={staff.id}
									onClick={() => handleStaffSelect(staff)} // Handle staff selection
									className={`text-sm border-b border-b-gray-200 cursor-pointer hover:bg-gray-200 p-2 transition-colors duration-200 ${
										highlightedIndex === index ? "bg-gray-200" : ""
									}`} // Highlight selected item
								>
									{`${staff.salutation}. ${staff.firstName} ${staff.middleName || ""} ${staff.lastName || ""}`}
								</li>
							))}
						</ul>
					)}
				</div>
				<div className="col-span-6 relative">
					{item.appointments && item.appointments.length > 0 && (
						<div className="col-span-6">
							<div className="text-xs text-gray-500">Appointment Date</div>
							<div className="text-xs text-gray-700 font-semibold">
								{item.appointments.map((appointment) => (
									<div key={appointment._id}>{appointment.date}</div>
								))}
							</div>
						</div>
					)}
				</div>

				{selectedServices && selectedServices.length > 0 && (
					<div className="col-span-12 relative">
						<div className="col-span-6 p-2">
							<div className="text-sm text-primary-700 font-semibold">Add Session Credits (Number of Sessions)</div>
							<div className="flex flex-row text-sm text-gray-700">
								{selectedServices.map((service: PlanService, index: number) => (
									<div key={service.serviceId} className="flex flex-row gap-2 p-2 items-center">
										<div className="font-semibold">{service.name}</div>
										<div className="">
											<input
												type="number"
												className="col-span-1 number-input text-sm border border-gray-300 rounded"
												value={service.quantity}
												min="1"
												max="500"
												onChange={(e) => {
													const newQuantity = parseInt(e.target.value) || 1;
													const updatedServices = [...selectedServices];
													updatedServices[index] = {
														...service,
														quantity: newQuantity,
													};
													setSelectedServices(updatedServices);
												}}
											/>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
