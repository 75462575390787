import React, { useState, useEffect, useCallback, useRef } from "react";
import {
	Calendar,
	Clock,
	ChevronLeft,
	ChevronRight,
	MoreHorizontal,
	ArrowLeft,
	ArrowRight,
	ArrowUpRight,
	X,
} from "lucide-react";
import { v4 as uuidv4 } from "uuid";
import { format, addDays, isSameDay, isToday, parseISO } from "date-fns";
import { therapists, generateTimeSlots, bookingDurations } from "./data";
import { DaySchedule, TimeSlot, BookingDuration } from "./types";
import axios, { CancelTokenSource } from "axios";
import { endpoints, replaceParams } from "@utils/axios";
import { AvailableTimeSlots, BookedAppointment } from "../book-appointment/types/AvailableTimeSlots";
import moment, { duration } from "moment";
import { AvailableTimeSlotConvert, Freeslot } from "../types/AvailableTimeSlots";
import { ConvertStaff, Staff } from "@pages/manage-patient-new/types/Staff";
import { Convert, Patient } from "@common/types/Patient";

import { EntityBranchConvert } from "@pages/staff/types/EntityBranch";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import { EntityBranch } from "../types/EntityBranch";
import Applabel from "@components/hook-form/applabel";
import { applebelCustom as ApplebelCustom } from "@components/hook-form/applabel";
import PatientSearchV2 from "@common/components/PatientSearch/PatientSearchV2";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { AppointmentType } from "../book-appointment/BookAppointmentPage";
import { ValidationError } from "@components/hook-form";
import { Card } from "@components/ui/card";
import { Button } from "@components/button";
import { convertTo24HourFormat } from "@utils/timeConversionUtil";
import {
	WorkingHour,
	ConvertWorkingHours,
	convertWorkingHoursToLocalTimes,
} from "@pages/branches/view-branch/BranchWorkingHoursConfiguration";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { z } from "zod";
import { AppointmentDto } from "../types/AppointmentDto";
import { toast } from "react-toastify";
import { Service } from "@pages/branches/types/Service";
import { Appointment, Convert as AppointmentConvert } from "@pages/appointments/types/Appointment";
import { useNavigate } from "react-router-dom";
import { PageHeader } from "@components/ui/PageHeader";
import { ButtonVariant } from "@components/button/button";

interface SelectedSlot {
	id: string;
	date: Date;
	startTime: string;
	endTime: string;
	duration: number;
	isFree: boolean;
	userId: string;
	userName: string;
}

const SelectedSlotChip: React.FC<{
	slot: AppointmentDto;
	onRemove: () => void;
}> = ({ slot, onRemove }) => (
	<div className="flex items-center gap-2 px-3 py-2 bg-primary-100 rounded-lg border border-primary-800 text-gray-800">
		<div className="flex flex-col w-40">
			<span className="text-sm font-medium ">
				{format(slot.apptdate, "MMM d, yyyy")} {slot.startTimeLocal} ({slot.duration} mins)
			</span>

			<span className="text-xs overflow-hidden text-ellipsis whitespace-nowrap w-40">{slot.entityUserName}</span>
		</div>
		<button onClick={onRemove} className="p-1 hover:bg-secondary-100 rounded-full transition-colors">
			<XMarkIcon className="w-4 h-4" />
		</button>
	</div>
);

// First, define your schema
const AppointmentSchema = z.object({
	patientId: z.string().min(1, "Please select a patient"),
	appointmentType: z.string().min(1, "Please select an appointment type"),
	physioId: z.string().min(1, "Please select a physiotherapist"),
	slotToBook: z.string().min(1, "Please select a slot"),
});

function SingleCalander() {
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [selectedDuration, setSelectedDuration] = useState<BookingDuration>(bookingDurations[0]);
	const [scrollPosition, setScrollPosition] = useState(0);
	const [showLeftArrow, setShowLeftArrow] = useState(false);
	const [showRightArrow, setShowRightArrow] = useState(true);
	const [availableTimeSlots, setAvailableTimeSlots] = React.useState<AvailableTimeSlots[] | null>(null);

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState("");

	const [staffList, setStaffList] = React.useState<Staff[] | null>(null);
	const [selectedEntityUserId, setSelectedEntityUserId] = React.useState("");
	const [selectedEntityUserName, setSelectedEntityUserName] = React.useState("");
	const [selectedEntityUserPhone, setSelectedEntityUserPhone] = React.useState(0);
	const [weekStartDate, setWeekStartDate] = useState(new Date());
	const [branchWorkingHours, setBranchWorkingHours] = useState<WorkingHour[]>([]);
	const [staffWorkingHours, setStaffWorkingHours] = useState<WorkingHour[]>([]);

	const [startTime, setStartTime] = useState("08:00");
	const [endTime, setEndTime] = useState("20:00");
	const {
		setValue,
		setError: setZodError,
		clearErrors,
		formState: { errors },
	} = useForm<AppointmentType>({ resolver: zodResolver(AppointmentSchema) });
	const [selectedService, setSelectedService] = useState<Service | null>(null);

	const [consultationMode, setConsultationMode] = useState<string>("In-Clinic");

	const [isAnimating, setIsAnimating] = useState(false);

	const [isSidebarOpen, setIsSidebarOpen] = useState(false);

	const [startDate, setStartDate] = useState<string>("");
	const [endDate, setEndDate] = useState<string>("");
	const [days, setDays] = useState<number>(15);

	const [appointmentsByDate, setAppointmentsByDate] = useState<Map<string, Appointment[]>>(new Map());

	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const [selectedAppointments, setSelectedAppointments] = useState([]);

	const [selectedSlots, setSelectedSlots] = useState<AppointmentDto[]>([]);

	const getBranchWorkingHours = async () => {
		try {
			const paramsMap = new Map<string, string>([
				["entityId", loggedInUser?.entity_id!],
				["branchId", branchId!],
			]);
			const filteredEndpoint = replaceParams(endpoints.branch.getworkinghours, paramsMap);
			const response = await axios.get(filteredEndpoint);
			const data = response.data["data"];
			const convertedWorkingHours: WorkingHour[] = data.map((data: any) => {
				data["startTime"] = convertTo24HourFormat(data["startTime"]);
				data["endTime"] = convertTo24HourFormat(data["endTime"]);
				data["uuid"] = uuidv4();
				return ConvertWorkingHours.toWorkingHours(JSON.stringify(data));
			});

			const convertedWorkingHoursLocal = convertWorkingHoursToLocalTimes(convertedWorkingHours, moment.tz.guess());

			setBranchWorkingHours(convertedWorkingHoursLocal);
			setLoading(false);
		} catch (error) {
			console.error("Error fetching branch working hours:", error);
			setError(error.message);
			setLoading(false);
		}
	};

	const getStaffWorkingHours = async (signal: AbortSignal) => {
		if (signal.aborted) return;

		try {
			setLoading(true);
			const eneityuserId = selectedEntityUserId ? selectedEntityUserId : loggedInUser?.user_id;
			const paramsMap = new Map<string, string>([["id", eneityuserId]]);
			const filteredEndpoint = replaceParams(endpoints.staff.workingHours, paramsMap);

			const response = await axios.get(filteredEndpoint, { signal });

			// Check if request was aborted after the response
			if (signal.aborted) return;

			const data = response.data["data"];
			const timeZone = moment.tz.guess();

			const convertedWorkingHours: WorkingHour[] = data
				.map((data: any) => {
					try {
						const startMoment = moment.utc(data["startTime"], "HH:mm A");
						data["startTime"] = startMoment.isValid() ? startMoment.tz(timeZone).format("hh:mm A") : "Invalid Time";

						const endMoment = moment.utc(data["endTime"], "HH:mm A");
						data["endTime"] = endMoment.isValid() ? endMoment.tz(timeZone).format("hh:mm A") : "Invalid Time";

						return ConvertWorkingHours.toWorkingHours(JSON.stringify(data));
					} catch (parseError) {
						console.error("Error parsing working hours:", parseError);
						return null; // Handle potential parsing issues gracefully
					}
				})
				.filter(Boolean); // Remove any null values from parsing errors

			setStaffWorkingHours(convertedWorkingHours);
		} catch (error: any) {
			if (axios.isCancel(error)) {
				console.log("Request canceled:", error.message);
			} else {
				console.error("Error fetching staff working hours:", error);
				setError(error.message);
			}
		} finally {
			setLoading(false);
		}
	};

	const [services, setServices] = useState<Service[]>([]);

	async function getServices() {
		try {
			const paramsMap = new Map<string, string>([["branchId", branchId || ""]]);
			const filteredEndpoint = replaceParams(endpoints.branch.services, paramsMap);
			const response = await axios.get(filteredEndpoint);
			if (response.status === 200) {
				if (response.data["data"] && response.data["data"].length > 0) {
					setServices(response.data["data"]);
					setSelectedService(response.data["data"][0]);
				} else {
					/** Get appointment types if*/
					console.log("No services found");
				}
			} else {
				toast.error(response.data["message"]);
			}
		} catch (error) {
			toast.error(error);
		}
	}

	// Define the return type for the function
	type WorkingHoursRange = {
		minStartTime: string | null;
		maxEndTime: string | null;
	};

	const findWorkingHoursRange = (workingHours: { startTime: string; endTime: string }[]): WorkingHoursRange => {
		if (!workingHours || workingHours.length === 0) return { minStartTime: null, maxEndTime: null };

		return workingHours.reduce(
			(acc: WorkingHoursRange, current) => {
				const currentStartTime = current.startTime; // Assuming startTime is a string in "HH:mm" format
				const currentEndTime = current.endTime; // Assuming endTime is a string in "HH:mm" format

				// Update minimum start time
				if (acc.minStartTime === null || currentStartTime < acc.minStartTime) {
					acc.minStartTime = currentStartTime;
				}

				// Update maximum end time
				if (acc.maxEndTime === null || currentEndTime > acc.maxEndTime) {
					acc.maxEndTime = currentEndTime;
				}

				return acc;
			},
			{ minStartTime: null, maxEndTime: null } // Initial accumulator
		);
	};

	// Usage
	const { minStartTime, maxEndTime } = findWorkingHoursRange(branchWorkingHours);

	useEffect(() => {
		const fetchData = async () => {
			await getBranchWorkingHours();
			setLoading(false);
		};
		getBranches();
		fetchData();
		getServices();

		setStartDate(moment().format("DD-MM-YYYY"));
		setEndDate(
			moment()
				.add(days - 1, "days")
				.format("DD-MM-YYYY")
		);

		if (
			userData?.user_role === "ENTITY_OWNER" ||
			userData?.user_role === "ASSISTANT" ||
			userData?.user_role === "BRANCH_ADMIN"
		) {
			fetchStaffList();
		}

		//setBranchId();
	}, []);

	useEffect(() => {
		//if (!selectedEntityUserId) return;

		const controller = new AbortController();
		const signal = controller.signal;

		getStaffWorkingHours(signal);
		getAllAppointments(signal);

		return () => controller.abort(); // Cleanup on unmount or dependency change
	}, [selectedEntityUserId]);

	useEffect(() => {
		const workingHours = branchWorkingHours[selectedDate.getDay()];
		if (workingHours) {
			setStartTime(workingHours.startTime);
			setEndTime(workingHours.endTime);
		}
	}, [selectedDate, branchWorkingHours]);

	/* 	// Only run this after working hours are set
	useEffect(() => {
		if (!loading && branchWorkingHours.length > 0 && selectedEntityUserId) {
			getStaffWorkingHours();
			getAllAppointments();
		}
		if (branchWorkingHours.length > 0) {
			const workingHours = branchWorkingHours[selectedDate.getDay()];
			if (workingHours) {
				setStartTime(workingHours.startTime);
				setEndTime(workingHours.endTime);
			}
		}
	}, [selectedDate, selectedDuration, branchWorkingHours, selectedEntityUserId]); */

	const [timeSlots, setTimeSlots] = useState<TimeSlot[]>([]);
	useEffect(() => {
		if (branchWorkingHours.length > 0) {
			const generatedSlots = generateTimeSlots(minStartTime ?? "", maxEndTime ?? "");
			setTimeSlots(generatedSlots);
		}
	}, [branchWorkingHours]);

	useEffect(() => {
		setTimeSlots(generateTimeSlots(startTime, endTime));
	}, [weekStartDate, startTime, endTime]);

	const handleScroll = (direction: "left" | "right") => {
		const container = document.getElementById("calendar-container");
		if (container) {
			const scrollAmount = 500;
			const newPosition =
				direction === "left"
					? Math.max(0, scrollPosition - scrollAmount)
					: Math.min(scrollPosition + scrollAmount, container.scrollWidth - container.clientWidth);

			container.scrollTo({
				left: newPosition,
				behavior: "smooth",
			});
			setScrollPosition(newPosition);
			setShowLeftArrow(newPosition > 0);
			setShowRightArrow(newPosition < container.scrollWidth - container.clientWidth);
		}
	};

	const handleScrollCheck = () => {
		const container = document.getElementById("calendar-container");
		if (container) {
			setShowLeftArrow(container.scrollLeft > 0);
			setShowRightArrow(container.scrollLeft <= container.scrollWidth - container.clientWidth);
		}
	};

	useEffect(() => {
		const container = document.getElementById("calendar-container");
		if (container) {
			container.addEventListener("scroll", handleScrollCheck);
			handleScrollCheck();
		}
		return () => {
			container?.removeEventListener("scroll", handleScrollCheck);
		};
	}, []);

	const handleBooking = (therapistName: string, startTime: string, endTime: string) => {
		alert(`Booking with ${therapistName} from ${startTime} to ${endTime}`);
	};

	const isSlotAvailable = (slots: TimeSlot[], startIndex: number, duration: number) => {
		const slotsNeeded = duration / 15;
		for (let i = 0; i < slotsNeeded; i++) {
			const slot = slots[startIndex + i];
			if (!slot || !slot.isAvailable || slot.isBooked) {
				return false;
			}
		}
		return true;
	};

	const fetchStaffList = async () => {
		try {
			let response;
			if (branchId) {
				response = await axios.get(endpoints.staff.list, {
					params: {
						branchId: branchId.toString() ?? "",
					},
				});
			} else {
				response = await axios.get(endpoints.staff.list);
			}

			const data = response.data.data;
			const convertedStaffList = data.map((staff: any) => ConvertStaff.toStaff(JSON.stringify(staff)));
			setStaffList(convertedStaffList);

			/** Set default staff in dropdown */
			if (userData?.user_role === "ASSISTANT") {
				setSelectedEntityUserId(convertedStaffList[0].id ?? "");
				setSelectedEntityUserName(
					`${convertedStaffList[0].salutation}. ${convertedStaffList[0].firstName} ${convertedStaffList[0].lastName}`
				);
			} else {
				setSelectedEntityUserId(userData?.user_id ?? "");
				setSelectedEntityUserName(userData?.name ?? "");
			}
		} catch (error) {
			console.error("Error fetching staff list:", error);
			setError(error.message);
		}
	};

	const userData = getUserPersistedOnLocalStorage();
	const [branches, setBranches] = React.useState<EntityBranch[] | null>(null); // this is the list of branches [{}]
	const [branchId, setBranchId] = React.useState<string>(userData?.branch_id.toString() ?? "");
	const getBranches = async () => {
		try {
			const paramsMap = new Map<string, string>([["entityId", userData?.entity_id ?? ""]]);
			const filteredEndpoint = replaceParams(endpoints.branch.list, paramsMap);

			const response = await axios.get(filteredEndpoint);
			const data = response.data["data"];
			const convertedBranchList = data.map((entity: any) => EntityBranchConvert.toEntityBranch(JSON.stringify(entity)));

			setBranches(convertedBranchList);
			console.log("convertedBranchList", convertedBranchList);
			setLoading(false);
		} catch (error) {
			console.error("Error fetching entity list:", error);
		}
	};

	const formatEndTime = (startSlot: TimeSlot) => {
		const endMinutes = startSlot.minutesFromMidnight + selectedDuration.minutes;
		const hours = Math.floor(endMinutes / 60);
		const minutes = endMinutes % 60;
		const period = hours >= 12 ? "PM" : "AM";
		const displayHour = hours % 12 || 12;
		return `${displayHour}:${minutes.toString().padStart(2, "0")} ${period}`;
	};

	const getEndTime = (startSlot: TimeSlot) => {
		const endMinutes = startSlot.minutesFromMidnight + selectedDuration.minutes;
		const hours = Math.floor(endMinutes / 60);
		const minutes = endMinutes % 60;
		return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
	};
	const [selectedPatientBranchId, setSelectedPatientBranchId] = React.useState<string | null>(null);
	interface AppointmentData {
		AppointmentDuration: number[];
		AppointmentType: string[];
		appointmentString: string[];
	}

	const [selectedPatient, setSelectedPatient] = useState<Patient | null>(null);
	const [selectedPatientId, setSelectedPatientId] = React.useState<string | null>(null);
	const [selectedPatientName, setSelectedPatientName] = React.useState<string | null>(null);
	const [selectedPatientAddress, setSelectedPatientAddress] = React.useState<string | null>(null);
	const [selectedPatientEmail, setSelectedPatientEmail] = React.useState<string | null>(null);
	const [selectedPatientPhone, setSelectedPatientPhone] = React.useState<number | null>(null);

	const loggedInUser = getUserPersistedOnLocalStorage();

	const getStatusClasses = (status: string) => {
		if (status === "booked") return "bg-orange-50 text-orange-400 cursor-pointer";
		if (status === "available") return "bg-gray-50 text-gray-600 hover:bg-green-100 cursor-pointer";
		return "bg-gray-50 text-gray-400 cursor-pointer";
	};
	const [postInProgress, setPostInProgress] = React.useState<boolean>(false);
	const cancelToken = React.useRef<CancelTokenSource | null>(null);

	function setStaffDetails(staffid: string): void {
		const employee = staffList?.find((staff) => staff.id === staffid);
		setSelectedEntityUserId(employee?.id ?? "");
		setSelectedEntityUserName(`${employee?.salutation}. ${employee?.firstName} ${employee?.lastName}`);
		setSelectedEntityUserPhone(employee?.phone ?? 0);
	}

	type AppointmentType = z.infer<typeof AppointmentSchema>;

	const addTimeToTimeZoneAndConvertToISOString = (date: Date, timeString: string): Date => {
		//   Parse input date and time using Moment.js

		const inputDate = moment(date);
		const inputTime = moment(timeString, "hh:mm A");

		// Combine date and time
		let resultDate = inputDate.set({
			hour: inputTime.hours(),
			minute: inputTime.minutes(),
		});
		// Convert to ISO string and return
		return new Date(moment(resultDate).toISOString());
	};

	const onSubmit: SubmitHandler<AppointmentType> = async () => {
		if (cancelToken.current) {
			cancelToken.current.cancel("Request already in progress!");
		}
		cancelToken.current = axios.CancelToken.source();

		if (postInProgress) return;
		try {
			setPostInProgress(true);
			await axios.post(
				`${endpoints.appointment.recurringAppointment}`,
				{
					appointmentdetails: selectedSlots,
				},
				{
					cancelToken: cancelToken.current.token,
				}
			);
			setPostInProgress(false);
			toast.success("Appointment Booked Successfully");

			// Resetting the state after successful submission
			setSelectedSlots([]); // Clear selected slots
			setSelectedPatient(null); // Reset selected patient
			setSelectedPatientId(null); // Reset selected patient ID
			setSelectedPatientName(null); // Reset selected patient name
			setSelectedPatientAddress(null); // Reset selected patient address
			setSelectedPatientEmail(null); // Reset selected patient email
			setSelectedPatientPhone(null); // Reset selected patient phone
			setConsultationMode("In-Clinic"); // Reset consultation mode to default
			setSelectedService(services?.[0]); // Reset selected service
			setSelectedDuration(bookingDurations[0]); // Reset selected duration to default
			setWeekStartDate(new Date()); // Reset week start date to today
			setSelectedDate(new Date()); // Reset selected date to today
			const controller = new AbortController();
			const signal = controller.signal;
			getStaffWorkingHours(signal);
			getAllAppointments(signal);
			setIsSidebarOpen(false);
		} catch (error) {
			console.error("Error submitting form:", error);
			toast.error(error["response"]["data"]["data"]);
			setPostInProgress(false);
		}
	};

	const handleBranchSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setBranchId(event.target.value);
	};

	const [rows, setRows] = useState([]);

	const getAllAppointments = async (signal: AbortSignal) => {
		if (signal.aborted) return;
		try {
			setLoading(true);
			const entityUserId = selectedEntityUserId ? selectedEntityUserId : loggedInUser?.user_id;
			const response = await axios.get(endpoints.appointment.list, {
				params: {
					branchId: branchId,
					requestedFromDate: startDate,
					requestedToDate: endDate,
					requestedAppointmentStatus: "Scheduled",
					userId: entityUserId,
					sort: "asc",
				},
				signal, // Ensure the request is cancellable
			});

			if (signal.aborted) return; // Prevent processing after an abort

			const data = response.data["data"];

			if (!data) {
				setAppointmentsByDate(new Map());
				return;
			}

			const appointmentsMap = new Map<string, Appointment[]>();
			const timeZone = moment.tz.guess();

			data.forEach((appointment: any) => {
				try {
					let appointmentShadow = AppointmentConvert.toAppointment(JSON.stringify(appointment));
					let duration = appointment.endTimeMinutes - appointment.startTimeMinutes;

					// Ensure proper date parsing
					appointmentShadow.apptdate = moment.utc(appointmentShadow.apptdate).local().toDate();

					// Attempt to parse the start time
					const startMoment = moment.utc(appointmentShadow.startTime, "HH:mm A");
					appointmentShadow.startTime = startMoment.isValid()
						? startMoment.tz(timeZone).format("hh:mm A")
						: "Invalid Time";

					// Attempt to parse the end time
					const endMoment = moment.utc(appointmentShadow.endTime, "HH:mm A");
					appointmentShadow.endTime = endMoment.isValid() ? endMoment.tz(timeZone).format("hh:mm A") : "Invalid Time";

					// Format date key
					const dateKey = moment(appointmentShadow.apptdate).format("DD-MM-YYYY");

					if (!appointmentsMap.has(dateKey)) {
						appointmentsMap.set(dateKey, []);
					}
					appointmentsMap.get(dateKey)?.push(appointmentShadow);
				} catch (parseError) {
					console.error("Error parsing appointment:", parseError);
				}
			});

			setAppointmentsByDate(appointmentsMap);
		} catch (error: any) {
			if (axios.isCancel(error)) {
				console.log("Request canceled:", error.message);
			} else {
				console.error("Error fetching appointments list:", error);
				setError(error.message);
			}
		} finally {
			setLoading(false);
		}
	};

	const getAppointmentType = useCallback(async () => {
		try {
			const userData = getUserPersistedOnLocalStorage();
			const entityIdset = userData?.entity_id;

			// Ensure entityIdset is defined before using it
			if (entityIdset !== undefined) {
				// Create a Map with the entityIdset as the value
				const paramsMap = new Map<string, string>([["id", entityIdset]]);

				// Continue with your logic using paramsMap
				const filteredEndpoint = replaceParams(endpoints.branch.services, paramsMap);
				const response = await axios.get(filteredEndpoint);
				const data: AppointmentData = response.data["data"];
				setAppointmentTypeData(data);
			}
		} catch (error) {
			console.error("Error fetching appointment type:", error);
		}
	}, []);
	const formatDateForInput = (date: Date) => {
		return date.toISOString().split("T")[0];
	};
	const handleDateChange = (date: string) => {
		const newDate = new Date(date);
		setSelectedDate(newDate);
		setStartDate(moment().format("DD-MM-YYYY"));
		setEndDate(
			moment()
				.add(days - 1, "days")
				.format("DD-MM-YYYY")
		);

		// Adjust week view to include the selected date
		const dayOfWeek = newDate.getDay();
		const weekStart = new Date(newDate);
		weekStart.setDate(newDate.getDate() - dayOfWeek);
		setWeekStartDate(weekStart);
	};

	useEffect(() => {
		if (consultationMode !== null && selectedSlots.length > 0) {
			selectedSlots.forEach((slot) => {
				slot.modeOfConsultation = consultationMode;
			});
		}
	}, [consultationMode]);

	useEffect(() => {
		if (selectedPatientId !== null && selectedSlots.length > 0) {
			selectedSlots.forEach((slot) => {
				slot.clientId = selectedPatientId;
				slot.clientName = selectedPatientName ?? "";
				slot.clientPhone = selectedPatientPhone ?? 0;
				slot.clientAddress = selectedPatientAddress ?? "";
				slot.email = selectedPatientEmail ?? "";
			});
		}
	}, [selectedPatientId, consultationMode]);

	// Add an effect to handle slot updates
	useEffect(() => {
		if (selectedSlots.length > 0) {
			// Handle slot updates here instead of in the handler
			// console.debug("Selected slots updated:", selectedSlots);
			// Additional logic like updating form values
			setValue("slotToBook", JSON.stringify(selectedSlots));
		}
	}, [selectedSlots]);

	const handleConsultationModeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setConsultationMode(e.target.value);
	};

	const handleAddAppointment = (appointmentRequest: AppointmentDto) => {
		const isDuplicate = selectedSlots.some(
			(slot) =>
				slot.startTime === appointmentRequest.startTime &&
				moment(slot.apptdate).isSame(appointmentRequest.apptdate, "day") &&
				slot.entityUserId === appointmentRequest.entityUserId
		);

		if (isDuplicate) {
			toast.warning("An appointment already exists at this time and date. Please select different slot"); // Display error message
			return; // Do not add the duplicate appointment
		}
		setSelectedSlots((prevSlot) => [...prevSlot, appointmentRequest]);
	};

	const handleTimeSlotClick = (timeSlot: TimeSlot, day: any) => {
		// Check if slot is already selected
		let entityUserId = selectedEntityUserId ? selectedEntityUserId : loggedInUser?.user_id;
		let entityUserName = selectedEntityUserName ? selectedEntityUserName : loggedInUser?.name;
		const isSlotSelected = selectedSlots.some(
			(slot) =>
				slot.entityUserId === entityUserId &&
				slot.startTimeLocal === timeSlot.time &&
				moment(slot.apptdate).isSame(day.fullDate, "day")
		);

		if (isSlotSelected) {
			// Deselect the slot if clicked again
			setSelectedSlots((prev) =>
				prev.filter(
					(slot) =>
						!(
							slot.entityUserId === entityUserId &&
							slot.startTimeLocal === timeSlot.time &&
							moment(slot.apptdate).isSame(day.fullDate, "day")
						)
				)
			);
			return; // deselect the slot and return if the slot is already selected
		}

		let slotDate = addTimeToTimeZoneAndConvertToISOString(day.fullDate, timeSlot.time);
		let date = moment.utc(slotDate).format("DD-MM-YYYY");
		// Converting String eg "10:00 AM" to Equivalent UTC String
		let startTime = moment.utc(slotDate).format("hh:mm A");
		let endTime = moment(startTime, "hh:mm A").add(selectedDuration.minutes, "minutes").format("hh:mm A");
		// Converting startTime Minutes to Equivalent UTC minutes
		let startTimeMinutes = slotDate.getUTCHours() * 60 + slotDate.getUTCMinutes();
		let endTimeMinutes = slotDate.getUTCHours() * 60 + slotDate.getUTCMinutes() + selectedDuration.minutes;
		const appointmentRequest: AppointmentDto = {
			clientId: selectedPatientId ?? "",
			clientName: selectedPatientName ?? "",
			clientPhone: selectedPatientPhone ?? 0,
			clientAddress: selectedPatientAddress ?? "",
			email: selectedPatientEmail ?? "",
			entityUserId: entityUserId,
			entityUserName: entityUserName,
			entityId: userData?.entity_id ?? "",
			entityBranchId: branchId ?? "",
			startTime: startTime,
			startTimeLocal: timeSlot.time,
			endTime: endTime,
			endTimeLocal: formatEndTime(timeSlot),
			startTimeMinutes: startTimeMinutes,
			endTimeMinutes: endTimeMinutes,
			duration: selectedDuration.minutes,
			date: date,
			apptdate: slotDate,
			appointmentType: selectedService?.name ?? "",
			serviceId: selectedService?.id ?? "",
			modeOfConsultation: consultationMode,
			isOnline: false,
			appointmentStatus: "Scheduled",
			appointmentSlot: 0,
			entityUserPhone: selectedEntityUserPhone ?? 0,
		};
		handleAddAppointment(appointmentRequest); // Use the new function to add appointment
	};

	useEffect(() => {
		if (selectedService) {
			const duration = bookingDurations.find((d) => d.minutes === Number(selectedService.duration));
			if (duration) {
				setSelectedDuration(duration);
			}
		}
	}, [selectedService]);

	useEffect(() => {
		const fetchData = async () => {
			await getServices();
			// await fetchStaffList(); // Reload staff list
			await getBranchWorkingHours(); // Reload working hours
		};

		fetchData(); // Call the fetch function
	}, [branchId]); // Dependency array includes branchId

	const getCalendarDays = () => {
		const days = [];

		for (let i = 0; i < 15; i++) {
			const date = new Date(selectedDate);
			date.setDate(selectedDate.getDate() + i);
			days.push({
				date: date,
				dayIndex: date.getDay(),
				fullDate: moment(date).format("YYYY-MM-DD"),
				isToday: i === 0,
			});
		}
		return days;
	};

	//  const [calendarDays, setCalendarDays] = useState([]);

	const calendarDays = getCalendarDays();
	/* 	const timeSlots: string[] = [];

	for (let hour = 8; hour < 20; hour++) {
		for (let minute = 0; minute < 60; minute += 15) {
			timeSlots.push(`${hour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}`);
		}
	} */
	const isWithinStaffWorkingHours = (time: string, dayOfWeek: number) => {
		return staffWorkingHours.some((staffHour) => {
			if (dayOfWeek !== staffHour.day) {
				return false;
			}
			const staffHourStart = moment(staffHour?.startTime, "HH:mm a");
			const staffHourEnd = moment(staffHour?.endTime, "HH:mm a");
			const timeSlotStart = moment(time, "HH:mm a");
			return timeSlotStart.isBetween(staffHourStart, staffHourEnd, undefined, "[]");
		});
	};

	const getAppointmentHeight = (duration: number) => {
		const slots = duration / 15;
		return `${slots * 3}rem`;
	};

	const fetchAppointmentsForSlot = (time: string, date: string) => {
		const formattedDate = moment(date).format("DD-MM-YYYY");
		return (
			appointmentsByDate
				.get(formattedDate)
				?.filter((apt) => moment(apt.startTime, "hh:mm A").isSame(moment(time, "hh:mm A"), "minute")) || []
		);
	};

	const isSlotOccupied = (time: string, date: string) => {
		const timeSlotStart = moment(time, "HH:mm a");
		return appointmentsByDate.get(date)?.some((appointment) => {
			const staffHourStart = moment(appointment?.startTime, "HH:mm a");
			const staffHourEnd = moment(appointment?.endTime, "HH:mm a");
			return timeSlotStart.isBetween(staffHourStart, staffHourEnd, undefined, "[]");
		});
	};

	const navigate = useNavigate(); // Initialize useNavigate

	return (
		<div className="flex flex-col gap-4">
			{/* Overlay */}
			{isSidebarOpen && (
				<div
					className="fixed inset-0 bg-gray-900/30 transition-opacity duration-300 z-20"
					onClick={() => setIsSidebarOpen(false)}
				/>
			)}

			{/* Toggle Button with Appointment Count */}
			<button
				onClick={() => setIsSidebarOpen(!isSidebarOpen)}
				className={`fixed bottom-[20%]  md:top-[60%] z-40 p-2 ${
					isSidebarOpen ? "bg-primary-50 border-primary-900" : "bg-primary-500 boarder-primary-100"
				} border hover:bg-primary-800 rounded-l-lg shadow-md border-r-0 flex items-center gap-2 text-gray-900 transition-colors md:h-20 h-16`}
				style={{
					right: isSidebarOpen ? "400px" : "0px",
					transition: "right 0.3s ease-in-out",
				}}
			>
				<ChevronRight
					className={`w-6 h-6 transform transition-transform ${
						isSidebarOpen ? "text-gray-800" : "text-primary-100 font-bold"
					} ${isSidebarOpen ? "rotate-180" : ""}`}
				/>
				{selectedSlots.length > 0 && (
					<div className="absolute -top-2 left-2 bg-primary-600 text-white text-xs font-bold rounded-full w-5 h-5 flex items-center justify-center">
						{selectedSlots.length}
					</div>
				)}
				{!isSidebarOpen ? (
					<div className="flex flex-col items-start">
						<span className="text-xs md:text-sm font-bold text-primary-100">
							{selectedSlots.length > 0 ? (
								<>
									{selectedSlots.length} {selectedSlots.length === 1 ? "Slot" : "Slots"} Selected
								</>
							) : (
								"Select slots"
							)}
						</span>
						<span className="text-xs md:text-sm text-gray-100">
							{selectedSlots.length > 0 ? "Click to complete booking" : " from calendar to start booking"}
						</span>
					</div>
				) : (
					<div className="flex flex-col items-start">
						<span className="text-sm font-medium text-gray-800">Close</span>
					</div>
				)}
			</button>

			{/* Main Content */}
			<div className="relative z-10">
				{/* Scrollable Appointment Slots */}
				{/* 				<PageHeader title="Consultant/Therapist Schedule" />
				 */}
				{/* Back Button */}
				<div className="flex justify-between items-center mb-4">
					<span className="flex flex-row text-sm md:text-base lg:text-lg font-semibold  items-center pb-2 md:pb-0">
						Schedule
					</span>
					<Button
						onClick={() => navigate(-1)} // Navigate back to the previous page
						className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400"
						variant={ButtonVariant.GRAY}
					>
						<ArrowLeft className="w-4 h-4" /> Back
					</Button>
				</div>
				<div className="flex flex-col rounded-lg ">
					{/* Calander Area */}
					{/* Calander Area */}
					<div className="w-full overflow-hidden relative">
						{/* Title and Legends */}
						<div className="flex items-center justify-between p-4 border rounded-lg bg-gray-50">
							<div className="flex flex-col lg:flex-row gap-4 w-full">
								{/* Service, Duration, and Date/Time Selectors */}
								<div className="flex flex-col lg:w-1/3">
									<ApplebelCustom
										className="text-sm font-semibold text-primary-600"
										label="Appointment Type/Service"
										mandatory
									/>
									<select
										className="block w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6 cursor-pointer"
										value={selectedService?.id}
										onChange={(e) => {
											const newServiceId = e.target.value;
											if (newServiceId === selectedService?.id) return; // No need to change

											const newService = services?.find((service) => service.id === newServiceId);
											if (!newService) return;

											if (selectedSlots.length > 0) {
												const confirmChange = window.confirm(
													"Changing the service will clear existing selected slots. Do you want to proceed?"
												);
												if (!confirmChange) {
													return; // Exit early if user cancels change
												}
												setSelectedSlots([]); // Clear slots if confirmed
											}
											setSelectedService(newService);
										}}
										required
									>
										<option value="" disabled>
											Select a service
										</option>{" "}
										{/* Placeholder */}
										{services?.map((service) => (
											<option key={service.id} value={service.id}>
												{service.name}
											</option>
										))}
									</select>
								</div>
								<div className="flex flex-col md:flex-row lg:w-2/3 gap-2">
									{/* Duration Selector */}
									<div className="flex flex-col flex-1">
										<ApplebelCustom className="text-sm font-bold text-primary-600" label="Duration" />
										<select
											className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 bg-gray-100 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 cursor-pointer"
											value={selectedDuration.minutes}
											onChange={(e) => {
												const duration = bookingDurations.find((d) => d.minutes === Number(e.target.value));
												if (duration) setSelectedDuration(duration);
											}}
											required
										>
											{bookingDurations.map((duration) => (
												<option key={duration.label} value={duration.minutes}>
													{duration.label}
												</option>
											))}
										</select>
									</div>
									{/* Date and Time Selectors */}
									<div className="flex flex-col flex-1">
										<Applabel label="Select Date" />
										<input
											type="date"
											value={moment(selectedDate).format("YYYY-MM-DD")}
											onChange={(e) => handleDateChange(e.target.value)}
											className="w-full border border-gray-300 rounded-lg text-sm focus:ring-secondary-500 focus:border-secondary-500 py-2"
										/>
									</div>
									<div className="flex flex-row gap-2">
										<div className="flex flex-col flex-1">
											<Applabel label="Start Time" />
											<input
												type="time"
												value={startTime}
												min="00:00"
												max={endTime}
												onChange={(e) => setStartTime(e.target.value)}
												className="w-full border border-gray-300 rounded-lg text-sm focus:ring-secondary-500 focus:border-secondary-500 py-2"
											/>
										</div>
										<div className="flex flex-col flex-1">
											<Applabel label="End Time" />
											<input
												type="time"
												value={endTime}
												min={startTime}
												max="23:59"
												onChange={(e) => setEndTime(e.target.value)}
												className="w-full border border-gray-300 rounded-lg text-sm focus:ring-primary-500 focus:border-primary-500 py-2"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="flex flex-col py-4 mt-4 border border-gray-300 rounded-lg bg-gray-50">
							<div className="flex text-xs w-full flex-row my-2 gap-2 px-2 justify-between">
								<div className="flex flex-row gap-2 items-center">
									{/* Branch Selector */}
									{userData?.user_role === "ENTITY_OWNER" && (
										<div className="flex items-center gap-2">
											<span className="text-sm font-medium text-gray-600">Branch:</span>

											<select
												onChange={handleBranchSelectChange}
												name="entityBranchId"
												value={branchId?.toString()}
												className="w-48 rounded-lg border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 cursor-pointer"
											>
												{branches?.map((branch) => (
													<option key={branch.id} value={branch.id}>
														{branch.name}
													</option>
												))}
											</select>
										</div>
									)}
									{/** Physio selector*/}
									{(userData?.user_role === "ENTITY_OWNER" ||
										userData?.user_role === "ASSISTANT" ||
										userData?.user_role === "BRANCH_ADMIN") && (
										<div className="flex items-center gap-2">
											<span className="text-sm font-medium text-gray-600">Consultant/Therapist:</span>
											<select
												className="lock w-full rounded-lg border-0 py-1 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
												value={selectedEntityUserId}
												disabled={loading}
												onChange={(e) => {
													setStaffDetails(e.target.value);
													setValue("physioId", e.target.value);
													setSelectedSlots([]);
													if (e.target.value !== "") {
														clearErrors("physioId");
													}
												}}
												required
											>
												{staffList &&
													staffList.map((staff: Staff) => {
														if (
															(staff.role?.role === "PRACTITIONER" ||
																staff.role?.role === "ENTITY_OWNER" ||
																staff.role?.role === "BRANCH_ADMIN") &&
															staff.isActive
														)
															return (
																<option value={staff.id}>
																	{`${staff.salutation}. ${staff.firstName} ${staff.middleName || ""} ${
																		staff.lastName || ""
																	}`}
																</option>
															);
													})}
											</select>
											{errors.physioId && <ValidationError message={errors.physioId?.message?.toString() ?? ""} />}
										</div>
									)}
								</div>

								<div className="flex flex-row gap-2 items-center">
									<h2 className="text-xs font-medium text-gray-900">Time Slots</h2>
									<div className="flex items-center space-x-2">
										<div className={`w-4 h-4 rounded border border-gray-700 bg-white`}></div>
										<span className="text-gray-600">Available</span>
									</div>
									<div className="flex items-center space-x-2">
										<div className={`w-4 h-4 rounded border border-gray-700 bg-primary-400`}></div>
										<span className="text-gray-600">Booked</span>
									</div>
									<div className="flex items-center space-x-2">
										<div className={`w-4 h-4 rounded border border-gray-700 bg-gray-200`}></div>
										<span className="text-gray-600">Off Hours</span>
									</div>

									{/* Navigation Arrows */}
									<div className="flex space-x-2 justify-end items-end text-right">
										<button
											onClick={() => handleScroll("left")}
											className={`p-2 rounded-lg border ${
												showLeftArrow
													? "bg-white text-primary-600 border-secondary-200 hover:bg-primary-50"
													: "bg-gray-100 text-gray-400 border-gray-200 cursor-not-allowed"
											}`}
											disabled={!showLeftArrow}
											aria-label="Scroll left"
										>
											<ChevronLeft className="w-6 h-6" />
										</button>
										<button
											onClick={() => handleScroll("right")}
											className={`p-2 rounded-lg border ${
												showRightArrow
													? "bg-white text-primary-600 border-secondary-200 hover:bg-primary-50"
													: "bg-gray-100 text-gray-400 border-gray-200 cursor-not-allowed"
											}`}
											disabled={!showRightArrow}
											aria-label="Scroll right"
										>
											<ChevronRight className="w-6 h-6" />
										</button>
									</div>
								</div>
							</div>
							<div className="relative flex border border-gray-300 rounded-md" style={{ minWidth: "1400px" }}>
								{/* Scrollable Content */}
								<div className="overflow-x-auto scrollbar-hide" id="calendar-container">
									{/*  Header Starts */}
									<div className="grid sticky top-0 bg-gray-100 border z-2 grid-cols-[100px_repeat(15,minmax(220px,1fr))]">
										{/* Fixed "Time" Header */}
										<div className="p-4 font-medium bg-white border-r sticky top-0 left-0 z-40">Time</div>

										{/* Calendar Days Header */}
										{calendarDays.map((day) => (
											<div
												key={`header-${day.fullDate}`}
												className={`p-4 border-r last:border-r-0 transition-colors ${
													day.isToday ? "bg-primary-50" : "bg-white"
												}`}
											>
												<div className="font-medium text-sm items-center justify-center text-center text-gray-900">
													{moment(day.fullDate).format("ddd D MMM")}
												</div>
											</div>
										))}
									</div>
									{/*  Header Ends */}
									{/* Time slots */}
									<div className="relative">
										{timeSlots.map((timeSlot) => {
											let time = timeSlot.time;
											let slotEndtime = formatEndTime(timeSlot);
											/*             const isSelectedSlot = selectedSlots.some((slot) => {
                        return (
                          slot.startTimeLocal === timeSlot.time &&
                          moment(slot.date).isSame(selectedDate, "day")
                        );
                      });
                      console.log(timeSlot.time + " " + selectedDate + " " + isSelectedSlot); */
											return (
												<div key={`row-${time}`} className="grid grid-cols-[100px_repeat(15,minmax(220px,1fr))]">
													<div
														className={`p-2 border text-sm font-medium text-gray-600 h-12 sticky left-0 top-0 z-40 bg-white`}
													>
														<div className="flex items-start gap-1 ">
															{time.includes(":00") ? <Clock className="w-4 h-4" /> : <div className="w-4 h-4" />}
															{time}
														</div>
													</div>
													{calendarDays.map((day) => {
														const appointments = fetchAppointmentsForSlot(time, day.fullDate);
														// Check if the current time slot is selected
														// Optimize selected slot lookup using a Set
														const selectedSlotSet = new Set(
															selectedSlots.map(
																(slot) => `${slot.startTimeLocal}-${moment(slot.apptdate).format("YYYY-MM-DD")}`
															)
														);
														const isSelectedSlot = selectedSlotSet.has(
															`${timeSlot.time}-${moment(day.fullDate).format("YYYY-MM-DD")}`
														);

														return (
															<div
																key={`cell-${day.fullDate}-${time}`}
																className={`border h-12 relative w-[220px] ${
																	isWithinStaffWorkingHours(time, day.dayIndex) ? "bg-white" : "bg-gray-100"
																}`}
															>
																<div
																	className={`absolute  w-full h-full ${
																		isSelectedSlot
																			? "bg-primary-50  border-2 shadow-md scale-[1.02] z-10 font-medium"
																			: ""
																	}`}
																	onClick={() => {
																		handleTimeSlotClick(timeSlot, day);
																	}}
																>
																	{isSelectedSlot && (
																		<div className="items-center p-2 text-xs text-right z-40">
																			{timeSlot.time} to {slotEndtime} ({selectedDuration.minutes} mins)
																			<div className="mt-1 text-xs text-primary-600">✓ Selected</div>
																		</div>
																	)}
																</div>
																{appointments.map((appointment) => {
																	if (appointment) {
																		const duration = appointment.endTimeMinutes - appointment.startTimeMinutes;
																		return (
																			<div
																				className={`absolute left-0 right-0 m-1 p-2 bg-blue-100  rounded-sm w-2/3 border border-primary-400 shadow-md overflow-hidden`}
																				onClick={() => {
																					setSelectedAppointments(appointments);
																					setIsPopupOpen(true);
																				}}
																				style={{ height: getAppointmentHeight(duration), zIndex: 10 }}
																			>
																				<div className="font-medium text-xs justify-between">
																					<div className="flex items-center gap-2">
																						{appointment.clientName}
																						{appointments.length > 1 && (
																							<div className="border-primary-900 border text-xs bg-primary-500 text-white p-2 rounded-2xl w-4 h-4 flex items-center justify-center">
																								{appointments.length}
																							</div>
																						)}
																					</div>
																					<div>
																						{timeSlot.time} ({appointment.endTimeMinutes - appointment.startTimeMinutes}{" "}
																						mins)
																					</div>
																				</div>
																			</div>
																		);
																	}
																	return null;
																})}
																{!isSlotOccupied(time, day.date) && <div className="w-full h-full"></div>}
															</div>
														);
													})}
												</div>
											);
										})}
									</div>
								</div>
								{/* Scrollable Content */}
								{/*               <div className="relative">
								 */}
								{/* 							<div className="ml-[120px] overflow-x-auto scrollbar-hide" id="calendar-container">
								 */}
								{/* 								<div className="grid grid-cols-[repeat(10,minmax(200px,1fr))] border-b">
								 */}{" "}
								{/** Main Calander View starts here */}
								{/* Main Calander View ends here */}
								{/* 								
              </div> </div> */}
								{/* Day Headers */}
								{/*                 <div className="grid grid-cols-[repeat(10,minmax(220px,1fr))] border-b">
                            {availableTimeSlots?.map((availableTimeSlot) => {
                              let bookedAppointments: BookedAppointment[] = [];
                              availableTimeSlot.freeslots.forEach((freeslot) => {
                                if (freeslot.appointmentsCount > 0) {
                                  // Get unique appointments based on clientId
                                  bookedAppointments = Array.from(
                                    new Map(
                                      freeslot.bookedAppointments.map((item) => [
                                        item.clientId,
                                        item,
                                      ])
                                    ).values()
                                  );
                                }
                              });
                              return (
                                <div className="w-[220px] bg-white z-10">
                                  <div className="h-16 flex items-center px-4 font-medium text-gray-500 border-b last:border-b-0 shadow-md text-sm overflow-hidden text-ellipsis whitespace-nowrap">
                                    {moment(availableTimeSlot.date).format("ddd D MMM")}
                                  </div>
                                
                                </div>
                              );
                            })}
                          </div> */}
								{/* </div> */}
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* Appointment Form - increase z-index to stay above overlay */}
			<div
				id="appointment-form"
				className="fixed bottom-[20px] right-0 z-50 bg-gray-50 border border-primary-400 rounded-md shadow-lg p-4 h-screen overflow-y-auto transition-all duration-300 ease-in-out"
				style={{
					width: "400px",
					transform: isSidebarOpen ? "translateX(0)" : "translateX(100%)",
				}}
			>
				<div className="flex flex-col gap-4">
					<div className="flex flex-col gap-2">
						<div className="relative my-1">
							{/* Close Button for Small Screens */}
							<button
								onClick={() => setIsSidebarOpen(false)}
								className="fixed top-4 right-4 sm:hidden bg-primary-300 flex items-center justify-center hover:bg-gray-700 disabled:opacity-50 disabled:cursor-not-allowed rounded-md text-sm p-2"
								aria-label="Close"
							>
								<X className="h-4 w-4" /> Close
							</button>
						</div>

						<div className="flex basis-1/2 flex-col">
							<Applabel label="Patient Name" mandatory />
							<PatientSearchV2
								patient={selectedPatient}
								onSelect={(patient: Patient | null) => {
									if (patient) {
										setSelectedPatient(patient);
										setSelectedPatientName(patient.firstName + " " + patient.lastName);
										setSelectedPatientEmail(patient.email);
										setSelectedPatientPhone(patient.phone);
										setSelectedPatientBranchId(patient.entityBranchId);
										let address =
											patient.address.address +
											", " +
											patient.address.city +
											", " +
											patient.address.state +
											", " +
											patient.address.postalCode;
										setSelectedPatientAddress(address);
										setSelectedPatientId(patient._id);
										setValue("patientId", patient._id);
										clearErrors("patientId");
									} else {
										// Reset patient-related state if null
										setSelectedPatientName(null);
										setSelectedPatientEmail(null);
										setSelectedPatientPhone(null);
										setSelectedPatientBranchId(null);
										setSelectedPatientAddress(null);
										setSelectedPatientId(null);
									}
								}}
								key={selectedPatientId || "default"}
							/>
							{errors.patientId && <ValidationError message={errors.patientId?.message?.toString() ?? ""} />}
						</div>

						<div className="flex flex-col">
							<Applabel label="Mode" mandatory />
							<div className="flex flex-row items-center text-sm">
								<input
									type="radio"
									id="inClinic"
									name="consultationMode"
									value="In-Clinic"
									checked={consultationMode === "In-Clinic"}
									onChange={handleConsultationModeChange}
									className="mr-2"
								/>
								<label htmlFor="inClinic" className="text-sm font-medium text-grey-900">
									In&nbsp;Clinic
								</label>
								<span className="pr-2" />
								<input
									type="radio"
									id="homeVisit"
									name="consultationMode"
									value="Home Visit"
									checked={consultationMode === "Home Visit"}
									onChange={handleConsultationModeChange}
									className="mr-2"
								/>
								<label htmlFor="homeVisit pr-2" className="text-sm font-medium text-grey-900">
									Home&nbsp;Visit
								</label>
								<span className="pr-2" />
								<input
									type="radio"
									id="remoteOnline"
									name="consultationMode"
									value="Remote/Online"
									checked={consultationMode === "Remote/Online"}
									onChange={handleConsultationModeChange}
									className="mr-2"
								/>
								<label htmlFor="remoteOnline" className="text-sm font-medium text-grey-900">
									Remote/Online
								</label>
							</div>
						</div>
					</div>

					<div className="flex flex-col">
						<Applabel label="Selected Slots" />
						<div className="flex items-center justify-between gap-4">
							<div className="flex flex-col gap-2 flex-grow">
								{selectedSlots.length > 0 ? (
									<div className="flex flex-wrap gap-2">
										{selectedSlots.map((selectedSlot, index) => (
											<SelectedSlotChip
												key={index}
												slot={selectedSlot}
												onRemove={() => {
													setSelectedSlots((prev) => prev.filter((_, i) => i !== index));
												}}
											/>
										))}
									</div>
								) : (
									<div className="text-sm text-gray-500">No slots selected</div>
								)}
							</div>
						</div>
					</div>

					<button
						onClick={onSubmit}
						disabled={selectedSlots.length === 0 || selectedPatientId === null || postInProgress}
						className="mt-auto px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 disabled:opacity-50 disabled:cursor-not-allowed"
					>
						Book Appointment
					</button>
				</div>
			</div>

			{/* Render the Appointment Popup */}
			{isPopupOpen && <AppointmentPopup appointments={selectedAppointments} onClose={() => setIsPopupOpen(false)} />}
		</div>
	);
}

const AppointmentPopup = ({ appointments, onClose }) => {
	return (
		<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
			<div className="bg-white p-4 rounded-lg shadow-lg max-w-md w-full">
				<h2 className="font-bold text-lg">Appointment Details</h2>
				<div className="max-h-60 overflow-y-auto">
					<ul>
						{appointments.map((appointment, index) => (
							<li key={index} className="border-b py-2 text-sm">
								<div>{`${appointment.clientName}`}</div>
								<div>{`Time: ${moment(appointment.startTime, "hh:mm A").format("hh:mm A")}`}</div>
								<div>{`Duration: ${appointment.endTimeMinutes - appointment.startTimeMinutes} mins`}</div>
								<div>{`Type: ${appointment.appointmentType}`}</div>
							</li>
						))}
					</ul>
				</div>
				<button onClick={onClose} className="mt-4 bg-primary-600 text-white px-4 py-2 rounded">
					Close
				</button>
			</div>
		</div>
	);
};

export default SingleCalander;
