import { OutlinedButton } from "@components/button";
import Button, { ButtonVariant } from "@components/button/button";
import { endpoints, replaceParams } from "@utils/axios";
import axios from "axios";
import React, { useContext } from "react";
import { toast } from "react-toastify";
import { ManagePatientNewContext } from "./ManagePatientNewV2";

export default function ImportantNote(props: {
  handleCloseImportatntNoteModal: () => void;
  notes?: string | undefined;
  patientId: string;
  getPatientData: () => Promise<void>;
}) {
  const [notes, setNotes] = React.useState("");
  // const patientId = useContext(ManagePatientNewContext);

  const addImportantNote = async () => {
    try {
      const paramsMap = new Map<string, string>([["id", props.patientId]]);
      const filteredEndpoint = replaceParams(
        endpoints.managePatient.updateImportantNote,
        paramsMap
      );
      const response = await axios.put(filteredEndpoint, {
        notes: notes,
      });

      toast.success("Important note updated");
      props.handleCloseImportatntNoteModal();
      props.getPatientData();
    } catch (error) {
      toast.error("Something went wrong. Please try again!");
    }
  };

  function setData(value: string): void {
    const formattedValue = value.replace(/\n/g, "<br>");
    setNotes(formattedValue);
  }

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-row w-full justify-between mx-4 my-2">
        <h5 className="text-md leading-6 font-bold text-gray-900">
          Important Note
        </h5>
      </div>
      <hr className="w-full" />

      <div className="p-4">
        <textarea
          className={`w-full h-40 border focus:ring-primary-500 focus:border-primary-500 rounded text-red-500 font-bold overflow-auto`}
          placeholder="Enter/update important note"
          onChange={(e) => setNotes(e.target.value)}
          style={{ whiteSpace: "pre-wrap" }}
        >
          {props.notes ?? ""}
        </textarea>
      </div>
      <div className="flex flex-row rounded-bl-2xl rounded-br-2xl bg-gray-100 items-center p-4 mt-2">
        <div className="flex flex-row w-full justify-end">
          <OutlinedButton
            variant={ButtonVariant.PRIMARY}
            type="button"
            onClick={props.handleCloseImportatntNoteModal}
            children="Close"
            className="mr-2"
          />
          <OutlinedButton
            variant={ButtonVariant.SECONDARY}
            type="button"
            onClick={addImportantNote}
            children="Update Note"
            className=""
          />
        </div>
      </div>
    </div>
  );
}
