import React, { useState, useEffect, useCallback } from "react";
import {
	Calendar,
	Clock,
	ChevronLeft,
	ChevronRight,
	MoreHorizontal,
	ArrowLeft,
	ArrowRight,
	ArrowUpRight,
} from "lucide-react";
import { v4 as uuidv4 } from "uuid";
import { format, addDays, isSameDay, isToday, parseISO } from "date-fns";
import { therapists, generateTimeSlots, bookingDurations } from "./data";
import { DaySchedule, TimeSlot, BookingDuration } from "./types";
import axios, { CancelTokenSource } from "axios";
import { endpoints, replaceParams } from "@utils/axios";
import { AvailableTimeSlots, BookedAppointment } from "../book-appointment/types/AvailableTimeSlots";
import moment from "moment";
import { AvailableTimeSlotConvert, Freeslot } from "../types/AvailableTimeSlots";
import { ConvertStaff, Staff } from "@pages/manage-patient-new/types/Staff";
import { Convert, Patient } from "@common/types/Patient";

import { EntityBranchConvert } from "@pages/staff/types/EntityBranch";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import { EntityBranch } from "../types/EntityBranch";
import Applabel from "@components/hook-form/applabel";
import { applebelCustom as ApplebelCustom } from "@components/hook-form/applabel";
import PatientSearchV2 from "@common/components/PatientSearch/PatientSearchV2";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { AppointmentType } from "../book-appointment/BookAppointmentPage";
import { AppointmentSchema } from "@pages/staff/add-appointment-staff/BookAppointmentPageStaff";
import { ValidationError } from "@components/hook-form";
import { Card } from "@components/ui/card";
import { Button } from "@components/button";
import { convertTo24HourFormat } from "@utils/timeConversionUtil";
import {
	WorkingHour,
	ConvertWorkingHours,
	convertWorkingHoursToLocalTimes,
} from "@pages/branches/view-branch/BranchWorkingHoursConfiguration";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { z } from "zod";
import { AppointmentDto } from "../types/AppointmentDto";
import { toast } from "react-toastify";
import { Service } from "@pages/branches/types/Service";

interface SelectedSlot {
	id: string;
	date: Date;
	startTime: string;
	endTime: string;
	duration: number;
	isFree: boolean;
	userId: string;
	userName: string;
}

const SelectedSlotChip: React.FC<{
	slot: AppointmentDto;
	onRemove: () => void;
}> = ({ slot, onRemove }) => (
	<div className="flex items-center gap-2 px-3 py-2 bg-primary-100 rounded-lg border border-primary-800 text-gray-800">
		<div className="flex flex-col w-40">
			<span className="text-sm font-medium ">
				{format(slot.apptdate, "MMM d, yyyy")} {slot.startTimeLocal}
			</span>
			<span className="text-xs overflow-hidden text-ellipsis whitespace-nowrap w-40">{slot.entityUserName}</span>
		</div>
		<button onClick={onRemove} className="p-1 hover:bg-secondary-100 rounded-full transition-colors">
			<XMarkIcon className="w-4 h-4" />
		</button>
	</div>
);

function StaffCalander() {
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [schedule, setSchedule] = useState<DaySchedule[]>([]);
	const [selectedDuration, setSelectedDuration] = useState<BookingDuration>(bookingDurations[0]);
	const [scrollPosition, setScrollPosition] = useState(0);
	const [showLeftArrow, setShowLeftArrow] = useState(false);
	const [showRightArrow, setShowRightArrow] = useState(true);
	const [availableTimeSlots, setAvailableTimeSlots] = React.useState<AvailableTimeSlots[] | null>(null);

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState("");

	const [staffList, setStaffList] = React.useState<Staff[] | null>(null);
	const [selectedEntityUserId, setSelectedEntityUserId] = React.useState("");
	const [selectedEntityUserName, setSelectedEntityUserName] = React.useState("");
	const [selectedEntityUserPhone, setSelectedEntityUserPhone] = React.useState(0);
	const [weekStartDate, setWeekStartDate] = useState(new Date());
	const [workingHours, setWorkingHours] = useState<WorkingHour[]>([]);
	const [startTime, setStartTime] = useState("08:00");
	const [endTime, setEndTime] = useState("20:00");
		const AppointmentSchema = z.object({
			patientId: z.string().min(1, { message: "Please select patient name" }).default(""),
			appointmentType: z.string().min(1, { message: "Please select appointment type" }).default(""),
			physioId: z.string().min(1, { message: "Please select Physiotherapist" }).default(""),
			slotToBook: z.string().min(2, { message: "Please select slot/slots" }),
		});

	type AppointmentType = z.infer<typeof AppointmentSchema>;
	
	const {
		setValue,
		setError: setZodError,
		clearErrors,
		formState: { errors },
	} = useForm<AppointmentType>({ resolver: zodResolver(AppointmentSchema) });
	const [selectedService, setSelectedService] = useState<Service | null>(null);

	const [consultationMode, setConsultationMode] = useState<string>("In-Clinic");

	const [isAnimating, setIsAnimating] = useState(false);

	const [isSidebarOpen, setIsSidebarOpen] = useState(false);

	const getBranchWorkingHours = async () => {
		try {
			const userData = getUserPersistedOnLocalStorage();
			const paramsMap = new Map<string, string>([
				["entityId", userData?.entity_id!],
				["branchId", branchId!],
			]);
			const filteredEndpoint = replaceParams(endpoints.branch.getworkinghours, paramsMap);
			const response = await axios.get(filteredEndpoint);
			const data = response.data["data"];
			const convertedWorkingHours: WorkingHour[] = data.map((data: any) => {
				data["startTime"] = convertTo24HourFormat(data["startTime"]);
				data["endTime"] = convertTo24HourFormat(data["endTime"]);
				data["uuid"] = uuidv4();
				return ConvertWorkingHours.toWorkingHours(JSON.stringify(data));
			});

			const convertedWorkingHoursLocal = convertWorkingHoursToLocalTimes(convertedWorkingHours, moment.tz.guess());

			setWorkingHours(convertedWorkingHoursLocal);
			setLoading(false);
		} catch (error) {
			console.error("Error fetching branch working hours:", error);
			setError(error.message);
			setLoading(false);
		}
	};

	const [services, setServices] = useState<Service[]>([]);

	async function getServices() {
		try {
			const paramsMap = new Map<string, string>([["branchId", branchId || ""]]);
			const filteredEndpoint = replaceParams(endpoints.branch.services, paramsMap);
			const response = await axios.get(filteredEndpoint);
			if (response.status === 200) {
				if (response.data["data"] && response.data["data"].length > 0) {
					setServices(response.data["data"]);
					setSelectedService(response.data["data"][0]);
				} else {
					/** Get appointment types if*/
					console.log("No services found");
				}
			} else {
				toast.error(response.data["message"]);
			}
		} catch (error) {
			toast.error(error);
		}
	}

	useEffect(() => {
		const fetchData = async () => {
			await getBranchWorkingHours();
			setLoading(false);
		};
		getBranches();
		fetchData();
		getServices();
		//setBranchId();
	}, []);

	// Only run this after working hours are set
	useEffect(() => {
		if (!loading && workingHours.length > 0) {
			getAvailableTimeSlots();
		}
		if (workingHours.length > 0) {
			setStartTime(workingHours[selectedDate.getDay()].startTime);
			setEndTime(workingHours[selectedDate.getDay()].endTime);
		}
	}, [selectedDate, selectedDuration, workingHours]);

	useEffect(() => {
		if (!loading && workingHours.length > 0) {
			const today = new Date();
			const weekSchedule = Array.from({ length: 7 }, (_, i) => {
				const date = new Date(today);
				date.setDate(today.getDate() + i);
				const dayInNumber = date.getDay();
				return {
					date,
					slots: generateTimeSlots(workingHours[dayInNumber].startTime, workingHours[dayInNumber].endTime),
				};
			});
			setSchedule(weekSchedule);
			fetchStaffList();
			// getAppointmentType();
		}
	}, [workingHours]);

	useEffect(() => {
		const weekSchedule = Array.from({ length: 7 }, (_, i) => {
			const date = new Date(weekStartDate);
			date.setDate(weekStartDate.getDate() + i);
			return {
				date,
				slots: generateTimeSlots(startTime, endTime),
			};
		});
		setSchedule(weekSchedule);
	}, [weekStartDate, startTime, endTime]);

	const handleWeekChange = (direction: "prev" | "next") => {
		const newDate = new Date(weekStartDate);
		if (direction === "prev") {
			newDate.setDate(weekStartDate.getDate() - 7);
		} else {
			newDate.setDate(weekStartDate.getDate() + 7);
		}
		setWeekStartDate(newDate);
		setSelectedDate(newDate);
	};

	const getAvailableTimeSlots = async () => {
		try {
			setLoading(true);
			const response = await axios.get(endpoints.appointment.availableTimeSlots, {
				params: {
					requestedDate: moment(new Date(selectedDate)).format("DD-MM-YYYY"),
					requestedAppointmentDuration: selectedDuration.minutes,
					requestedSlotInterval: 15,
					branchId: branchId,
				},
			});
			const data = response.data["data"]["availableEntityUsers"];
			if (data != null) {
				const convertedAvailableTimeSlots = data.map((slot: any) =>
					AvailableTimeSlotConvert.toAvailableTimeSlots(JSON.stringify(slot))
				);
				const timeZone = moment.tz.guess();
				const convertedAvailableTimeSlotsLocalTime = convertedAvailableTimeSlots.map(
					(available: AvailableTimeSlots) => {
						return available.freeslots.map((freeslot: Freeslot) => {
							const startMoment = moment.utc(freeslot.startTime, "HH:mm A");
							freeslot.startTime = startMoment.tz(timeZone).format("hh:mm A");
							const endMoment = moment.utc(freeslot.endTime, "HH:mm A");
							freeslot.endTime = endMoment.tz(timeZone).format("hh:mm A");

							return freeslot;
						});
					}
				);
				setAvailableTimeSlots(convertedAvailableTimeSlots);
				setLoading(false);
			} else {
				setLoading(false);
				setAvailableTimeSlots(null);
			}
		} catch (error) {
			console.error("Error fetching available time slots...", error);
			setAvailableTimeSlots(null);
			setError(error.message);
			setLoading(false);
		}
	};

	const handleScroll = (direction: "left" | "right") => {
		const container = document.getElementById("calendar-container");
		if (container) {
			const scrollAmount = 500;
			const newPosition =
				direction === "left"
					? Math.max(0, scrollPosition - scrollAmount)
					: Math.min(scrollPosition + scrollAmount, container.scrollWidth - container.clientWidth);

			container.scrollTo({
				left: newPosition,
				behavior: "smooth",
			});
			setScrollPosition(newPosition);
			setShowLeftArrow(newPosition > 0);
			setShowRightArrow(newPosition < container.scrollWidth - container.clientWidth);
		}
	};

	const handleScrollCheck = () => {
		const container = document.getElementById("calendar-container");
		if (container) {
			setShowLeftArrow(container.scrollLeft > 0);
			setShowRightArrow(container.scrollLeft <= container.scrollWidth - container.clientWidth);
		}
	};

	useEffect(() => {
		const container = document.getElementById("calendar-container");
		if (container) {
			container.addEventListener("scroll", handleScrollCheck);
			handleScrollCheck();
		}
		return () => {
			container?.removeEventListener("scroll", handleScrollCheck);
		};
	}, []);

	const handleBooking = (therapistName: string, startTime: string, endTime: string) => {
		alert(`Booking with ${therapistName} from ${startTime} to ${endTime}`);
	};

	const isSlotAvailable = (slots: TimeSlot[], startIndex: number, duration: number) => {
		const slotsNeeded = duration / 15;
		for (let i = 0; i < slotsNeeded; i++) {
			const slot = slots[startIndex + i];
			if (!slot || !slot.isAvailable || slot.isBooked) {
				return false;
			}
		}
		return true;
	};

	const fetchStaffList = async () => {
		try {
			let response;
			if (branchId) {
				response = await axios.get(endpoints.staff.list, {
					params: {
						branchId: branchId.toString() ?? "",
					},
				});
			} else {
				response = await axios.get(endpoints.staff.list);
			}

			const data = response.data.data;
			const convertedStaffList = data.map((staff: any) => ConvertStaff.toStaff(JSON.stringify(staff)));
			setStaffList(convertedStaffList);
		} catch (error) {
			console.error("Error fetching staff list:", error);
			setError(error.message);
		}
	};

	const userData = getUserPersistedOnLocalStorage();
	const [branches, setBranches] = React.useState<EntityBranch[] | null>(null); // this is the list of branches [{}]
	const [branchId, setBranchId] = React.useState<string>(userData?.branch_id.toString() ?? "");
	const getBranches = async () => {
		try {
			const paramsMap = new Map<string, string>([["entityId", userData?.entity_id ?? ""]]);
			const filteredEndpoint = replaceParams(endpoints.branch.list, paramsMap);

			const response = await axios.get(filteredEndpoint);
			const data = response.data["data"];
			const convertedBranchList = data.map((entity: any) => EntityBranchConvert.toEntityBranch(JSON.stringify(entity)));

			setBranches(convertedBranchList);
			setLoading(false);
		} catch (error) {
			console.error("Error fetching entity list:", error);
		}
	};

	const formatEndTime = (startSlot: TimeSlot) => {
		const endMinutes = startSlot.minutesFromMidnight + selectedDuration.minutes;
		const hours = Math.floor(endMinutes / 60);
		const minutes = endMinutes % 60;
		const period = hours >= 12 ? "PM" : "AM";
		const displayHour = hours % 12 || 12;
		return `${displayHour}:${minutes.toString().padStart(2, "0")} ${period}`;
	};

	const selectedDaySchedule = schedule.find((day) => day.date.toDateString() === selectedDate.toDateString());

	const getEndTime = (startSlot: TimeSlot) => {
		const endMinutes = startSlot.minutesFromMidnight + selectedDuration.minutes;
		const hours = Math.floor(endMinutes / 60);
		const minutes = endMinutes % 60;
		return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
	};
	const [selectedPatientBranchId, setSelectedPatientBranchId] = React.useState<string | null>(null);
	interface AppointmentData {
		AppointmentDuration: number[];
		AppointmentType: string[];
		appointmentString: string[];
	}

	const [selectedPatient, setSelectedPatient] = useState<Patient | null>(null);
	const [selectedPatientId, setSelectedPatientId] = React.useState<string | null>(null);
	const [selectedPatientName, setSelectedPatientName] = React.useState<string | null>(null);
	const [selectedPatientAddress, setSelectedPatientAddress] = React.useState<string | null>(null);
	const [selectedPatientEmail, setSelectedPatientEmail] = React.useState<string | null>(null);
	const [selectedPatientPhone, setSelectedPatientPhone] = React.useState<number | null>(null);
	const getStatusClasses = (status: string) => {
		if (status === "booked") return "bg-orange-50 text-orange-400 cursor-pointer";
		if (status === "available") return "bg-gray-50 text-gray-600 hover:bg-green-100 cursor-pointer";
		return "bg-gray-50 text-gray-400 cursor-pointer";
	};
	const [postInProgress, setPostInProgress] = React.useState<boolean>(false);
	const cancelToken = React.useRef<CancelTokenSource | null>(null);

	const [selectedSlots, setSelectedSlots] = useState<AppointmentDto[]>([]);



	const addTimeToTimeZoneAndConvertToISOString = (date: Date, timeString: string): Date => {
		//   Parse input date and time using Moment.js

		const inputDate = moment(date);
		const inputTime = moment(timeString, "hh:mm A");

		// Combine date and time
		let resultDate = inputDate.set({
			hour: inputTime.hours(),
			minute: inputTime.minutes(),
		});
		// Convert to ISO string and return
		return new Date(moment(resultDate).toISOString());
	};

	const onSubmit: SubmitHandler<AppointmentType> = async () => {
		if (cancelToken.current) {
			cancelToken.current.cancel("Request already in progress!");
		}
		cancelToken.current = axios.CancelToken.source();

		if (postInProgress) return;
		try {
			setPostInProgress(true);
			await axios.post(
				`${endpoints.appointment.recurringAppointment}`,
				{
					appointmentdetails: selectedSlots,
				},
				{
					cancelToken: cancelToken.current.token,
				}
			);
			setPostInProgress(false);
			toast.success("Appointment Booked Successfully");

			// Resetting the state after successful submission
			setSelectedSlots([]); // Clear selected slots
			setSelectedPatient(null); // Reset selected patient
			setSelectedPatientId(null); // Reset selected patient ID
			setSelectedPatientName(null); // Reset selected patient name
			setSelectedPatientAddress(null); // Reset selected patient address
			setSelectedPatientEmail(null); // Reset selected patient email
			setSelectedPatientPhone(null); // Reset selected patient phone
			setConsultationMode("In-Clinic"); // Reset consultation mode to default
			setSelectedService(services?.[0]); // Reset selected service
			setSelectedDuration(bookingDurations[0]); // Reset selected duration to default
			setWeekStartDate(new Date()); // Reset week start date to today
			setSelectedDate(new Date()); // Reset selected date to today
			setIsSidebarOpen(false);
		} catch (error) {
			console.error("Error submitting form:", error);
			toast.error(error["response"]["data"]["data"]);
			setPostInProgress(false);
		}
	};

	const handleBranchSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setBranchId(event.target.value);
	};

	const getAppointmentType = useCallback(async () => {
		try {
			const userData = getUserPersistedOnLocalStorage();
			const entityIdset = userData?.entity_id;

			// Ensure entityIdset is defined before using it
			if (entityIdset !== undefined) {
				// Create a Map with the entityIdset as the value
				const paramsMap = new Map<string, string>([["id", entityIdset]]);

				// Continue with your logic using paramsMap
				const filteredEndpoint = replaceParams(endpoints.branch.services, paramsMap);
				const response = await axios.get(filteredEndpoint);
				const data: AppointmentData = response.data["data"];
				setAppointmentTypeData(data);
			}
		} catch (error) {
			console.error("Error fetching appointment type:", error);
		}
	}, []);
	const formatDateForInput = (date: Date) => {
		return date.toISOString().split("T")[0];
	};
	const handleDateChange = (date: string) => {
		const newDate = new Date(date);
		setSelectedDate(newDate);

		// Adjust week view to include the selected date
		const dayOfWeek = newDate.getDay();
		const weekStart = new Date(newDate);
		weekStart.setDate(newDate.getDate() - dayOfWeek);
		setWeekStartDate(weekStart);
	};

	useEffect(() => {
		if (consultationMode !== null && selectedSlots.length > 0) {
			selectedSlots.forEach((slot) => {
				slot.modeOfConsultation = consultationMode;
			});
		}
	}, [consultationMode]);

	useEffect(() => {
		if (selectedPatientId !== null && selectedSlots.length > 0) {
			selectedSlots.forEach((slot) => {
				slot.clientId = selectedPatientId;
				slot.clientName = selectedPatientName ?? "";
				slot.clientPhone = selectedPatientPhone ?? 0;
				slot.clientAddress = selectedPatientAddress ?? "";
				slot.email = selectedPatientEmail ?? "";
			});
		}
	}, [selectedPatientId, consultationMode]);

	// Add an effect to handle slot updates
	useEffect(() => {
		if (selectedSlots.length > 0) {
			// Handle slot updates here instead of in the handler
			// Additional logic like updating form values
			setValue("slotToBook", JSON.stringify(selectedSlots));
		}
	}, [selectedSlots]);

	const handleConsultationModeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setConsultationMode(e.target.value);
	};

	const handleAddAppointment = (appointmentRequest: AppointmentDto) => {
		const isDuplicate = selectedSlots.some(
			(slot) =>
				slot.startTime === appointmentRequest.startTime &&
				moment(slot.apptdate).isSame(appointmentRequest.apptdate, "day") &&
				slot.entityUserId === appointmentRequest.entityUserId
		);

		if (isDuplicate) {
			toast.warning("An appointment already exists at this time and date. Please select different slot"); // Display error message
			return; // Do not add the duplicate appointment
		}

		setSelectedSlots((prevSlot) => [...prevSlot, appointmentRequest]);
	};

	const handleTimeSlotClick = (timeSlot: TimeSlot, entityUserId: string, entityUserName: string) => {
		// Check if slot is already selected
		const isSlotSelected = selectedSlots.some(
			(slot) =>
				slot.entityUserId === entityUserId &&
				slot.startTimeLocal === timeSlot.time &&
				moment(slot.apptdate).isSame(selectedDate, "day")
		);

		if (isSlotSelected) {
			// Deselect the slot
			setSelectedSlots((prev) =>
				prev.filter(
					(slot) =>
						!(
							slot.entityUserId === entityUserId &&
							slot.startTimeLocal === timeSlot.time &&
							moment(slot.apptdate).isSame(selectedDate, "day")
						)
				)
			);
			return; // deselect the slot and return if the slot is already selected
		}

		let slotDate = addTimeToTimeZoneAndConvertToISOString(selectedDate, timeSlot.time);
		let date = moment.utc(slotDate).format("DD-MM-YYYY");
		// Converting String eg "10:00 AM" to Equivalent UTC String
		let startTime = moment.utc(slotDate).format("hh:mm A");
		let endTime = moment(startTime, "hh:mm A").add(selectedDuration.minutes, "minutes").format("hh:mm A");
		// Converting startTime Minutes to Equivalent UTC minutes
		let startTimeMinutes = slotDate.getUTCHours() * 60 + slotDate.getUTCMinutes();
		let endTimeMinutes = slotDate.getUTCHours() * 60 + slotDate.getUTCMinutes() + selectedDuration.minutes;

		const appointmentRequest: AppointmentDto = {
			clientId: selectedPatientId ?? "",
			clientName: selectedPatientName ?? "",
			clientPhone: selectedPatientPhone ?? 0,
			clientAddress: selectedPatientAddress ?? "",
			email: selectedPatientEmail ?? "",
			entityUserId: entityUserId,
			entityUserName: entityUserName,
			entityId: userData?.entity_id ?? "",
			entityBranchId: branchId ?? "",
			startTime: startTime,
			startTimeLocal: timeSlot.time,
			endTime: endTime,
			endTimeLocal: formatEndTime(timeSlot),
			startTimeMinutes: startTimeMinutes,
			endTimeMinutes: endTimeMinutes,
			duration: selectedDuration.minutes,
			date: date,
			apptdate: slotDate,
			appointmentType: selectedService?.name ?? "",
			serviceId: selectedService?.id ?? "",
			modeOfConsultation: consultationMode,
			isOnline: false,
			appointmentStatus: "Scheduled",
			appointmentSlot: 0,
			entityUserPhone: selectedEntityUserPhone ?? 0,
		};
		handleAddAppointment(appointmentRequest); // Use the new function to add appointment
	};

	useEffect(() => {
		if (selectedService) {
			const duration = bookingDurations.find((d) => d.minutes === Number(selectedService.duration));
			if (duration) {
				setSelectedDuration(duration);
				getAvailableTimeSlots(); // Refresh time slots when duration changes
			}
		}
	}, [selectedService]);

	useEffect(() => {
		const fetchData = async () => {
			await getServices();
			await fetchStaffList(); // Reload staff list
			await getBranchWorkingHours(); // Reload working hours
			await getAvailableTimeSlots(); // Reload booked appointments
		};

		fetchData(); // Call the fetch function
	}, [branchId]); // Dependency array includes branchId

	return (
		<div className="flex flex-col gap-4">
			{/* Overlay */}
			{isSidebarOpen && (
				<div
					className="fixed inset-0 bg-gray-900/30 transition-opacity duration-300 z-20"
					onClick={() => setIsSidebarOpen(false)}
				/>
			)}

			{/* Toggle Button with Appointment Count */}
			<button
				onClick={() => setIsSidebarOpen(!isSidebarOpen)}
				className={`fixed bottom-[10%]  md:top-[60%] z-40 p-2 ${
					isSidebarOpen ? "bg-primary-50 border-primary-900" : "bg-primary-500 border-primary-100"
				} border  hover:bg-primary-400 rounded-l-lg shadow-md border-r-0 flex items-center gap-2 md:h-20 h-16`}
				style={{
					right: isSidebarOpen ? "400px" : "0px",
					transition: "right 0.3s ease-in-out",
				}}
			>
				<ChevronRight
					className={`w-6 h-6 transform transition-transform ${
						isSidebarOpen ? "text-gray-800" : "text-primary-100 font-bold"
					} ${isSidebarOpen ? "rotate-180" : ""}`}
				/>
				{selectedSlots.length > 0 && (
					<div className="absolute -top-2 left-2 bg-primary-600 text-white text-xs font-bold rounded-full w-5 h-5 flex items-center justify-center">
						{selectedSlots.length}
					</div>
				)}
				{!isSidebarOpen ? (
					<div className="flex flex-col items-start">
						<span className="text-xs md:text-sm font-bold text-gray-100">
							{selectedSlots.length > 0 ? (
								<>
									{selectedSlots.length} {selectedSlots.length === 1 ? "Slot" : "Slots"} Selected
								</>
							) : (
								"Select slots"
							)}
						</span>
						<span className="text-xs md:text-sm text-gray-100">
							{selectedSlots.length > 0 ? "Click to complete booking" : " from calendar to start booking"}
						</span>
					</div>
				) : (
					<div className="flex flex-col items-start">
						<span className="text-sm font-medium text-gray-800">Close</span>
					</div>
				)}
			</button>

			{/* Main Content */}
			<div className="relative z-10">
				{/* Scrollable Appointment Slots */}
				<div className="flex flex-col gap-4 rounded-lg ">
					{/* Week Navigation and Date Selection */}
					<div className="flex flex-col p-4 border rounded-lg bg-gray-50">
						<div className="flex items-center justify-between mb-4 p-2 rounded-lg">
							<div className="flex flex-col lg:flex-row gap-4 w-full">
								{/* Service, Duration, and Date/Time Selectors */}
								<div className="flex flex-col lg:w-1/3">
									<ApplebelCustom
										className="text-sm font-semibold text-primary-600"
										label="Appointment Type/Service"
										mandatory
									/>
									<select
										className="block w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6 cursor-pointer"
										value={selectedService?.id}
										onChange={(e) => {
											const newServiceId = e.target.value;
											if (newServiceId === selectedService?.id) return; // No need to change

											const newService = services?.find((service) => service.id === newServiceId);
											if (!newService) return;

											if (selectedSlots.length > 0) {
												const confirmChange = window.confirm(
													"Changing the service will clear existing selected slots. Do you want to proceed?"
												);
												if (!confirmChange) {
													return; // Exit early if user cancels change
												}
												setSelectedSlots([]); // Clear slots if confirmed
											}
											setSelectedService(newService);
										}}
										required
									>
										<option value="" disabled>
											Select a service
										</option>{" "}
										{/* Placeholder */}
										{services?.map((service) => (
											<option key={service.id} value={service.id}>
												{service.name}
											</option>
										))}
									</select>
								</div>
								<div className="flex flex-col md:flex-row lg:w-2/3 gap-2">
									{/* Duration Selector */}
									<div className="flex flex-col flex-1">
										<ApplebelCustom className="text-sm font-bold text-primary-600" label="Duration" />
										<select
											className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 bg-gray-100 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 cursor-pointer"
											value={selectedDuration.minutes}
											onChange={(e) => {
												const duration = bookingDurations.find((d) => d.minutes === Number(e.target.value));
												if (duration) setSelectedDuration(duration);
											}}
											required
										>
											{bookingDurations.map((duration) => (
												<option key={duration.label} value={duration.minutes}>
													{duration.label}
												</option>
											))}
										</select>
									</div>
									{/* Date and Time Selectors */}
									<div className="flex flex-col flex-1">
										<Applabel label="Select Date" />
										<input
											type="date"
											value={moment(selectedDate).format("YYYY-MM-DD")}
											onChange={(e) => handleDateChange(e.target.value)}
											className="w-full border border-gray-300 rounded-lg text-sm focus:ring-secondary-500 focus:border-secondary-500 py-2"
										/>
									</div>
									<div className="flex flex-row gap-2">
										<div className="flex flex-col flex-1">
											<Applabel label="Start Time" />
											<input
												type="time"
												value={startTime}
												min="00:00"
												max={endTime}
												onChange={(e) => setStartTime(e.target.value)}
												className="w-full border border-gray-300 rounded-lg text-sm focus:ring-secondary-500 focus:border-secondary-500 py-2"
											/>
										</div>
										<div className="flex flex-col flex-1">
											<Applabel label="End Time" />
											<input
												type="time"
												value={endTime}
												min={startTime}
												max="23:59"
												onChange={(e) => setEndTime(e.target.value)}
												className="w-full border border-gray-300 rounded-lg text-sm focus:ring-primary-500 focus:border-primary-500 py-2"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="grid grid-cols-3 md:grid-cols-6 lg:grid-cols-9 gap-2 items-end">
							<button
								onClick={() => handleWeekChange("prev")}
								className="flex items-center px-2 py-3 text-sm font-medium text-primary-600 bg-white rounded-lg border border-primary-300 hover:bg-primary-50 w-24"
							>
								<ChevronLeft className="w-4 h-4" />
								Previous
							</button>
							{schedule.map((day) => {
								const isSelected = day.date.toDateString() === selectedDate.toDateString();
								const isToday = day.date.toDateString() === new Date().toDateString();

								return (
									<button
										key={day.date.toISOString()}
										onClick={() => setSelectedDate(day.date)}
										className={`
                      relative py-3 px-2 rounded-xl transition-all duration-200 
                      ${
												isSelected
													? "bg-primary-600 text-white shadow-lg shadow-primary-100 scale-105 z-10"
													: "bg-primary-100 hover:bg-gray-50 hover:shadow-md"
											}
                      ${isToday && !isSelected ? "ring-2 ring-secondary-400" : ""}
                    `}
									>
										<div className="flex items-center justify-center space-x-2">
											<span className={`text-sm ${isSelected ? "font-semibold" : "font-medium"}`}>
												{day.date.toLocaleDateString("en-US", {
													weekday: "short",
												})}
											</span>
											<span className={`text-sm ${isSelected ? "font-bold" : "font-semibold"}`}>
												{day.date.getDate()}{" "}
												{day.date.toLocaleDateString("en-US", {
													month: "short",
												})}
											</span>
										</div>
										{isToday && !isSelected && (
											<div className="absolute bottom-1 left-1/2 transform -translate-x-1/2">
												<div className="w-1 h-1 rounded-full bg-primary-500"></div>
											</div>
										)}
									</button>
								);
							})}
							<button
								onClick={() => handleWeekChange("next")}
								className="flex justify-center items-center text-sm font-medium text-primary-600 bg-white rounded-lg border border-primary-300 hover:bg-primary-50 w-24 px-2 py-3"
							>
								<span>Next</span>
								<ChevronRight className="w-4 h-4" />
							</button>
						</div>
					</div>
					{/* Therapist Navigation Header */}
					<div className="flex flex-col py-4 border rounded-lg bg-gray-50">
						<div className="flex items-center justify-between mb-4">
							<div className="flex md:flex-row flex-col items-center gap-8">
								{/* Branch Selector */}
								{userData?.user_role === "ENTITY_OWNER" && (
									<div className="flex items-center gap-2 px-2">
										<span className="text-sm font-medium text-gray-600">Branch:</span>
										<select
											onChange={handleBranchSelectChange}
											name="entityBranchId"
											value={branchId?.toString()}
											className="w-48 rounded-lg border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 cursor-pointer"
										>
											{branches?.map((branch) => (
												<option key={branch.id} value={branch.id}>
													{branch.name}
												</option>
											))}
										</select>
									</div>
								)}
							</div>

							{/* Navigation Arrows */}
							<div className="flex space-x-2">
								{/* Title and Legends */}
								<div className="flex items-center space-x-4 text-xs">
									<h2 className="text-xs font-medium text-gray-900">Time Slots</h2>
									<div className="flex items-center space-x-2">
										<div className={`w-4 h-4 rounded border border-gray-700 bg-white`}></div>
										<span className="text-gray-600">Available</span>
									</div>
									<div className="flex items-center space-x-2">
										<div className={`w-4 h-4 rounded border border-gray-700 bg-primary-100`}></div>
										<span className="text-gray-600">Booked</span>
									</div>
									<div className="flex items-center space-x-2">
										<div className={`w-4 h-4 rounded border border-gray-700 bg-gray-200`}></div>
										<span className="text-gray-600">Off Hours</span>
									</div>
								</div>
								<button
									onClick={() => handleScroll("left")}
									className={`p-2 rounded-lg border ${
										showLeftArrow
											? "bg-white text-primary-600 border-secondary-200 hover:bg-primary-50"
											: "bg-gray-100 text-gray-400 border-gray-200 cursor-not-allowed"
									}`}
									disabled={!showLeftArrow}
									aria-label="Scroll left"
								>
									<ChevronLeft className="w-6 h-6" />
								</button>
								<button
									onClick={() => handleScroll("right")}
									className={`p-2 rounded-lg border ${
										showRightArrow
											? "bg-white text-primary-600 border-secondary-200 hover:bg-primary-50"
											: "bg-gray-100 text-gray-400 border-gray-200 cursor-not-allowed"
									}`}
									disabled={!showRightArrow}
									aria-label="Scroll right"
								>
									<ChevronRight className="w-6 h-6" />
								</button>
							</div>
						</div>
						<div className="relative border border-gray-300 rounded-md">
							{/* Fixed Time Column */}
							<div className="absolute left-0 top-0 bottom-0 w-[120px] bg-white z-10 border-r">
								<div className="h-16 flex items-center px-4 font-medium text-gray-500 border-b last:border-b-0 border-r text-sm shadow-md">
									Time
								</div>
								{selectedDaySchedule?.slots.map((timeSlot) => {
									// Only show times that are on the hour (minutes = 00)
									const isHourMark = timeSlot.time.includes(":00");

									return (
										<div
											key={timeSlot.time}
											className={`h-14 flex items-center px-4 font-medium text-gray-500 border-b last:border-b-0 border-r 
                      ${isHourMark ? "justify-center text-sm font-semibold" : "justify-end text-xs"}
                      `}
										>
											{isHourMark ? timeSlot.time : timeSlot.time}
										</div>
									);
								})}
							</div>
							{/* Scrollable Content */}
							<div className="ml-[120px] overflow-x-auto scrollbar-hide" id="calendar-container">
								{/* Therapist Headers */}
								<div className="grid grid-cols-[repeat(10,minmax(220px,1fr))] border-b">
									{availableTimeSlots?.map((availableTimeSlot) => {
										let bookedAppointments: BookedAppointment[] = [];
										availableTimeSlot.freeslots.forEach((freeslot) => {
											if (freeslot.appointmentsCount > 0) {
												// Get unique appointments based on clientId
												bookedAppointments = Array.from(
													new Map(freeslot.bookedAppointments.map((item) => [item.clientId, item])).values()
												);
											}
										});

										return (
											<div className="w-[220px] bg-white z-10">
												<div className="h-16 flex items-center px-4 font-medium text-gray-500 border-b last:border-b-0 shadow-md text-sm overflow-hidden text-ellipsis whitespace-nowrap">
													{availableTimeSlot.entityUserName}
												</div>

												{selectedDaySchedule?.slots.map((timeSlot) => {
													const endTime = formatEndTime(timeSlot);

													const availabilityStatus = availableTimeSlot.freeslots.map((freeslot) => {
														const normalizeTime = (time: string) => {
															const [rawTime, period] = time.split(" ");
															const [hours, minutes] = rawTime.split(":");
															const paddedHours = hours.padStart(2, "0");
															return `${paddedHours}:${minutes} ${period}`;
														};

														if (normalizeTime(freeslot.startTime) === normalizeTime(timeSlot.time)) {
															if (freeslot.appointmentsCount > 0) {
																bookedAppointments = freeslot.bookedAppointments;
																return "booked";
															}
															return "available";
														} else {
															return "unavailable";
														}
													});

													const status = availabilityStatus.find((s) => s !== "unavailable") ?? "unavailable";

													// Check if the current time slot is selected
													const isSelectedSlot = selectedSlots.some((slot) => {
														return (
															slot.entityUserId === availableTimeSlot.entityUserId &&
															slot.startTimeLocal === timeSlot.time &&
															moment(slot.apptdate).isSame(selectedDate, "day")
														);
													});

													return (
														<div key={timeSlot.time} className="relative">
															<button
																type="button"
																onClick={() => {
																	if (status === "unavailable") {
																		const confirmBooking = window.confirm(
																			"This slot is out of working hours. Do you still want to book it?"
																		);
																		if (!confirmBooking) {
																			return;
																		}
																	}
																	setIsAnimating(true);
																	handleTimeSlotClick(
																		timeSlot,
																		availableTimeSlot.entityUserId,
																		availableTimeSlot.entityUserName
																	);
																}}
																aria-label={`Select time slot ${timeSlot.time}`}
																className={`h-14 w-full text-left flex items-center font-light border text-sm p-2 cursor-pointer transition-all duration-200 ${
																	isSelectedSlot
																		? "bg-primary-100  border-2 shadow-md scale-[1.02] z-10 font-medium"
																		: "hover:scale-[1.02] hover:bg-primary-50 hover:border-primary-200"
																}
                              ${
																status === "booked"
																	? "bg-primary-100 border-primary-400 border"
																	: status === "available"
																	? "bg-white"
																	: "bg-gray-200"
															}`}
															>
																{(() => {
																	if (status === "available") {
																		return (
																			<span
																				className={`text-sm px-4 w-full ${
																					isSelectedSlot ? "text-primary-800 font-medium" : "text-gray-800"
																				}`}
																			>
																				{/*                                         {timeSlot.time} to {endTime}
																				 */}{" "}
																				{isSelectedSlot && (
																					<>
																						{timeSlot.time} to {endTime}
																						<div className="mt-1 text-xs text-primary-600">✓ Selected</div>
																					</>
																				)}
																			</span>
																		);
																	}
																	if (status === "unavailable") {
																		return (
																			<span className="text-xs font-medium px-4 w-full text-gray-600">
																				{/*                                         {timeSlot.time} to {endTime}
																				 */}{" "}
																			</span>
																		);
																	}
																	if (status === "booked") {
																		return (
																			<div className="flex flex-col  w-full text-primary-900 ">
																				<div className="text-xs font-medium">{timeSlot.time}</div>
																				<div className="text-xs w-full text-muted-foreground">
																					{bookedAppointments.length > 1 ? (
																						<div className="flex flex-row w-full text-xs justify-between">
																							<div className=" truncate">{bookedAppointments[0].clientName}</div>
																							<div className="text-right">
																								(+
																								{bookedAppointments.length - 1}
																								&nbsp; more){" "}
																								<button>
																									<ArrowUpRight className="h-4 w-4" />
																								</button>
																							</div>
																						</div>
																					) : (
																						<div className="truncate text-xs">{bookedAppointments[0].clientName}</div>
																					)}
																				</div>
																			</div>
																		);
																	}
																	return <span className="text-xs text-gray-400"></span>;
																})()}
															</button>
														</div>
													);
												})}
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* Appointment Form - increase z-index to stay above overlay */}
			<div
				id="appointment-form"
				className="fixed bottom-[20px] right-0 z-50 bg-gray-50 border border-primary-400 rounded-md shadow-lg p-4 h-screen overflow-y-auto transition-all duration-300 ease-in-out"
				style={{
					width: "400px",
					transform: isSidebarOpen ? "translateX(0)" : "translateX(100%)",
				}}
			>
				<div className="flex flex-col gap-4">
					<div className="flex flex-col gap-2">
						<div className="flex basis-1/2 flex-col">
							<Applabel label="Patient Name" mandatory />
							<PatientSearchV2
								patient={selectedPatient}
								onSelect={(patient: Patient | null) => {
									if (patient) {
										setSelectedPatient(patient);
										setSelectedPatientName(patient.firstName + " " + patient.lastName);
										setSelectedPatientEmail(patient.email);
										setSelectedPatientPhone(patient.phone);
										setSelectedPatientBranchId(patient.entityBranchId);
										let address =
											patient.address.address +
											", " +
											patient.address.city +
											", " +
											patient.address.state +
											", " +
											patient.address.postalCode;
										setSelectedPatientAddress(address);
										setSelectedPatientId(patient._id);
										setValue("patientId", patient._id);
										clearErrors("patientId");
									} else {
										// Reset patient-related state if null
										setSelectedPatientName(null);
										setSelectedPatientEmail(null);
										setSelectedPatientPhone(null);
										setSelectedPatientBranchId(null);
										setSelectedPatientAddress(null);
										setSelectedPatientId(null);
									}
								}}
								key={selectedPatientId || "default"}
							/>
							{errors.patientId && <ValidationError message={errors.patientId?.message?.toString() ?? ""} />}
						</div>

						<div className="flex flex-col">
							<Applabel label="Mode" mandatory />
							<div className="flex flex-row items-center text-sm">
								<input
									type="radio"
									id="inClinic"
									name="consultationMode"
									value="In-Clinic"
									checked={consultationMode === "In-Clinic"}
									onChange={handleConsultationModeChange}
									className="mr-2"
								/>
								<label htmlFor="inClinic" className="text-sm font-medium text-grey-900">
									In&nbsp;Clinic
								</label>
								<span className="pr-2" />
								<input
									type="radio"
									id="homeVisit"
									name="consultationMode"
									value="Home Visit"
									checked={consultationMode === "Home Visit"}
									onChange={handleConsultationModeChange}
									className="mr-2"
								/>
								<label htmlFor="homeVisit pr-2" className="text-sm font-medium text-grey-900">
									Home&nbsp;Visit
								</label>
								<span className="pr-2" />
								<input
									type="radio"
									id="remoteOnline"
									name="consultationMode"
									value="Remote/Online"
									checked={consultationMode === "Remote/Online"}
									onChange={handleConsultationModeChange}
									className="mr-2"
								/>
								<label htmlFor="remoteOnline" className="text-sm font-medium text-grey-900">
									Remote/Online
								</label>
							</div>
						</div>
					</div>

					<div className="flex flex-col">
						<Applabel label="Selected Slots" />
						<div className="flex items-center justify-between gap-4">
							<div className="flex flex-col gap-2 flex-grow">
								{selectedSlots.length > 0 ? (
									<div className="flex flex-wrap gap-2">
										{selectedSlots.map((selectedSlot, index) => (
											<SelectedSlotChip
												key={index}
												slot={selectedSlot}
												onRemove={() => {
													setSelectedSlots((prev) => prev.filter((_, i) => i !== index));
												}}
											/>
										))}
									</div>
								) : (
									<div className="text-sm text-gray-500">No slots selected</div>
								)}
							</div>
						</div>
					</div>

					<button
						onClick={onSubmit}
						disabled={selectedSlots.length === 0 || selectedPatientId === null}
						className="mt-auto px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 disabled:opacity-50 disabled:cursor-not-allowed"
					>
						Book Appointment
					</button>
				</div>
			</div>
		</div>
	);
}

export default StaffCalander;
