import React, { useState, useMemo, useEffect } from "react";
import { Search, ChevronLeft, ChevronRight, Plus, Minus } from "lucide-react";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import { replaceParams } from "@utils/axios";
import axios from "axios";
import { toast } from "react-toastify";
import { OutlinedButton } from "@components/button";
import { ButtonVariant } from "@components/button/outline-button";
import { Service } from "../types/Service";
import { z } from "zod";

export const SelectedServiceSchema = z.object({
  serviceId: z.string(),
  quantity: z.number(),
});
export type SelectedService = z.infer<typeof SelectedServiceSchema>;

interface ServiceSelectorProps {
  services: Service[];
  selectedServices: SelectedService[];
  onChange: (services: SelectedService[]) => void;
  isView?: boolean;
}

export default function ServiceSelector({
  services,
  selectedServices,
  onChange,
  isView,
}: ServiceSelectorProps) {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [localSelectedServices, setLocalSelectedServices] =
    useState<SelectedService[]>(selectedServices);

  const [sortedServices, setSortedServices] = useState<Service[]>([]);

  // Sort services by quantity (selected services come first)
  useEffect(() => {
    if (services?.length > 0) {
      const handler = setTimeout(() => {
        const sorted = [...services].sort((a, b) => {
          const aSelected =
            selectedServices.find((s) => s.serviceId === a.id)?.quantity || 0;
          const bSelected =
            selectedServices.find((s) => s.serviceId === b.id)?.quantity || 0;

          // Sort by quantity in descending order
          if (bSelected !== aSelected) {
            return bSelected - aSelected;
          }

          // Secondary sort: alphabetical order if quantities are equal
          return a.name.localeCompare(b.name);
        });
        setSortedServices(sorted);
      }, 1000);
      return () => {
        clearTimeout(handler);
      };
    }
  }, [services, selectedServices]);

  const filteredServices = sortedServices?.filter((service: any) =>
    service.name.trim().toLowerCase().includes(search.toLowerCase())
  );

  const handleAddService = (service: Service) => {
    const updatedServices = [
      ...localSelectedServices,
      { serviceId: service.id, quantity: 1 },
    ];
    setLocalSelectedServices(updatedServices);
    onChange(updatedServices);
  };

  const handleQuantityChange = (service: Service, increment: boolean) => {
    const updatedServices = localSelectedServices
      .map((s) => {
        if (s.serviceId === service.id) {
          const newQuantity = increment ? s.quantity + 1 : s.quantity - 1;
          return { ...s, quantity: newQuantity };
        }
        return s;
      })
      .filter((s) => s.quantity > 0);

    setLocalSelectedServices(updatedServices);
    onChange(updatedServices);
  };

  useEffect(() => {
    setLocalSelectedServices(selectedServices);
  }, [selectedServices]);

  return (
    <div className="space-y-4">
      <div className="relative">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
        <input
          type="text"
          placeholder="Search services..."
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            setCurrentPage(1);
          }}
          className="pl-10 pr-4 py-2 w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 text-sm"
        />
      </div>

      <div className="flex flex-col h-[220px] overflow-y-auto justify-between space-y-3 pr-2">
        {filteredServices &&
          filteredServices.map((service: any) => {
            const selectedService = selectedServices.find(
              (s) => s.serviceId === service.id
            );
            const isSelected = !!selectedService;

            return (
              <div
                key={service.name}
                className={`bg-white rounded-lg border hover:shadow-md transition-shadow w-full ${
                  isSelected
                    ? "border-primary-200 shadow-lg"
                    : "border-gray-200"
                }`}
              >
                <div className="p-4 h-[60px]">
                  <div className="flex justify-between items-center h-full">
                    <div className="truncate w-3/4">
                      <p className="truncate text-ellipsis font-medium text-gray-900 overflow-hidden whitespace-nowrap">
                        {service.name}
                      </p>
                    </div>
                    <div className="flex justify-end items-center">
                      {isSelected ? (
                        <div className="flex items-center justify-between w-28 px-2 py-1.5 rounded-md bg-primary-50 hover:bg-primary-100 transition-colors">
                          <span className="text-sm font-medium text-gray-600">
                            Qty:
                          </span>
                          <div className="flex items-center gap-0.5">
                            <button
                              type="button"
                              onClick={() =>
                                handleQuantityChange(service, false)
                              }
                              className="p-1 text-black hover:bg-primary-100 rounded"
                              disabled={isView}
                            >
                              <Minus className="w-3.5 h-3.5" />
                            </button>
                            <span className="w-4 text-center text-sm font-medium text-gray-900">
                              {selectedService.quantity}
                            </span>
                            <button
                              type="button"
                              onClick={() =>
                                handleQuantityChange(service, true)
                              }
                              className="p-1 text-black hover:bg-primary-100 rounded"
                              disabled={isView}
                            >
                              <Plus className="w-3.5 h-3.5" />
                            </button>
                          </div>
                        </div>
                      ) : (
                        <button
                          type="button"
                          onClick={() => handleAddService(service)}
                          className="px-3 py-1.5 rounded-md bg-primary-50 text-black hover:bg-primary-100 transition-colors text-sm font-medium w-28"
                          disabled={isView}
                        >
                          Add
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
