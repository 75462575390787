import React, { useCallback, useEffect, useState } from "react";

import axios from "axios";
import { endpoints, replaceParams } from "@utils/axios";

import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import {
	MasterAssessmentLedger,
	MasterAssessmentLedgerConvert,
} from "@pages/manage-patient-new/types/MasterAssessmentLedger";
import { useNavigate, useParams } from "react-router-dom";
import Button from "@components/button/outline-button";
import SolidButton, { ButtonVariant } from "@components/button/outline-button";
import { paths } from "@routes/paths";
import Modal from "@components/modal";
import NewMasterAssessment from "./NewMasterAssessment";
import moment from "moment";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { OutlinedButton } from "@components/button";
import ManagePatientNewV2 from "..";
function classNames(...classes: any) {
	return classes.filter(Boolean).join(" ");
}

export default function ExaminationAndPlan({ inputPatientId }: { inputPatientId?: string }) {
	const [masterAssessmentList, setmasterAssessmentList] = useState<MasterAssessmentLedger[] | null>(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [assessmentId, setAssessmentId] = useState<string>("");

	let { patientId } = useParams();
	const getPatientId = useCallback((): string => inputPatientId ?? patientId ?? "", [inputPatientId, patientId]);

	const getmasterAssessmentList = useCallback(async () => {
		try {
			const paramsMap = new Map<string, string>([["patientId", getPatientId()]]);
			const filteredEndpoint = replaceParams(endpoints.assessment.masterAssessmentList, paramsMap);
			const response = await axios.get(filteredEndpoint);
			const data = response.data["data"];

			if (data != null) {
				const convertedmasterAssessmentList = data.map((assessment: any) =>
					MasterAssessmentLedgerConvert.toMasterAssessmentLedger(JSON.stringify(assessment))
				);
				setmasterAssessmentList(convertedmasterAssessmentList);
				convertedmasterAssessmentList.length > 0 && setAssessmentId(convertedmasterAssessmentList[0].id);
			} else {
				setmasterAssessmentList([]);
			}
		} catch (error) {
			console.error("Error fetching master assessment list:", error);
		}
	}, [getPatientId]);

	useEffect(() => {
		getmasterAssessmentList();
	}, [getmasterAssessmentList]);

	const handleCloseModal = () => setIsModalOpen(false);
	const handleOpenModal = () => setIsModalOpen(true);

	const handleUpdate = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const selectedOption = e.target.selectedOptions[0];
		const key = selectedOption.getAttribute("data-key");
		setAssessmentId(key ?? "");
	};

	return (
		<div className="flex flex-col ">
			<div className="flex flex-col w-full mt-2">
				<div className="flex flex-row">
					{masterAssessmentList && masterAssessmentList.length > 0 ? (
						<>
							<div className="flex w-full basis-3/4">
								<select
									className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-secondary-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 cursor-pointer"
									onChange={handleUpdate}
								>
									{masterAssessmentList &&
										masterAssessmentList.map((assessment, activityItemIdx) => {
											return (
												<option data-key={assessment.id} value={assessment.entityUserName}>
													<div
														className={`cursor-pointer flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200 hover:border-2  border-secondary-200 }`}
													>
														<div className="text-xs leading-6 text-gray-500 ">
															Assessed on{" : "}
															{moment(assessment.audit.createdAt).format("DD MMMM YYYY")} &nbsp; (
															{assessment.entityUserName})&nbsp;
															{activityItemIdx == 0 && <b>Latest</b>}
														</div>
													</div>
												</option>
											);
										})}
								</select>
							</div>
							<div className="flex justify-end basis-1/4">
								<Button variant={ButtonVariant.GRAY} className="" onClick={() => handleOpenModal()}>
									<PlusCircleIcon className="h-5 w-5 text-primary-500" />
									<span className="pl-1 text-xs md:hidden flex"> Create</span>
									<span className="pl-1 text-xs md:flex hidden"> Create New</span>
								</Button>
								{/*             <OutlinedButton
                  variant={ButtonVariant.PRIMARY}
                  className="ml-2"
                  onClick={() => {
                    navigator("/patient");
                  }}
                >
                  <span className="pl-1 text-sm"> Back </span>
                </OutlinedButton> */}
							</div>
						</>
					) : (
						<span className="justify-center text-center w-full ">
							<OutlinedButton variant={ButtonVariant.PRIMARY} className="" onClick={() => handleOpenModal()}>
								<PlusCircleIcon className="h-4 w-4 text-primary-600" />
								<span className="pl-1 text-sm md:flex hidden"> Create Initial Assessemnt</span>
							</OutlinedButton>
						</span>
					)}
				</div>
			</div>
			<div className="flex flex-col w-full mt-2 ">
				{assessmentId && (
					<>
						<ManagePatientNewV2 masterAssessmentId={assessmentId} patientId={inputPatientId ? inputPatientId : ""} />
					</>
				)}
			</div>
			<Modal isOpen={isModalOpen} onClose={handleCloseModal}>
				<NewMasterAssessment
					handleCloseModal={handleCloseModal}
					handleOnSubmit={async () => {
						await getmasterAssessmentList();
					}}
					masterAssessmentLedgerLength={masterAssessmentList?.length ?? 0}
					patientId={getPatientId()}
				/>
			</Modal>

			{/*       <ul
        role="list"
        className="space-y-6 overflow-auto max-h-[80vh] w-full p-2 mt-2 "
      >
        {(masterAssessmentList === null ||
          masterAssessmentList.length === 0) && (
            <div className="text-center text-gray-500">
              <p className="text-lg font-semibold mb-2">No Assessments found.</p>
              <p className="text-sm">
                Add Assessments to keep track of your sessions.
              </p>
            </div>
          )}
        {masterAssessmentList &&
          masterAssessmentList.map((activityItem, activityItemIdx) => (
            <li
              key={activityItem.id}
              className="relative flex gap-x-4 "
              onClick={() => {
                viewMasterAssessment(activityItem.id);
              }}
            >
              <div
                className={classNames(
                  activityItemIdx === masterAssessmentList.length - 1
                    ? "h-6"
                    : "-bottom-6",
                  "absolute left-0 top-0 flex w-6 justify-center"
                )}
              >
                <div className="w-px bg-gray-200" />
              </div>

              <>
                <svg
                  className="relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-50"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <circle cx="12" cy="12" r="8" strokeWidth="2" />
                </svg>
                <div
                  className={`cursor-pointer flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200 hover:border-2  border-primary-200 ${activityItemIdx == 0 ? "border-2 border-success-400" : ""
                    }`}
                >
                  <div className="flex justify-between gap-x-4">
                    <div className="py-0.5 text-sm leading-5 text-gray-500">
                      Assessed by{" : "}
                      <span className="font-medium text-gray-900">
                        {activityItem.entityUserName}
                      </span>
                    </div>
                    {activityItemIdx == 0 && (
                      <div className="bg-success-400 text-success-700 rounded-full py-1 px-4 ml-2 text-xs">
                        current
                      </div>
                    )}
                  </div>
                  <p className="text-sm leading-6 text-gray-500">
                    Assessed on{" : "}
                    <span className="font-medium text-gray-900">
                      {moment(activityItem.assessmentDate).format(
                        "DD MMMM YYYY"
                      )}
                    </span>
                  </p>
                </div>
              </>
            </li>
          ))}
      </ul>
 */}
		</div>
	);
}
