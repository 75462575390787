import { OutlinedButton } from "@components/button";
import Button, { ButtonVariant } from "@components/button/button";
import { Switch } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { InputField, ValidationError } from "@components/hook-form";
import TextAreaField from "@components/hook-form/textarea-field";
import { endpoints, replaceParams } from "@utils/axios";
import axios from "axios";
import { Appointment } from "../appointments/types/Appointment";

import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import Modal from "@components/modal";
import { CancelTokenSource } from "axios";
import moment from "moment";
import Applabel from "@components/hook-form/applabel";
import { PersonalInformationDetails } from "@pages/manage-patient-new/types/PatientPersonalDetails";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { RadioGroup } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { ConfirmationDialog } from "@components/modal/ConfirmationDialog";

//original
export const PaymentDetailsSchema = z.object({
	amount: z.string().optional(),
	billamount: z.string().min(1, "Please add bill amount"),
	payoutamount: z.string().optional(),
	outstandingAmount: z.string().optional(),
	paymentMode: z.string().nullable().optional(),
	paymentStatus: z.string().optional(),
	notes: z.string().optional(),
	startDate: z.string().optional(),
	isOutStandingPay: z.boolean().default(true),
	addPayout: z.boolean().default(false),
	noOfSessions: z.string().min(1, "Please add session").max(2, "Please add sessions less than 100.").optional(),
});

export type PaymentDetailsSchemaType = z.infer<typeof PaymentDetailsSchema>;

const paymentStatus = [
	/** DO NOT CHANGE SEQUENCE OR ID */
	{ id: 1, title: "Unpaid", description: "Bill is not paid", key: "pending" },
	{ id: 2, title: "Paid", description: "Bill is paid", key: "paid" },
	{
		id: 3,
		title: "Partially Paid",
		description: "Bill is partially paid",
		key: "partlyPaid",
	},
];

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(" ");
}

export default function AppointmentLevelBilling(props: {
	onClose: () => void;
	onConfirm: () => void;
	openClientBilling: () => void;
	appointmentDetails: Appointment;
	patientPersonalInformation?: PersonalInformationDetails | null;
	outStandingAmount?: number | null;
	patientId?: string | null;
}) {
	const entityData = getUserPersistedOnLocalStorage();
	let [isOpen, setIsOpen] = useState(true);
	const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);
	const [billAmount, setBillAmount] = useState<number>(props.appointmentDetails?.billAmount || 0);
	const [paidAmount, setPaidAmount] = useState<number>(props.appointmentDetails?.billAmount || 0);

	const [billing, setBilling] = useState(null);

	const [payOutAmount, setPayOutAmount] = useState<number>(props.appointmentDetails?.payoutamount || 0);
	const [isPaid, setIsPaid] = useState<boolean>(
		typeof props.appointmentDetails?.paymentStatus === "string" &&
			(props.appointmentDetails?.paymentStatus.toLowerCase() === "paid" ||
				props.appointmentDetails?.paymentStatus.toLowerCase() === "partlypaid")
	);
	const navigate = useNavigate();

	const navigateToPatientScreen = (patientId: string) => {
		navigate(`/patient/manage/${patientId}`);
	};

	const handlePaidAmount = (e) => {
		const inputValue = e.target.value;
		setPaidAmount(inputValue);
		setIsSubmitDisabled(true);
		if (inputValue === "") {
			methods.setError("amount", {
				message: "Please enter amount",
			});
		} else if (Number(inputValue) < 0) {
			methods.setError("amount", {
				message: "amount should be positive",
			});
		} else if (selectedPaymentStatus.id === 2 && Number(inputValue) !== Number(billAmount)) {
			methods.setError("amount", {
				message: `Paid amount is not matching with billing amount. To capture partial payments, select 'Partially Paid' option.`,
			});
		} else if (Number(inputValue) > billAmount) {
			methods.setError("amount", {
				message: `Paid amount should not be greater than billing amount. To pay old bills, visit Patient's billing section `,
			});
		} else {
			setIsSubmitDisabled(false);
			methods.clearErrors("amount");
			methods.setValue("amount", e.target.value);
			methods.setValue("outstandingAmount", billAmount - e.target.value);
		}
	};
	const handleBillAmount = (e) => {
		const inputValue = e.target.value;
		setBillAmount(inputValue);
		setIsSubmitDisabled(true);
		if (inputValue === "") {
			methods.setError("billamount", {
				message: "Please enter bill amount",
			});
		} else if (Number(inputValue) < 0) {
			methods.setError("billamount", {
				message: "Bill amount should be positive",
			});
		} else {
			setIsSubmitDisabled(false);
			methods.clearErrors("billamount");
			methods.setValue("billamount", inputValue);
			methods.clearErrors("amount");
			methods.setValue("amount", e.target.value);
			setPaidAmount(inputValue);
		}
	};

	const handlePayoutamount = (e) => {
		const inputValue = e.target.value;
		setPayOutAmount(inputValue);

		if (inputValue === "") {
			methods.setError("payoutamount", {
				message: "Please enter payout amount",
			});
		} else if (Number(inputValue) < 0) {
			methods.setError("payoutamount", {
				message: "Payout amount should be positive",
			});
		} else {
			methods.clearErrors("payoutamount");
			methods.setValue("v", inputValue);
		}
	};
	const [isAdvancedPayment, setIsAdvancedPayment] = useState<boolean>(false);
	const [addPayout, setAddPayout] = useState<boolean>(false);
	const [formData, setFormData] = useState({});
	const [postInProgress, setPostInProgress] = React.useState<boolean>(false);
	const [selectedDate, setSelectedDate] = React.useState<Date>(new Date());
	const cancelToken = React.useRef<CancelTokenSource | null>(null);
	const methods = useForm({
		resolver: zodResolver(PaymentDetailsSchema),
	});
	const [billingDetails, setBillingDetails] = useState(null);
	function closeModal() {
		setIsOpen(false);
		props.onClose();
	}
	const [billDetails, setBillDetails] = useState(null);

	const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value != "") {
			setSelectedDate(new Date(e.target.value));
		}
	};

	const errors = methods.formState.errors;
	const [selectedPaymentStatus, setSelectedPaymentStatus] = useState(paymentStatus[0]);

	const getPatientBillingDetails = async () => {
		try {
			const paramsMap = new Map<string, string>([["id", props.appointmentDetails?.clientId]]);
			const filteredEndpoint = replaceParams(endpoints.patient.getBillingDetails, paramsMap);
			const response = await axios.get(filteredEndpoint);
			const data = response.data["data"];

			setBillingDetails(data);
		} catch (error) {}
	};

	const onSubmit: SubmitHandler<PaymentDetailsSchemaType> = async (data) => {
		if (postInProgress) return;
		if (selectedPaymentStatus && selectedPaymentStatus.id !== 1 && !data.amount) {
			methods.setError("amount", {
				type: "manual",
				message: "Please add amount paid",
			});
			return;
		}

		methods.trigger();

		if (cancelToken.current) {
			cancelToken.current.cancel("Request already in progress!");
		}

		cancelToken.current = axios.CancelToken.source();

		try {
			setPostInProgress(true);
			const combinedData = { ...formData, ...data };

			PaymentDetailsSchema.parse(combinedData);
			const userData = getUserPersistedOnLocalStorage();
			const endpoint = isPaid ? endpoints.payment.payout : endpoints.payment.add;
			/** if paid amount is higher than billing amount mark status as paid ignoring whatever status selected  */
			if (Number(data.billamount) < Number(data.amount)) {
				setSelectedPaymentStatus(paymentStatus[1])
			}  
				await axios.post(
					endpoint,
					props.appointmentDetails !== null &&
						props.appointmentDetails !== undefined && {
							//This section is called when  the payment page is accessed from the appointment booking page or Patient profile page appointments table
							clientId: props.appointmentDetails?.clientId,
							entityId: props.appointmentDetails?.entityId,
							entityBranchId: props.appointmentDetails?.entityBranchId,
							entityUserId: props.appointmentDetails?.entityUserId,
							clientName: props.appointmentDetails?.clientName,
							entityUserName: props.appointmentDetails?.entityUserName,
							amount: Number(data.amount),
							outstandingAmount: Number(data.outstandingAmount),
							appointmentId: props.appointmentDetails?.id,
							paymentMode: data.paymentMode,
							paymentStatus: selectedPaymentStatus.key,
							notes: data.notes,
							createdDate: props.appointmentDetails?.date,
							createdBy: userData?.user_id,
							isAdvancePay: isAdvancedPayment,
							isOutStandingPay: data.isOutStandingPay,
							noOfSessions: Number(data.noOfSessions),
							startDate: selectedDate,
							billamount: Number(data.billamount ? Number(data.billamount) : 0),
							appointmentDate: props.appointmentDetails?.date,
							appointmentType: props.appointmentDetails?.appointmentType,
							payoutamount: Number(data.payoutamount),
							isAlreadyPaid: isPaid,
						},
					{
						cancelToken: cancelToken.current.token,
					}
				);
			setPostInProgress(false);
			closeModal();
			props.appointmentDetails.billAmount = Number(data.billamount);
			props.appointmentDetails.paymentStatus = selectedPaymentStatus.key;
			props.onConfirm();
		} catch (error) {
			console.error("Error submitting form:", error);
			setPostInProgress(false);
		}
	};

	const [enabled, setEnabled] = useState(false);

	const getEntityPaymentDetails = async () => {
		try {
			const paramsMap = new Map<string, string>([["id", entityData?.entity_id ?? ""]]);
			const filteredEndpoint = replaceParams(endpoints.entity.getAppointmentPaymentDetails, paramsMap);

			const response = await axios.get(filteredEndpoint, {
				params: {
					appointmenttype: `${props.appointmentDetails?.appointmentType}`,
				},
			});
			const data = response.data["data"];
			// setBillAmount(data.appointmentSettings[0].charges);
			// methods.setValue("billAmount", data.appointmentSettings[0].charges);

			setBillAmount(data.appointmentSettings[0].charges);
		} catch (error) {
			console.error("Error fetching entity list:", error);
		}
	};

	useEffect(() => {
		console.log(props.appointmentDetails);
		if (!props.appointmentDetails?.billAmount) getEntityPaymentDetails();
	}, []);

	const [openConfirmationBox, setOpenconfirmationBox] = useState(false);

	const onChangePaymentStatus = (data: any) => {
		setSelectedPaymentStatus(data);
		if (props.outStandingAmount && props.outStandingAmount > 0) {
			setOpenconfirmationBox(true);
		} else {
			setOpenconfirmationBox(false);
		}
	};

	return (
		<>
			<Modal isOpen={isOpen} onClose={closeModal}>
				<div className="w-[600px] max-w-[90vw] p-2 bg-white rounded-lg shadow-lg">
					<div className="flex flex-row w-full justify-between mx-4 my-4">
						<span className="text-sm leading-6 font-semibolds text-gray-800">
							{props.appointmentDetails?.clientName}, {props.appointmentDetails?.appointmentType} (
							{props.appointmentDetails?.date} {props.appointmentDetails?.startTime})
						</span>
					</div>
					<hr className="w-full" />
					<FormProvider {...methods}>
						<form onSubmit={methods.handleSubmit(onSubmit)}>
							<div className="flex flex-col w-full h-full px-4 space-y-2">
								<div className="flex flex-row ">
									<div className="flex flex-col w-1/2 mr-4">
										{/* <label className="block text-base font-bold leading-6 text-grey-900 mt-2 mb-2">
											Bill Amount<span className="text-red-500">*</span>
										</label> */}
										{isPaid ? (
											<>
												<Applabel label="Bill Amount" />
												<p className="p-1 text-base font-semibold">₹ {billAmount} </p>
												<input
													{...methods.register("billamount")}
													placeholder="Enter bill amount"
													value={billAmount}
													hidden={true}
													onChange={handleBillAmount}
													readOnly={isPaid}
													type="number"
													className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
												/>
												{props.appointmentDetails?.paymentType === "session-credit" ? (
													<p className="p-1 text-base text-success-600 font-semibold">
														Bill Paid using Session Credits (Advance Payment)
													</p>
												) : props.appointmentDetails?.paymentStatus === "partlyPaid" ? (
													<p className="p-1 text-base text-success-600 font-semibold">Bill Partly Paid</p>
												) : (
													<p className="p-1 text-base text-success-600 font-semibold">Bill Paid</p>
												)}
											</>
										) : (
											<>
												<Applabel label="Bill Amount" mandatory="true" />
												<input
													{...methods.register("billamount")}
													placeholder="Enter bill amount"
													value={billAmount}
													onChange={handleBillAmount}
													readOnly={isPaid}
													type="number"
													className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
												/>
												{errors.billamount && (
													<ValidationError message={errors.billamount?.message?.toString() ?? ""} />
												)}
											</>
										)}
									</div>
								</div>
								{!isPaid && (
									<>
										<hr className="w-full" />
										<RadioGroup
											{...methods.register("paymentStatus")}
											value={selectedPaymentStatus}
											onChange={onChangePaymentStatus}
											className="mt-2 mb-4"
										>
											<RadioGroup.Label className="text-base font-semibold leading-6 text-gray-900">
												Select a Payment Status
											</RadioGroup.Label>
											<div className="mt-2 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
												{paymentStatus.map((paymentStatusList) => (
													<RadioGroup.Option
														key={paymentStatusList.id}
														value={paymentStatusList}
														className={({ active }) =>
															classNames(
																active ? "border-indigo-600 ring-2 ring-indigo-600" : "border-gray-300",
																"relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none"
															)
														}
													>
														{({ checked, active }) => (
															<>
																<span className="flex flex-1">
																	<span className="flex flex-col">
																		<RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
																			{paymentStatusList.title}
																		</RadioGroup.Label>
																		<RadioGroup.Description
																			as="span"
																			className="mt-2 flex items-start text-xs text-gray-500"
																		>
																			{paymentStatusList.description}
																		</RadioGroup.Description>
																	</span>
																</span>
																<CheckCircleIcon
																	className={classNames(
																		!checked ? "invisible" : "",
																		"h-5 w-5 text-primary-600 group-hover:text-primary-700"
																	)}
																	aria-hidden="true"
																/>
																<span
																	className={classNames(
																		active ? "border" : "border-2",
																		checked ? "border-indigo-600" : "border-transparent",
																		"pointer-events-none absolute -inset-px rounded-lg"
																	)}
																	aria-hidden="true"
																/>
															</>
														)}
													</RadioGroup.Option>
												))}
											</div>
										</RadioGroup>
									</>
								)}
								<hr className="w-full" />
								{openConfirmationBox && (
									<div>
										<span className="text-base font-medium text-orange-800">
											{props.appointmentDetails?.clientName} has unpaid bills. It is recommended to update payments on
											existing pending bills. Do you still want to proceed with marking payment for this single bill
											only?
										</span>
										<div className="flex flex-row w-full mt-4 p-4 bg-gray-100 justify-end">
											<Button
												variant={ButtonVariant.PRIMARY}
												type="button"
												onClick={() => {
													closeModal();
													props.openClientBilling();
												}}
												children="View Outstanding Bills"
												className="mr-2"
											/>

											<Button
												variant={ButtonVariant.PRIMARY}
												type="button"
												onClick={() => {
													setOpenconfirmationBox(false);
												}}
												children="Proceed"
												className="mr-2"
											/>
										</div>
									</div>
								)}
								{selectedPaymentStatus && selectedPaymentStatus.id !== 1 && openConfirmationBox == false && (
									<>
										<div className="flex flex-row ">
											<div className="flex flex-col w-1/2 mr-4">
												<Applabel label="Amount Paid" mandatory />
												<input
													{...methods.register("amount")}
													placeholder="Enter amount paid"
													value={paidAmount}
													onChange={handlePaidAmount}
													type="number"
													className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
												/>
												{errors.amount && <ValidationError message={errors.amount?.message?.toString() ?? ""} />}
											</div>
											{selectedPaymentStatus.id === 3 && (
												<div className="flex flex-col w-1/2 mr-2">
													<Applabel label="Outstanding/Due Amount" />
													<input
														{...methods.register("outstandingAmount")}
														placeholder="Enter outstanding amount"
														onChange={(e) => {
															if (e.target.value !== "" && Number(e.target.value) < 0) {
																methods.setError("outstandingAmount", {
																	message: "Amount should be positive",
																});
															} else {
																methods.clearErrors("outstandingAmount");
																methods.setValue("outstandingAmount", e.target.value);
															}
														}}
														type="number"
														className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
													/>
													{errors.outamount && (
														<ValidationError message={errors.outamount?.message?.toString() ?? ""} />
													)}
												</div>
											)}
										</div>
										{/*	{props.outStandingAmount && props.outStandingAmount > 0 && (
											<div className="flex flex-row ">
												 	<div className=" border-red-100">
													<input
														{...methods.register("isOutStandingPay")}
														id="isOutStandingPay"
														name="isOutStandingPay"
														defaultChecked
														type="checkbox"
														className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
													/>
												</div>
																							<div className="ml-3 text-sm mt-1">
													Deduct from outstanding balance amount
													<span className="text-red-600 "> ₹{props.outStandingAmount}</span>
												</div> 
												{  ----                     <div className="flex flex-col w-2/3 mr-2">
													<div className="ml-3 text-xs leading-6">Total outstanding amount</div>
													{credits && credits.OutstandingAmount > 0 ? (
														<div className="ml-3 text-base text-red-500">{credits.OutstandingAmount}</div>
													) : (
														<div className="ml-3 text-sm">0</div>
													)}
												</div> }
											</div>
										)}*/}
										<div className="flex flex-col ">
											<Applabel label="Payment Mode" />
											<div className="flex">
												{["UPI", "Cash", "Card", "Online", "Other"].map((option) => (
													<label key={option} className="flex-none text-sm mx-2">
														<input
															className={`h-4 w-4 border-gray-300 text-primary-600 focus:ring-indigo-600 mr-2`}
															{...methods.register("paymentMode")}
															type="radio"
															name="paymentMode"
															value={option}
														/>
														{option}
													</label>
												))}
											</div>
											{errors.paymentMode && (
												<ValidationError message={errors.paymentMode?.message?.toString() ?? ""} />
											)}
										</div>
										<div className="mt-2">
											<TextAreaField
												key="notes"
												name="notes"
												placeholder="Enter notes (max 3 lines)"
												rows={3}
												label="Notes"
												value={methods.watch("notes")}
											/>
											{errors.notes && <ValidationError message={errors.notes?.message?.toString() ?? ""} />}
										</div>
									</>
								)}
							</div>
							<div className="flex flex-row w-full mt-2 p-4">
								<div className="flex flex-row ">
									<div className=" border-red-100">
										<input
											type="checkbox"
											className="h-4 w-4 rounded border-primary-300 text-indigo-600 focus:ring-primary-600"
											checked={addPayout || payOutAmount > 0}
											onChange={() => setAddPayout(!addPayout)}
										/>
									</div>
									<div className="ml-3 text-sm mt-1">
										Add payout amount for
										<span className="text-primary-600 pl-1">{props.appointmentDetails?.entityUserName}</span>
									</div>
								</div>
							</div>
							{(addPayout || payOutAmount > 0) && (
								<div className="flex flex-row w-full px-4 ">
									<div className="flex flex-col w-1/2 mr-4">
										<label className="block text-xs font-medium leading-6 text-grey-900">Payout to</label>
										<span className="">{props.appointmentDetails?.entityUserName}</span>
									</div>
									<div className="flex flex-col w-1/2 mr-4">
										<label className="block text-xs font-medium leading-6 text-grey-900">Payout Amount</label>
										<input
											{...methods.register("payoutamount")}
											placeholder="Enter payout amount"
											value={payOutAmount}
											onChange={handlePayoutamount}
											type="number"
											className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
										/>
										{errors.payoutamount && (
											<ValidationError message={errors.payoutamount?.message?.toString() ?? ""} />
										)}
									</div>
								</div>
							)}
							<div className="flex flex-row w-full mt-4 p-4 bg-gray-100 justify-between">
								{/* 								<div>
									<OutlinedButton
										variant={ButtonVariant.SECONDARY}
										type="button"
										onClick={() => {
											navigateToPatientScreen(props.appointmentDetails?.clientId);
										}}
										children="Manage Patient"
									/>
								</div> */}
								<div className="">
									<OutlinedButton variant={ButtonVariant.PRIMARY} onClick={closeModal} className="mr-2" type="button">
										Cancel
									</OutlinedButton>
									<Button
										variant={ButtonVariant.PRIMARY}
										isDisabled={isSubmitDisabled || openConfirmationBox}
										onClick={() => {
											setFormData({
												...formData,
												...methods.getValues(),
											});

											setBillAmount();
										}}
										type="submit"
									>
										{selectedPaymentStatus && selectedPaymentStatus.id === 1 ? <>Save</> : <>Mark Paid</>}
									</Button>
								</div>
								{/* <OutlinedButton
									variant={ButtonVariant.TERTIARY}
									onClick={props.onConfirm}
									className="ml-2"
									type="submit"
								>
									Pay Later
								</OutlinedButton> */}
							</div>
						</form>
					</FormProvider>
				</div>
			</Modal>
			{/* 			{openConfirmationBox && (
				<ConfirmationDialog
					onClose={() => {
						setOpenconfirmationBox(false);
						//					setIsRightOffcanvasPatientBilling(true);
					}}
					onConfirm={() => {
						setOpenconfirmationBox(false);
						//				addInvoice(appointmentDetails);
					}}
					confirmationButtonText="Yes, Create New Bill"
					cancelText="View & Update Outstanding Bills"
					confirmationText={`${props.appointmentDetails?.clientName} has unpaid bills. It is recommended to update the existing bill instead of adding a new one. Do you still want to proceed with a new bill?`}
				/>
			)} */}
		</>
	);
}
