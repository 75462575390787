import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import { isRouteAccessible, paths } from "@routes/paths";
import {
  BarChart2,
  Calendar,
  LayoutGrid,
  Clock,
  Building2,
  Users,
  Hospital,
  UserSquare2,
  Play,
  Library,
  Receipt,
  CreditCard,
  LogOutIcon,
  UserIcon,
  CalendarDays,
} from "lucide-react";
import React from "react";

const navigation = [
  {
    category: "main",
    items: [
      { name: "Dashboard", href: paths.dashboard.route, icon: <BarChart2 /> },
      {
        name: "Entities",
        href: paths.entity.index.route,
        icon: <Hospital />,
      },
      {
        name: "My Today's Schedule",
        href: paths.mySchedule.route,
        icon: <Calendar />,
      },
      {
        name: "Assistant Dashboard",
        href: paths.assistantDashboardV2.route,
        icon: <LayoutGrid />,
      },
      { name: "Patients", href: paths.patient.index.route, icon: <Users /> },
    ],
  },
  {
    category: "Schedule Management",
    items: [
      {
        name: "Staff Schedule",
        href: paths.calander.single.route,
        icon: <CalendarDays />,
      },
      {
        name: "Multi-Staff Schedule",
        href: paths.calander.staff.route,
        icon: <CalendarDays />,
      },
      {
        name: "Appointments",
        href: paths.appointment.index.route,
        icon: <Clock />,
      },
    ],
  },
  {
    category: "Financial",
    items: [
      {
        name: "Billings",
        href: paths.billing.index.route,
        icon: <CreditCard />,
      },
      { name: "Expenses", href: paths.expense.index.route, icon: <Receipt /> },
    ],
  },
  {
    category: "Staff & Locations",
    items: [
      { name: "Staff", href: paths.staff.index.route, icon: <UserSquare2 /> },
      {
        name: "Branches",
        href: paths.branch.index.route,
        icon: <Building2 />,
      },
    ],
  },
  {
    category: "Treatment & Exercises",
    items: [
      {
        name: "Gritup Library",
        href: paths.grituplibrary.index.route,
        icon: <Library />,
      },
      { name: "Exercises", href: paths.exercise.index.route, icon: <Play /> },
    ],
  },
];

function getAccessibleNavItems() {
  return navigation.filter((navItem) => {
    // If the navItem has a category, filter the items in the category
    if (navItem.category) {
      navItem.items = navItem.items.filter((item) => {
        return isRouteAccessible(item.href);
      });
    }
    return navItem.items.length > 0;
  });
}

export { navigation, getAccessibleNavItems };
