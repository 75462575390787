import { OutlinedButton } from "@components/button";
import Button, { ButtonVariant } from "@components/button/button";
import { Dialog, Transition, Combobox } from "@headlessui/react";
import { endpoints, replaceParams } from "@utils/axios";
import React, { useState, Fragment, createContext, useEffect } from "react";
import { CheckBadgeIcon, ChevronUpDownIcon, UserPlusIcon } from "@heroicons/react/20/solid";

import moment from "moment";
import { AppointmentDto } from "../types/AppointmentDto";
import { toast } from "react-toastify";
import PatientSearch from "./PatientSearch";
import { ConvertFreeSlot, FreeSlot } from "../types/FreeSlot";
import { AvailableTimeSlotConvert, AvailableTimeSlots, Freeslot } from "../types/AvailableTimeSlots";
import useAxios from "@routes/hooks/use-axios";
import { Convert } from "../types/PatientType";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ValidationError } from "@components/hook-form";
import { STORAGE_KEYS } from "@constants/storage-keys";
import { CancelTokenSource } from "axios";
import Applabel from "@components/hook-form/applabel";
import PatientSearchV2 from "@common/components/PatientSearch/PatientSearchV2";
import { Patient } from "@common/types/Patient";
import { Staff } from "@pages/add-staff/types/Staff";
import { ConvertStaff } from "@pages/manage-patient-new/types/Staff";
import { Service } from "@common/types/ServiceType";
import { bookingDurations } from "../calender/data";
import { BookingDuration } from "../calender/types";

interface AppointmentData {
	AppointmentDuration: number[];
	AppointmentType: string[];
	appointmentString: string[];
	branchId: string;
}

export const AppointmentSchema = z.object({
	patientId: z.string().min(1, { message: "Please select patient name" }).default(""),
	appointmentType: z.string().min(1, { message: "Please select appointment type" }).default(""),
	physioId: z
		.string({
			required_error: "Please select a staff member",
		})
		.min(1, "Please select a staff member"),
	startTime: z.string().min(1, { message: "Please select slot" }).default(""),
});

export type AppointmentType = z.infer<typeof AppointmentSchema> & {
	physioId: string;
};

function CheckInPage(props: {
	setQuickPatientAddDialog: React.Dispatch<React.SetStateAction<boolean>>;
	setCheckInDialog: React.Dispatch<React.SetStateAction<boolean>>;
	getAppointments: () => Promise<void>;
	branchId: string;
}) {
	const [patients, setPatients] = React.useState<Patient[] | null>(null);
	const naviagtor = useNavigate();
	const [postInProgress, setPostInProgress] = React.useState<boolean>(false);
	const cancelToken = React.useRef<CancelTokenSource | null>(null);
	const [loading, setLoading] = React.useState(true);
	const [error, setError] = React.useState("");
	const [selectedPatientId, setSelectedPatientId] = React.useState<string | null>(null);
	const [selectedPatientName, setSelectedPatientName] = React.useState<string | null>(null);
	const [selectedPatientEmail, setSelectedPatientEmail] = React.useState<string | null>(null);
	const [selectedPatientPhone, setSelectedPatientPhone] = React.useState<number | null>(null);
	const [selectedEntityUserName, setSelectedEntityUserName] = React.useState<string | null>(null);
	const [selectedEntityUserId, setSelectedEntityUserId] = React.useState<string | null>(null);
	const [selectedEntityUserPhone, setSelectedEntityUserPhone] = React.useState<number | null>(null);
	const [selectedPatientBranchId, setSelectedPatientBranchId] = React.useState<string | null>(null);
	const [selectedPatientAddress, setSelectedPatientAddress] = React.useState<string | null>(null);
	const [selectedPatient, setSelectedPatient] = useState<Patient | null>(null);

	const [appointmentDuration, setAppointmentDuration] = React.useState(0);
	const [appointmentTypeData, setAppointmentTypeData] = React.useState<AppointmentData | null>(null);
	const axios = useAxios();
	const [availablePhysiotherapists, setAvailablePhysiotherapists] = React.useState<AvailableTimeSlots[] | null>(null);
	const [timeSlots, setTimeSlots] = React.useState<FreeSlot[] | null>(null);
	//const [selectedTimeSlot, setSelectedTimeSlot] = React.useState<FreeSlot | null>(null);
	const [selectedTime, setSelectedTime] = React.useState<string | null>(null);
	const [selectedDate, setSelectedDate] = React.useState(new Date());
	const [availableTimeSlots, setAvailableTimeSlots] = React.useState<AvailableTimeSlots[] | null>(null);
	const [notes, setNotes] = React.useState<string>("");
	const [multipleBookingWarning, setMultipleBookingWarning] = React.useState(false);
	const [consultationMode, setConsultationMode] = useState<string>("In-Clinic");

	const {
		register,
		handleSubmit,
		watch,
		reset,
		control,
		setValue,
		clearErrors,
		formState: { errors },
	} = useForm<AppointmentType>({ resolver: zodResolver(AppointmentSchema) });

	const [query, setQuery] = useState("");
	const [selectedStaff, setSelectedStaff] = useState<Staff | null>(null);

	useEffect(() => {
		getServices();
		if (
			userData?.user_role === "ENTITY_OWNER" ||
			userData?.user_role === "ASSISTANT" ||
			userData?.user_role === "BRANCH_ADMIN"
		) {
			fetchStaffList();
		}
	}, []);

	useEffect(() => {
		const getAppointmentType = async () => {
			try {
				const userData = getUserPersistedOnLocalStorage();
				const entityIdset = userData?.entity_id;

				// Ensure entityIdset is defined before using it
				if (entityIdset !== undefined) {
					// Create a Map with the entityIdset as the value
					const paramsMap = new Map<string, string>([["id", entityIdset]]);

					// Continue with your logic using paramsMap
					const filteredEndpoint = replaceParams(endpoints.appointment.fetchAppointmentDuration, paramsMap);
					const response = await axios.get(filteredEndpoint);
					const data: AppointmentData = response.data["data"];
					setAppointmentTypeData(data);
				}
			} catch (error) {
				console.error("Error fetching appointment type:", error);
			}
		};
		getAppointmentType();
	}, []);

	useEffect(() => {
		setTimeSlots([]);
		setAvailableTimeSlots([]);
		setAvailablePhysiotherapists([]);
		setSelectedTime("");
		setSelectedEntityUserId("");
		setMultipleBookingWarning(false);
		const getAvailableTimeSlots = async () => {
			try {
				const response = await axios.get(endpoints.appointment.availableTimeSlots, {
					params: {
						requestedDate: moment(new Date(selectedDate)).format("DD-MM-YYYY"),
						requestedAppointmentDuration: appointmentDuration,
						requestedSlotInterval: 15,
					},
				});
				const data = response.data["data"]["availableEntityUsers"];
				if (data != null) {
					const convertedAvailableTimeSlots = data.map((slot: any) =>
						AvailableTimeSlotConvert.toAvailableTimeSlots(JSON.stringify(slot))
					);
					const timeZone = moment.tz.guess();
					const convertedAvailableTimeSlotsLocalTime = convertedAvailableTimeSlots.map(
						(available: AvailableTimeSlots) => {
							return available.freeslots.map((freeslot: Freeslot) => {
								const startMoment = moment.utc(freeslot.startTime, "HH:mm A");
								freeslot.startTime = startMoment.tz(timeZone).format("hh:mm A");
								const endMoment = moment.utc(freeslot.endTime, "HH:mm A");
								freeslot.endTime = endMoment.tz(timeZone).format("hh:mm A");

								return freeslot;
							});
						}
					);
					setAvailableTimeSlots(convertedAvailableTimeSlots);
					setLoading(false);
				} else {
					setLoading(false);
					setAvailableTimeSlots(null);
				}
			} catch (error) {
				console.error("Error fetching available time slots...", error);
				setAvailableTimeSlots(null);
				setError(error.message);
				setLoading(false);
			}
		};

		if (appointmentDuration != null && selectedDate != null && appointmentDuration != 0) {
			getAvailableTimeSlots();
		}
	}, [selectedDate, appointmentDuration]);

	const addTimeToTimeZoneAndConvertToUTC = (date: Date, timeString: string): Date => {
		//   Parse input date and time using Moment.js

		const inputDate = moment(date);
		const inputTime = moment(timeString, "hh:mm A");

		// Combine date and time
		let resultDate = inputDate.set({
			hour: inputTime.hours(),
			minute: inputTime.minutes(),
		});
		// Convert to ISO string and return
		return new Date(moment(resultDate).toISOString());
	};

	const onSubmit = async () => {
		if (postInProgress) return;
		const userData = getUserPersistedOnLocalStorage();
		if (cancelToken.current) {
			cancelToken.current.cancel("Request already in progress!");
		}

		cancelToken.current = axios.CancelToken.source();

		try {
			setPostInProgress(true);
			let newSelectedDate: Date = new Date();
			newSelectedDate = addTimeToTimeZoneAndConvertToUTC(new Date(selectedDate), startTime);
			const selectedTimeSlot = {
				startTime: moment.utc(newSelectedDate).format("hh:mm A"),
				startTimeLocal: moment(selectedDate).format("hh:mm A"),
				endTime: moment.utc(newSelectedDate).add(selectedDuration.minutes, "minutes").format("hh:mm A"),
				endTimeLocal: moment(selectedDate).add(selectedDuration.minutes, "minutes").format("hh:mm A"),
				startTimeMinutes: newSelectedDate.getUTCHours() * 60 + newSelectedDate.getUTCMinutes(),
				endTimeMinutes: newSelectedDate.getUTCHours() * 60 + newSelectedDate.getUTCMinutes() + selectedDuration.minutes,
			};

			console.log("selectedTimeSlot ", selectedTimeSlot + "appointmentDuration " + appointmentDuration);
			let data: AppointmentDto = {
				clientId: selectedPatientId ?? "",
				clientName: selectedPatientName ?? "",
				clientPhone: selectedPatientPhone ?? 0,
				clientAddress: selectedPatientAddress ?? "",
				date: moment(new Date(selectedDate)).format("DD-MM-YYYY"),
				apptdate: newSelectedDate,
				appointmentType: selectedService?.name ?? "",
				serviceId: selectedService?.id ?? "",
				email: selectedPatientEmail ?? "",
				entityBranchId: userData?.branch_id ?? "",
				entityId: userData?.entity_id ?? "",
				notes: notes,
				endTime: selectedTimeSlot?.endTime ?? "",
				endTimeLocal: selectedTimeSlot?.endTimeLocal ?? "",
				endTimeMinutes: selectedTimeSlot?.endTimeMinutes ?? 0,
				startTime: selectedTimeSlot?.startTime ?? "",
				startTimeLocal: selectedTimeSlot?.startTimeLocal ?? "",
				startTimeMinutes: selectedTimeSlot?.startTimeMinutes ?? 0,
				entityUserId: selectedEntityUserId ?? "",
				entityUserName: selectedEntityUserName ?? "",
				appointmentStatus: "Scheduled",
				appointmentSlot: 0,
				duration: appointmentDuration,
				entityUserPhone: selectedEntityUserPhone ?? 0,
				statusChangeComment: "",
				isOnline: false,
				modeOfConsultation: consultationMode,
			};
			await axios.post(`${endpoints.appointment.createAppointment}`, data, {
				cancelToken: cancelToken.current.token,
			});
			setPostInProgress(false);

			props.getAppointments();
			toast.success("Appointment Booked Successfully");
			closeModal();
		} catch (error) {
			console.error("Error submitting form:", error);
			toast.error(error["response"]["data"]["data"]);
			setSelectedTime("");
			setValue("startTime", "");
			setMultipleBookingWarning(false);
			setPostInProgress(false);
		}
	};
	let [isOpen, setIsOpen] = React.useState(true);

	function closeModal() {
		setIsOpen(false);
		props.setCheckInDialog(false);
	}

	// const handleAppointmentTypeChange = (
	//   e: React.ChangeEvent<HTMLSelectElement>,
	// ) => {
	//   setSelectedEntityUserId('');
	//   setSelectedTime('');
	//   setValue('physioId', '');
	//   setValue('startTime', '');
	//   setMultipleBookingWarning(false);

	//   setAppointmentType(Number(e.target.value));
	//   clearErrors('appointmentType');
	// };
	const handleAppointmentTypeChange = (e: React.ChangeEvent<HTMLSelectElement>, keys: string[], values: number[]) => {
		const selectedValue = Number(e.target.value);
		const selectedIndex = values.indexOf(selectedValue);

		if (selectedIndex !== -1) {
			const selectedKey = keys[selectedIndex];
			setSelectApppointmentType(selectedKey.toString());
			setAppointmentDuration(selectedValue);
			clearErrors("appointmentType");
		}
	};

	const userData = getUserPersistedOnLocalStorage();
	const [staffList, setStaffList] = React.useState<Staff[] | null>(null);
	const [selectedService, setSelectedService] = useState<Service | null>(null);
	const [selectedDuration, setSelectedDuration] = useState<BookingDuration>(bookingDurations[0]);
	const [startTime, setStartTime] = useState(moment().format("HH:mm"));

	useEffect(() => {
		if (selectedService) {
			const duration = bookingDurations.find((d) => d.minutes === Number(selectedService.duration));
			console.log("duration ", 	duration);
			if (duration) {
				setSelectedDuration(duration);
			}
		}
	}, [selectedService]);

	const fetchStaffList = async () => {
		setLoading(true);
		try {
			let response;
			if (props.branchId) {
				response = await axios.get(endpoints.staff.list, {
					params: {
						branchId: props.branchId.toString() ?? "",
					},
				});
			} else {
				response = await axios.get(endpoints.staff.list);
			}

			const data = response.data.data;
			const convertedStaffList = data.map((staff: any) => ConvertStaff.toStaff(JSON.stringify(staff)));
			setStaffList(convertedStaffList);
		} catch (error) {
			console.error("Error fetching staff list:", error);
			setError(error.message);
		} finally {
			setLoading(false);
		}
	};

	function setStaffDetails(staffid: string): void {
		const employee = staffList?.find((staff) => staff.id === staffid);
		setSelectedEntityUserId(employee?.id ?? "");
		setSelectedEntityUserName(`${employee?.salutation}. ${employee?.firstName} ${employee?.lastName}`);
		setSelectedEntityUserPhone(employee?.phone ?? 0);
	}

	const filteredStaff =
		query === ""
			? staffList
			: staffList?.filter((staff) => {
					const fullName = `${staff.salutation}. ${staff.firstName} ${staff.middleName || ""} ${
						staff.lastName || ""
					}`.toLowerCase();
					return fullName.includes(query.toLowerCase());
			  });
	const [services, setServices] = useState<Service[]>([]);

	async function getServices() {
		try {
			const paramsMap = new Map<string, string>([["branchId", props.branchId || ""]]);
			const filteredEndpoint = replaceParams(endpoints.branch.services, paramsMap);
			const response = await axios.get(filteredEndpoint);
			if (response.status === 200) {
				if (response.data["data"] && response.data["data"].length > 0) {
					setServices(response.data["data"]);
					setSelectedService(response.data["data"][0]);
				} else {
					/** Get appointment types if*/
					console.log("No services found");
				}
			} else {
				toast.error(response.data["message"]);
			}
		} catch (error) {
			toast.error(error);
		}
	}
	return (
		<>
			<Transition appear show={isOpen} as={Fragment}>
				<Dialog as="div" className="relative z-50" onClose={closeModal}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black bg-opacity-25" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
									<Dialog.Title as="h3" className="text-lg font-medium leading-6 px-6 pt-6 text-gray-900">
										<div className="flex justify-between items-center">
											<div className="flex-grow text-xl font-bold">Daily Check-In</div>
											<div className="">
												<OutlinedButton
													variant={ButtonVariant.PRIMARY}
													onClick={() => {
														closeModal();
														props.setQuickPatientAddDialog(true);
													}}
													className="ml-2"
													type="button"
												>
													<UserPlusIcon className="w-5 h-5" />
													New Patient
												</OutlinedButton>
											</div>
										</div>
										<div className="flex flex-col">
											<span className="text-sm">Quick check-ins for walk-in clients and regular service users.</span>
										</div>
									</Dialog.Title>
									<form onSubmit={handleSubmit(onSubmit)} noValidate>
										<div className="max-h-[75vh] overflow-auto">
											<div className="flex flex-col mt-5">
												<div className="grid grid-cols-1 gap-4 lg:grid-cols-2 lg:space-x-2 w-full lg:flex-row lg:justify-between items-center px-6 ">
													<div className="flex flex-col w-full z-40">
														<Applabel label="Select Patient Name" mandatory={true} />
														{/* // TODO: Migrate to PatientSearchV2 */}
														<PatientSearchV2
															patient={selectedPatient}
															onSelect={(patient: Patient | null) => {
																if (patient) {
																	setSelectedPatient(patient);
																	setSelectedPatientName(patient.firstName + " " + patient.lastName);
																	setSelectedPatientEmail(patient.email);
																	setSelectedPatientPhone(patient.phone);
																	setSelectedPatientBranchId(patient.entityBranchId);
																	let address =
																		patient.address.address +
																		", " +
																		patient.address.city +
																		", " +
																		patient.address.state +
																		", " +
																		patient.address.postalCode;
																	setSelectedPatientAddress(address);
																	setSelectedPatientId(patient._id);
																	setValue("patientId", patient._id);
																	clearErrors("patientId");
																} else {
																	// Reset patient-related state if null
																	setSelectedPatientName(null);
																	setSelectedPatientEmail(null);
																	setSelectedPatientPhone(null);
																	setSelectedPatientBranchId(null);
																	setSelectedPatientAddress(null);
																	setSelectedPatientId(null);
																}
															}}
															key={selectedPatientId || "default"}
														/>
														{errors.patientId && (
															<ValidationError message={errors.patientId?.message?.toString() ?? ""} />
														)}
													</div>
													{/** Physio selector*/}
													{(userData?.user_role === "ENTITY_OWNER" ||
														userData?.user_role === "ASSISTANT" ||
														userData?.user_role === "BRANCH_ADMIN") && (
														<div className="flex flex-col w-full">
															<Applabel label="Consultant/Therapist" mandatory={true} />

															<div className="relative">
																<Combobox
																	value={selectedStaff}
																	onChange={(staff) => {
																		if (staff) {
																			setSelectedStaff(staff);
																			setStaffDetails(staff.id);
																			setValue("physioId", staff.id);
																			clearErrors("physioId");
																		} else {
																			setSelectedStaff(null);
																			setStaffDetails("");
																			setValue("physioId", "");
																		}
																	}}
																>
																	<div className="relative w-full">
																		<Combobox.Input
																			className="w-full rounded-lg border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 "
																			displayValue={(staff: Staff | null) =>
																				staff
																					? `${staff.salutation}. ${staff.firstName} ${staff.middleName || ""} ${
																							staff.lastName || ""
																					  }`
																					: ""
																			}
																			onChange={(event) => setQuery(event.target.value)}
																			placeholder="Choose consultant or therapists from list..."
																			disabled={loading}
																			required
																		/>
																		<Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
																			<ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
																		</Combobox.Button>
																	</div>
																	<Transition
																		enter="transition duration-100 ease-out"
																		enterFrom="transform scale-95 opacity-0"
																		enterTo="transform scale-100 opacity-100"
																		leave="transition duration-75 ease-out"
																		leaveFrom="transform scale-100 opacity-100"
																		leaveTo="transform scale-95 opacity-0"
																	>
																		<Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
																			{filteredStaff?.length === 0 && query !== "" ? (
																				<div className="relative cursor-default select-none px-4 py-2 text-gray-700">
																					Nothing found.
																				</div>
																			) : (
																				filteredStaff?.map((staff) => (
																					<Combobox.Option
																						key={staff.id}
																						value={staff}
																						className={({ active }) =>
																							`relative cursor-default select-none py-2 pl-4 pr-4 ${
																								active ? "bg-primary-100 text-primary-900" : "text-gray-900"
																							}`
																						}
																					>
																						{({ selected, active }) => (
																							<span
																								className={`block truncate ${selected ? "font-medium" : "font-normal"}`}
																							>
																								{`${staff.salutation}. ${staff.firstName} ${staff.middleName || ""} ${
																									staff.lastName || ""
																								}`}
																							</span>
																						)}
																					</Combobox.Option>
																				))
																			)}
																		</Combobox.Options>
																	</Transition>
																</Combobox>
															</div>
															{errors.physioId && (
																<span className="text-sm text-red-600 mt-1">
																	{errors.physioId.message?.toString() ?? "Please select a staff member"}
																</span>
															)}
														</div>
													)}
													<div className="flex flex-col w-full">
														<Applabel
															className="text-sm font-semibold text-primary-600"
															label="Appointment Type/Service"
															mandatory
														/>
														<select
															className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6 cursor-pointer"
															onChange={(e) => {
																const service = services?.find((service) => service.id === e.target.value);
																if (service) {
																	setSelectedService(service);
																}
															}}
															required
														>
															{services?.map((service, index) => (
																<option key={index} value={service.id}>
																	{service.name}
																</option>
															))}
														</select>
													</div>
													<div className="flex flex-col flex-1">
														<Applabel label="Duration" />
														<select
															className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 bg-gray-100 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 cursor-pointer"
															value={selectedDuration.minutes}
															onChange={(e) => {
																const duration = bookingDurations.find((d) => d.minutes === Number(e.target.value));
																if (duration) setSelectedDuration(duration);
															}}
															required
														>
															{bookingDurations.map((duration) => (
																<option key={duration.label} value={duration.minutes}>
																	{duration.label}
																</option>
															))}
														</select>
													</div>
													{/* Date and Time Selectors */}
													<div className="flex flex-row gap-2">
														<div className="flex flex-col flex-1">
															<Applabel label="Select Date" />
															<input
																type="date"
																value={moment(selectedDate).format("YYYY-MM-DD")}
																onChange={(e) => {
																	setSelectedDate(new Date(e.target.value));
																}}
																className="w-full border border-gray-300 rounded-lg text-sm focus:ring-secondary-500 focus:border-secondary-500 py-2"
															/>
														</div>
														<div className="flex flex-col flex-1">
															<Applabel label="Start Time" />
															<input
																type="time"
																value={startTime}
																min="00:00"
																max="23:59"
																onChange={(e) => setStartTime(e.target.value)}
																className="w-full border border-gray-300 rounded-lg text-sm focus:ring-secondary-500 focus:border-secondary-500 py-2"
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="flex flex-row rounded-bl-2xl rounded-br-2xl bg-gray-100 items-center p-4 mt-2">
											<div className="flex flex-row w-full justify-end">
												<OutlinedButton
													variant={ButtonVariant.PRIMARY}
													type="button"
													onClick={closeModal}
													children="Close"
													className="mr-2"
												/>
												<Button variant={ButtonVariant.SECONDARY} type="submit" onClick={onSubmit}>
													<span className="flex flex-row items-center">
														<CheckBadgeIcon className="w-5 h-5 mr-1" />
														Check-in
													</span>
												</Button>
											</div>
										</div>
									</form>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	);
}

export default CheckInPage;
