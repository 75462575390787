import React from "react";
import { RouterProvider } from "react-router-dom";

import Router from "./routes/pages";
import { AuthConsumer, AuthProvider } from "./authentication/context/jwt";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "../node_modules/react-toastify/dist/ReactToastify.css";
import { CurrentColorContext, defaultColorScheme } from "@theme/theme-context";
import { endpoints } from "@utils/axios";
import Values from "values.js";

const router = Router;

export default function App() {
  // useScrollToTop();

  const [colors, setColors] = React.useState(defaultColorScheme);

  axios.defaults.baseURL = process.env.API_BASE_URL; //'http://65.1.180.61:8081';
  axios.defaults.withCredentials = true;

  React.useEffect(() => {
    const _init = async () => {
      const brandNameResult = window.localStorage.getItem("brandName");
      if (brandNameResult) {
        document.title = brandNameResult;
      }
      const faviconResult = window.localStorage.getItem("favicon");
      if (faviconResult) {
        const link = document.getElementById("favicon") as HTMLLinkElement;
        link.href = faviconResult;
      }

      const colorSchemeResult = window.localStorage.getItem("newColorScheme");
      if (colorSchemeResult) setColors(JSON.parse(colorSchemeResult));
      if (!brandNameResult || !colorSchemeResult || !faviconResult)
        await fetchData();
    };

    _init();
  }, []);

  const makeTintsShades = async (
    primaryHex: string,
    secondaryHex: string,
    tertiaryHex: string
  ) => {
    const color1 = new Values(primaryHex);
    const color2 = new Values(secondaryHex);
    const color3 = new Values(tertiaryHex);

    return {
      primaryShades: {
        50: "#" + color1.tint(95).hex,
        100: "#" + color1.tint(90).hex,
        200: "#" + color1.tint(80).hex,
        300: "#" + color1.tint(70).hex,
        400: "#" + color1.tint(60).hex,
        500: primaryHex,
        600: "#" + color1.shade(10).hex,
        700: "#" + color1.shade(20).hex,
        800: "#" + color1.shade(30).hex,
        900: "#" + color1.shade(40).hex,
      },
      secondaryShades: {
        50: "#" + color2.tint(95).hex,
        100: "#" + color2.tint(90).hex,
        200: "#" + color2.tint(80).hex,
        300: "#" + color2.tint(70).hex,
        400: "#" + color2.tint(60).hex,
        500: secondaryHex,
        600: "#" + color2.shade(10).hex,
        700: "#" + color2.shade(20).hex,
        800: "#" + color2.shade(30).hex,
        900: "#" + color2.shade(40).hex,
      },
      tertiaryShades: {
        50: "#" + color3.tint(95).hex,
        100: "#" + color3.tint(90).hex,
        200: "#" + color3.tint(80).hex,
        300: "#" + color3.tint(70).hex,
        400: "#" + color3.tint(60).hex,
        500: tertiaryHex,
        600: "#" + color3.shade(10).hex,
        700: "#" + color3.shade(20).hex,
        800: "#" + color3.shade(30).hex,
        900: "#" + color3.shade(40).hex,
      },
    };
  };

  const fetchData = async () => {
    try {
      const origin = window.location.origin;

      const resp = await axios.get(endpoints.themeData, {
        headers: {
          Origin: origin,
        },
      });
      const data = resp.data.data;

      document.title = data.brandName;

      const link = document.getElementById("favicon") as HTMLLinkElement;

      link.href = data.favicon;

      window.localStorage.setItem("loginBannerImage", data.loginBannerImage);
      window.localStorage.setItem("primaryLogo", data.primaryLogo);
      window.localStorage.setItem("brandName", data.brandName);
      window.localStorage.setItem("brandTagline", data.brandTagline);
      window.localStorage.setItem("favicon", data.favicon);
      const colorSchemePrimaryColor = data["colorScheme"].primaryColor;
      const colorSchemeSecondaryColor = data["colorScheme"].secondaryColor;
      const colorSchemeTertiaryColor = data["colorScheme"].tertiaryColor;

      let newColorScheme = defaultColorScheme;
      if (
        colorSchemePrimaryColor !== "" &&
        colorSchemeSecondaryColor !== "" &&
        colorSchemeTertiaryColor !== ""
      ) {
        newColorScheme = await makeTintsShades(
          colorSchemePrimaryColor,
          colorSchemeSecondaryColor,
          colorSchemeTertiaryColor
        );
      }

      window.localStorage.setItem(
        "newColorScheme",
        JSON.stringify(newColorScheme)
      );
      const result = window.localStorage.getItem("newColorScheme");
      if (result) setColors(JSON.parse(result));
    } catch (error) {}
  };

  return (
    <AuthProvider>
      <AuthConsumer>
        <CurrentColorContext.Provider value={{ colors, setColors }}>
          <style>{`
           :root {
            --color-primary-50: ${colors.primaryShades[50]};
            --color-primary-100: ${colors.primaryShades[100]};
            --color-primary-200: ${colors.primaryShades[200]};
            --color-primary-300: ${colors.primaryShades[300]};
            --color-primary-400: ${colors.primaryShades[400]};
            --color-primary-500: ${colors.primaryShades[500]};
            --color-primary-600: ${colors.primaryShades[600]};
            --color-primary-700: ${colors.primaryShades[700]};
            --color-primary-800: ${colors.primaryShades[800]};
            --color-primary-900: ${colors.primaryShades[900]};

            --color-secondary-50: ${colors.secondaryShades[50]};
            --color-secondary-100: ${colors.secondaryShades[100]};
            --color-secondary-200: ${colors.secondaryShades[200]};
            --color-secondary-300: ${colors.secondaryShades[300]};
            --color-secondary-400: ${colors.secondaryShades[400]};
            --color-secondary-500: ${colors.secondaryShades[500]};
            --color-secondary-600: ${colors.secondaryShades[600]};
            --color-secondary-700: ${colors.secondaryShades[700]};
            --color-secondary-800: ${colors.secondaryShades[800]};
            --color-secondary-900: ${colors.secondaryShades[900]};

            --color-tertiary-50: ${colors.tertiaryShades[50]};
            --color-tertiary-100: ${colors.tertiaryShades[100]};
            --color-tertiary-200: ${colors.tertiaryShades[200]};
            --color-tertiary-300: ${colors.tertiaryShades[300]};
            --color-tertiary-400: ${colors.tertiaryShades[400]};
            --color-tertiary-500: ${colors.tertiaryShades[500]};
            --color-tertiary-600: ${colors.tertiaryShades[600]};
            --color-tertiary-700: ${colors.tertiaryShades[700]};
            --color-tertiary-800: ${colors.tertiaryShades[800]};
            --color-tertiary-900: ${colors.tertiaryShades[900]};
            `}</style>

          <RouterProvider router={router} />
          <ToastContainer
            autoClose={2000}
            position="top-right"
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover={false}
            theme="colored"
          />
        </CurrentColorContext.Provider>
      </AuthConsumer>
    </AuthProvider>
  );
}
