import {
  getCurrentUserRole,
  getUserRoleFromString,
} from "@authentication/context/jwt/utils";

type Role =
  | "practitioner"
  | "assistant"
  | "platformAdmin"
  | "entityOwner"
  | "branchAdmin"
  | "unauthorized";

function getUserRoleFromToken(token: string): Role {
  // Logic here to get current user role
  const role: string | undefined = getCurrentUserRole();

  // the role can be {PLATFORM_ADMIN, ENTITY_OWNER, BRANCH_ADMIN, PRACTITIONER, ASSISTANT}
  // we will convert it to {platformAdmin, businessAdmin, branchAdmin, practitioner, assistant}
  if (role === "PLATFORM_ADMIN") return "platformAdmin";
  if (role === "ENTITY_OWNER") return "entityOwner";
  if (role === "BRANCH_ADMIN") return "branchAdmin";
  if (role === "PRACTITIONER") return "practitioner";
  if (role === "ASSISTANT") return "assistant";
  else return "unauthorized";
}

type RouteMetadata = {
  route: string;
  title: string;
  permissions: Role[];
};

const ROOTS = {
  AUTH: "/auth",
  DASHBOARD: "/dashboard",
  ENTITY: "/entity",
  APPOINTMENT: "/appointment",
  CALANDER: "/calander",
  BRANCH: "/branch",
  STAFF: "/staff",
  EXERCISE: "/exercise",
  PATIENT: "/patient",
  BILLING: "/billings",
  REFERRALBILLING: "/referral-billing",
  GRITUPLIBRARY: "/gritup-library",
  ENTITYCONFIG: "/entity-configuration",
  BRANCHCONFIG: "/branch-configuration",
  STAFFCONFIG: "/staff-configuration",
  FINANCE: "/expenses",
  CREDITS: "/credits",
};

export const routesMetadata: Record<string, RouteMetadata> = {
  dashboard: {
    route: ROOTS.DASHBOARD,
    title: "Dashboard",
    permissions: [
      "practitioner",
      "assistant",
      "platformAdmin",
      "entityOwner",
      "branchAdmin",
    ],
  },
  "practitioner-dashboard": {
    route: `${ROOTS.DASHBOARD}/practitioner`,
    title: "Practitioner Dashboard",
    permissions: ["practitioner"],
  },
  "assistant-dashboard": {
    route: `/assistant-dashboard`,
    title: "Assistant Dashboard",
    permissions: ["entityOwner", "branchAdmin"],
  },
  "assistant-dashboard-v2": {
    route: `/assistant-dashboard-v2`,
    title: "Assistant Dashboard V2",
    permissions: ["entityOwner", "branchAdmin"],
  },
  "my-schedule": {
    route: `/my-schedule`,
    title: "My Schedule",
    permissions: ["entityOwner", "branchAdmin", "practitioner"],
  },

  "staff-dashboard": {
    route: `/staff-dashboard`,
    title: "Staff Dashboard",
    permissions: ["entityOwner", "branchAdmin"],
  },
  "platform-admin-dashboard": {
    route: `${ROOTS.DASHBOARD}/platform-admin`,
    title: "Platform Admin Dashboard",
    permissions: ["platformAdmin"],
  },
  "entity-owner-dashboard": {
    route: `${ROOTS.DASHBOARD}/entity-owner`,
    title: "Entity Owner Dashboard",
    permissions: ["entityOwner"],
  },
  "branch-admin-dashboard": {
    route: `${ROOTS.DASHBOARD}/branch-admin`,
    title: "Branch Admin Dashboard",
    permissions: ["branchAdmin"],
  },
  "entity-index": {
    route: `${ROOTS.ENTITY}`,
    title: "Entities",
    permissions: ["platformAdmin"],
  },
  "entity-onboard": {
    route: `${ROOTS.ENTITY}/onboard`,
    title: "Entity Onboard",
    permissions: ["platformAdmin"],
  },
  "manage-entity": {
    route: `${ROOTS.ENTITY}/manage/:entityId`,
    title: "Manage Entity",
    permissions: ["platformAdmin"],
  },

  "branch-index": {
    route: `${ROOTS.BRANCH}`,
    title: "Branches",
    permissions: ["entityOwner" ],
  },
  "branch-onboard": {
    route: `${ROOTS.BRANCH}/onboard`,
    title: "Branch Onboard",
    permissions: ["entityOwner", "branchAdmin"],
  },
  "branch-view": {
    route: `${ROOTS.BRANCH}/view/:branchId`,
    title: "Branch View",
    permissions: ["entityOwner", "branchAdmin", "platformAdmin"],
  },
  "staff-index": {
    route: `${ROOTS.STAFF}`,
    title: "Staff",
    permissions: ["entityOwner", "branchAdmin", "assistant", "platformAdmin"],
  },
  "staff-onboard": {
    route: `${ROOTS.STAFF}/onboard`,
    title: "Staff Onboard",
    permissions: ["entityOwner", "branchAdmin", "assistant"],
  },
  "staff-view": {
    route: `${ROOTS.STAFF}/view/:staffId`,
    title: "Staff View",
    permissions: [
      "entityOwner",
      "branchAdmin",
      "assistant",
      "platformAdmin",
      "practitioner",
    ],
  },
  "patient-index": {
    route: `${ROOTS.PATIENT}`,
    title: "Patients",
    permissions: ["entityOwner", "branchAdmin", "assistant", "practitioner"],
  },
  "patient-onboard": {
    route: `${ROOTS.PATIENT}/onboard`,
    title: "Patient Onboard",
    permissions: ["entityOwner", "branchAdmin", "assistant", "practitioner"],
  },
  "patient-manage": {
    route: `${ROOTS.PATIENT}/manage/:patientId`,
    title: "Patient Manage",
    permissions: [
      "entityOwner",
      "branchAdmin",
      "assistant",
      "practitioner",
      "platformAdmin",
    ],
  },
  "patient-manage-v2": {
    route: `${ROOTS.PATIENT}/manage/:patientId/v2`,
    title: "Patient Manage",
    permissions: [
      "entityOwner",
      "branchAdmin",
      "assistant",
      "practitioner",
      "platformAdmin",
    ],
  },
  "patient-master-assessment": {
    route: `${ROOTS.PATIENT}/manage/:patientId/master-assessment/:masterAssessmentId`,
    title: "Patient Master Assessment",
    permissions: ["entityOwner", "branchAdmin", "assistant", "practitioner"],
  },
  "patient-physical-examination": {
    route: `${ROOTS.PATIENT}/manage/:patientId/physical-examination/:masterAssessmentId`,
    title: "Patient Physical Assessment",
    permissions: ["entityOwner", "branchAdmin", "assistant", "practitioner"],
  },
  "appointment-index": {
    route: `${ROOTS.APPOINTMENT}/index`,
    title: "Appointments",
    permissions: ["entityOwner", "branchAdmin", "assistant", "practitioner"],
  },

  "book-appointment": {
    route: `${ROOTS.APPOINTMENT}/book`,
    title: "Book Appointment",
    permissions: ["entityOwner", "branchAdmin", "assistant"],
  },
  "staff-calender": {
    route: `${ROOTS.CALANDER}/staff`,
    title: "Staff Calender",
    permissions: ["entityOwner", "branchAdmin", "assistant"],
  },
  "single-calender": {
    route: `${ROOTS.CALANDER}/single`,
    title: "Single Calender",
    permissions: ["entityOwner", "branchAdmin", "assistant", "practitioner"],
  },
  "exercise-index": {
    route: `${ROOTS.EXERCISE}`,
    title: "Exercises",
    permissions: [
      "entityOwner",
      "branchAdmin",
      "assistant",
      "practitioner",
      "platformAdmin",
    ],
  },
  "exercise-create": {
    route: `${ROOTS.EXERCISE}/create`,
    title: "Exercise Create",
    permissions: [
      "entityOwner",
      "branchAdmin",
      "assistant",
      "practitioner",
      "platformAdmin",
    ],
  },
  "exercise-view": {
    route: `${ROOTS.EXERCISE}/view`,
    title: "Exercise View",
    permissions: [
      "entityOwner",
      "branchAdmin",
      "assistant",
      "practitioner",
      "platformAdmin",
    ],
  },
  "gritup-library": {
    route: `${ROOTS.GRITUPLIBRARY}`,
    title: "Gritup Library",
    permissions: [
      "entityOwner",
      "branchAdmin",
      "assistant",
      "practitioner",
      "platformAdmin",
    ],
  },
  "gritup-library-view": {
    route: `${ROOTS.GRITUPLIBRARY}/view`,
    title: "Gritup Library",
    permissions: [
      "entityOwner",
      "branchAdmin",
      "assistant",
      "practitioner",
      "platformAdmin",
    ],
  },
  expenses: {
    route: ROOTS.FINANCE,
    title: "Expenses",
    permissions: [
      "entityOwner",
      "branchAdmin",
      "platformAdmin",
      "assistant",
      "practitioner",
    ],
  },
  invoices: {
    route: `${ROOTS.ENTITY}/invoices/view`,
    title: "Invoices",
    permissions: ["entityOwner", "branchAdmin", "platformAdmin"],
  },
  billing: {
    route: `${ROOTS.BILLING}`,
    title: "Billing",
    permissions: ["entityOwner", "branchAdmin", "platformAdmin", "assistant"],
  },
  referralBilling: {
    route: `${ROOTS.REFERRALBILLING}`,
    title: "Referral Billing",
    permissions: ["entityOwner", "branchAdmin", "platformAdmin", "assistant"],
  },

  "user-manage-entity": {
    route: `${ROOTS.ENTITYCONFIG}/:userId/:entityId`,
    title: "Manage Entity",
    permissions: ["entityOwner"],
  },
  "user-manage-branch": {
    route: `${ROOTS.BRANCHCONFIG}/:userId/:branchId`,
    title: "Manage Branch",
    permissions: ["entityOwner", "branchAdmin"],
  },
  "user-profile-view": {
    route: `${ROOTS.STAFFCONFIG}/:staffId`,
    title: "Profile View",
    permissions: [
      "entityOwner",
      "branchAdmin",
      "assistant",
      "practitioner",
      "platformAdmin",
    ],
  },
  credits: {
    route: `${ROOTS.CREDITS}`,
    title: "Credits",
    permissions: ["entityOwner", "branchAdmin", "platformAdmin"],
  },
};

export function getCurrentRouteMetadata(path: string): RouteMetadata {
  const userRole = getUserRoleFromString();
  const routeMetadata = Object.values(routesMetadata).find((routeMetadata) =>
    isRouteEqual(routeMetadata.route, path)
  );
  if (routeMetadata) {
    if (routeMetadata.permissions.includes(userRole)) {
      return routeMetadata;
    } else {
      return routesMetadata["dashboard"];
    }
  } else {
    return routesMetadata["dashboard"];
  }
}

// function to check route equality which also handles id params which are mongo ids
function isRouteEqual(route: string, path: string): boolean {
  const routeArray = route.split("/");
  const pathArray = path.split("/");
  if (routeArray.length !== pathArray.length) return false;
  for (let i = 0; i < routeArray.length; i++) {
    if (routeArray[i] !== pathArray[i]) {
      if (routeArray[i].startsWith(":")) {
        continue;
      } else {
        return false;
      }
    }
  }
  return true;
}

export function isRouteAccessible(path: string): boolean {
  const userRole = getUserRoleFromString();
  const routeMetadata = Object.values(routesMetadata).find(
    (routeMetadata) => routeMetadata.route === path
  );
  if (routeMetadata) {
    if (routeMetadata.permissions.includes(userRole)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function getRouteMetadataByRole(role: Role): RouteMetadata[] {
  return Object.values(routesMetadata).filter((routeMetadata) =>
    routeMetadata.permissions.includes(role)
  );
}

export const paths = {
  // Auth
  auth: {
    login: `${ROOTS.AUTH}/login`,
    forgotPassword: `${ROOTS.AUTH}/forgot-password`,
    resetPassword: `${ROOTS.AUTH}/reset-password`,
  },
  // Dashboard
  dashboard: routesMetadata["dashboard"],
  assistantDashboard: routesMetadata["assistant-dashboard"],
  assistantDashboardV2: routesMetadata["assistant-dashboard-v2"],
  mySchedule: routesMetadata["my-schedule"],
  staffSchedule: routesMetadata["book-appointment"],
  staffDashboard: routesMetadata["staff-dashboard"],
  platformAdminDashboard: routesMetadata["platform-admin-dashboard"],
  entityOwnerDashboard: routesMetadata["entity-owner-dashboard"],
  branchAdminDashboard: routesMetadata["branch-admin-dashboard"],
  practitionerDashboard: routesMetadata["practitioner-dashboard"],
  entity: {
    index: routesMetadata["entity-index"],
    onboard: routesMetadata["entity-onboard"],
    // view: `${ROOTS.ENTITY}/view/:entityId/:entityName`,
    manage: routesMetadata["manage-entity"],
  },
  branch: {
    index: routesMetadata["branch-index"],
    onboard: routesMetadata["branch-onboard"],
    view: routesMetadata["branch-view"],
  },
  staff: {
    index: routesMetadata["staff-index"],
    onboard: routesMetadata["staff-onboard"],
    view: routesMetadata["staff-view"],
    entityManage: routesMetadata["user-manage-entity"],
    branchManage: routesMetadata["user-manage-branch"],
    profileView: routesMetadata["user-profile-view"],
  },
  patient: {
    index: routesMetadata["patient-index"],
    onboard: routesMetadata["patient-onboard"],
    manage: routesMetadata["patient-manage"],
    manageV2: routesMetadata["patient-manage-v2"],
    masterAssessment: routesMetadata["patient-master-assessment"],
    physicalExamination: routesMetadata["patient-physical-examination"],
  },
  appointment: {
    index: routesMetadata["appointment-index"],
    book: routesMetadata["book-appointment"],
    staff: routesMetadata["staff-calender"],
    single: routesMetadata["single-calender"],
  },
  calander: {
    staff: routesMetadata["staff-calender"],
    single: routesMetadata["single-calender"],
  },
  exercise: {
    index: routesMetadata["exercise-index"],
    onboard: routesMetadata["exercise-create"],
    view: routesMetadata["exercise-view"],
  },
  grituplibrary: {
    index: routesMetadata["gritup-library"],
    view: routesMetadata["gritup-library-view"],
  },
  expense: {
    index: routesMetadata["expenses"],
  },
  invoices: {
    index: routesMetadata["invoices"],
  },
  billing: {
    index: routesMetadata["billing"],
  },
  credits: {
    index: routesMetadata["credits"],
  },
  referralBilling: {
    index: routesMetadata["referralBilling"],
  },
};

export function getPageTitleByPath(path: string) {
  const pathArray = path.split("/");
  const pathName = pathArray[pathArray.length - 1];
  const pathNameArray = pathName.split("-");
  const pathNameArrayCapitalized = pathNameArray.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return pathNameArrayCapitalized.join(" ");
}
