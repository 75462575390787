import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import SolidButton, { ButtonVariant } from "@components/button/button";
import { NewMasterAssessmentData } from "../types/NewMasterAssessment";
import PdfExportDialog from "./PdfExportDialog";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import { endpoints, replaceParams } from "@utils/axios";
import axios from "axios";
import { PersonalInformationDetails } from "../types/PatientPersonalDetails";
import moment from "moment";
import { toast } from "react-toastify";
import { OutlinedButton } from "@components/button";
import ExaminationReportHtml from "./ExaminationReportHtml";
import Modal from "@components/modal";

export interface Assessments {
	[key: string]: AssessmentDetails[];
}

interface AssessmentDetails {
	name: string;
	isChecked: boolean;
}

type PDFProps = {
	isOpen: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	patientId: string;
	masterAssessmentData: NewMasterAssessmentData | null;
};

const PdfCheckboxDialog = (props: PDFProps) => {
	const userData = getUserPersistedOnLocalStorage();
	var checklistData: any = {
		medicalHistory: [
			{ name: "Chief Complaint", isChecked: false },
			{ name: "History of Present Illness", isChecked: false },
			{ name: "History of Past Illness", isChecked: false },
			{ name: "Pre Existing Health Conditions", isChecked: false },
			{ name: "Past Treatment", isChecked: false },
			{ name: "Surgeries", isChecked: false },
			{ name: "Family Medical History", isChecked: false },
		],
		physicalAssessments: [],
		diagnosis: [
			// { name: "Chief Complaint", isChecked: false },
			{ name: "Provisional Diagnosis", isChecked: false },
			{ name: "Differential Diagnosis", isChecked: false },
			{ name: "Final Diagnosis", isChecked: false },
			{ name: "Radiological Findings", isChecked: false },
			{ name: "Medial Findings", isChecked: false },
		],
		treatmentPlan: [
			{ name: "Prescription", isChecked: false },
			{ name: "Exercise Library", isChecked: false },
			{ name: "Medical Prescription", isChecked: false },
			{ name: "Goals", isChecked: false },
		],
	};

	const [assessmentData, setAssessmentData] = useState<Assessments | null>(checklistData);
	const [isPdfOpen, setIsPdfOpen] = React.useState(false);
	const [selectAll, setSelectAll] = useState(false);

	const handleCheckboxChange = (section: string, index: number) => {
		setAssessmentData((prevData) => {
			if (prevData) {
				const newData = { ...prevData };
				newData[section][index].isChecked = !newData[section][index].isChecked;
				return newData;
			}
			return prevData;
		});
	};

	const handleSelectAll = () => {
		const updatedData = { ...assessmentData };
		for (const section in updatedData) {
			updatedData[section as keyof Assessments] = updatedData[section as keyof Assessments].map((item) => ({
				...item,
				isChecked: !selectAll,
			}));
		}
		setAssessmentData(updatedData);
		setSelectAll(!selectAll);
	};

	function closeModal(value: boolean): void {}

	React.useEffect(() => {
		if (props.masterAssessmentData?.vitalAssessmentId !== "000000000000000000000000") {
			checklistData.physicalAssessments.push({
				name: "Vitals",
				isChecked: false,
			});
		}

		if (props.masterAssessmentData?.painAssessmentId !== "000000000000000000000000") {
			checklistData.physicalAssessments.push({
				name: "Pain",
				isChecked: false,
			});
		}

		if (props.masterAssessmentData?.rangeOfMotionAssessmentId !== "000000000000000000000000") {
			checklistData.physicalAssessments.push({
				name: "Range of Motion",
				isChecked: false,
			});
		}

		if (props.masterAssessmentData?.adlAssessmentId !== "000000000000000000000000") {
			checklistData.physicalAssessments.push({
				name: "ADL",
				isChecked: false,
			});
		}

		if (props.masterAssessmentData?.customAssessmentId !== "000000000000000000000000") {
			checklistData.physicalAssessments.push({
				name: "Custom",
				isChecked: false,
			});
		}

		if (props.masterAssessmentData?.postureAssessmentId !== "000000000000000000000000") {
			checklistData.physicalAssessments.push({
				name: "Posture",
				isChecked: false,
			});
		}

		if (props.masterAssessmentData?.gaitAssessmentId !== "000000000000000000000000") {
			checklistData.physicalAssessments.push({
				name: "Gait",
				isChecked: false,
			});
		}

		if (props.masterAssessmentData?.muscleToneAssessmentId !== "000000000000000000000000") {
			checklistData.physicalAssessments.push({
				name: "Muscle Tone",
				isChecked: false,
			});
		}

		if (props.masterAssessmentData?.reflexAssessmentId !== "000000000000000000000000") {
			checklistData.physicalAssessments.push({
				name: "Reflex",
				isChecked: false,
			});
		}

		if (props.masterAssessmentData?.oedemaAssessmentId !== "000000000000000000000000") {
			checklistData.physicalAssessments.push({
				name: "Oedema",
				isChecked: false,
			});
		}

		if (props.masterAssessmentData?.limbLengthAssessmentId !== "000000000000000000000000") {
			checklistData.physicalAssessments.push({
				name: "Limb Length",
				isChecked: false,
			});
		}

		if (props.masterAssessmentData?.limbGirthAssessmentId !== "000000000000000000000000") {
			checklistData.physicalAssessments.push({
				name: "Limb Girth",
				isChecked: false,
			});
		}

		if (props.masterAssessmentData?.muscleWastingAssessmentId !== "000000000000000000000000") {
			checklistData.physicalAssessments.push({
				name: "Muscle Wasting",
				isChecked: false,
			});
		}

		if (props.masterAssessmentData?.sensoryAssessmentId !== "000000000000000000000000") {
			checklistData.physicalAssessments.push({
				name: "Sensory",
				isChecked: false,
			});
		}

		if (props.masterAssessmentData?.balanceAssessmentId !== "000000000000000000000000") {
			checklistData.physicalAssessments.push({
				name: "Balance",
				isChecked: false,
			});
		}

		if (props.masterAssessmentData?.muscleTightnessAssessmentId !== "000000000000000000000000") {
			checklistData.physicalAssessments.push({
				name: "Muscle Tightness",
				isChecked: false,
			});
		}

		if (props.masterAssessmentData?.muscleStrengthAssessmentId !== "000000000000000000000000") {
			checklistData.physicalAssessments.push({
				name: "Muscle Strength",
				isChecked: false,
			});
		}

		if (props.masterAssessmentData?.scarAssessmentId !== "000000000000000000000000") {
			checklistData.physicalAssessments.push({
				name: "Scar",
				isChecked: false,
			});
		}

		if (props.masterAssessmentData?.specialTestsAssessmentId !== "000000000000000000000000") {
			checklistData.physicalAssessments.push({
				name: "Special Test",
				isChecked: false,
			});
		}

		if (props.masterAssessmentData?.nerveTestAssessmentId !== "000000000000000000000000") {
			checklistData.physicalAssessments.push({
				name: "Nerve Test",
				isChecked: false,
			});
		}

		if (props.masterAssessmentData?.structuralDeformitiesAssessmentId !== "000000000000000000000000") {
			checklistData.physicalAssessments.push({
				name: "Structural Deformities",
				isChecked: false,
			});
		}

		setAssessmentData(checklistData);
	}, [props.isOpen]);

	return (
		<>
			<Transition appear show={props.isOpen} as={Fragment}>
				<Dialog as="div" className="relative z-50" onClose={closeModal}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black/25" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
									<Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-1100">
										<h4 className="font-medium text-md mb-1">Examination Report</h4>

										<p className="text-grey-500 text-sm">Please select the fields you would like to download</p>
									</Dialog.Title>
									<div className="mt-2 w-full">
										<ul>
											<li className="flex flex-row items-center justify-between">
												<span className="font-bold">Select All</span>
												<input
													type="checkbox"
													checked={selectAll}
													onChange={handleSelectAll}
													className="focus:outline-none"
												></input>
											</li>
										</ul>
										<span className="font-bold">1. Medical History</span>
										<ul>
											{assessmentData &&
												assessmentData.medicalHistory.map((e, index) => (
													<li className="flex flex-row items-center justify-between">
														<p>{e.name}</p>
														<input
															type="checkbox"
															checked={e.isChecked}
															onChange={() => handleCheckboxChange("medicalHistory", index)}
															className="focus:outline-none"
														></input>
													</li>
												))}
										</ul>
										{assessmentData?.physicalAssessments.length !== 0 && (
											<div>
												<span className="font-bold">2. Physical Assessments</span>
												<ul>
													{assessmentData &&
														assessmentData.physicalAssessments.map((e, index) => (
															<li className="flex flex-row items-center justify-between">
																<p>{e.name}</p>
																<input
																	type="checkbox"
																	checked={e.isChecked}
																	onChange={() => handleCheckboxChange("physicalAssessments", index)}
																	className="focus:outline-none"
																></input>
															</li>
														))}
												</ul>
											</div>
										)}
										<span className="font-bold">
											{assessmentData?.physicalAssessments.length === 0 ? <>2.</> : <>3.</>} Diagnosis
										</span>
										<ul>
											{assessmentData &&
												assessmentData.diagnosis.map((e, index) => (
													<li className="flex flex-row items-center justify-between">
														<p>{e.name}</p>
														<input
															type="checkbox"
															checked={e.isChecked}
															onChange={() => handleCheckboxChange("diagnosis", index)}
															className="focus:outline-none"
														></input>
													</li>
												))}
										</ul>
										{assessmentData?.physicalAssessments.length === 0 ? <>3.</> : <>4.</>}{" "}
										<span className="font-bold"> Treatment Plan</span>
										<ul>
											{assessmentData &&
												assessmentData.treatmentPlan.map((e, index) => (
													<li className="flex flex-row items-center justify-between">
														<p>{e.name}</p>
														<input
															type="checkbox"
															checked={e.isChecked}
															onChange={() => handleCheckboxChange("treatmentPlan", index)}
														></input>
													</li>
												))}
										</ul>
									</div>

									<div className="mt-4 flex flex-row justify-between">
										<div className="relative inline-block">
											{/* Coming Soon Border Animation */}
											<div className="absolute inset-0 border-2 border-dashed border-green-500 animate-pulse rounded-lg" />

											{/* Main Button */}
											<button
												className="relative px-4 py-3 bg-green-600 
                   text-white font-semibold rounded-lg 
                   flex items-center gap-2 transition-colors
                   m-1 text-xs " // margin to show animated border
												disabled
											>
												{/* WhatsApp Icon */}
												<svg
													fill="#ffffff"
													height="200px"
													width="200px"
													version="1.1"
													id="Layer_1"
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 308 308"
													stroke="#ffffff"
													className="w-4 h-4"
												>
													<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
													<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
													<g id="SVGRepo_iconCarrier">
														{" "}
														<g id="XMLID_468_">
															{" "}
															<path
																id="XMLID_469_"
																d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156 c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687 c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887 c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153 c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348 c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802 c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922 c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0 c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458 C233.168,179.508,230.845,178.393,227.904,176.981z"
															></path>{" "}
															<path
																id="XMLID_470_"
																d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716 c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396 c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188 l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677 c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867 C276.546,215.678,222.799,268.994,156.734,268.994z"
															></path>{" "}
														</g>{" "}
													</g>
												</svg>
												<span className="text-xs py-1">Share on WhatsApp</span>
												{/* Coming Soon Label */}
												<span className="absolute -top-3 -right-3 bg-gray-800 text-xs text-gray-50 px-2 py-1 rounded-full">
													Coming Soon
												</span>
											</button>
										</div>

										<div>
											<SolidButton
												variant={ButtonVariant.PRIMARY}
												className="mr-2"
												onClick={() => {
													props.setIsOpen(false);
													setAssessmentData(checklistData);
													setSelectAll(false);
												}}
											>
												Close
											</SolidButton>
											<SolidButton
												variant={ButtonVariant.PRIMARY}
												className="mr-2"
												onClick={() => {
													setIsPdfOpen(true);
												}}
											>
												Download / Print
											</SolidButton>
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
			<PdfExportDialog
				isOpen={isPdfOpen}
				setIsOpen={setIsPdfOpen}
				patientId={props.patientId ?? ""}
				masterAssessmentData={props.masterAssessmentData}
				assessmentData={assessmentData}
				setIsCheckboxDialogOpen={props.setIsOpen}
				setAssessmentData={setAssessmentData}
				setSelectAll={setSelectAll}
			></PdfExportDialog>
		</>
	);
};

export default PdfCheckboxDialog;
