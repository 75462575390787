import React, { useState } from "react";
import { ChevronDown, Plus, Trash2 } from "lucide-react";
import { endpoints, replaceParams } from "@utils/axios";
import axios from "axios";
import { toast } from "react-toastify";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import Button, { ButtonVariant } from "@components/button/outline-button";

interface MedicationRow {
	medicine: string;
	dose: string;
	when: string;
	frequency: string;
	duration: string;
	notes: string;
}

const DOSE_OPTIONS = ["1-0-0", "1-1-1", "1-0-1", "0-0-1", "0-1-0", "1-1-0", "0-1-1"];
const FREQUENCY_OPTIONS = ["1 day", "1 week", "1 Month", "1 Year"];
const DURATION_OPTIONS = [
	"Daily",
	"Alternate Day",
	"Weekly",
	"Fort Night",
	"Monthly",
	"Stat",
	"SOS",
	"Weekly Twice",
	"Weekly Thrice",
];

const WHEN = [
	"Before Food",
	"After Food",
	"Before Breakfast",
	"After Breakfast",
	"Before Lunch",
	"After Lunch",
	"Before Dinner",
	"After Dinner",
	"Enpty Stomach",
	"Bed Time",
];

function Medications({ treatmentPlanId }: { treatmentPlanId: string }) {
	const userData = getUserPersistedOnLocalStorage();
	const [medications, setMedications] = useState<MedicationRow[]>([
		{
			medicine: "",
			dose: DOSE_OPTIONS[0],
			when: "",
			frequency: FREQUENCY_OPTIONS[0],
			duration: DURATION_OPTIONS[0],
			notes: "",
		},
	]);

	const addRow = () => {
		setMedications([
			...medications,
			{
				medicine: "",
				dose: DOSE_OPTIONS[0],
				when: "",
				frequency: FREQUENCY_OPTIONS[0],
				duration: DURATION_OPTIONS[0],
				notes: "",
			},
		]);
	};

	const removeRow = (index: number) => {
		if (medications.length > 1) {
			setMedications(medications.filter((row, idx) => idx !== index));
		}
	};

	const updateRow = (index: number, field: keyof MedicationRow, value: string) => {
		setMedications(medications.map((row, idx) => (idx === index ? { ...row, [field]: value } : row)));
	};

	const handleSaveRequest = () => {
		async function handlePOST() {
			try {
				let paramsMap = new Map<string, string>([["id", treatmentPlanId]]);
				let endpoint = replaceParams(endpoints.patient.addMedication, paramsMap);
				let request = await axios.post(endpoint, medications);
				if (request.status == 200) {
					toast.success("Medication have been added successfully");
				} else {
					toast.error(request.statusText);
				}
			} catch (error) {
				toast.error(error.message);
			}
		}

		handlePOST();
	};

	const handleGET = async () => {
		try {
			let paramsMap = new Map<string, string>([["id", treatmentPlanId]]);
			let endpoint = replaceParams(endpoints.patient.getMedications, paramsMap);
			let request = await axios.get(endpoint);

			if (request.status === 200) {
				if (request.data["data"]["medication"] != null) {
					setMedications(request.data["data"]["medication"]);
				}
			}
		} catch (error) {
			toast.error(error.message);
		}
	};

	React.useEffect(() => {
		handleGET();
	}, []);

	return (
		<div className="min-h-screen bg-gray-50 p-4">
			<div className="max-w-[1920px] mx-auto">
				<div className="bg-white rounded-lg shadow-lg overflow-hidden">
					<div className="overflow-x-auto">
						<table className="w-full divide-y divide-gray-200">
							<thead>
								<tr className="bg-gray-100">
									<th className="pl-2 pr-1 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider w-[7%]">
										Sr No
									</th>
									<th className="px-1 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider w-[20%]">
										Medicine
									</th>
									<th className="px-1 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider w-[10%]">
										Dose
									</th>
									<th className="px-1 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider w-[15%]">
										When
									</th>
									<th className="px-1 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider w-[15%]">
										Frequency
									</th>
									<th className="px-1 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider w-[15%]">
										Duration
									</th>
									<th className="px-1 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider w-[15%]">
										Notes
									</th>
									<th className="p2-2 pl-1 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider w-[5%]">
										Action
									</th>
								</tr>
							</thead>
							<tbody className="bg-white divide-y divide-gray-200">
								{medications.map((row, index) => (
									<tr key={index} className="hover:bg-gray-50">
										<td className="pl-2 pr-1 py-4 text-sm text-gray-900">{index + 1}</td>
										<td className="px-1 py-4">
											<input
												type="text"
												value={row.medicine}
												onChange={(e) => updateRow(index, "medicine", e.target.value)}
												className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-base py-2"
												placeholder="Enter medicine name"
											/>
										</td>
										<td className="px-1 py-4">
											<div className="relative">
												<select
													value={row.dose}
													onChange={(e) => updateRow(index, "dose", e.target.value)}
													className="block w-full appearance-none rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-base py-2 pr-8"
													style={{ WebkitAppearance: "none", MozAppearance: "none" }}
												>
													{DOSE_OPTIONS.map((option) => (
														<option key={option} value={option}>
															{option}
														</option>
													))}
												</select>
											</div>
										</td>
										<td className="px-1 py-4">
											<div className="relative">
												<select
													value={row.when}
													onChange={(e) => updateRow(index, "when", e.target.value)}
													className="block w-full appearance-none rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-base py-2 pr-8"
													style={{ WebkitAppearance: "none", MozAppearance: "none" }}
												>
													{WHEN.map((option) => (
														<option key={option} value={option}>
															{option}
														</option>
													))}
												</select>
											</div>
										</td>
										<td className="px-1 py-4">
											<div className="relative">
												<select
													value={row.frequency}
													onChange={(e) => updateRow(index, "frequency", e.target.value)}
													className="block w-full appearance-none rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-base py-2 pr-8"
													style={{ WebkitAppearance: "none", MozAppearance: "none" }}
												>
													{FREQUENCY_OPTIONS.map((option) => (
														<option key={option} value={option}>
															{option}
														</option>
													))}
												</select>
											</div>
										</td>
										<td className="px-1 py-4">
											<div className="relative">
												<select
													value={row.duration}
													onChange={(e) => updateRow(index, "duration", e.target.value)}
													className="block w-full appearance-none rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-base py-2 pr-8"
													style={{ WebkitAppearance: "none", MozAppearance: "none" }}
												>
													{DURATION_OPTIONS.map((option) => (
														<option key={option} value={option}>
															{option}
														</option>
													))}
												</select>
											</div>
										</td>
										<td className="px-1 py-4">
											<input
												type="text"
												value={row.notes}
												onChange={(e) => updateRow(index, "notes", e.target.value)}
												className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-base py-2"
												placeholder="Enter notes"
											/>
										</td>
										<td className="pr-2 pl-1 py-4">
											<button
												onClick={() => removeRow(index)}
												className="text-red-600 hover:text-red-800 transition-colors p-2 rounded hover:bg-red-50"
												disabled={medications.length === 1}
											>
												<Trash2 className="h-5 w-5" />
											</button>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>

				<div className="mt-6 flex">
					<Button variant={ButtonVariant.PRIMARY} onClick={addRow}>
						<Plus className="h-5 w-5 mr-2" />
						Add Medicine
					</Button>
					<Button variant={ButtonVariant.PRIMARY} onClick={handleSaveRequest} className="ml-4">
						Save
					</Button>
				</div>
			</div>
		</div>
	);
}

export default Medications;
