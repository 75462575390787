import React, { useEffect, useState } from "react";
import { Button } from "@components/button";
import { ButtonVariant } from "@components/button/button";
import Applabel from "@components/hook-form/applabel";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import { endpoints, replaceParams } from "@utils/axios";
import axios from "axios";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ContentState, Editor, EditorState, convertFromHTML, convertToRaw } from "draft-js";
import RichTextEditor from "@components/rich-text-editor/RichTextEditor";
import draftToHtml from "draftjs-to-html";
import { Switch } from "@headlessui/react";

export default function LetterHeadPage({ branchId }: { branchId: string }) {
  const userData = getUserPersistedOnLocalStorage();
	const [headerEditorState, setHeaderEditorState] = React.useState(EditorState.createEmpty());

  const [headerValue, setHeaderValue] = useState("");
	const [footerValue, setFooterValue] = useState("");
	const [signatureEditorState, setSignatureEditorState] = React.useState(EditorState.createEmpty());
	const [headerEnabled, setHeaderEnabled] = useState(false);
	const [headerLogoEnabled, setHeaderLogoEnabled] = useState(false);
	const [hideHeader, setHideHeader] = useState(false);

	const [footerEnabled, setFooterEnabled] = useState(true);
	const [logoPreview, setLogoPreview] = useState<string | null>(null);
	const [footerSignaturePreview, setFooterSignaturePreview] = useState<string | null>(null);

	const [termsEnabled, setTermsEnabled] = useState(true);
	const [termsValue, setTermsValue] = useState("");

	const [logoPosition, setLogoPosition] = useState("start");

	const [includeLogoOnPage, setIncludeLogoOnPage] = useState(false);
	const [footerAlignment, setFooterAlignment] = useState("start");
	const [includeSignature, setIncludeSignature] = useState(false);

  useEffect(() => {
    getBranchLetterHead();
  }, []);

  const getBranchLetterHead = async () => {
    try {
      const paramsMap = new Map<string, string>([["id", branchId ?? ""]]);
			const filteredEndpoint = replaceParams(endpoints.branch.letterHead, paramsMap);

      const response = await axios.get(filteredEndpoint);

      const data = response.data["data"];

			if (data) {
				setHeaderValue(data.headerContent || "");
				setFooterValue(data.footerContent || "");
				setHeaderEnabled(data.isHeaderEnabled);
				setHeaderLogoEnabled(data.isHeaderLogoEnabled || false);
				setLogoPosition(data.headerLogoPosition || "start");
				setFooterEnabled(data.isFooterEnabled);
				setTermsEnabled(data.isTermsAndConditionsEnabled);
				setTermsValue(data.termsAndConditions || "");
				setIncludeLogoOnPage(data.includeLogoOnPage || false);
				// setFooterAlignment(data.footerAlignment || "start");
				setFooterAlignment(data.signaturePosition || "start");
				setIncludeSignature(data.isSignatureEnabled || false);
			}
    } catch (error) {
			console.error("Error fetching letterhead:", error);
			toast.error("Failed to fetch letterhead data");
    }
  };

  const handleSave = async () => {
    try {
			const requestBody = {
				isHeaderEnabled: headerEnabled,
				isHeaderLogoEnabled: headerLogoEnabled,
				headerContent: headerValue || "",

				logoPreview: logoPreview,
				includeLogoOnPage: includeLogoOnPage,
				headerLogoPosition: logoPosition,
				isFooterEnabled: footerEnabled,
				footerContent: footerValue || "",
				footerAlignment: footerAlignment,
				isSignatureEnabled: includeSignature,
				// signaturePreview: signaturePreview,

				signature: {
					name: "",
					designation: "",
					qualification: "",
					registrationNo: "",
					phone: "",
					email: "",
					imageId: "000000000000000000000000",
				},
				// signaturePosition: "",
				signaturePosition: footerAlignment,
				isTermsAndConditionsEnabled: termsEnabled,
				termsAndConditions: termsValue || "",
			};

      const paramsMap = new Map<string, string>([["id", branchId ?? ""]]);
			const filteredEndpoint = replaceParams(endpoints.branch.letterHead, paramsMap);
			const response = await axios.put(filteredEndpoint, requestBody);

      if (response.data.status === 200) {
        toast.success("Letterhead saved successfully.");
      } else {
        toast.error(response.data.message || "Failed to save letterhead.");
      }
    } catch (error) {
			console.error("Save error:", {
				message: error.message,
				status: error.response?.status,
				data: error.response?.data,
			});
      toast.error("Failed to save letterhead.");
    }
  };

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				setLogoPreview(reader.result as string);
			};
			reader.readAsDataURL(file);
		}
	};

	const handleFooterSignatureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				setFooterSignaturePreview(reader.result as string);
			};
			reader.readAsDataURL(file);
		}
	};

  return (
    <div className="flex flex-col">
			<div className="flex flex-col w-3/4">
				<div className="flex flex-col my-2 rounded-md p-4">
					<div className="flex justify-between items-center mb-2">
						<div>
							<h5 className="font-medium leading-6 text-gray-900">Header</h5>
						</div>
						<div className="text-sm font-medium text-gray-600 flex items-center gap-2">
							Hide header&nbsp;
							<Switch
								checked={headerEnabled}
								onChange={setHeaderEnabled}
								className={`${
									headerEnabled ? "bg-primary-600" : "bg-gray-200"
								} relative inline-flex h-6 w-11 items-center rounded-full`}
							>
								<span className="sr-only">Hide header</span>
								<span
									className={`${
										headerEnabled ? "translate-x-6" : "translate-x-1"
									} inline-block h-4 w-4 transform rounded-full bg-white transition`}
								/>
							</Switch>
						</div>
					</div>

					{headerEnabled ? (
						<>
							<ReactQuill
								theme="snow"
								value={headerValue}
								onChange={setHeaderValue}
								placeholder="Please add your header content here..."
								modules={{
									toolbar: [
										["bold", "italic", "underline"],
										[{ list: "ordered" }, { list: "bullet" }],
									],
								}}
							/>

							<div className="my-4 flex flex-col">
								<div className="mt-1 rounded-lg p-3">
									<div className="flex justify-between items-start mb-4">
										<div>
											<h5 className="text-lg font-semibold text-gray-900">Logo</h5>
											<p className="text-sm text-gray-500 mt-1">Configure the logo and settings for the clinic logo</p>
										</div>
									</div>

									<div className="grid grid-cols-12 gap-8">
										<div className="col-span-12 flex items-start">
											<div className="my-4 flex items-center">
												{/* Logo Preview section
												<div className="w-32 h-32 border-2 border-dashed border-gray-300 rounded-lg bg-white flex items-center justify-center overflow-hidden mr-4">
													{logoPreview ? (
														<img src={logoPreview} alt="Logo Preview" className="w-full h-full object-contain p-2" />
													) : (
														<div className="text-center p-4">
															<svg
																className="mx-auto h-8 w-8 text-gray-400"
																stroke="currentColor"
																fill="none"
																viewBox="0 0 48 48"
															>
																<path
																	d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4-4m4-12h8m-4-4v8m-12 4h.02"
																	strokeWidth="2"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
															</svg>
															<p className="mt-1 text-xs text-gray-500">No logo</p>
														</div>
													)}
												</div>
												*/}

												{/* Entity Logo Section
												<div className="flex-1 flex flex-col">
													<h6 className="text-sm font-medium text-gray-900 mb-1">Entity Logo</h6>
													<p className="text-sm text-gray-500 mb-3">Select .png, .jpg files to upload under 1 MB</p>
													<label className="inline-flex items-center px-3 py-1.5 bg-primary-600 text-white text-xs font-medium rounded-md hover:bg-primary-700 cursor-pointer transition-colors w-32">
														<svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
															<path
																strokeLinecap="round"
																strokeLinejoin="round"
																strokeWidth="2"
																d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0l-4 4m4-4v12"
															/>
														</svg>
														<span>Upload Logo</span>
														<input type="file" accept="image/*" className="hidden" onChange={handleFileChange} />
													</label>
												</div>
												*/}

												<div className="border-l border-gray-300 h-28 mx-4"></div>

												<div className="flex items-center mr-4">
													<div className="space-y-4">
														<div className="flex items-center">
															<input
																type="checkbox"
																id="isHeaderLogoEnabled"
																checked={headerLogoEnabled}
																onChange={(e) => setHeaderLogoEnabled(e.target.checked)}
																className="h-4 w-4 text-primary-600 rounded border-gray-300 focus:ring-primary-500"
															/>
															<label htmlFor="includelogo" className="ml-2 text-sm text-gray-700">
																Include logo on page
															</label>
														</div>
														<div>
															<label htmlFor="position" className="block text-sm font-medium text-gray-700 mb-1">
																Logo Position
															</label>
															<select
																id="position"
																value={logoPosition}
																onChange={(e) => setLogoPosition(e.target.value)}
																className="mt-1 block w-25 pl-3 pr-10 py-1.5 text-sm border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 rounded-md"
															>
																<option value="start">Start</option>
																<option value="middle">Middle</option>
																<option value="end">End</option>
															</select>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="relative mt-0">
								<div className="absolute inset-0 flex items-center" aria-hidden="true">
									<div className="w-full border-t border-gray-200"></div>
								</div>
							</div>
						</>
					) : (
						<div className="space-y-2 p-4 rounded-md">
							<p className="text-gray-500 text-base">Please enable header to configure.</p>
							<p className="text-sm text-gray-400 italic font-medium">
								Disabling the header will hide it in all printables.
							</p>
						</div>
					)}
				</div>

				<div className="flex flex-col my-1 rounded-md p-4">
					<div className="flex justify-between items-center mb-2">
						<div>
							<h5 className="font-medium leading-6 text-gray-900">Footer</h5>
						</div>
						<div className="text-sm font-medium text-gray-600 flex items-center gap-2">
							Hide footer&nbsp;
							<Switch
								checked={footerEnabled}
								onChange={setFooterEnabled}
								className={`${
									footerEnabled ? "bg-primary-600" : "bg-gray-200"
								} relative inline-flex h-6 w-11 items-center rounded-full`}
							>
								<span className="sr-only">Hide footer</span>
								<span
									className={`${
										footerEnabled ? "translate-x-6" : "translate-x-1"
									} inline-block h-4 w-4 transform rounded-full bg-white transition`}
								/>
							</Switch>
						</div>
					</div>

					{footerEnabled ? (
						<>
							<ReactQuill
								theme="snow"
								value={footerValue}
								onChange={setFooterValue}
								placeholder="Please add your footer content here..."
								modules={{
									toolbar: [
										["bold", "italic", "underline"],
										[{ list: "ordered" }, { list: "bullet" }],
									],
								}}
							/>

							<div className="my-4">
								<label htmlFor="footerPosition" className="block text-sm font-medium text-gray-700 mb-1">
									Footer Alignment
								</label>
								<select
									id="footerPosition"
									value={footerAlignment}
									onChange={(e) => setFooterAlignment(e.target.value)}
									className="mt-1 block w-25 pl-3 pr-10 py-1.5 text-sm border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 rounded-md"
								>
									<option value="start">Start</option>
									<option value="center">Center</option>
									<option value="end">End</option>
									<option value="justify">Justify</option>
								</select>
							</div>

							<div className="mt-1 rounded-lg p-4">
								<div className="flex justify-between items-start mb-4">
									<div>
										<h5 className="text-lg font-semibold text-gray-900">Digital Signature</h5>
										<p className="text-sm text-gray-500 mt-1">Configure the image and settings for the signature</p>
									</div>
								</div>

								<div className="my-2 flex items-center">
									{/* Signature Preview section
									<div className="w-32 h-32 border-2 border-dashed border-gray-300 rounded-lg bg-white flex items-center justify-center overflow-hidden mr-4">
										{footerSignaturePreview ? (
											<img
												src={footerSignaturePreview}
												alt="Footer Signature Preview"
												className="w-full h-full object-contain p-2"
											/>
										) : (
											<div className="text-center p-4">
												<svg
													className="mx-auto h-8 w-8 text-gray-400"
													stroke="currentColor"
													fill="none"
													viewBox="0 0 48 48"
												>
													<path
														d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4-4m4-12h8m-4-4v8m-12 4h.02"
														strokeWidth="2"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
												<p className="mt-1 text-xs text-gray-500">No signature</p>
											</div>
										)}
									</div>
									*/}

									<div className="flex p-4">
										{/* Signature Upload Section
										<div className="flex-1 flex flex-col">
											<h6 className="text-sm font-medium text-gray-900 mb-1">Footer Signature</h6>
											<p className="text-sm text-gray-500 mb-3">Select .png, .jpg files to upload under 1 MB</p>
											<label className="inline-flex items-center px-3 py-1.5 bg-primary-600 text-white text-xs font-medium rounded-md hover:bg-primary-700 cursor-pointer transition-colors w-fit">
												<span>Upload Signature</span>
												<input
													type="file"
													accept=".png, .jpg, .jpeg"
													className="hidden"
													onChange={handleFooterSignatureChange}
												/>
											</label>
										</div>
										*/}

										<div className="border-l border-gray-300 h-28 mx-4"></div>

										<div className="flex items-center">
											<div className="flex items-center">
												<input
													type="checkbox"
													id="includesignature"
													checked={includeSignature}
													onChange={(e) => setIncludeSignature(e.target.checked)}
													className="h-4 w-4 text-primary-600 rounded border-gray-300 focus:ring-primary-500"
												/>
												<label htmlFor="includesignature" className="ml-2 text-sm text-gray-700">
													Include signature on page
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="relative mt-2">
								<div className="absolute inset-0 flex items-center" aria-hidden="true">
									<div className="w-full border-t border-gray-200"></div>
								</div>
							</div>
						</>
					) : (
						<div className="space-y-2 p-4 rounded-md">
							<p className="text-gray-500 text-base">Please enable footer to configure.</p>
							<p className="text-sm text-gray-400 italic font-medium">
								Disabling the footer will hide it in all printables.
							</p>
						</div>
					)}
				</div>

				<div className="flex flex-col rounded-md p-4 pt-4">
					<div className="flex justify-between items-center mb-2">
						<div>
							<h5 className="font-medium leading-6 text-gray-900">Terms & Conditions</h5>
						</div>
						<div className="text-sm font-medium text-gray-600 flex items-center gap-2">
							Hide Terms&nbsp;
							<Switch
								checked={termsEnabled}
								onChange={setTermsEnabled}
								className={`${
									termsEnabled ? "bg-primary-600" : "bg-gray-200"
								} relative inline-flex h-6 w-11 items-center rounded-full`}
							>
								<span className="sr-only">Hide Terms</span>
								<span
									className={`${
										termsEnabled ? "translate-x-6" : "translate-x-1"
									} inline-block h-4 w-4 transform rounded-full bg-white transition`}
								/>
							</Switch>
						</div>
					</div>

					{termsEnabled ? (
						<div className="mb-16">
							<ReactQuill
								theme="snow"
								value={termsValue}
								onChange={setTermsValue}
								placeholder="Please add your terms and conditions here..."
								modules={{
									toolbar: [
										["bold", "italic", "underline"],
										[{ list: "ordered" }, { list: "bullet" }],
									],
								}}
							/>
        </div>
					) : (
						<p className="text-gray-500 mb-16">Please enable terms and conditions to configure.</p>
					)}
        </div>
      </div>
      <div className="my-4">
        <div className="fixed items-start w-full justify-end bottom-0 h-16 z-50 shrink-0 mt-4 p-4 -ml-4 lg:-ml-8 shadow bg-gray-100">
          <Button
            variant={ButtonVariant.PRIMARY}
            onClick={() => {
              handleSave();
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}
