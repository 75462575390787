import Button, { ButtonVariant } from "@components/button/button";
import { Dialog, Transition } from "@headlessui/react";
import { endpoints, replaceParams } from "@utils/axios";

import React, { Fragment, useEffect } from "react";

import { Appointment } from "../types/Appointment";

import moment from "moment";

import { RescheduleAppointmentDto } from "../types/RescheduleAppointmentDto";
import { toast } from "react-toastify";
import useAxios from "@routes/hooks/use-axios";

import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { ValidationError } from "@components/hook-form";
import { OutlinedButton } from "@components/button";
import { CancelTokenSource } from "axios";
import Applabel from "@components/hook-form/applabel";
import { AvailableTimeSlotConvert, AvailableTimeSlots, Freeslot } from "../types/AvailableTimeSlots";

interface AppointmentData {
	AppointmentDuration: number[];
	AppointmentType: string[];
	appointmentString: string[];
}

export const AppointmentSchema = z.object({
	userIndexId: z.string().min(1, { message: "Please select Consultant/Therapist " }),
});

export type AppointmentType = z.infer<typeof AppointmentSchema>;

function TransferAppointmentPage(props: {
	onClose: () => void;
	appointmentDetails: Appointment;
	getAppointments: () => Promise<void>;
}) {
	const userData = getUserPersistedOnLocalStorage();
	const [loading, setLoading] = React.useState(true);
	const [error, setError] = React.useState("");
	const [postInProgress, setPostInProgress] = React.useState<boolean>(false);
	const cancelToken = React.useRef<CancelTokenSource | null>(null);
	const [selectedEntityUsername, setSelectedEntityUsername] = React.useState<string | null>(null);
	const [selectedEntityUserId, setSelectedEntityUserId] = React.useState<string | null>(null);
	const [selectedEntityUserPhone, setSelectedEntityUserPhone] = React.useState<number | null>(null);
	const [appointmentDuration, setAppointmentDuration] = React.useState(0);
	const [selectAppointmentType, setSelectApppointmentType] = React.useState("");
	const [selectedUser, setSelectedUser] = React.useState("");

	const [appointmentTypeData, setAppointmentTypeData] = React.useState<AppointmentData | null>(null);
	const [availableTimeSlots, setAvailableTimeSlots] = React.useState<AvailableTimeSlots[] | null>(null);
	const [availableTimeSlot, setAvailableTimeSlot] = React.useState<AvailableTimeSlots | null>(null);

	const axios = useAxios();

	const [multipleBookingWarning, setMultipleBookingWarning] = React.useState(false);

	const {
		register,
		handleSubmit,
		watch,
		reset,
		control,
		setValue,
		clearErrors,
		formState: { errors },
	} = useForm<AppointmentType>({ resolver: zodResolver(AppointmentSchema) });

	useEffect(() => {
		const getAvailableTimeSlots = async () => {
			try {
				let duration: number;
				if (props.appointmentDetails.endTimeMinutes > props.appointmentDetails.startTimeMinutes)
					duration = props.appointmentDetails.endTimeMinutes - props.appointmentDetails.startTimeMinutes;
				else duration = props.appointmentDetails.startTimeMinutes - props.appointmentDetails.endTimeMinutes;

				const response = await axios.get(endpoints.appointment.availableTimeSlots, {
					params: {
						requestedDate: moment(new Date(props.appointmentDetails.apptdate)).format("DD-MM-YYYY"),
						requestedAppointmentDuration: appointmentDuration,
						requestedSlotInterval: 15,
					},
				});
				const data = response.data["data"]["availableEntityUsers"];
				const userData = getUserPersistedOnLocalStorage();

				if (data != null) {
					const convertedAvailableTimeSlots = data.map((slot: any) => {
						return AvailableTimeSlotConvert.toAvailableTimeSlots(JSON.stringify(slot));
					});
					const timeZone = moment.tz.guess();
					const convertedAvailableTimeSlotsLocalTime = convertedAvailableTimeSlots.map(
						(available: AvailableTimeSlots) => {
							return available.freeslots.map((freeslot: Freeslot) => {
								const startMoment = moment.utc(freeslot.startTime, "HH:mm A");
								freeslot.startTime = startMoment.tz(timeZone).format("hh:mm A");
								const endMoment = moment.utc(freeslot.endTime, "HH:mm A");
								freeslot.endTime = endMoment.tz(timeZone).format("hh:mm A");

								return freeslot;
							});
						}
					);

					setAvailableTimeSlots(convertedAvailableTimeSlots);
					setLoading(false);
				} else {
					setLoading(false);
					setAvailableTimeSlots(null);
				}
			} catch (error) {
				console.error("Error fetching available time slots...", error);
				setError(error.message);
				// setAvailableTimeSlots(null);
				setLoading(false);
			}
		};

		getAvailableTimeSlots();
	}, []);

	const onSubmit: SubmitHandler<AppointmentType> = async (parseData) => {
		if (postInProgress) return;
		if (cancelToken.current) {
			cancelToken.current.cancel("Request already in progress!");
		}
		cancelToken.current = axios.CancelToken.source();

		try {
			setPostInProgress(true);

			AppointmentSchema.parse(parseData);

			let data = {
				entityUserId: availableTimeSlot?.entityUserId,
				entityUserName: availableTimeSlot?.entityUserName,
			};

			const paramsMap = new Map<string, string>([["id", props.appointmentDetails.id]]);
			const filteredEndpoint = replaceParams(endpoints.appointment.transfer, paramsMap);
			await axios.put(filteredEndpoint, data, {
				cancelToken: cancelToken.current.token,
			});
			setPostInProgress(false);
			closeModal();

			props.getAppointments();
			toast.success("Appointment Transfered Successfully");
		} catch (error) {
			console.error("Error Transferring Appointment:", error);
			toast.error(error["response"]["data"]["data"]);
			setPostInProgress(false);
		}
	};

	let [isOpen, setIsOpen] = React.useState(true);

	function closeModal() {
		setIsOpen(false);
		props.onClose();
	}

	// //   e: React.ChangeEvent<HTMLSelectElement>,
	// // ) => {
	// //   setSelectedEntityUserId('');
	// //   setSelectedTime('');
	// //   setValue('physioId', '');
	// //   setValue('startTime', '');
	// //   setMultipleBookingWarning(false);
	// //   setAppointmentType(Number(e.target.value));
	// //   clearErrors('appointmentType');
	// // };
	// const handleUserChange = (
	//   e: React.ChangeEvent<HTMLSelectElement>,
	//   keys: string[],
	//   values: number[],
	// ) => {
	//   const selectedValue = e.target.value;
	//   const selectedIndex = values.indexOf(selectedValue);

	//   if (selectedIndex !== -1) {
	//     const selectedKey = keys[selectedIndex];
	//     setSelectApppointmentType(selectedKey.toString());
	//     setAppointmentDuration(selectedValue);
	//     clearErrors('appointmentType');
	//   }
	// };

	return (
		<>
			<Transition appear show={isOpen} as={Fragment}>
				<Dialog as="div" className="relative z-50" onClose={closeModal}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black bg-opacity-25" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
									<Dialog.Title as="h3" className="text-xl font-bold leading-6 px-6 pt-6 text-gray-900">
										Transfer Appointment
									</Dialog.Title>
									<form onSubmit={handleSubmit(onSubmit)} noValidate>
										<div className="max-h-[75vh] overflow-auto">
											<div className="flex flex-col w-full h-full px-6 py-2 space-y-2">
												<div className="w-full sm:w-1/2 mb-4 sm:mb-0">
													<div className="flex flex-col">
														<h1 className="text-lg font-semibold">Patient Information</h1>
														<span className="text-sm">{props.appointmentDetails.clientName}</span>
														<span className="text-sm">
															Contact Number:{" "}
															{userData?.user_role === "ENTITY_OWNER" || userData?.user_role === "PLATFORM_ADMIN" ? (
																<span>{props.appointmentDetails?.clientPhone}</span>
															) : props.appointmentDetails?.clientPhone.toString().length > 4 ? (
																<span>{props.appointmentDetails?.clientPhone}</span>
															) : (
																<span>******{props.appointmentDetails?.clientPhone}</span>
															)}
														</span>
													</div>
												</div>
												<div className="w-full sm:w-1/2">
													<div className="flex flex-col">
														<h1 className="text-lg font-semibold">Appointment Information</h1>
														<span className="text-sm">Date: {props.appointmentDetails.date}</span>
														<span className="text-sm">Slot: {props.appointmentDetails.startTime}</span>
														<span className="text-sm">Type: {props.appointmentDetails.appointmentType}</span>
													</div>
												</div>

												<div className="flex flex-col">
													<h1 className="text-lg font-semibold">Previous Consulting Physiotherapist</h1>
													<span className="text-sm">{props.appointmentDetails.entityUserName}</span>
												</div>

												<div className="flex flex-col">
													<h1 className="text-lg font-semibold">Additional Information</h1>
													<span className="text-xs">
														Private note specific to the appointment for Physiotherapist reference
													</span>
													<span className="text-sm">
														{props.appointmentDetails.notes === ""
															? "No additional information provided"
															: props.appointmentDetails.notes}
													</span>
												</div>
												<div className="flex flex-col">
													<h1 className="text-lg font-semibold">Transfer To</h1>
													<select
														{...register("userIndexId")}
														name="userIndexId"
														className="lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
														required
														onChange={(e) => {
															if (availableTimeSlots && e.target.value !== "") {
																setAvailableTimeSlot(availableTimeSlots[Number(e.target.value)]);
																clearErrors("userIndexId");
															}
														}}
													>
														<option key="" value="">
															Consultant/Therapist
														</option>

														{availableTimeSlots?.map((user, index) => {
															if (user.entityUserId === props.appointmentDetails.entityUserId) return null;

															return (
																<option key={user.entityUserId} value={index}>
																	{user.entityUserName}
																</option>
															);
														})}
													</select>
													{errors.userIndexId && (
														<ValidationError message={errors.userIndexId?.message?.toString() ?? ""} />
													)}
												</div>
											</div>
										</div>
										<div className="flex flex-row rounded-bl-2xl rounded-br-2xl bg-gray-100 items-center p-4 mt-2">
											<div className="flex flex-row w-full justify-end">
												<OutlinedButton
													variant={ButtonVariant.PRIMARY}
													type="button"
													onClick={closeModal}
													children="Close"
													className="mr-2"
												/>
												<Button variant={ButtonVariant.SECONDARY} type="submit" children="Transfer Appointment" />
											</div>
										</div>
									</form>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	);
}

export default TransferAppointmentPage;
