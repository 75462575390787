import React, { useState } from "react";
import * as z from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Convert, PrescriptionDetails } from "../types/PrescriptionDetails";
import { endpoints, replaceParams } from "@utils/axios";
import axios from "axios";
import { ValidationError } from "@components/hook-form";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import { NewMasterAssessmentData } from "../types/NewMasterAssessment";
import OutReferralSearch from "../components/OutReferralSearch";
import { ConvertOutReferrals, OutReferrals } from "../types/OutReferrals";
import Select from "react-select";

interface PersonalInformationDetails {
  salutation: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  isActive: boolean;
  isOnboarded: boolean;
  phone: number;
  phoneCode: number;
  emergencyPhone: number;
  emergencyPhoneCode: number;
  birthPlace?: string;
  bloodGroup: string;
  address: Address;
  age: number;
  gender: string;
  dob: Date;
  notes?: string;
}

interface Address {
  address: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
}

interface ActiveExercise {
  id: string;
  title: string;
  instructions: string;
  config?: Config;
}
interface Config {
  sets?: number | undefined;
  repetitions?: number | undefined;
  hold?: number | undefined;
  interval?: number | undefined;
  side?: string | undefined;
  weights?: number | undefined;
  weightsUnit?: string | undefined;
  intervalUnit?: string | undefined;
  holdUnit?: string | undefined;
}

interface InitialData {
  EntityBranchAddress: EntityBranchAddress;
  EntityBranchName?: string;
  EntityBranchPhone: string;
  EntityName?: string;
  isEntityDetailsCompleted: boolean;
  isTherapiesConfigurationCompleted: boolean;
  entityTimeZone: string;
  isWorkingHoursConfigurationCompleted: boolean;
  isConsentCompleted: boolean;
  isEntityWorkingHoursConfigCompleted: boolean;
  isEntityBranchWorkingHoursConfigCompleted: boolean;
  RoleName?: string;
}

interface EntityBranchAddress {
  address: string;
  city: string;
  state: string;
  country: string;
  postalCode: number;
}

export const TherapySchema = z.object({
  id: z.string(),
  therapyName: z.string(),
});
export type Therapy = z.infer<typeof TherapySchema>;

export const AddNewPrescriptionDetailsSchema = z.object({
  advice: z.string().optional(),
  precautions: z.string().optional(),
  testadviced: z.string().optional(),
  outReferral: z.string().optional().default(""),
  sessionCount: z.coerce.number().optional(),
  sessionFrequency: z.coerce.number().optional(),
  sessionFrequencyUnit: z.string().optional(),
  type: z.string().optional(),
  prescribedThrapeuticProcedures: z.string().array().optional(),
  shortTermGoals: z.string().optional(),
  midTermGoals: z.string().optional(),
  longTermGoals: z.string().optional(),
});
export type PrescriptionDetailsType = z.infer<
  typeof AddNewPrescriptionDetailsSchema
>;

export function TreatmentPlanTab(props: {
  patientId: string;
  masterAssessmentData: NewMasterAssessmentData | null;
  getMasterAssessmentLedger: () => Promise<void>;
}) {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<PrescriptionDetailsType>({
    resolver: zodResolver(AddNewPrescriptionDetailsSchema),
  });
  const [formData, setFormData] = useState({});
  const [selectedTherapy, setSelectedTherapy] = useState<string[]>();
  const [updatedTherapy, setUpdatedTherapy] = useState<string[]>();

  const [radioValue, setRadioValue] = useState("Home");
  const [therapyList, setTherapyList] = useState<Therapy[]>([]);
  const [treatmentPlanData, setTreatmentPlanData] =
    useState<PrescriptionDetails>();
  const [patientPersonalInformation, setPatientPersonalInformation] =
    React.useState<PersonalInformationDetails | null>(null);
  const [activeExerciseProgram, setActiveExerciseProgram] = React.useState<
    ActiveExercise[] | null
  >(null);
  const [initialData, setInitialData] = useState<InitialData>();
  const userData = getUserPersistedOnLocalStorage();
  const [selectedOutReferralName, setSelectedOutReferralName] = useState("");
  const [referringData, setReferringData] = React.useState<OutReferrals | null>(
    null
  ); // This is the list of doctors
  const [isDisabled, setIsDisabled] = useState(false);

  const getEntityData = async () => {
    try {
      const paramsMap = new Map<string, string>([
        ["userid", userData?.user_id ?? ""],
      ]);
      const filteredEndpoint = replaceParams(
        endpoints.dashboardInitialData.initialData,
        paramsMap
      );
      const response = await axios.get(filteredEndpoint);
      const data = response.data["data"];
      if (data != null) {
        setInitialData(data);
      }
    } catch (error) {
      console.error("Error fetching staff data:", error);
    }
  };

  const getPatientData = async () => {
    try {
      const paramsMap = new Map<string, string>([
        ["patientId", props.patientId],
      ]);
      const filteredEndpoint = replaceParams(endpoints.patient.view, paramsMap);
      const response = await axios.get(filteredEndpoint);
      const data = response.data["data"];
      setPatientPersonalInformation(data);
    } catch (error) {
      console.error("Error getting patient information", error);
    }
  };

  const getAssignedExercises = async () => {
    try {
      const paramsMap = new Map<string, string>([
        ["id", props.masterAssessmentData?.treatmentPlanId!],
      ]);
      const response = await axios.get(
        replaceParams(endpoints.managePatient.getAssignedExercises, paramsMap)
      );
      const data = response.data["data"]["homeExerciseProgram"];

      const toSet: ActiveExercise[] = [];
      if (data !== null) {
        for (const exercise of data) {
          toSet.push(exercise);
        }
      }
      setActiveExerciseProgram(toSet);
    } catch (error) {
      console.error("Error fetching active exercise program:", error);
    }
  };

  const getTherapiesList = async () => {
    try {
      const userData = getUserPersistedOnLocalStorage();
      const paramsMap = new Map<string, string>([
        ["entityId", userData?.entity_id ?? ""],
      ]);
      const filteredEndpoint = replaceParams(
        endpoints.treatmentPlan.therapyList,
        paramsMap
      );
      const response = await axios.get(filteredEndpoint);
      const data = response.data["data"];
      if (data) {
        setTherapyList(data);
      } else {
        setTherapyList([]);
      }
    } catch (error) {
      console.error("Error getting treatment plans", error);
    }
  };

  const getTreatmentPlanData = async () => {
    try {
      const paramsMap = new Map<string, string>([
        ["id", props.masterAssessmentData?.treatmentPlanId ?? ""],
      ]);
      const filteredEndpoint = replaceParams(
        endpoints.treatmentPlan.view,
        paramsMap
      );
      const response = await axios.get(filteredEndpoint);
      const data = response.data["data"];
      const convertedTreatmentPlanData = Convert.toAddNewPrescriptionDetails(
        JSON.stringify(data)
      );

      if (data.outReferral != "") {
        setSelectedOutReferralName(data.outReferral);
      }

      if (convertedTreatmentPlanData.prescribedThrapeuticProcedures === null) {
        convertedTreatmentPlanData.prescribedThrapeuticProcedures = [];
      }

      if (convertedTreatmentPlanData.type === "") {
        convertedTreatmentPlanData.type = "Home";
      }

      reset({ ...convertedTreatmentPlanData });
      setSelectedTherapy(
        convertedTreatmentPlanData.prescribedThrapeuticProcedures
      );
      setRadioValue(convertedTreatmentPlanData.type);
      setTreatmentPlanData(convertedTreatmentPlanData);
    } catch (error) {
      setRadioValue("Home");
      setSelectedTherapy([]);
      reset({
        advice: "",
        longTermGoals: "",
        midTermGoals: "",

        precautions: "",
        prescribedThrapeuticProcedures: [],
        sessionCount: 0,
        sessionFrequency: 0,
        sessionFrequencyUnit: "",
        type: "Home",
        shortTermGoals: "",
      });
      console.error("Error fetching data:", error);
    }
  };

  React.useEffect(() => {
    getPatientData();
    getAssignedExercises();
    getEntityData();
    getTherapiesList();
    fetchOutReferrals();
  }, []);

  React.useEffect(() => {
    getTreatmentPlanData();
  }, [props.masterAssessmentData]);

  const onSubmit: SubmitHandler<PrescriptionDetailsType> = async (data) => {
    if (
      props.masterAssessmentData?.treatmentPlanId === "000000000000000000000000"
    ) {
      try {
        data.prescribedThrapeuticProcedures = selectedTherapy;
        const combinedData = { ...formData, ...data };
        AddNewPrescriptionDetailsSchema.parse(combinedData);

        const paramsMap = new Map<string, string>([
          ["mid", props.masterAssessmentData?.id!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.treatmentPlan.createPrescription,
          paramsMap
        );

        const response = await axios.post(filteredEndpoint, {
          advice: combinedData?.advice,
          precautions: combinedData.precautions,
          testadviced: combinedData.testadviced,
          type: combinedData.type ? combinedData.type : radioValue,
          prescribedThrapeuticProcedures:
            combinedData.prescribedThrapeuticProcedures,
          shortTermGoals: combinedData?.shortTermGoals,
          midTermGoals: combinedData?.midTermGoals,
          longTermGoals: combinedData?.longTermGoals,
          sessionCount: combinedData.sessionCount,
          sessionFrequency: combinedData.sessionFrequency,
          sessionFrequencyUnit: combinedData.sessionFrequencyUnit,
          outReferral: combinedData.outReferral,
        });
        props.getMasterAssessmentLedger();
        await getTreatmentPlanData();
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    } else {
      try {
        data.prescribedThrapeuticProcedures = selectedTherapy;
        const combinedData = { ...formData, ...data };
        AddNewPrescriptionDetailsSchema.parse(combinedData);
        const paramsMap = new Map<string, string>([
          ["id", props.masterAssessmentData?.treatmentPlanId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.treatmentPlan.update,
          paramsMap
        );
        const response = await axios.put(filteredEndpoint, {
          advice: combinedData?.advice,
          precautions: combinedData.precautions,
          testadviced: combinedData.testadviced,
          type: combinedData.type ? combinedData.type : radioValue,
          prescribedThrapeuticProcedures:
            combinedData.prescribedThrapeuticProcedures,
          shortTermGoals: combinedData?.shortTermGoals,
          midTermGoals: combinedData?.midTermGoals,
          longTermGoals: combinedData?.longTermGoals,
          sessionCount: combinedData.sessionCount,
          sessionFrequency: combinedData.sessionFrequency,
          sessionFrequencyUnit: combinedData.sessionFrequencyUnit,
          outReferral: combinedData.outReferral,
        });
        await getTreatmentPlanData();
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  React.useEffect(() => {
    /**
     * We cannot put selectedTherapy in useeffect becuase its udated form various palces.
     * So we use updatedTherapy as a proxy to trigger the onSubmit function.
     */
    if (updatedTherapy && updatedTherapy.length > 0) {
      handleSubmit(onSubmit)();
    }
  }, [updatedTherapy]);

  const fetchOutReferrals = async () => {
    try {
      const response = await axios.get(endpoints.treatmentPlan.getOutReferrals);

      const data = response.data["data"];
      const converted = ConvertOutReferrals.toOutReferrals(
        JSON.stringify(data)
      );

      setReferringData(converted);
    } catch (error) {
      console.error("Error fetching staff list:", error);
    }
  };

  const options = therapyList.map((therapy) => ({
    value: therapy.therapyName,
    label: therapy.therapyName,
  }));

  const selectedOptions = selectedTherapy?.map((therapy) => {
    return {
      value: therapy,
      label: therapy,
    };
  });

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      minHeight: "50px",
      padding: "5px",
      borderColor: "var(--primary-color)",
      boxShadow: "0 1px 2px rgba(0, 0, 0, 0.2)",
      "&:hover": {
        borderColor: "#004d40",
      },
      borderRadius: "8px",
    }),
    input: (provided: any) => ({
      ...provided,
      border: "none", // Remove the border from the input
      boxShadow: "none", // Remove any shadow from the input
    }),
    multiValue: (provided: any) => ({
      ...provided,
      borderColor: "var(--primary-color)",
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      fontWeight: "semibold",
    }),
    multiValueRemove: (provided: any) => ({
      ...provided,
      color: "#d32f2f",
      ":hover": {
        backgroundColor: "var(--primary-color)",
        color: "white",
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 9999,
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#e0f7fa" : "white",
      color: state.isSelected ? "#004d40" : "black",
      fontWeight: state.isSelected ? "bold" : "normal",
    }),
  };

  return (
    <div className="flex flex-col p-2">
      <form>
        <div className="flex flex-col">
          <div className="py-4 flex flex-col  w-3/4">
            <span className="flex-none font-medium text-sm mb-2">
              Modalities/Therapies
            </span>
            <Select
              closeMenuOnSelect={true}
              isMulti
              isSearchable={false}
              value={selectedOptions}
              options={options}
              styles={customStyles}
              isClearable={false}
              onChange={(selected) => {
                const selectedValues = selected.map((option) => option.value);
                // Update state and call onSubmit with the new values
                setSelectedTherapy(selectedValues);
                setUpdatedTherapy(selectedValues);
              }}
            />
          </div>

          <div>
            <div className="flex flex-col md:flex-row ">
              <div className="flex lg:basis-1/3 mt-2 gap-2">
                <span className="font-medium text-sm pt-2">
                  Recommended No. of Sessions
                </span>
                <input
                  className="border-b border-teal-500 w-1/5 text-gray-700 py-1 px-2 focus:outline-none font-medium text-sm leading-6 rounded-md"
                  {...register("sessionCount")}
                  type="number"
                  value={watch("sessionCount")}
                  onBlur={handleSubmit(onSubmit)}
                />
              </div>

              <div className="flex lg:basis-1/3 mt-2 gap-2">
                <span className="font-medium text-sm pt-2">Frequency</span>
                <input
                  className="border-b border-teal-500 w-1/5 text-gray-700 py-1 px-2 focus:outline-none font-medium text-sm leading-6 rounded-md"
                  {...register("sessionFrequency")}
                  type="number"
                  value={watch("sessionFrequency")}
                  onBlur={handleSubmit(onSubmit)}
                  // onKeyDown="return event.keyCode !== 69 && event.keyCode !== 189"
                />
                <span className="flex-none text-sm font-medium mb-0 text-center pt-2">
                  sessions per
                </span>
                <select
                  {...register("sessionFrequencyUnit")}
                  className={`appearance-none bg-transparent border-b border-teal-500 w-full lg:w-1/5 text-gray-700 py-1 px-2 focus:outline-none  font-medium text-sm leading-6 rounded-md`}
                  value={watch("sessionFrequencyUnit")}
                  onBlur={handleSubmit(onSubmit)}
                >
                  {["Week", "Month", "Year", "Days"].map(
                    (sessionFrequencyUnit) => {
                      return (
                        <option
                          key={sessionFrequencyUnit}
                          value={sessionFrequencyUnit}
                          className="p-2"
                        >
                          {sessionFrequencyUnit}
                        </option>
                      );
                    }
                  )}
                </select>
              </div>
            </div>
          </div>
          <div className="py-2 flex flex-col md:flex-row w-full mt-2 md:space-x-4">
            <div className="flex flex-col  w-full ">
              <span className="flex-none font-medium text-sm mb-2">
                Session Type
              </span>
              <div className="flex">
                {["Clinic", "Home", "Remote"].map((option) => (
                  <label
                    key={option}
                    className="flex-none font-medium text-sm mx-2"
                  >
                    <input
                      className={`ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 mr-1`}
                      type="radio"
                      name="radioValues"
                      onBlur={handleSubmit(onSubmit)}
                      value={option}
                      checked={radioValue === option}
                      onChange={(e) => {
                        setRadioValue(e.target.value),
                          setValue("type", e.target.value);
                      }}
                    />
                    {option}
                    <br />
                  </label>
                ))}
              </div>
              {errors.sessionFrequencyUnit && (
                <ValidationError
                  message={
                    errors.sessionFrequencyUnit?.message?.toString() ?? ""
                  }
                />
              )}
            </div>
          </div>
          <div className="py-2 flex flex-col md:flex-row w-full mt-4 md:space-x-4">
            <div className="flex flex-col  w-full ">
              <span className="flex-none font-medium text-sm">Advice</span>
              <div className="flex flex-row w-full mt-1">
                <textarea
                  {...register("advice")}
                  key="advice"
                  name="advice"
                  placeholder="Enter advice details "
                  rows={3}
                  value={watch("advice")}
                  onBlur={handleSubmit(onSubmit)}
                  className="block w-full rounded-lg border-0 py-2 ring-1 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                />
                {errors.advice && (
                  <ValidationError
                    message={errors.advice?.message?.toString() ?? ""}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="py-2 flex flex-col md:flex-row w-full mt-4 md:space-x-4">
            <div className="flex flex-col  w-full ">
              <span className="flex-none font-medium text-sm">Precaution</span>
              <div className="flex flex-row w-full mt-1">
                <textarea
                  {...register("precautions")}
                  key="precautions"
                  name="precautions"
                  placeholder="Enter details"
                  rows={3}
                  value={watch("precautions")}
                  onBlur={handleSubmit(onSubmit)}
                  className="block w-full rounded-lg border-0 py-2 ring-1 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                />
                {errors.precautions && (
                  <ValidationError
                    message={errors.precautions?.message?.toString() ?? ""}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="py-2 flex flex-col md:flex-row w-full mt-4 md:space-x-4">
            <div className="flex flex-col  w-full ">
              <span className="flex-none font-medium text-sm">
                Test Advised
              </span>
              <div className="flex flex-row w-full mt-1">
                <textarea
                  {...register("testadviced")}
                  key="testadviced"
                  name="testadviced"
                  placeholder="Enter details"
                  rows={3}
                  value={watch("testadviced")}
                  onBlur={handleSubmit(onSubmit)}
                  className="block w-full rounded-lg border-0 py-2 ring-1 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                />
                {errors.precautions && (
                  <ValidationError
                    message={errors.precautions?.message?.toString() ?? ""}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="py-2 flex flex-col md:flex-row w-full mt-4 md:space-x-4">
            <div className="flex flex-col  w-full ">
              <span className="flex-none font-medium text-sm">
                Out Referral
              </span>

              <div className="flex flex-row w-full mt-1">
                <OutReferralSearch
                  data={referringData?.OutReferrals || []}
                  setSelectedOutReferralName={setSelectedOutReferralName}
                  selectedOutReferralName={selectedOutReferralName}
                  register={register}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  handleSubmit={handleSubmit}
                  onSubmit={onSubmit}
                />
                {errors.precautions && (
                  <ValidationError
                    message={errors.precautions?.message?.toString() ?? ""}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
