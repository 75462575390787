import React, { useState, useCallback, useEffect } from "react";
import Select from "react-select/async";
import axios from "axios";
import { FixedSizeList as List } from "react-window";
import { endpoints } from "@utils/axios";
import { Patient } from "@common/types/Patient";

const PatientSearchV2 = ({
	onSelect,
	patient,
	key,
}: {
	onSelect: (patient: Patient | null) => void;
	patient: Patient | null | undefined;
	key: string;
}) => {
	const [options, setOptions] = useState<Patient[]>([]);
	const [cursor, setCursor] = useState(null);
	const [hasMore, setHasMore] = useState(true);
	const isFetching = React.useRef(false);
	const [selectedPatient, setSelectedPatient] = useState<Patient | null | undefined>(patient);

	// Effect to reset selectedPatient when patient prop changes
	/*   useEffect(() => {
    setSelectedPatient(patient);
  }, [patient]); */

	const fetchOptions = useCallback(async (inputValue = "", currentCursor = null) => {
		try {
			const params = {
				search: inputValue,
				cursor: currentCursor,
				limit: 50,
				isActive: true,
			};

			const response = await axios.get(endpoints.patient.list, { params });
			const newOptions: Patient[] = response.data.data.clients.map((item: any) => ({
				_id: item._id,
				firstName: item.firstName,
				middleName: item.middleName,
				lastName: item.lastName,
				phone: item.phone,
				email: item.email,
				address: {
					address: item.address?.address,
					city: item.address?.city,
					state: item.address?.state,
					country: item.address?.country,
					postalCode: item.address?.postalCode,
				},
				entityBranchId: item.entityBranchId,
				entityId: item.entityId,
				salutation: item.salutation,
			}));

			setOptions((prevOptions) => (currentCursor ? [...prevOptions, ...newOptions] : newOptions));

			setCursor(response.data.data.nextCursor || null);
			setHasMore(response.data.data.hasMore);

			return newOptions;
		} catch (error) {
			console.error("Error fetching options:", error);
			return [];
		}
	}, []);

	const loadOptions = useCallback(
		(inputValue: string | undefined, callback: (arg0: any) => any) => {
			fetchOptions(inputValue).then((results) => callback(results));
		},
		[fetchOptions]
	);

	const loadMoreOptions = useCallback(() => {
		if (hasMore && cursor && !isFetching.current) {
			isFetching.current = true;
			fetchOptions("", cursor).finally(() => {
				isFetching.current = false;
			});
		}
	}, [hasMore, cursor, fetchOptions]);

	const handleSelect = (selectedOption: any) => {
		const patient = options.find((option) => option._id === selectedOption._id);
		if (patient) {
			setSelectedPatient(patient);
			onSelect(patient);
		}
	};

	const handleChange = () => {
		setSelectedPatient(null);
		onSelect(null); // Notify parent that no patient is selected
	};

	const CustomMenuList = ({
		children,
		selectOption,
		...props
	}: {
		children: React.ReactNode;
		selectOption: (option: any) => void;
	} & any) => {
		const options = props.options as Patient[];
		const height = 200;
		const itemHeight = 75;

		return (
			<List height={height} itemCount={options.length} itemSize={itemHeight} width="100%">
				{({ index, style }: { index: number; style: React.CSSProperties }) => {
					const option = options[index];
					return (
						<div
							className="p-1 border-b border-gray-300 flex flex-col justify-center cursor-pointer"
							style={style}
							onClick={() => selectOption(option)}
						>
							<div className="truncate">{`${option.firstName} ${option.lastName}`}</div>
							<div className="text-sm text-gray-600 truncate">{option.phone}</div>
						</div>
					);
				}}
			</List>
		);
	};

	return (
		<>
			{selectedPatient ? (
				<div className="flex flex-row">
					<div className="flex flex-col flex-grow">
						<div className="text-md font-bold">{`${selectedPatient.firstName} ${selectedPatient.lastName}`}</div>
						<div className="text-xs text-gray-600">{selectedPatient.phone}</div>
					</div>

					<button
						onClick={handleChange}
						className="px-2 py-1 text-sm text-primary-500 border border-primary-500 rounded-xl cursor-pointer hover:bg-primary-50 mt-1 w-fit"
					>
						Change
					</button>
				</div>
			) : (
				<Select
					cacheOptions
					defaultOptions
					loadOptions={loadOptions}
					onMenuScrollToBottom={loadMoreOptions}
					className="text-sm"
					components={{
						MenuList: (props) => <CustomMenuList {...props} selectOption={handleSelect} />,
					}}
					placeholder={"Enter patient name or phone number..."}
					onChange={(option) => handleSelect(option)}
				/>
			)}
		</>
	);
};

export default PatientSearchV2;
