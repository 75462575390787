import { OutlinedButton } from "@components/button";
import axios from "axios";
import React, { useState } from "react";
import { endpoints, replaceParams } from "@utils/axios";
import {
	Appointment,
	Convert as ConvertAppointment,
	Appointment as PatientAppointment,
} from "@pages/appointments/types/Appointment";
import moment from "moment";
import Button, { ButtonVariant } from "@components/button/outline-button";
import SolidButton from "@components/button/button";
import { PersonalInformationDetails } from "@pages/manage-patient/types/PatientPersonalDetails";
import { PatientPaymentDetailsDomain } from "@pages/manage-patient-new/types/PatientPaymentDetailsDomain";
import { Convert } from "@pages/appointments/types/PatientType";

import BillingAndPaymentDetails from "@pages/payments/BillingAndPaymentDetails";

import AppointmentDetails from "@pages/dashboard/components/AppointmentDetails";
import { Service } from "@/src/common/types/ServiceType";
import { Plan } from "@common/types/PlanType";
import { PlusCircleIcon } from "lucide-react";

export default function UnbilledAppointments(props: {
	patientId: string;
	patientAppointments: Appointment[];
	totalAppointemnts: number;
	handleBack: () => void;
	services: Service[];
	plans: Plan[];
	onAddSelected: (selectedAppointments: Appointment[]) => void;
}) {
	const [details, setDetails] = React.useState<PatientPaymentDetailsDomain | null>();
	const [patientAppointments, setPatientAppointments] = React.useState<Appointment[] | null>(props.patientAppointments);
	const [totalAppointemnts, setTotalAppointments] = React.useState(0);

	const [showAppointmentDetails, setShowAppointmentDetails] = React.useState(false);
	const [selectedAppointmentId, setSelectedAppointmentId] = React.useState("");
	const [appointmentDetails, setAppointmentDetails] = React.useState<Appointment | null>(null);
	const [addPaymentDetailsForAppt, setAddPaymentDetailsForAppt] = React.useState(false);
	const [selectedAppointments, setSelectedAppointments] = useState<string[]>([]);

	const confirmPaymentAndChangeStatus = async () => {
		try {
			setAddPaymentDetailsForAppt(false);
			handleStatusChange();
		} catch (error) {
			console.error(error);
		}
	};
	const showDialog = (appId: string) => {
		setSelectedAppointmentId(appId);
		setShowAppointmentDetails(true);
	};

	const handleStatusChange = async () => {
		if (props.patientId) {
			const paramsMap = new Map<string, string>([["id", props.patientId]]);
			const filteredEndpoint = replaceParams(endpoints.patient.getUnbilledAppointments, paramsMap);
			const response = await axios.get(filteredEndpoint);

			const data = response.data["data"];
			const convertedAppointmentList = data.map((appointment: any) => {
				return ConvertAppointment.toAppointment(JSON.stringify(appointment));
			});

			setTotalAppointments(convertedAppointmentList.length);
			setPatientAppointments(convertedAppointmentList);
		}
	};

	const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			// Select all appointments
			const allAppointmentIds = patientAppointments?.map((appt) => appt.id) || [];
			setSelectedAppointments(allAppointmentIds);
		} else {
			// Deselect all appointments
			setSelectedAppointments([]);
		}
	};

	const isAllSelected = selectedAppointments.length === (patientAppointments?.length || 0);

	const handleAddSelected = () => {
		const selected = patientAppointments?.filter((appt) => selectedAppointments.includes(appt.id)) || [];
		props.onAddSelected(selected);
	};

	return (
		<div className="flex h-full w-full flex-col ">
			<div className="flex flex-col mr-1">
				<div className="flex flex-row justify-between  px-2">
					<div className="flex font-bold py-2">Completed Unbilled Appointments/Sessions</div>
					<div className="gap-2 flex flex-row">
						<div>
							<SolidButton variant={ButtonVariant.PRIMARY} onClick={handleAddSelected} className="gap-1 pr-1">
								<PlusCircleIcon className="w-4 h-4" />
								Add Selected
							</SolidButton>
						</div>
						<div>
							<SolidButton variant={ButtonVariant.GHOST} className="" type="button" onClick={props.handleBack}>
								Back
							</SolidButton>
						</div>
					</div>
				</div>
				<div className="mt-4 relative overflow-x-auto shadow-md sm:rounded-lg">
					<table className="w-full text-left text-sm text-gray-500 rtl:text-right ">
						<thead className="text-xs uppercase text-gray-700 bg-primary-100 sticky top-0 z-10">
							<tr>
								<th scope="col" className="px-6 py-3">
									<input type="checkbox" checked={isAllSelected} onChange={handleSelectAll} />
								</th>
								<th scope="col" className="px-6 py-3">
									Date/Time
								</th>
								<th scope="col" className="px-6 py-3">
									Type
								</th>
								<th scope="col" className="px-6 py-3">
									Practitioner
								</th>
								<th scope="col" className="px-6 py-3">
									Bill Amount
								</th>
							</tr>
						</thead>
						<tbody>
							{patientAppointments &&
								patientAppointments.map((appt, index) => (
									<tr className="border-b border-gray-200 dark:border-gray-700" key={appt.id}>
										<td className="px-6 py-4">
											<input
												type="checkbox"
												checked={selectedAppointments.includes(appt.id)}
												onChange={(e) => {
													const newSelected = selectedAppointments.includes(appt.id)
														? selectedAppointments.filter((id) => id !== appt.id)
														: [...selectedAppointments, appt.id];
													setSelectedAppointments(newSelected);
												}}
											/>
										</td>
										<td scope="row" className="px-6 py-4 ">
											{moment(new Date(appt.apptdate)).format("DD-MM-YYYY hh:mm a")}
										</td>
										<td className="px-6 py-4">{appt.appointmentType}</td>
										<td className="px-6 py-4 ">{appt.entityUserName}</td>
										<td className="px-6 py-4 gap-1 flex flex-row items-center">
											<div>₹</div>
											<div>
												<input
													type="number"
													value={appt.billAmount}
													onChange={(e) => {
														const newBillAmount = Number(e.target.value); // Convert input value to number
														const updatedAppointments = [...patientAppointments]; // Create a new array
														updatedAppointments[index].billAmount = newBillAmount; // Update the billAmount
														setPatientAppointments(updatedAppointments); // Update state
													}}
													className="border rounded px-2 py-1 w-24"
												/>
											</div>
										</td>
									</tr>
								))}
						</tbody>
					</table>
				</div>
			</div>
			{addPaymentDetailsForAppt && (
				<BillingAndPaymentDetails
					appointmentDetails={appointmentDetails!}
					onClose={() => setAddPaymentDetailsForAppt(false)}
					onConfirm={confirmPaymentAndChangeStatus}
				/>
			)}

			{showAppointmentDetails && (
				<AppointmentDetails onClose={() => setShowAppointmentDetails(false)} appId={selectedAppointmentId} />
			)}
		</div>
	);
}
