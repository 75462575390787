import { endpoints, replaceParams } from "@utils/axios";
import React, { useState } from "react";

import axios from "axios";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  NewMasterAssessmentData,
  NewMasterAssessmentDataConvert,
} from "@pages/manage-patient-new/types/NewMasterAssessment";

import moment from "moment";

import { ButtonVariant } from "@components/button/outline-button";

import PatientInfoCard from "./PatientInfoCard";
import { paths } from "@routes/paths";
import { toast } from "react-toastify";
import TreatmentProtocol from "./TreatmentProtocol";
import PatientInfoCardV2 from "./PatientInfoCardV2";
import { OutlinedButton } from "@components/button";
import { PrinterIcon } from "@heroicons/react/24/solid";
import Button from "@components/button/outline-button";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@components/ui/accordion";
import AssessmentHistory from "@pages/manage-patient/view/AssessmentHistory";
import PdfCheckboxDialog from "@pages/manage-patient-new/dialog/PdfCheckboxDialog";
import { CheckCircle2, FileText, Users } from "lucide-react";
import SessionNotesV2 from "./SessionNotesV2";
import ViewMasterAssessmentNew from "@pages/manage-patient-new/view/ViewAssesmentsNew";
import MedicalHistory from "@pages/manage-patient-new/view/MedicalHistory";
import TreatmentPlan from "@pages/manage-patient-new/view/TreatmentPlan";
import Diagnosis from "@pages/manage-patient-new/view/Diagnosis";
// Define the context with default values
export const ManagePatientNewContext = React.createContext({
  patientId: "",
  masterAssessmentId: "",
});

type assessmentProps = {
  masterAssessmentId: string;
  patientId: string;
};
const Tabs = [
  { id: 1, label: "Medical History", icon: FileText },
  { id: 2, label: "Physical Assessments", icon: Users },
  { id: 3, label: "Diagnosis", icon: CheckCircle2 },
  { id: 4, label: "Treatment Plan", icon: FileText },
  /*   { id: 5, label: "Session Notes", icon: FileText },
   */ { id: 5, label: "Treatment Protocol", icon: FileText },
];

const Tabs2 = [
  { id: 6, label: "Session Notes", icon: FileText },
  /*   { id: 6, label: "Treatment Protocol", icon: FileText },
   */
];
const steps = [
  MedicalHistory,
  ViewMasterAssessmentNew,
  Diagnosis,
  TreatmentPlan,
  TreatmentProtocol,
  SessionNotesV2,
];

export default function ManagePatientNewV2(props: assessmentProps) {
  let { patientId, masterAssessmentId } = useParams();
  // Fallback to props if URL parameters are null or undefined
  patientId = patientId || props.patientId;
  masterAssessmentId = masterAssessmentId || props.masterAssessmentId;
  const [activeStep, setActiveStep] = useState(1);

  const [masterAssessmentData, setMasterAssessmentData] =
    React.useState<NewMasterAssessmentData | null>(null);

  const [isPdfOpen, setIsPdfOpen] = React.useState(false);
  const [activeTillDate, setActiveTillDate] = useState("");

  const TabContentComponent = steps[activeStep > 1 ? activeStep - 1 : 0];

  const getMasterAssessment = React.useCallback(async () => {
    try {
      const paramsMap = new Map<string, string>([
        ["masterAssessmentId", masterAssessmentId!],
      ]);
      const filteredEndpoint = replaceParams(
        endpoints.assessment.getMasterAssessment,
        paramsMap
      );
      const response = await axios.get(filteredEndpoint);
      const data = response.data["data"];
      //console.log("data", data);
      if (data != null) {
        const convertedMasterAssessmentLedger =
          NewMasterAssessmentDataConvert.toNewMasterAssessment(
            JSON.stringify(data)
          );
        setMasterAssessmentData(convertedMasterAssessmentLedger);
        setActiveTillDate(data.activetill);
      }
    } catch (error) {
      console.error("Error fetching master assessment list:", error);
    }
  }, [masterAssessmentId]);

  const upadteActiveTillDate = React.useCallback(
    async (selectedDate: string) => {
      try {
        const paramsMap = new Map<string, string>([
          ["id", masterAssessmentId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.assessment.updateActiveTillDate,
          paramsMap
        );

        const formattedEndDate = selectedDate
          ? convertToUTCWithoutTime(moment(selectedDate))
          : null;

        if (formattedEndDate) {
          const response = await axios.put(filteredEndpoint, {
            ActiveTill: formattedEndDate,
          });

          const data = response.data["data"];

          toast.success(
            response.data.message === "success"
              ? "Date updated successfully"
              : response.data.message
          );
        }
      } catch (error) {
        console.error("Error updating active till date:", error);
      }
    },
    [masterAssessmentId]
  );

  React.useEffect(() => {
    getMasterAssessment();
  }, [getMasterAssessment, masterAssessmentId, props.masterAssessmentId]);

  const handleOpenDatePicker = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    const inputDateElement = document.querySelector(
      'input[type="date"]'
    ) as HTMLInputElement;
    inputDateElement.showPicker();
  };
  const convertToUTCWithoutTime = (date: moment.Moment): string => {
    // Set time to midnight (00:00:00)
    const resultDate = date.set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    // Convert to UTC and format in '2023-12-20T00:00:00Z' format
    const formattedUTC = moment
      .utc(resultDate)
      .format("YYYY-MM-DDTHH:mm:ss[Z]");
    return formattedUTC;
  };

  return (
    <ManagePatientNewContext.Provider
      value={{ patientId: patientId!, masterAssessmentId: masterAssessmentId! }}
    >
      <>
        {/*         <PatientInfoCardV2 patientId={patientId!} />
         */}
        {masterAssessmentData && (
          <>
            <div className="flex  flex-row  justify-between items-center w-full">
              <div className="flex-shrink-0 ">
                {activeTillDate ? (
                  <div className="flex flex-col md:flex-row ">
                    <div className="flex flex-row flex-grow flex-1 items-center md:justify-center justify-start mr-2 text-sm ">
                      <span className="font-normal mt-1 lg:mt-0 lg:mr-2">
                        Valid Till ,{" "}
                        {moment(activeTillDate).format("DD MMMM YYYY")}
                      </span>
                    </div>

                    <div
                      className="flex flex-row items-center md:justify-center
                    justify-start"
                    >
                      <div className="flex items-center text-sm mt-2 lg:mt-0">
                        <a
                          href="#"
                          className="text-blue-500 text-secondary-500 mr-2"
                          onClick={handleOpenDatePicker}
                        >
                          Change Date
                        </a>
                        <div className="w-0 h-0">
                          <input
                            type="date"
                            style={{
                              width: "0px",
                              border: "0",
                              padding: "0",
                              height: "0px",
                            }}
                            min={moment(new Date()).format("YYYY-MM-DD")}
                            onChange={(e) => {
                              const selectedDate = moment(
                                e.target.value
                              ).toLocaleString();
                              setActiveTillDate(selectedDate);
                              upadteActiveTillDate(selectedDate);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-row  items-center ">
                    <div className="flex md:flex-row items-center md:justify-center justify-start mr-2">
                      <span className="font-normal text-sm ">
                        Valid till date
                      </span>
                    </div>
                    <div className="flex md:flex-row items-center md:justify-center justify-start">
                      <div>
                        <a
                          href="#"
                          className="text-blue-500 text-secondary-500 mr-2 text-sm"
                          onClick={handleOpenDatePicker}
                        >
                          Add Date
                        </a>
                        <div className="h-0 w-0">
                          <input
                            type="date"
                            style={{
                              width: "0px",
                              border: "0",
                              padding: "0",
                              height: "0px",
                            }}
                            min={moment(new Date()).format("YYYY-MM-DD")}
                            onChange={(e) => {
                              const selectedDate = moment(
                                e.target.value
                              ).toLocaleString();
                              setActiveTillDate(selectedDate);
                              upadteActiveTillDate(selectedDate);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex flex-row md:justify-center justify-end md:mt-2 mt-0">
                <div className="flex flex-row">
                  <OutlinedButton
                    variant={ButtonVariant.GRAY}
                    className=""
                    onClick={() => {
                      setIsPdfOpen(true);
                    }}
                  >
                    <PrinterIcon className="h-4 w-4 text-secondary-600" />
                    <span className="pl-1 text-sm md:flex hidden">
                      {" "}
                      Download / Print
                    </span>
                  </OutlinedButton>
                  {/*                   <Button
                    variant={ButtonVariant.GRAY}
                    className="mx-1"
                    onClick={() => {
                      const paramsMap = new Map<string, string>([
                        ["patientId", patientId!],
                      ]);
                      navigator(
                        replaceParams(paths.patient.manage.route, paramsMap)
                      );
                    }}
                  >
                    <PrinterIcon className="h-5 w-5" />
                    <span className="pl-1 text-sm"> Back</span>
                  </Button> */}
                </div>
              </div>
            </div>
            <hr className="my-2" />

            {/* For lg sm */}
            <div className="flex flex-col  mt-2 pt-2">
              {/*    <Accordion type="single" collapsible className="w-full text-base">
                
                <AccordionItem value="item-1">
                  <AccordionTrigger>Medical History</AccordionTrigger>
                  <AccordionContent>
                    <MedicalHistory patientId={patientId ?? ""}
                      getMasterAssessmentLedger={getMasterAssessment}
                      masterAssessmentData={masterAssessmentData} />
                  </AccordionContent>
                </AccordionItem>
                <AccordionItem value="item-2">
                  <AccordionTrigger>Physical Assessments</AccordionTrigger>
                  <AccordionContent>
                    <AssessmentHistory patientId={patientId ?? ""}
                      getMasterAssessmentLedger={getMasterAssessment}
                      masterAssessmentData={masterAssessmentData} />
                  </AccordionContent>
                </AccordionItem>
                <AccordionItem value="item-3">
                  <AccordionTrigger>Is it animated?</AccordionTrigger>
                  <AccordionContent>
                    Yes. It&apos;s animated by default, but you can disable it if you
                    prefer.
                  </AccordionContent>
                </AccordionItem>
              </Accordion> */}
              <div className="flex flex-col">
                <div className="grid grid-cols-3 md:grid-cols-6 gap-2">
                  {Tabs.map((step, index) => {
                    const StepIcon = step.icon;
                    const isActive = step.id === activeStep;
                    const isCompleted = step.id < activeStep;
                    return (
                      <div
                        key={step.id}
                        className={`relative ${
                          index < Tabs.length - 1
                            ? 'after:content-[""] after:absolute after:top-[22px] after:left-[calc(100%_-_24px)] after:w-full after:h-[2px] after:bg-gray-200'
                            : ""
                        }`}
                      >
                        <button
                          type="button"
                          onClick={() => setActiveStep(step.id)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === " ") {
                              setActiveStep(step.id);
                            }
                          }}
                          tabIndex={0}
                          role="tab"
                          aria-selected={isActive}
                          aria-controls={`panel-${step.id}`}
                          className={`w-full group transition-all duration-200 ease-in-out focus:outline-none rounded-lg ${
                            isActive
                              ? "opacity-100"
                              : "opacity-70 hover:opacity-100"
                          }`}
                        >
                          <div className="flex flex-col items-center">
                            <div
                              className={`
                          w-11 h-11 rounded-full flex items-center justify-center mb-2
                          ${
                            isActive
                              ? "bg-primary-600 text-white"
                              : "bg-primary-100 text-gray-400"
                          }
                          group-hover:shadow-md transition-all duration-200
                        `}
                            >
                              <StepIcon className="w-5 h-5" />
                            </div>
                            <div className="text-center">
                              <div
                                className={`text-xs font-medium ${
                                  isActive
                                    ? "text-primary-600"
                                    : "text-gray-700"
                                }`}
                              >
                                Step {step.id}
                              </div>
                              <div
                                className={`text-sm font-medium ${
                                  isActive
                                    ? "text-primary-600"
                                    : "text-gray-700"
                                }`}
                              >
                                {step.label}
                              </div>
                            </div>
                          </div>
                        </button>
                      </div>
                    );
                  })}
                  {Tabs2.map((step, index) => {
                    const isActive = step.id === activeStep;
                    const StepIcon = step.icon;

                    return (
                      <div
                        key={step.id}
                        // className={`relative ${
                        //   index < Tabs.length - 1
                        //     ? 'after:content-[""] after:absolute after:top-[22px] after:left-[calc(100%_-_24px)] after:w-full after:h-[2px] after:bg-gray-200'
                        //     : ""
                        // }`}
                      >
                        <button
                          type="button"
                          onClick={() => setActiveStep(step.id)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === " ") {
                              setActiveStep(step.id);
                            }
                          }}
                          tabIndex={0}
                          role="tab"
                          aria-selected={isActive}
                          aria-controls={`panel-${step.id}`}
                          className={`w-full group transition-all duration-200 ease-in-out focus:outline-none rounded-lg ${
                            isActive
                              ? "opacity-100"
                              : "opacity-70 hover:opacity-100"
                          }`}
                        >
                          <div className="flex flex-col items-center">
                            <div
                              className={`
                          w-11 h-11 rounded-full flex items-center justify-center mb-2
                          ${
                            isActive
                              ? "bg-primary-600 text-white"
                              : "bg-primary-600 text-white"
                          }
                          group-hover:shadow-md transition-all duration-200
                        `}
                            >
                              <StepIcon className="w-5 h-5" />
                            </div>
                            <div className="text-center">
                              {/* <div
                                className={`text-xs font-medium ${
                                  isActive
                                    ? "text-primary-600"
                                    : "text-gray-700"
                                }`}
                              >
                                Step {step.id}
                              </div> */}
                              <div
                                className={`text-sm font-medium ${
                                  isActive
                                    ? "text-primary-600"
                                    : "text-gray-700"
                                }`}
                              >
                                {step.label}
                              </div>
                            </div>
                          </div>
                        </button>
                      </div>
                    );
                  })}
                </div>
                <div className="hidden">
                  <div className="flex flex-col justify-between rounded-xl bg-white w-full overflow-clip border border-gray-400 mt-4">
                    <div className="flex flex-row rounded-xl bg-white-300 items-center text-xs font-semibold space-x-2 p-4 pb-0 w-full">
                      <div className="flex flex-col w-full ">
                        <span className="whitespace-nowrap overflow-hidden truncate">
                          Assessed by {masterAssessmentData.entityUserName}
                        </span>
                      </div>
                    </div>

                    <div className="flex flex-row rounded-bl-xl rounded-br-xl bg-tertiary-500 items-center px-4 py-2 mt-2">
                      <div className="flex flex-row w-full justify-center font-semibold text-white text-sm">
                        {moment(masterAssessmentData.assessmentDate).format(
                          "DD MMMM YYYY"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-2">
                <hr className="my-2" />

                <TabContentComponent
                  patientId={patientId ?? ""}
                  getMasterAssessmentLedger={getMasterAssessment}
                  masterAssessmentData={masterAssessmentData}
                />
              </div>
            </div>
            {/* For lg */}
            {/*             <div className="hidden lg:flex lg:flex-col xl:hidden w-full">
              <div className="flex flex-row mr-2 justify-between w-full">
                {Tabs.map((tab, index) => (
                  <div
                    className={`flex items-center px-6 py-2 rounded-full cursor-pointer ${index === currentStep
                      ? "bg-primary-100 text-primary"
                      : "bg-gray-200 text-gray-700"
                      }`}
                    key={tab}
                    onClick={() => {
                      setActiveTab({ tab: Tabs[index] });
                      setCurrentStep(index);
                    }}
                  >
                    <span className="text-xs">{tab}</span>
                  </div>
                ))}
              </div>
              <div className="p-2">
                <TabContentComponent
                  patientId={patientId ?? ""}
                  getMasterAssessmentLedger={getMasterAssessment}
                  masterAssessmentData={masterAssessmentData}
                />
              </div>
            </div> */}
            {/* For xl */}
            {/*             <div className="hidden xl:flex xl:flex-row">
              <div className="flex flex-col w-1/5 mr-2 justify-between">
                <div className="">
                  {Tabs.map((tab, index) => (
                    <div
                      className={`flex px-4 py-3 w-full justify-between rounded-xl cursor-pointer ${index === currentStep
                        ? "bg-primary-100 text-primary"
                        : "bg-transparent text-gray-500"
                        }`}
                      key={tab}
                      onClick={() => {
                        setActiveTab({ tab: Tabs[index] });
                        setCurrentStep(index);
                      }}
                    >
                      <span className=" flex-initial text-black">{tab}</span>
                    </div>
                  ))}
                </div>
                <div className="">
                  <div className="flex flex-col justify-between rounded-xl bg-white w-full overflow-clip border border-gray-400 mt-4">
                    <div className="flex flex-row rounded-xl bg-white-300 items-center text-xs font-semibold space-x-2 p-4 pb-0 w-full">
                      <div className="flex flex-col w-full ">
                        <span className="whitespace-nowrap overflow-hidden truncate">
                          Assessed by {masterAssessmentData.entityUserName}
                        </span>
                      </div>
                    </div>

                    <div className="flex flex-row rounded-bl-xl rounded-br-xl bg-tertiary-500 items-center px-4 py-2 mt-2">
                      <div className="flex flex-row w-full justify-center font-semibold text-white text-sm">
                        {moment(masterAssessmentData.assessmentDate).format(
                          "DD MMMM YYYY"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-4/5  border-gray-200 h-[550px] overflow-y-auto p-2">
                <TabContentComponent
                  patientId={patientId ?? ""}
                  getMasterAssessmentLedger={getMasterAssessment}
                  masterAssessmentData={masterAssessmentData}
                />
              </div>
            </div> */}
          </>
        )}

        <PdfCheckboxDialog
          isOpen={isPdfOpen}
          setIsOpen={setIsPdfOpen}
          patientId={patientId ?? ""}
          masterAssessmentData={masterAssessmentData}
        ></PdfCheckboxDialog>
      </>
    </ManagePatientNewContext.Provider>
  );
}
