import { STORAGE_KEYS } from "@constants/storage-keys";
import { endpoints } from "@utils/axios";
import axios, { AxiosResponse } from "axios";
import { useEffect, useReducer, useCallback, useMemo, useState } from "react";
import { ActionMapType, AuthStateType, AuthTokensType } from "src/authentication/types";
import { AuthContext } from "./auth-context";
import { isValidToken, setSession, setUserData } from "./utils";

import React from "react";
import { paths } from "@routes/paths";

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
	INITIAL = "INITIAL",
	LOGIN = "LOGIN",
	LOGOUT = "LOGOUT",
}

type Payload = {
	[Types.INITIAL]: {
		tokens: AuthTokensType;
	};
	[Types.LOGIN]: {
		tokens: AuthTokensType;
	};
	[Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
	loading: true,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
	if (action.type === Types.INITIAL) {
		return {
			loading: false,
			tokens: action.payload.tokens,
		};
	}
	if (action.type === Types.LOGIN) {
		return {
			...state,
			tokens: null,
		};
	}
	if (action.type === Types.LOGOUT) {
		return {
			...state,
			tokens: null,
		};
	}
	return state;
};

// ----------------------------------------------------------------------

type Props = {
	children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
	const [state, dispatch] = useReducer(reducer, initialState);
	const [isUnderMaintenance, setIsUnderMaintenance] = useState(false);

	const initialize = useCallback(async () => {
		try {
			const response = await axios.get(endpoints.health.healthcheck);
			const data = response.data["data"];
			const status = response.status;
			if (status == 200) {
				setIsUnderMaintenance(false);
			} else {
				setIsUnderMaintenance(true);
			}
			const accessToken = sessionStorage.getItem(STORAGE_KEYS.APP_STATE.ACCESS_TOKEN);
			const refreshToken = sessionStorage.getItem(STORAGE_KEYS.APP_STATE.REFRESH_TOKEN);
			const googleAccessToken = sessionStorage.getItem(STORAGE_KEYS.APP_STATE.GOOGLE_ACCESS_TOKEN);
			const googleRefreshToken = sessionStorage.getItem(STORAGE_KEYS.APP_STATE.GOOGLE_REFRESH_TOKEN);

			const areValidTokens = accessToken && refreshToken && isValidToken(accessToken) && isValidToken(refreshToken);

			if (areValidTokens) {
				setSession(accessToken, refreshToken, googleAccessToken, googleRefreshToken);

				// const response = await axios.get(endpoints.auth.me);

				// const { user } = response.data;

				const tokens = {
					accessToken,
					refreshToken,
				};

				dispatch({
					type: Types.INITIAL,
					payload: {
						// user,
						// TODO: Uncomment this when the API is ready for 'me' which returns the user
						// user: new AuthUserType(),
						tokens: tokens,
					},
				});
			} else {
				dispatch({
					type: Types.INITIAL,
					payload: {
						tokens: null,
					},
				});
			}
		} catch (error) {
			setIsUnderMaintenance(true);

			console.error(error);
			dispatch({
				type: Types.INITIAL,
				payload: {
					tokens: null,
				},
			});
		}
	}, []);

	useEffect(() => {
		initialize();
	}, [initialize]);

	// LOGIN
	const login = useCallback(async (email: string, password: string) => {
		const data: Record<string, string> = {
			username: email,
			password: password,
		};

		const cookie = document.cookie;
		const tokenPrefix = "csrf_=";

		const token = cookie.slice(cookie.indexOf(tokenPrefix) + tokenPrefix.length);

		const response = await axios.post(endpoints.auth.login, data, {
			headers: { "X-CSRF-Token": token },
		});

		const tokens: AuthTokensType = {
			accessToken: response.data["data"].access_token,
			refreshToken: response.data["data"].refresh_token,
			expiresIn: response.data["data"].expires_in,
			googleAccessToken: response.data["data"].google_access_token,
			googleRefreshToken: response.data["data"].google_refresh_token,
			googleExpiry: response.data["data"].google_expiry,
			googleTokenType: response.data["data"].google_token_type,
			authProvider: response.data["data"].auth_provider,
		};

		setSession(tokens.accessToken, tokens.refreshToken, null, null);

		setUserData(tokens.accessToken);

		sessionStorage.setItem(STORAGE_KEYS.IS_GOOGLE_AUTH_ENABLED, "false");

		axios.defaults.headers.common.Authorization = `Bearer ${tokens.accessToken}`;

		dispatch({
			type: Types.LOGIN,
			payload: {
				// user,
				tokens: tokens,
			},
		});
	}, []);

	const loginWithGoogle = useCallback(async (response: any) => {
		const tokens: AuthTokensType = {
			accessToken: response.accessToken,
			refreshToken: response.refreshToken,
			expiresIn: response.expiresIn,
			googleAccessToken: response.googleAccessToken,
			googleRefreshToken: response.googleRefreshToken,
			googleExpiry: response.googleExpiry,
			googleTokenType: response.googleTokenType,
			authProvider: response.authProvider,
		};

		setSession(tokens.accessToken, tokens.refreshToken, tokens.googleAccessToken, tokens.googleRefreshToken);

		setUserData(tokens.accessToken);
		if (tokens.googleAccessToken && tokens.googleRefreshToken) {
			sessionStorage.setItem(STORAGE_KEYS.IS_GOOGLE_AUTH_ENABLED, "true");
		} else {
			sessionStorage.setItem(STORAGE_KEYS.IS_GOOGLE_AUTH_ENABLED, "false");
		}
		axios.defaults.headers.common.Authorization = `Bearer ${tokens.accessToken}`;

		dispatch({
			type: Types.LOGIN,
			payload: {
				// user,
				tokens: tokens,
			},
		});
	}, []);

	// LOGOUT
	const logout = useCallback(async () => {
		const response = await axios.get(endpoints.auth.logout);
		setSession(null, null, null, null);

		delete axios.defaults.headers.common.Authorization;

		dispatch({
			type: Types.LOGOUT,
		});
	}, []);

	// LOGOUT
	const reset = useCallback(async () => {
		setSession(null, null, null, null);

		delete axios.defaults.headers.common.Authorization;

		dispatch({
			type: Types.LOGOUT,
		});
	}, []);

	// ----------------------------------------------------------------------

	const checkAuthenticated = state.tokens ? "authenticated" : "unauthenticated";

	const status = state.loading ? "loading" : checkAuthenticated;

	const memoizedValue = useMemo(
		() => ({
			tokens: state.tokens,
			method: "jwt",
			loading: status === "loading",
			authenticated: status === "authenticated",
			unauthenticated: status === "unauthenticated",
			isUnderMaintenance: isUnderMaintenance,
			login,
			loginWithGoogle,
			logout,
			reset,
		}),
		[login, loginWithGoogle, logout, reset, isUnderMaintenance, state.tokens, status]
	);

	return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
