import { InvoiceDetails, PaymentDetails } from "@pages/finances/types/InvoiceDetails";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Modal from "./modal";
import { CURRENCY_SYMBOL } from "../config/currencyConfig";
import { endpoints } from "@utils/axios";
import { replaceParams } from "@utils/axios";
import axios, { CancelToken, CancelTokenSource } from "axios";
import { toast } from "react-toastify";

interface EditPaymentModalProps {
	isOpen: boolean;
	onClose: () => void;
	payment: PaymentDetails; // Assuming PaymentDetails is defined in your types
	invoice: InvoiceDetails;
}

const EditPaymentModal: React.FC<EditPaymentModalProps> = ({ isOpen, onClose, payment, invoice }) => {
	const [amountPaid, setAmountPaid] = useState<number>(0);
	const [totalAmount, setTotalAmount] = useState<number>(0);
	const [totalPaidAmount, setTotalPaidAmount] = useState<number>(0);
	const [outStandingAmount, setOutStandingAmount] = useState<number>(0);
	const [paymentMode, setPaymentMode] = useState<PaymentDetails["paymentMode"]>("");
	const [transactionId, setTransactionId] = useState<string>("");
	const [note, setNote] = useState<string>("");
	const [paymentDate, setPaymentDate] = useState<Date>(new Date());
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		if (payment) {
			setAmountPaid(payment.amountPaid);
			setPaymentMode(payment.paymentMode);
			setNote(payment.note);
			setPaymentDate(new Date(payment.paymentDate));
			setTransactionId(payment.transactionId);
			setTotalAmount(invoice.totalAmount);
			setTotalPaidAmount(invoice.totalPaid);
			setOutStandingAmount(invoice.outStandingAmount);
		}
	}, [payment]);
	const cancelToken = React.useRef<CancelTokenSource | null>(null);

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		if (cancelToken.current) {
			cancelToken.current.cancel("Request already in progress!");
		}
		cancelToken.current = axios.CancelToken.source();
		try {
			setError(null);
			// Make API call
			const paramsMap = new Map<string, string>();
			paramsMap.set("id", invoice.id!);
			let endpoint = replaceParams(endpoints.invoice.updatePaymentDetails, paramsMap);
			let response = await axios.put(
				endpoint,
				{
					amountPaid: Number(amountPaid),
					paymentMode: paymentMode,
					note: note,
					paymentDate: paymentDate,
					transactionId: transactionId,
				},
				{
					cancelToken: cancelToken.current.token,
				}
			);
			if (response && response.status === 200) {
				toast.success("Payment details updated successfully");
				onClose();
			}
		} catch (err) {
			setError(err instanceof Error ? err.message : "An unexpected error occurred");
		}
	};

	if (!isOpen) return null;

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<div className="flex flex-col w-full justify-between p-6">
				<h2 className="text-base font-semibold text-gray-900 flex items-center pb-4">Edit Payment Details</h2>
				<div className="py-1">{error && <div className="error-message text-red-500 text-sm">{error}</div>}</div>
				<form onSubmit={handleSubmit}>
					<div className="flex flex-row gap-2 py-2">
						<div className="flex basis-1/2">
							<label>Transaction Id</label>
						</div>
						<div className="flex basis-1/2">
							<span className="font-thin text-xs">{transactionId}</span>
						</div>
					</div>
					<div className="flex flex-row gap-2 py-2">
						<div className="flex basis-1/2">
							<label>Amount Paid</label>
						</div>
						<div className="flex basis-1/2">
							<span className="mr-2 font-mono">{CURRENCY_SYMBOL}</span>
							<span className="text-base text-primary-600 font-mono">{amountPaid}</span>
						</div>
					</div>
					<div className="flex flex-row gap-2 py-2">
						<div className="flex basis-1/2">
							<label>Outstanding Amount</label>
						</div>
						<div className="flex basis-1/2">
							<span className="mr-2 font-mono">{CURRENCY_SYMBOL}</span>
							<span className="text-base text-red-600 font-mono">{outStandingAmount}</span>
						</div>
					</div>
					<div className="flex flex-row gap-2 py-2">
						<div className="flex basis-1/2">
							<label>Payment Mode</label>
						</div>
						<div className="flex basis-1/2">
							<select
								className="input-field text-sm border border-gray-300 rounded"
								value={paymentMode}
								onChange={(e) => setPaymentMode(e.target.value as PaymentDetails["paymentMode"])}
							>
								<option value=""></option>
								<option value="cash">Cash</option>
								<option value="upi">UPI</option>
								<option value="online">Online</option>
								<option value="card">Card</option>
								<option value="bank_transfer">Bank Transfer</option>
								<option value="check">Check</option>
								<option value="other">Other</option>
							</select>
						</div>
					</div>
					<div className="flex flex-row gap-2 py-2">
						<div className="flex basis-1/2">
							<label>Note</label>
						</div>
						<div className="flex basis-1/2">
							<textarea
								className="input-field text-sm  border border-gray-300 rounded"
								value={note}
								onChange={(e) => setNote(e.target.value)}
							/>
						</div>
					</div>
					<div className="flex flex-row gap-2 py-2">
						<div className="flex basis-1/2">
							<label>Payment Date</label>
						</div>
						<div className="flex basis-1/2">
							<input
								className="flex px-2 rounded-lg border-0 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 text-sm text-gray-500 cursor-pointer"
								type="date"
								style={{ width: "150px" }}
								value={moment(paymentDate, "DD-MM-YYYY").format("YYYY-MM-DD")}
								onChange={(e) => {
									//  handleDateChange(e);
									setPaymentDate(new Date(e.target.value));
								}}
							/>
						</div>
					</div>
					<div className="flex flex-row justify-end gap-2 py-2">
						<button
							type="button"
							onClick={onClose}
							className="flex items-center gap-1.5 font-medium px-2.5 py-1.5 rounded-lg hover:bg-blue-50 transition-colors duration-200 text-sm border bg-secondary-50 border-secondary-500"
						>
							Cancel
						</button>
						<button
							type="submit"
							className="flex items-center gap-1.5 font-medium px-2.5 py-1.5 rounded-lg hover:bg-blue-50 transition-colors duration-200 text-sm border bg-primary-50 border-primary-500"
						>
							Save
						</button>
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default EditPaymentModal;
