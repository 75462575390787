// To parse this data:
//
//   import { Convert, BillingDetails } from "./file";
//
//   const billingDetails = Convert.toBillingDetails(json);


export type BillingDetails = {
  clientOutStandingBillAmount?: number;
  clientTotalBillAmount?: number;
  clientTotalBillAmountPaid?: number;
  sessionCredits?: SessionCreditDetailed[];
};

export interface SessionCreditDetailed {
  id: string;
  entityId: string;
  entityBranchId: string;
  clientId: string;
  serviceId: string;
  quantity: number;
  name: string;
  audit: {
    createdBy: string;
    lastUpdatedBy: string;
    createdAt: string;
    lastUpdatedAt: string;
  };
}



// Converts JSON strings to/from your types
export class Convert {
  public static toBillingDetails(json: string): BillingDetails {
    return JSON.parse(json);
  }

  public static billingDetailsToJson(value: BillingDetails): string {
    return JSON.stringify(value);
  }
}
