import React, { useState, useRef, useEffect, lazy } from "react";
import { Save, Eye, FileText, Download, Share2, Printer, ChevronLeft, ChevronRight, Send } from "lucide-react";
import { Document, Page, pdfjs } from "react-pdf";
import { toast } from "react-toastify";
import moment from "moment";
import axios, { CancelTokenSource } from "axios";
import PatientSearchV2 from "@common/components/PatientSearch/PatientSearchV2";
import Modal from "@components/modal";
import { Patient } from "@common/types/Patient";
import { InvoiceDetails } from "../types/InvoiceDetails";
import { endpoints, replaceParams } from "@utils/axios";
import { arrayBuffer } from "stream/consumers";
import { OutlinedButton } from "@components/button";
import { ButtonVariant } from "@components/button/button";
// import InvoicePreview from "./InvoicePreview/InvoicePreview";

const InvoicePreview = lazy(() => import("./InvoicePreview/InvoicePreview"));

pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.mjs", import.meta.url).toString();

interface InvoiceHeaderProps {
	onDateChange: (date: string) => void;
	onPatientChange: (patient: Patient | null) => void;
	invoice: InvoiceDetails;
	clientName: string;
	readOnly: boolean;
	onClose: () => void;
	handleSaveInvoice: () => void;
	handlePrint: () => void;
	resetPaymentFields: boolean;
	makeEditable: () => void;
}

interface PageSettings {
	headerEnabled: boolean;
	footerEnabled: boolean;
	watermarkEnabled: boolean;
	footerText: string;
	margins: [number, number, number, number]; // [Top, Bottom, Left, Right]
}

export default function InvoiceHeader({
	invoice,
	onDateChange,
	onPatientChange,
	onClose,
	clientName,
	handleSaveInvoice,
	handlePrint,
	readOnly,
	resetPaymentFields,
	makeEditable,
}: InvoiceHeaderProps) {
	let initialValue = {
		headerEnabled: false,
		footerEnabled: false,
		watermarkEnabled: false,
		footerText: "",
		margins: [1.0, 1.0, 1.0, 1.0],
	};

	const [selectedDate, setSelectedDate] = useState<string>(
		invoice.invoiceDate ? moment(invoice.invoiceDate).format("DD-MM-YYYY") : moment(new Date()).format("DD-MM-YYYY")
	);
	const [postInProgress, setPostInProgress] = useState<boolean>(false);
	const cancelToken = useRef<CancelTokenSource | null>(null);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isViewModalOpen, setIsViewModalOpen] = useState<boolean>(false);

	const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value !== "") {
			setSelectedDate(e.target.value);
			invoice.invoiceDate = new Date(e.target.value);

			if (!readOnly) return;
			if (postInProgress) return;
			if (cancelToken.current) {
				cancelToken.current.cancel("Request already in progress!");
			}
			cancelToken.current = axios.CancelToken.source();

			try {
				setPostInProgress(true);
				if (invoice && invoice.id) {
					const paramsMap = new Map<string, string>([["id", invoice.id]]);
					const filteredEndpoint = replaceParams(endpoints.invoice.updateBillingDate, paramsMap);

					axios
						.put(
							filteredEndpoint,
							{ id: invoice.id, invoiceDate: new Date(e.target.value) },
							{ cancelToken: cancelToken.current.token }
						)
						.then((res) => {
							if (res.status === 200) {
								toast.success(res.data.message === "success" ? "Billing date updated successfully" : res.data.message);
							}
						})
						.catch((error) => {
							toast.error(error.message);
						});
				}
				setPostInProgress(false);
			} catch (error) {
				console.error("Error submitting form:", error);
				setPostInProgress(false);
			}
		}
	};

	const handleView = () => setIsViewModalOpen(true);

	return (
		<div className="bg-gray-50">
			<div className="max-w-7xl mx-auto py-4 px-3">
				<div className="bg-white rounded-lg shadow-sm p-3">
					<div className="flex flex-wrap items-center gap-3">
						{/* Invoice Number Display */}
						<div className="flex items-center space-x-2">
							<div className="h-10 w-10 bg-primary-100 rounded-md flex items-center justify-center">
								<FileText className="h-5 w-5 text-primary-700" />
							</div>
							<div>
								<h3 className="text-xs font-medium text-gray-700">Bill Number</h3>
								<span className="text-sm text-gray-500">{invoice?.invoiceNumber || "New Bill"}</span>
							</div>
						</div>

						{/* Patient Selection */}
						<div className="flex-1 min-w-[180px]">
							<h3 className="text-xs font-medium text-gray-700">Patient Name</h3>
							{!invoice?.clientName || invoice?.clientName === "" ? (
								<PatientSearchV2 onSelect={onPatientChange} patient={null} />
							) : (
								<span className="text-sm text-gray-500">
									{readOnly ? (
										invoice?.clientName || "No client selected"
									) : (
										<PatientSearchV2
											onSelect={onPatientChange}
											patient={{
												_id: "",
												firstName: invoice.clientName,
												lastName: "",
												middleName: "",
												isActive: true,
												phoneCode: 1,
												profilePictureUrl: "",
												email: "",
												address: {
													address: "",
													city: "",
													state: "",
													country: "",
													postalCode: "",
												},
												entityBranchId: "",
												entityId: "",
												salutation: "",
											}}
										/>
									)}
								</span>
							)}
						</div>

						{/* Invoice Date */}
						<div className="w-auto">
							<h3 className="text-xs font-medium text-gray-700">Billing Date</h3>
							{readOnly ? (
								<span className="text-sm text-gray-500">{selectedDate}</span>
							) : (
								<input
									className="px-2 py-1 rounded border border-gray-300 shadow-sm focus:ring-1 focus:ring-primary-500 focus:border-primary-500 text-sm text-gray-500 w-[130px]"
									type="date"
									value={moment(selectedDate, "DD-MM-YYYY").format("YYYY-MM-DD")}
									onChange={(e) => {
										handleDateChange(e);
										setSelectedDate(moment(e.target.value, "YYYY-MM-DD").format("DD-MM-YYYY"));
									}}
								/>
							)}
						</div>

						{/* Action Buttons */}
						<div className="flex flex-col gap-2 ml-auto">
							<div className="flex flex-wrap gap-1 justify-end">
								<button
									onClick={readOnly ? () => setIsModalOpen(true) : handleSaveInvoice}
									className="flex items-center gap-1 px-3 py-1.5 rounded-md font-medium text-white text-sm transition-all duration-200 bg-primary-600 hover:bg-primary-700 active:bg-primary-800"
								>
									{readOnly ? "Edit Bill" : "Save Bill"}
								</button>
								{invoice.id ? (
									<button
										onClick={handleView}
										className="flex items-center gap-1 px-3 py-1.5 text-primary-600 hover:text-primary-700 rounded-md hover:bg-primary-50 transition-colors duration-200 text-sm font-medium border border-primary-300"
									>
										<Eye className="h-3.5 w-3.5" />
										View
									</button>
								) : (
									<div></div>
								)}
								<button
									onClick={onClose}
									className="flex items-center gap-1 px-3 py-1.5 text-primary-600 hover:text-primary-700 font-medium rounded-md hover:bg-primary-50 transition-colors duration-200 text-sm border border-primary-300"
								>
									Close
								</button>
							</div>
						</div>
					</div>

					{/* Confirmation Modal */}
					{isModalOpen && (
						<div className="fixed inset-0 flex items-center justify-center z-50">
							<div className="absolute inset-0 bg-black opacity-50" onClick={() => setIsModalOpen(false)}></div>
							<div className="bg-white rounded-lg p-4 z-10 max-w-md w-full">
								<h2 className="text-lg font-semibold text-gray-800 mb-2">Confirm Edit</h2>
								<p className="text-gray-600 mb-3 text-sm">
									Invoice is already paid. Are you sure you want to edit this invoice?
								</p>
								<div className="flex justify-end gap-2">
									<button
										className="px-3 py-1.5 bg-gray-200 hover:bg-gray-300 text-gray-800 rounded-md transition-colors text-sm"
										onClick={() => setIsModalOpen(false)}
									>
										Cancel
									</button>
									<button
										className="px-3 py-1.5 bg-primary-600 hover:bg-primary-700 text-white rounded-md transition-colors text-sm"
										onClick={() => {
											makeEditable();
											setIsModalOpen(false);
										}}
									>
										Confirm
									</button>
								</div>
							</div>
						</div>
					)}

					{/* Document View Modal */}
					{isViewModalOpen && (
						<InvoicePreview
							invoice={invoice}
							isViewModalOpen={true}
							setIsViewModalOpen={() => setIsViewModalOpen(false)}
						></InvoicePreview>
					)}
				</div>
			</div>
		</div>
	);
}
