import { endpoints, replaceParams } from "@utils/axios";
import React, { useRef, useState } from "react";
import { Convert, PersonalInformationDetails } from "../types/PatientPersonalDetails";
import axios from "axios";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import EditPersonalInformation from "./EditPersonalInformation";
import MedicalHistory from "./MedicalHistory";
import Modal from "@components/modal";
import { NewMasterAssessmentData, NewMasterAssessmentDataConvert } from "../types/NewMasterAssessment";
import NewMasterAssessment from "./NewMasterAssessment";
import { MasterAssessmentLedger, MasterAssessmentLedgerConvert } from "../types/MasterAssessmentLedger";
import Diagnosis from "./Diagnosis";
import moment from "moment";

import ViewMasterAssessmentNew from "./ViewAssesmentsNew";
import Button from "@components/button/outline-button";
import SolidButton from "@components/button/button";
import { ButtonVariant } from "@components/button/button";
import TreatmentPlan from "./TreatmentPlan";
import FeedbackComponent from "@pages/entities/view-entity/FeedbackComponent";
import PatientFeedback from "./PatientFeedback";
import RightOffcanvas from "@components/right-offcanvas";
import SessionNotes from "./SessionNotes";
import Reports from "./Reports";
import ImportantNote from "./ImportantNote";
import PatientInfoCard from "./PatientInfoCard";
import { paths } from "@routes/paths";
import PdfExportDialog from "../dialog/PdfExportDialog";
import { date } from "zod";
import { toast } from "react-toastify";
import PdfCheckboxDialog from "../dialog/PdfCheckboxDialog";
import TreatmentProtocol from "./TreatmentProtocol";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import { Entity } from "../types/Entity";
import ManagePatientNewV2 from "./ManagePatientNewV2";
import ExaminationDialog from "../dialog/ExaminationDialog";
export const ManagePatientNewContext = React.createContext({ patientId: "" });

class ConvertEntity {
	public static toEntity(json: string): Entity {
		return JSON.parse(json);
	}

	public static entityToJson(value: Entity): string {
		return JSON.stringify(value);
	}
}

type assessmentProps = {
	assessmentId: string;
};
const Tabs = [
	"1. Medical History",
	"2. Physical Assessments",
	"3. Diagnosis",
	"4. Treatment Plan",
	"5. Session Notes",
	"6. Treatment Protocol",
];

const steps = [MedicalHistory, ViewMasterAssessmentNew, Diagnosis, TreatmentPlan, SessionNotes, TreatmentProtocol];

export default function ManagePatientNew(props: assessmentProps) {
	let { patientId, masterAssessmentId } = useParams();

	let [activeTab, setActiveTab] = useSearchParams({ tab: Tabs[0] });
	const navigator = useNavigate();

	const [patientPersonalInformation, setPatientPersonalInformation] = React.useState<PersonalInformationDetails | null>(
		null
	);
	const [masterAssessmentData, setMasterAssessmentData] = React.useState<NewMasterAssessmentData | null>(null);

	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const [isRightOffcanvasOpen, setIsRightOffcanvasOpen] = React.useState(false);
	const [isRightOffcanvasOpenToFeedback, setIsRightOffcanvasOpenToFeedback] = React.useState(false);
	const [isRightOffcanvasOpenToReport, setIsRightOffcanvasOpenToReport] = React.useState(false);
	const [isImportantNoteModalOpen, setIsImportantNoteModalOpen] = React.useState(false);
	const [isPdfOpen, setIsPdfOpen] = React.useState(false);
	const [isExaminationDialogOpen, setIsExaminationDialogOpen] = React.useState(false);
	const [activeTillDate, setActiveTillDate] = useState("");

	const handleCloseModal = () => setIsModalOpen(false);
	const handleOpenModal = () => setIsModalOpen(true);

	const handleCloseImportantNoteModal = () => setIsImportantNoteModalOpen(false);
	const handleOpenImportantNoteModal = () => setIsImportantNoteModalOpen(true);

	const [currentStep, setCurrentStep] = React.useState(Tabs.indexOf(activeTab.get("tab") ?? Tabs[0]));
	const TabContentComponent = steps[currentStep];

	const getMasterAssessment = React.useCallback(async () => {
		try {
			const paramsMap = new Map<string, string>([["masterAssessmentId", masterAssessmentId!]]);
			const filteredEndpoint = replaceParams(endpoints.assessment.getMasterAssessment, paramsMap);
			const response = await axios.get(filteredEndpoint);
			const data = response.data["data"];

			if (data != null) {
				const convertedMasterAssessmentLedger = NewMasterAssessmentDataConvert.toNewMasterAssessment(
					JSON.stringify(data)
				);
				setMasterAssessmentData(convertedMasterAssessmentLedger);
				setActiveTillDate(data.activetill);
			}
		} catch (error) {
			console.error("Error fetching master assessment list:", error);
		}
	}, []);

	const upadteActiveTillDate = React.useCallback(
		async (selectedDate: string) => {
			try {
				const paramsMap = new Map<string, string>([["id", masterAssessmentId!]]);
				const filteredEndpoint = replaceParams(endpoints.assessment.updateActiveTillDate, paramsMap);

				const formattedEndDate = selectedDate ? convertToUTCWithoutTime(moment(selectedDate)) : null;

				if (formattedEndDate) {
					const response = await axios.put(filteredEndpoint, {
						ActiveTill: formattedEndDate,
					});

					const data = response.data["data"];

					toast.success(response.data.message === "success" ? "Date updated successfully" : response.data.message);
				}
			} catch (error) {
				console.error("Error updating active till date:", error);
			}
		},
		[masterAssessmentId]
	);

	React.useEffect(() => {
		//This is to get all the master assessment for particular patient. Visbile in dropdown
		getMasterAssessment();
		getEntities();
	}, []);

	const handleCloseRightOffCanvas = () => {
		setIsRightOffcanvasOpenToFeedback(false);
		setIsRightOffcanvasOpenToReport(false);
	};
	const handleOpenDatePicker = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		const inputDateElement = document.querySelector('input[type="date"]') as HTMLInputElement;
		inputDateElement.showPicker();
	};
	const convertToUTCWithoutTime = (date: moment.Moment): string => {
		// Set time to midnight (00:00:00)
		const resultDate = date.set({
			hour: 0,
			minute: 0,
			second: 0,
			millisecond: 0,
		});
		// Convert to UTC and format in '2023-12-20T00:00:00Z' format
		const formattedUTC = moment.utc(resultDate).format("YYYY-MM-DDTHH:mm:ss[Z]");
		return formattedUTC;
	};

	let userData = getUserPersistedOnLocalStorage();
	let [entities, setEntities] = React.useState<Entity[] | null>();

	const getEntities = async () => {
		try {
			const response = await axios.get(endpoints.entity.all);
			const data = response.data["data"];
			const convertedEntityList = data.map((entity: any) => ConvertEntity.toEntity(JSON.stringify(entity)));
			setEntities(convertedEntityList);
		} catch (error) {
			console.error("Error fetching entity list:", error);
		}
	};

	let currentEntity = entities?.find((entityObj) => entityObj.id === userData?.entity_id);

	return (
		<ManagePatientNewContext.Provider value={{ patientId: patientId! }}>
			<>
				<PatientInfoCard patientId={patientId!} />

				{masterAssessmentData && (
					<>
						{!currentEntity?.uiVersion || currentEntity?.uiVersion == "v1" || currentEntity?.uiVersion == "" ? (
							<>
								<div className="flex flex-col sm:flex-row  sm:justify-between sm:items-center w-full p-2">
									<div className="flex-shrink-0 ">
										{activeTillDate ? (
											<div className="flex flex-col lg:flex-row">
												<div className="flex flex-row flex-grow flex-1 items-center justify-center sm:justify-start mr-2">
													<span className="font-bold mt-1 lg:mt-0 lg:mr-2">
														Valid Till , {moment(activeTillDate).format("DD MMMM YYYY")}
													</span>
												</div>

												<div
													className="flex flex-row items-center justify-center
                    sm:justify-start"
												>
													<div className="flex items-center mt-2 lg:mt-0">
														<a
															href="#"
															className="text-blue-500 text-secondary-500 mr-2"
															onClick={handleOpenDatePicker}
														>
															Change Date
														</a>
														<div className="w-0 h-0">
															<input
																type="date"
																style={{
																	width: "0px",
																	border: "0",
																	padding: "0",
																	height: "0px",
																}}
																min={moment(new Date()).format("YYYY-MM-DD")}
																onChange={(e) => {
																	const selectedDate = moment(e.target.value).toLocaleString();
																	setActiveTillDate(selectedDate);
																	upadteActiveTillDate(selectedDate);
																}}
															/>
														</div>
													</div>
												</div>
											</div>
										) : (
											<div className="flex flex-col sm:flex-row">
												<div className="flex flex-row flex-grow flex-1 items-center justify-center sm:justify-start mr-2">
													<span className="font-bold ">Add valid till date</span>
												</div>
												<div className="flex flex-row items-center justify-center sm:justify-start">
													<div>
														<a
															href="#"
															className="text-blue-500 text-secondary-500 mr-2"
															onClick={handleOpenDatePicker}
														>
															Add Date
														</a>
														<div className="h-0 w-0">
															<input
																type="date"
																style={{
																	width: "0px",
																	border: "0",
																	padding: "0",
																	height: "0px",
																}}
																min={moment(new Date()).format("YYYY-MM-DD")}
																onChange={(e) => {
																	const selectedDate = moment(e.target.value).toLocaleString();
																	setActiveTillDate(selectedDate);
																	upadteActiveTillDate(selectedDate);
																}}
															/>
														</div>
													</div>
												</div>
											</div>
										)}
									</div>
									<div className="flex flex-row justify-center sm:justify-end mt-2 sm:mt-0">
										<div className="flex flex-row space-x-2">
											{/* <SolidButton
												variant={ButtonVariant.PRIMARY}
												className="mr-2"
												onClick={() => {
													setIsPdfOpen(true);
												}}
											>
												Download / Print
											</SolidButton> */}

											<SolidButton
												variant={ButtonVariant.PRIMARY}
												className="mr-2"
												onClick={() => {
													setIsExaminationDialogOpen(true);
												}}
											>
												Download / Print
											</SolidButton>

											<SolidButton
												variant={ButtonVariant.PRIMARY}
												className="lg:ml-2"
												onClick={() => {
													const paramsMap = new Map<string, string>([["patientId", patientId!]]);
													navigator(replaceParams(paths.patient.manage.route, paramsMap));
												}}
											>
												Back
											</SolidButton>
										</div>
									</div>
								</div>
								<hr className="my-1" />
								{/* For lg sm */}
								<div className="flex flex-col lg:hidden">
									<div className="flex flex-col mr-2 justify-between">
										<div className="">
											{Tabs.map((tab, index) => (
												<div
													className={`flex px-4 py-3 w-full justify-between rounded-xl cursor-pointer ${
														index === currentStep ? "bg-primary-100 text-primary" : "bg-transparent text-gray-500"
													}`}
													key={tab}
													onClick={() => {
														setActiveTab({ tab: Tabs[index] });
														setCurrentStep(index);
													}}
												>
													<span className=" flex-initial text-black">{tab}</span>
												</div>
											))}
										</div>
										<div className="hidden">
											<div className="flex flex-col justify-between rounded-xl bg-white w-full overflow-clip border border-gray-400 mt-4">
												<div className="flex flex-row rounded-xl bg-white-300 items-center text-xs font-semibold space-x-2 p-4 pb-0 w-full">
													<div className="flex flex-col w-full ">
														<span className="whitespace-nowrap overflow-hidden truncate">
															Assessed by {masterAssessmentData.entityUserName}
														</span>
													</div>
												</div>

												<div className="flex flex-row rounded-bl-xl rounded-br-xl bg-tertiary-500 items-center px-4 py-2 mt-2">
													<div className="flex flex-row w-full justify-center font-semibold text-white text-sm">
														{moment(masterAssessmentData.assessmentDate).format("DD MMMM YYYY")}
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="p-2">
										<TabContentComponent
											patientId={patientId ?? ""}
											getMasterAssessmentLedger={getMasterAssessment}
											masterAssessmentData={masterAssessmentData}
										/>
									</div>
								</div>
								{/* For lg */}
								<div className="hidden lg:flex lg:flex-col xl:hidden w-full">
									<div className="flex flex-row mr-2 justify-between w-full">
										{Tabs.map((tab, index) => (
											<div
												className={`flex items-center px-6 py-2 rounded-full cursor-pointer ${
													index === currentStep ? "bg-primary-100 text-primary" : "bg-gray-200 text-gray-700"
												}`}
												key={tab}
												onClick={() => {
													setActiveTab({ tab: Tabs[index] });
													setCurrentStep(index);
												}}
											>
												<span className="text-xs">{tab}</span>
											</div>
										))}
									</div>
									<div className="p-2">
										<TabContentComponent
											patientId={patientId ?? ""}
											getMasterAssessmentLedger={getMasterAssessment}
											masterAssessmentData={masterAssessmentData}
										/>
									</div>
								</div>
								{/* For xl */}
								<div className="hidden xl:flex xl:flex-row">
									<div className="flex flex-col w-1/5 mr-2 gap-48">
										<div className="flex flex-col">
											{Tabs.map((tab, index) => (
												<div
													className={`flex px-4 py-3 w-full justify-between rounded-xl cursor-pointer ${
														index === currentStep ? "bg-primary-100 text-primary" : "bg-transparent text-gray-500"
													}`}
													key={tab}
													onClick={() => {
														setActiveTab({ tab: Tabs[index] });
														setCurrentStep(index);
													}}
												>
													<span className=" flex-initial text-black">{tab}</span>
												</div>
											))}
										</div>
										<div className="flex flex-col justify-between rounded-xl bg-white w-full overflow-clip border border-gray-400 mt-4">
											<div className="flex flex-row rounded-xl bg-white-300 items-center text-xs font-semibold space-x-2 p-4 pb-0 w-full">
												<div className="flex flex-col w-full ">
													<span className="whitespace-nowrap overflow-hidden truncate">
														Assessed by {masterAssessmentData.entityUserName}
													</span>
												</div>
											</div>

											<div className="flex flex-row rounded-bl-xl rounded-br-xl bg-tertiary-500 items-center px-4 py-2 mt-2">
												<div className="flex flex-row w-full justify-center font-semibold text-white text-sm">
													{moment(masterAssessmentData.assessmentDate).format("DD MMMM YYYY")}
												</div>
											</div>
										</div>
									</div>
									<div className="w-4/5 border-l border-gray-200 p-2">
										<TabContentComponent
											patientId={patientId ?? ""}
											getMasterAssessmentLedger={getMasterAssessment}
											masterAssessmentData={masterAssessmentData}
										/>
									</div>
								</div>

								{/* Bottom spacing box */}
								<div className="h-32"></div>
							</>
						) : (
							<ManagePatientNewV2
								masterAssessmentId={props.assessmentId}
								patientId={patientId ?? ""}
							></ManagePatientNewV2>
						)}
					</>
				)}

				<PdfCheckboxDialog
					isOpen={isPdfOpen}
					setIsOpen={setIsPdfOpen}
					patientId={patientId ?? ""}
					masterAssessmentData={masterAssessmentData}
				></PdfCheckboxDialog>

				<ExaminationDialog
					isOpen={isExaminationDialogOpen}
					setIsOpen={setIsExaminationDialogOpen}
					patientId={patientId ?? ""}
					masterAssessmentData={masterAssessmentData}
				></ExaminationDialog>
			</>
		</ManagePatientNewContext.Provider>
	);
}
