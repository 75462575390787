import { endpoints, replaceParams } from "@utils/axios";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { Appointments } from "../types/Appointments";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Divider from "@components/divider/divider";

import useAxios from "@routes/hooks/use-axios";
import ViewPaymentDetails from "./ViewPaymentDetails";
import { CancelAppointmentDialog } from "./CancelAppointmentDialog";
import BillingAndPaymentDetails from "../../payments/BillingAndPaymentDetails";
import Button, { ButtonVariant } from "@components/button/button";
import { ConvertSessionCredit, SessionCredits } from "@pages/manage-patient-new/types/SessionCredits";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import {
	ArrowRightIcon,
	ArrowUpRightIcon,
	BadgeCentIcon,
	BadgeIndianRupeeIcon,
	Calculator,
	CheckCircle,
	Clock,
	FileText,
	LucideIcon,
	PlayCircle,
	Plus,
	Sidebar,
	SquareArrowOutUpRightIcon,
	UserRoundCheck,
} from "lucide-react";
import { Sheet, SheetClose, SheetContent, SheetHeader, SheetTrigger } from "@components/ui/sheet";
import PatientRecord from "@pages/manage-patient-new-v2/view/PatientRecord";
import {
	ArrowRightCircleIcon,
	CheckBadgeIcon,
	HandThumbUpIcon,
	PlayCircleIcon,
	XMarkIcon,
} from "@heroicons/react/24/outline";
import { getUIVersion, getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import Modal from "@components/modal";
import RightOffcanvas from "@components/right-offcanvas/right-offcanvas";
import { WaitingTimeCounter } from "./WaitingTimeCounter";
import { AppointmentStatus } from "../types/AppointmentStatus";
import App from "@/src/App";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import AppointmentDetails from "./AppointmentDetails";
import { Appointment } from "@pages/appointments/types/Appointment";
import { OutlinedButton } from "@components/button";
import InvoicePage from "@pages/finances/view/InvoicePage";
import LineItemRow from "@pages/finances/view/LineItemRow";
import { Service } from "@common/types/ServiceType";
import { Convert } from "@pages/manage-patient-new/types/BillingDetails";
import { ConfirmationDialog } from "@components/modal/ConfirmationDialog";
import AppointmentLevelBilling from "@pages/payments/AppointmentLevelBilling";

export default function AppointmentTableRow(props: {
	appointmentStatus: AppointmentStatus;
	buttonName: string;
	appointmentList: Appointments[];
	callback: (id: string) => void;
	callbackCompleted: (id: string) => void;
	dateSwitcher: string;
	icon: LucideIcon;
	iconColor: string;
}) {
	const [searchQuery, setSearchQuery] = React.useState("");
	const [addPaymentDetails, setAddPaymentDetails] = useState(false);
	const [cancelDialog, setCancelDialog] = useState(false);
	const [showPaymentDetails, setShowPaymentDetails] = useState(false);
	const [showAppointmentDetails, setShowAppointmentDetails] = useState(false);
	const [isRightOffcanvasPatient, setIsRightOffcanvasPatient] = useState(false);
	const [isRightOffcanvasPatientBilling, setIsRightOffcanvasPatientBilling] = useState(false);

	const [selectedAppointmentId, setSelectedAppointmentId] = useState("");
	const [selectedApptClientId, setSelectedApptClientId] = useState("");
	const [appointmentDetails, setAppointmentDetails] = useState<Appointment | null>(null);

	const axios = useAxios();

	const navigate = useNavigate();

	const [openConfirmationBox, setOpenconfirmationBox] = useState(false);
	const [outstandingAmount, setOutstandingAmount] = useState(0);

	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [appointmentToComplete, setAppointmentToComplete] = useState<any>(null);

	const checkOutstandingAmount = async (appointment: Appointment) => {
		try {
			const paramsMap = new Map<string, string>([["id", appointment.clientId]]);
			const filteredEndpoint = replaceParams(endpoints.patient.getBillingDetails, paramsMap);
			const response = await axios.get(filteredEndpoint);
			const data = response.data["data"];

			const convertedDetails = Convert.toBillingDetails(JSON.stringify(data));
			const outstandingAmount =
				convertedDetails && convertedDetails.clientOutStandingBillAmount
					? convertedDetails.clientOutStandingBillAmount
					: 0;
			setOutstandingAmount(outstandingAmount);
			/* 			if (outstandingAmount !== undefined && outstandingAmount > 0) {
				setOpenconfirmationBox(true);
			} else {
				addInvoice(appointment);
			} */
		} catch (error) {}
	};

	const addInvoice = async (appointment: Appointment) => {
		/** Check any outstanding amount present for client */
		try {
			if (appointment.appointmentType && appointment.appointmentType !== "" && appointment.billAmount === 0) {
				try {
					const paramsMap = new Map<string, string>([["branchId", appointment.entityBranchId || ""]]);
					const filteredEndpoint = replaceParams(endpoints.branch.services, paramsMap);
					const response = await axios.get(filteredEndpoint);
					if (response.status === 200) {
						if (response.data["data"] && response.data["data"].length > 0) {
							let services = response.data["data"];
							if (services) {
								services.forEach((service: Service) => {
									if (service.name === appointment.appointmentType) {
										appointment.billAmount = service.defaultCost;
										return;
									}
								});
							}
						}
					}
				} catch (error) {
					error.error(error);
				}
			}
			setAppointmentDetails(appointment);
			setAddPaymentDetails(true);
		} catch (error) {}
	};

	const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchQuery(event.target.value);
	};

	const filteredAppointments = props.appointmentList?.filter((appointment) => {
		return appointment.clientName.toLowerCase().includes(searchQuery.toLowerCase());
	});

	const updateStatus = async (nextStatus: string, appId: string, appointmentDetails: Appointments) => {
		if (nextStatus) {
			if (nextStatus === AppointmentStatus.Cancelled) {
				setSelectedAppointmentId(appId);
				setCancelDialog(true);
			} else {
				handleStatusChange(appId, nextStatus);
			}
		}
	};
	const handleStatusChange = async (appointmentId: string, appointmentStatus: string) => {
		try {
			const paramsMap = new Map<string, string>([
				["id", appointmentId],
				["status", appointmentStatus],
			]);
			const filteredEndpoint = replaceParams(endpoints.assistantDashboard.updateAppointmentStatus, paramsMap);
			await axios.put(filteredEndpoint);
			if (appointmentStatus === AppointmentStatus.Completed) {
				props.callbackCompleted(appointmentId);
			} else {
				props.callback(appointmentId);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const confirmPaymentAndChangeStatus = async () => {
		try {
			setAddPaymentDetails(false);
			props.callback(selectedAppointmentId);
		} catch (error) {
			console.error(error);
		}
	};

	const handleJoinNowButton = (meetLink: string) => {
		window.open(meetLink, "_blank");
	};

	const handleClientNameClick = (clientId: string) => {
		navigate(`/patient/manage/${clientId}`);
	};

	const uiVersion = getUIVersion();

	const getStatusColor = (status: string) => {
		switch (status) {
			case "Scheduled":
				return "text-blue-500 bg-blue-50";
			case "Waiting":
				return "text-orange-500 bg-orange-50";
			case "Ongoing":
				return "text-purple-500 bg-purple-50";
			case "InSession":
				return "text-purple-500 bg-purple-50";
			case "Completed":
				return "text-green-500 bg-green-50";
			default:
				return "text-gray-500 bg-gray-50";
		}
	};

	const getStatusTextColor = (status: string) => {
		switch (status) {
			case "Scheduled":
				return "text-blue-500 ";
			case "Waiting":
				return "text-orange-500 ";
			case "Ongoing":
				return "text-purple-500 ";
			case "InSession":
				return "text-purple-500";
			case "Completed":
				return "text-green-500";
			default:
				return "text-gray-500 ";
		}
	};

	return (
		<>
			{filteredAppointments.length > 0 && (
				<tbody className="bg-white divide-y divide-gray-200">
					{/* Add a header row with divider for the appointment status section */}
					<tr className="bg-gray-50">
						<td colSpan={7} className="px-4 py-3 border-b border-gray-200">
							<div className="flex items-center">
								<props.icon className={`w-4 h-4 ${props.iconColor}`} />
								<span className={`ml-2 text-sm font-semibold ${props.iconColor}`}>{props.appointmentStatus}</span>
								<div className="ml-auto flex items-center">
									<span className={`text-xs text-gray-800`}>
										{filteredAppointments.length} {filteredAppointments.length === 1 ? "appointment" : "appointments"}
									</span>
								</div>
							</div>
						</td>
					</tr>

					{/* Appointment rows */}
					{filteredAppointments.map((appointment) => (
						<tr
							key={appointment.id}
							className="hover:bg-primary-100 hover:scale-y-110 cursor-pointer text-sm"
							onClick={(event) => {
								// Check if the click originated from the button
								if ((event.target as HTMLElement).closest("button")) {
									return; // If clicked on any button in table, do nothing
								}
								setSelectedApptClientId(appointment.clientId);
								setIsRightOffcanvasPatient(true);
							}}
						>
							<td className="p-1 whitespace-nowrap cursor-pointer overflow-clip">
								<span
									className={`inline-flex items-center px-4 py-2 w-50 h-full rounded-md text-sm font-medium text-gray-600 `}
								>
									{appointment.clientName}
									<ArrowUpRightIcon className=" w-4 h-4 ml-1" />
								</span>
							</td>
							<td className="px-4 py-2 whitespace-nowrap font-medium">
								{appointment.startTime}{" "}
								<Button
									type="button"
									variant={ButtonVariant.GHOST}
									onClick={() => {
										setSelectedAppointmentId(appointment.id);
										setShowAppointmentDetails(true);
									}}
									className="w-1/4 ml-4 sm:w-fit justify-center sm:justify-normal"
								>
									<span className="text-sm"> View </span>
									<ArrowUpRightIcon className="text-gray-500 w-4 h-4 ml-1" />
								</Button>
							</td>
							<td className="px-4 py-2 whitespace-nowrap text-sm">{appointment.appointmentType}</td>
							<td className="px-4 py-2 whitespace-nowrap text-sm">{appointment.entityUserName}</td>
							<td className="px-4 py-2 whitespace-nowrap">
								{props.appointmentStatus === AppointmentStatus.InSession ? (
									<span
										className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusTextColor(
											"InSession"
										)}`}
									>
										<props.icon className={`w-4 h-4 mr-1`} />
										<span className="ml-1">In Session</span>
									</span>
								) : (
									<span
										className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusTextColor(
											props.appointmentStatus
										)}`}
									>
										<props.icon className={`w-4 h-4 mr-1`} />
										<span className="ml-1">{props.appointmentStatus}</span>
									</span>
								)}
							</td>
							<td className="px-4 py-2 whitespace-nowrap">
								{props.appointmentStatus === "Waiting" && (
									<div className="flex flex-col items-left pr-4 font-semibold">
										<span className="text-xs">Waiting</span>
										<WaitingTimeCounterV2 t={appointment.checkInTime} />
									</div>
								)}
								{(props.appointmentStatus === "Ongoing" || props.appointmentStatus === "InSession") && (
									<div className="flex flex-col items-left pr-4 font-semibold">
										<span className="text-sm">In Session</span>
										<WaitingTimeCounterV2 t={appointment.sessionStartTime} />
									</div>
								)}
							</td>
							<td className="px-4 py-2 whitespace-nowrap">
								<div className="flex flex-row w-full justify-between gap-2">
									{props.appointmentStatus === AppointmentStatus.Scheduled && (
										<div>
											<OutlinedButton
												variant={ButtonVariant.GHOST}
												type="button"
												className="mr-2 w-1/2 sm:w-fit justify-center sm:justify-normal text-secondary-700 "
												isDisabled={!(props.dateSwitcher === moment(new Date()).format("DD-MM-YYYY"))}
												onClick={() => updateStatus(AppointmentStatus.Waiting, appointment.id, appointment)}
											>
												<ArrowRightCircleIcon className="w-4 h-4 mr-1" />
												<span className="pl-1 text-xs">Check-in</span>
											</OutlinedButton>
											<OutlinedButton
												variant={ButtonVariant.PRIMARY}
												type="button"
												className="mr-2 w-1/2 sm:w-fit justify-center sm:justify-normal text-primary-700"
												isDisabled={!(props.dateSwitcher === moment(new Date()).format("DD-MM-YYYY"))}
												onClick={() => {
													setAppointmentToComplete(appointment);
													setShowConfirmModal(true);
												}}
											>
												<CheckBadgeIcon className="w-4 h-4 mr-1" />
												<span className="pl-1 text-xs"> Mark Complete </span>
											</OutlinedButton>
										</div>
									)}
									{props.appointmentStatus === AppointmentStatus.InSession && (
										<div>
											<OutlinedButton
												variant={ButtonVariant.PRIMARY}
												type="button"
												className="mr-2 w-1/2 sm:w-fit justify-center sm:justify-normal text-primary-700"
												isDisabled={!(props.dateSwitcher === moment(new Date()).format("DD-MM-YYYY"))}
												onClick={() => {
													setAppointmentToComplete(appointment);
													setShowConfirmModal(true);
												}}
											>
												<CheckBadgeIcon className="w-4 h-4 mr-1" />
												<span className="pl-1 text-xs"> Mark Complete </span>
											</OutlinedButton>
										</div>
									)}
									{props.appointmentStatus === AppointmentStatus.Waiting && (
										<div>
											<OutlinedButton
												variant={ButtonVariant.GHOST}
												type="button"
												className="mr-2 w-1/2 sm:w-fit justify-center sm:justify-normal text-secondary-600"
												isDisabled={!(props.dateSwitcher === moment(new Date()).format("DD-MM-YYYY"))}
												onClick={() => updateStatus(AppointmentStatus.InSession, appointment.id, appointment)}
											>
												<PlayCircleIcon className="w-4 h-4 mr-1 " />
												<span className="pl-1 text-xs">Start Session</span>
											</OutlinedButton>
											<OutlinedButton
												variant={ButtonVariant.PRIMARY}
												type="button"
												className="mr-2 w-1/2 sm:w-fit justify-center sm:justify-normal text-primary-700"
												isDisabled={!(props.dateSwitcher === moment(new Date()).format("DD-MM-YYYY"))}
												onClick={() => {
													setAppointmentToComplete(appointment);
													setShowConfirmModal(true);
												}}
											>
												<CheckBadgeIcon className="w-4 h-4 mr-1" />
												<span className="pl-1 text-xs"> Mark Complete </span>
											</OutlinedButton>
										</div>
									)}
									{props.appointmentStatus !== AppointmentStatus.Completed && appointment.meetLink && (
										<div>
											<button
												className="self-end bg-secondary-300 hover:bg-secondary-500 text-white font-bold py-2 px-4 rounded-xl focus:outline-none focus:shadow-outline mr-3"
												onClick={() => handleJoinNowButton(appointment.meetLink)}
											>
												<span className="pl-1 text-xs">Join</span>
											</button>
										</div>
									)}
									{props.appointmentStatus === AppointmentStatus.Completed && (
										<>
											{appointment.paymentStatus !== "" ? (
												<div>
													<OutlinedButton
														variant={ButtonVariant.GHOST}
														type="button"
														className="mr-2 w-1/2 sm:w-fit justify-center sm:justify-normal "
														onClick={() => {
															setAppointmentDetails(appointment);
															setAddPaymentDetails(true);
														}}
													>
														<BadgeIndianRupeeIcon className="w-4 h-4" />
														<span className="pl-1 text-xs">Bill Paid</span>
													</OutlinedButton>
													<OutlinedButton
														variant={ButtonVariant.PRIMARY}
														className="mr-2 w-1/2 sm:w-fit justify-center sm:justify-normal text-primary-700"
														onClick={() => {
															setSelectedApptClientId(appointment.clientId);
															setIsRightOffcanvasPatientBilling(true);
														}}
													>
														<FileText className="w-4 h-4 mr-1" />
														<span className="pl-1 text-xs"> All Bills & Payments</span>
													</OutlinedButton>
												</div>
											) : (
												<div>
													<OutlinedButton
														variant={ButtonVariant.GHOST}
														className="mr-2 w-1/2 sm:w-fit justify-center sm:justify-normal text-gray-700"
														onClick={() => {
															checkOutstandingAmount(appointment);
															setSelectedApptClientId(appointment.clientId);
															addInvoice(appointment);
														}}
													>
														<Calculator className="w-4 h-4 mr-1" />
														<span className="pl-1 text-xs">Bill this Session</span>
													</OutlinedButton>
													<OutlinedButton
														variant={ButtonVariant.PRIMARY}
														className="mr-2 w-1/2 sm:w-fit justify-center sm:justify-normal text-primary-700"
														onClick={() => {
															setSelectedApptClientId(appointment.clientId);
															setIsRightOffcanvasPatientBilling(true);
														}}
													>
														<FileText className="w-4 h-4 mr-1" />
														<span className="pl-1 text-xs"> All Bills & Payments</span>
													</OutlinedButton>
												</div>
											)}
										</>
									)}
								</div>
							</td>
						</tr>
					))}
				</tbody>
			)}

			{addPaymentDetails && (
				<AppointmentLevelBilling
					appointmentDetails={appointmentDetails!}
					onClose={() => setAddPaymentDetails(false)}
					outStandingAmount={outstandingAmount}
					onConfirm={confirmPaymentAndChangeStatus}
					openClientBilling={() => setIsRightOffcanvasPatientBilling(true)}
				/>
			)}

			{showPaymentDetails && (
				<ViewPaymentDetails
					onClose={() => setShowPaymentDetails(false)}
					onConfirm={confirmPaymentAndChangeStatus}
					appointmentId={selectedAppointmentId}
					appointmentDetails={appointmentDetails!}
					setAddPaymentDetails={setAddPaymentDetails}
				/>
			)}

			{openConfirmationBox && appointmentDetails && (
				<ConfirmationDialog
					onClose={() => {
						setOpenconfirmationBox(false);
						setIsRightOffcanvasPatientBilling(true);
					}}
					onConfirm={() => {
						setOpenconfirmationBox(false);
						addInvoice(appointmentDetails);
					}}
					confirmationButtonText="Yes, Create New Bill"
					cancelText="View & Update Outstanding Bills"
					confirmationText={`${appointmentDetails.clientName} has unpaid bills. It is recommended to update the existing bill instead of adding a new one. Do you still want to proceed with a new bill?`}
				/>
			)}

			{cancelDialog && (
				<CancelAppointmentDialog
					onClose={() => setCancelDialog(false)}
					appId={selectedAppointmentId}
					callback={props.callback}
				/>
			)}
			{showAppointmentDetails && (
				<AppointmentDetails onClose={() => setShowAppointmentDetails(false)} appId={selectedAppointmentId} />
			)}
			{isRightOffcanvasPatient && (
				<RightOffcanvas
					isOpen={isRightOffcanvasPatient}
					onClose={() => {
						setIsRightOffcanvasPatient(false);
					}}
					customMaxWidthClass="xl:max-w-[75vw]"
				>
					<div className="flex flex-col w-full">
						<div className="flex justify-end items-end w-full mb-2">
							<Button
								variant={ButtonVariant.SECONDARY}
								type="button"
								onClick={() => {
									setIsRightOffcanvasPatient(false);
								}}
							>
								Close
							</Button>
						</div>
						<div className="">
							<PatientRecord inputPatientId={selectedApptClientId} defaultTab="pe" />
						</div>
					</div>
				</RightOffcanvas>
			)}
			{isRightOffcanvasPatientBilling && (
				<RightOffcanvas
					isOpen={isRightOffcanvasPatientBilling}
					onClose={() => {
						setIsRightOffcanvasPatientBilling(false);
					}}
					customMaxWidthClass="xl:max-w-[75vw]"
				>
					<div className="flex flex-col w-full">
						<div className="flex justify-end items-end w-full mb-2">
							<Button
								variant={ButtonVariant.SECONDARY}
								type="button"
								onClick={() => {
									setIsRightOffcanvasPatientBilling(false);
								}}
							>
								Close
							</Button>
						</div>
						<div className="">
							<PatientRecord inputPatientId={selectedApptClientId} defaultTab="billing" />
						</div>
					</div>
				</RightOffcanvas>
			)}
			{showConfirmModal && (
				<div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
					<div className="bg-white rounded-lg p-6 max-w-md w-full shadow-xl">
						<h3 className="text-lg font-medium text-gray-900 mb-4">Confirm Completion</h3>
						<p className="text-sm text-gray-500 mb-6">Are you sure you want to mark this appointment as complete?</p>
						<div className="flex justify-end space-x-3">
							<button
								onClick={() => setShowConfirmModal(false)}
								className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 transition-colors"
							>
								Cancel
							</button>
							<button
								onClick={() => {
									updateStatus(AppointmentStatus.Completed, appointmentToComplete.id, appointmentToComplete);
									setShowConfirmModal(false);
								}}
								className="px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 transition-colors"
							>
								Confirm
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

function WaitingTimeCounterV2(props: { t: Date }) {
	const [waitingTime, setWaitingTime] = useState<string>("");

	useEffect(() => {
		const updateWaitingTime = () => {
			const duration = moment.duration(moment().diff(moment(props.t)));
			const hours = duration.hours();
			const minutes = duration.minutes();

			let timeString = "";
			if (hours > 0) {
				timeString += `${hours}h `;
			}
			timeString += `${minutes}m`;

			setWaitingTime(timeString);
		};

		updateWaitingTime();
		const intervalId = setInterval(updateWaitingTime, 60000);

		return () => clearInterval(intervalId);
	}, [props.t]);

	return <span className={`text-xs`}>{waitingTime}</span>;
}
