import React from "react";

type Props = {
  label: string;
  mandatory?: boolean;
  className?: string;
};
export default function applebel({ label, mandatory = false }: Props) {
  return (
    <label className="block text-sm font-medium leading-6 text-grey-900 mt-2.5">
      {label} {mandatory && <span className="text-red-500">*</span>}
    </label>
  );
}

export function applebelCustom({ className, label, mandatory = false }: Props) {
  return (
    <label className={`block text-sm  leading-6 mt-2.5 ${className}`}>
      {label} {mandatory && <span className="text-red-500">*</span>}
    </label>
  );
}
