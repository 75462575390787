import React from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { Cog6ToothIcon, UserIcon } from "@heroicons/react/24/outline";
import { Building2, LogOutIcon } from "lucide-react";

interface DashboardNavigationProps {
  initialData: {
    entityLogoUrl?: string;
    EntityName?: string;
    EntityBranchName?: string;
    RoleName?: string;
  };
  logo: string;
  navigation: Array<{
    category: string;
    name?: string;
    href?: string;
    icon?: React.ReactNode;
    items: Array<{ name: string; href: string; icon: React.ReactNode }>;
  }>;
  isRouteAccessible: (href: string) => boolean;
  activeTabColorClasses: string;
  inActiveTabColorClasses: string;
  initials: string;
  formatRoleName: (roleName: string) => string;
  navigateStaffEntityManage: () => void;
  navigateStaffBranchManage: () => void;
  navigateViewStaff: () => void;
  logout: () => void;
  onClick?: () => void;
}

const DashboardNavigation: React.FC<DashboardNavigationProps> = ({
  initialData,
  logo,
  navigation,
  isRouteAccessible,
  activeTabColorClasses,
  inActiveTabColorClasses,
  initials,
  formatRoleName,
  navigateStaffEntityManage,
  navigateStaffBranchManage,
  navigateViewStaff,
  logout,
  onClick,
}) => {
   const userNavigation = [
    {
      name: "Your profile",
      icon: <UserIcon className="h-5 w-5 mr-2" />,
      callBack: navigateViewStaff,
    },

     {
       name: "Manage Entity",
       icon: <Building2 className="h-5 w-5 mr-2" />,
       role: "ENTITY_OWNER",
       callBack: navigateStaffEntityManage,
     },
     {
       name: "Manage Branch",
       icon: <Building2 className="h-5 w-5 mr-2" />,
       role: "BRANCH_ADMIN",
       callBack: navigateStaffBranchManage,
     },
     {
       name: "Sign out",
       icon: <LogOutIcon className="h-5 w-5 mr-2" />,
       callBack: logout,
     },
   ];
  return (
    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white border-r">
      <div className="flex shrink-0 justify-center w-full border-r">
        {initialData?.entityLogoUrl ? (
          <img
            src={initialData?.entityLogoUrl}
            alt="Entity Logo"
            className=" object-center object-cover h-16"
          />
        ) : (
          <img
            src={logo}
            alt="Default Logo"
            className="h-16 object-center object-cover"
          />
        )}
      </div>
      <nav className="flex flex-1 flex-col px-4 border-r">
        <ul className="flex flex-1 flex-col gap-y-2 ">
          {navigation.map((group, index) => (
            <li key={group.category}>
              {group.category !== "main" && (
                <h3 className="text-sm font-semibold text-gray-700 py-1">
                  {group.category}
                </h3>
              )}
              <ul className="-mx-2">
                {group.items.map((item) => (
                  <li key={item.name}>
                    <NavLink to={item.href}>
                      {({ isActive }) => (
                        <span
                          className={classNames(
                            isActive
                              ? activeTabColorClasses
                              : inActiveTabColorClasses,
                            "group flex gap-x-3 rounded-xl p-2 text-sm leading-6 font-medium"
                          )}
                        >
                          {item.icon}
                          {item.name}
                        </span>
                      )}
                    </NavLink>
                  </li>
                ))}
              </ul>
                <hr className="border-t border-primary-200" />
            </li>
          ))}
          <li key="userNavigation">
            <h3 className="text-sm font-semibold text-gray-700">Manage</h3>
            <ul className="-mx-2">
              {userNavigation.map(
                (item) =>
                  (!item.role || item.role === initialData?.RoleName) && (
                    <li key={item.name}>
                      <span
                        onClick={item.callBack}
                        className={classNames(
                          inActiveTabColorClasses,
                          "group flex gap-x-3 rounded-xl p-2 text-sm leading-6 font-medium"
                        )}
                      >
                        {item.icon}
                        {item.name}
                      </span>
                    </li>
                  )
              )}
            </ul>
          </li>
        </ul>
      </nav>
      {/* 
      <div className="mt-auto space-y-4">
        <div className="flex flex-col justify-between rounded-xl shadow-md bg-white w-full overflow-clip">
          <div className="flex flex-row rounded-xl bg-white-300 items-center text-xs font-semibold space-x-2 p-4 pb-0 w-full">
            <span
              className={classNames(
                "text-tertiary-600 border-tertiary-600 group-hover:border-tertiary-600 group-hover:text-tertiary-600",
                "flex h-10 w-10 shrink-0 items-center justify-center rounded-lg border text-lg font-extrabold bg-white "
              )}
            >
              {initials}
            </span>
            <div className="flex flex-col w-full ">
              <span className="whitespace-nowrap overflow-hidden truncate">
                {initialData?.EntityName}
              </span>
              <span className="whitespace-nowrap overflow-hidden truncate">
                {initialData?.EntityBranchName}
              </span>
            </div>
          </div>

          <div className="flex flex-row rounded-bl-xl rounded-br-xl bg-tertiary-500 items-center px-4 py-2 mt-2">
            <div className="flex flex-row w-full justify-center font-semibold text-white text-sm">
              {formatRoleName(initialData?.RoleName ?? "")}
            </div>
          </div>
        </div>
        {initialData?.RoleName === "ENTITY_OWNER" && (
          <>
            <span
              className="group -mx-2 flex gap-x-3 rounded-md p-2 text-base font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-tertiary-600"
              onClick={navigateStaffEntityManage}
            >
              <Cog6ToothIcon
                className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-tertiary-600"
                aria-hidden="true"
              />
              Manage Entity
            </span>
          </>
        )}
        {initialData?.RoleName === "BRANCH_ADMIN" && (
          <>
            <span
              className="group -mx-2 flex gap-x-3 rounded-md p-2 text-base font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-tertiary-600"
              onClick={navigateStaffBranchManage}
            >
              <Cog6ToothIcon
                className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-tertiary-600"
                aria-hidden="true"
              />
              Manage Branch
            </span>
          </>
        )}
      </div> */}
    </div>
  );
};

export default DashboardNavigation;
