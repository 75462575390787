import React, { Suspense, useEffect, useState } from "react";
import { NewMasterAssessmentData } from "../types/NewMasterAssessment";
import Modal from "@components/modal";
import { Activity, ClipboardList, FileText, Icon, Stethoscope } from "lucide-react";
import { OutlinedButton } from "@components/button";
import { ButtonVariant } from "@components/button/button";
// import DocumentPreview from "./document-preview/DocumentPreview";
import { lazy } from "react";

const DocumentPreview = lazy(() => import("./document-preview/DocumentPreview"));

const initialData = {
	medicalHistory: {
		chiefComplaint: true,
		historyOfPresentIllness: true,
		historyOfPastIllness: true,
		preExistingHealthConditions: true,
		pastTreatment: true,
		surgeries: true,
		familyMedicalHistory: true,
	},
	physicalAssessments: {
		vitals: true,
		pain: true,
	},
	diagnosis: {
		provisionalDiagnosis: true,
		differentialDiagnosis: true,
		finalDiagnosis: true,
		radiologicalFindings: true,
		medicalFindings: true,
	},
	treatmentPlan: {
		prescription: true,
		exerciseLibrary: true,
		goals: true,
	},
};

type PDFProps = {
	isOpen: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	patientId: string;
	masterAssessmentData: NewMasterAssessmentData | null;
};

const ExaminationDialog = (props: PDFProps) => {
	const [prescription, setPrescription] = useState(() => {
		let prescriptionSettings = localStorage.getItem("prescriptionSettings");
		return prescriptionSettings ? JSON.parse(prescriptionSettings) : initialData;
	});

	const [selectAll, setSelectAll] = useState(false);
	const [rememberPrescriptionSettings, setRememberPrescriptionSettings] = useState(() => {
		let prescriptionSettings = localStorage.getItem("prescriptionSettings");
		return prescriptionSettings !== null ? true : false;
	});
	const [isNextOpen, setIsNextOpen] = useState<boolean>(false);
	let prescriptionData = {
		medicalHistory: [
			"chiefComplaint",
			"historyOfPresentIllness",
			"historyOfPastIllness",
			"preExistingHealthConditions",
			"pastTreatment",
			"surgeries",
			"familyMedicalHistory",
		],
		medicalHistoryLables: [
			"Chief Complaint",
			"History Of Present Illness",
			"History Of Past Illness",
			"Pre-Existing Health Conditions",
			"Past Treatment",
			"Surgeries",
			"Family Medical History",
		],
		physicalAssessments: ["vitals", "pain"],
		physicalAssessmentsLables: ["Vitals", "Pain"],
		diagnosisLables: [
			"Provisional Diagnosis",
			"Differential Diagnosis",
			"Final Diagnosis",
			"Radiological Findings",
			"MedicalFindings",
		],
		diagnosis: [
			"provisionalDiagnosis",
			"differentialDiagnosis",
			"finalDiagnosis",
			"radiologicalFindings",
			"medicalFindings",
		],
		treatmentPlan: ["prescription", "exerciseLibrary", "goals"],
		treatmentPlanLables: ["Prescription", "Exercise Library", "Goals"],
	};

	const handleMedicalHistoryChange = (event: any) => {
		let { name, checked } = event.target;
		setPrescription((prev: any) => ({
			...prev,
			medicalHistory: {
				...prev.medicalHistory,
				[name]: checked,
			},
		}));
		if (prescription == initialData) {
			setSelectAll(true);
		} else {
			setSelectAll(false);
		}
		if (rememberPrescriptionSettings == true) {
			localStorage.setItem(
				"prescriptionSettings",
				JSON.stringify({
					...prescription,
					medicalHistory: {
						...prescription.medicalHistory,
						[name]: checked,
					},
				})
			);
		}
	};
	const handlePhysicalAssessmentsChange = (event: any) => {
		let { name, checked } = event.target;
		setPrescription((prev: any) => ({
			...prev,
			physicalAssessments: {
				...prev.physicalAssessments,
				[name]: checked,
			},
		}));
		if (prescription == initialData) {
			setSelectAll(true);
		} else {
			setSelectAll(false);
		}
		if (rememberPrescriptionSettings == true) {
			localStorage.setItem(
				"prescriptionSettings",
				JSON.stringify({
					...prescription,
					physicalAssessments: {
						...prescription.physicalAssessments,
						[name]: checked,
					},
				})
			);
		}
	};
	const handleDiagnosisChange = (event: any) => {
		let { name, checked } = event.target;
		setPrescription((prev: any) => ({
			...prev,
			diagnosis: {
				...prev.diagnosis,
				[name]: checked,
			},
		}));
		if (prescription == initialData) {
			setSelectAll(true);
		} else {
			setSelectAll(false);
		}
		if (rememberPrescriptionSettings == true) {
			localStorage.setItem(
				"prescriptionSettings",
				JSON.stringify({
					...prescription,
					diagnosis: {
						...prescription.diagnosis,
						[name]: checked,
					},
				})
			);
		}
	};
	const handleTreatmentPlanChange = (event: any) => {
		let { name, checked } = event.target;
		setPrescription((prev: any) => ({
			...prev,
			treatmentPlan: {
				...prev.treatmentPlan,
				[name]: checked,
			},
		}));
		if (prescription == initialData) {
			setSelectAll(true);
		} else {
			setSelectAll(false);
		}
		if (rememberPrescriptionSettings == true) {
			localStorage.setItem(
				"prescriptionSettings",
				JSON.stringify({
					...prescription,
					treatmentPlan: {
						...prescription.treatmentPlan,
						[name]: checked,
					},
				})
			);
		}
	};
	const handleSelectAll = (event: any) => {
		const { name, checked } = event.target;
		setSelectAll(checked);
		setPrescription({
			medicalHistory: {
				chiefComplaint: checked,
				historyOfPresentIllness: checked,
				historyOfPastIllness: checked,
				preExistingHealthConditions: checked,
				pastTreatment: checked,
				surgeries: checked,
				familyMedicalHistory: checked,
			},
			physicalAssessments: {
				vitals: checked,
				pain: checked,
			},
			diagnosis: {
				provisionalDiagnosis: checked,
				differentialDiagnosis: checked,
				finalDiagnosis: checked,
				radiologicalFindings: checked,
				medicalFindings: checked,
			},
			treatmentPlan: {
				prescription: checked,
				exerciseLibrary: checked,
				goals: checked,
			},
		});
		if (rememberPrescriptionSettings == true) {
			localStorage.setItem(
				"prescriptionSettings",
				JSON.stringify({
					...prescription,
				})
			);
		}
	};
	const handleRememberPrescriptionSettings = (event: any) => {
		const { name, checked } = event.target;

		setRememberPrescriptionSettings(checked);

		if (checked == true) {
			localStorage.setItem("prescriptionSettings", JSON.stringify(prescription));
		} else {
			localStorage.removeItem("prescriptionSettings");
		}
	};
	const handleNext = () => {
		setIsNextOpen(true);
	};

	return (
		<Modal isOpen={props.isOpen} onClose={() => {}}>
			<div className="relative flex flex-col w-[30rem]">
				<div className="bg-white border-b border-gray-200 pb-1 px-6 pt-6">
					<div className="flex items-center gap-3">
						<FileText className="w-6 h-6 text-primary-600" />
						<h1 className="text-2xl font-bold text-gray-800">Examination Report</h1>
					</div>
				</div>

				<div className="flex flex-row justify-between items-center px-6 mt-2">
					<label htmlFor="selectAll">Select All</label>
					<input
						id="selectAll"
						name="selectAll"
						type="checkbox"
						onChange={handleSelectAll}
						checked={selectAll}
						className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded "
					/>
				</div>

				<div className="flex px-6 flex-col">
					<div className="flex items-center gap-2 mb-2 mt-2">
						<ClipboardList className="w-5 h-5 text-primary-600" />
						<h3 className="text-lg font-semibold text-gray-800">Medical History</h3>
					</div>
					{prescriptionData.medicalHistory.map((elememt, idx) => (
						<div className="flex flex-row justify-between items-center">
							<label htmlFor={elememt}>{prescriptionData.medicalHistoryLables[idx]}</label>
							<input
								id={elememt}
								name={elememt}
								type="checkbox"
								onChange={handleMedicalHistoryChange}
								checked={prescription.medicalHistory[elememt]}
								className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
							/>
						</div>
					))}
					<div className="flex items-center gap-2 mb-2 mt-2">
						<Activity className="w-5 h-5 text-primary-600" />
						<h3 className="text-lg font-semibold text-gray-800">Physical Assessments</h3>
					</div>
					{prescriptionData.physicalAssessments.map((elememt, idx) => (
						<div className="flex flex-row justify-between items-center">
							<label htmlFor={elememt}>{prescriptionData.physicalAssessmentsLables[idx]}</label>
							<input
								id={elememt}
								name={elememt}
								type="checkbox"
								onChange={handlePhysicalAssessmentsChange}
								checked={prescription.physicalAssessments[elememt]}
								className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
							/>
						</div>
					))}
					<div className="flex items-center gap-2 mb-2 mt-2">
						<Stethoscope className="w-5 h-5 text-primary-600" />
						<h3 className="text-lg font-semibold text-gray-800">Diagnosis</h3>
					</div>
					{prescriptionData.diagnosis.map((elememt, idx) => (
						<div className="flex flex-row justify-between items-center">
							<label htmlFor={elememt}>{prescriptionData.diagnosisLables[idx]}</label>
							<input
								id={elememt}
								name={elememt}
								type="checkbox"
								onChange={handleDiagnosisChange}
								checked={prescription.diagnosis[elememt]}
								className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
							/>
						</div>
					))}
					<div className="flex items-center gap-2 mb-2 mt-2">
						<ClipboardList className="w-5 h-5 text-primary-600" />
						<h3 className="text-lg font-semibold text-gray-800">Treatment Plan</h3>
					</div>
					{prescriptionData.treatmentPlan.map((elememt, idx) => (
						<div className="flex flex-row justify-between items-center">
							<label htmlFor={elememt}>{prescriptionData.treatmentPlanLables[idx]}</label>
							<input
								id={elememt}
								name={elememt}
								type="checkbox"
								onChange={handleTreatmentPlanChange}
								checked={prescription.treatmentPlan[elememt]}
								className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
							/>
						</div>
					))}
				</div>

				<hr className="my-2 mx-6"></hr>

				<div className="flex flex-row justify-between items-center px-6">
					<label htmlFor="rememberPrescriptionSettings">Remember Settings</label>
					<input
						id="rememberPrescriptionSettings"
						name="rememberPrescriptionSettings"
						type="checkbox"
						onChange={handleRememberPrescriptionSettings}
						checked={rememberPrescriptionSettings}
						className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded "
					/>
				</div>

				<div className="flex justify-between bg-gray-100 px-8 py-6 w-full mt-1">
					<OutlinedButton
						variant={ButtonVariant.PRIMARY}
						type="button"
						onClick={() => props.setIsOpen(false)}
						children="Close"
					/>
					<OutlinedButton variant={ButtonVariant.PRIMARY} type="button" onClick={handleNext} children="Next >" />
				</div>

				{isNextOpen && (
					<Suspense fallback={<div>Loading...</div>}>
						<DocumentPreview
							assessment={props.masterAssessmentData}
							isViewModalOpen={true}
							setIsViewModalOpen={setIsNextOpen}
							prescriptionSettings={prescription}
						></DocumentPreview>
					</Suspense>
				)}
			</div>
		</Modal>
	);
};

export default ExaminationDialog;
