import React, { useEffect, useState } from "react";
import { Pain } from "../type/PainAssessment";
import { Box, Slider } from "@mui/material";
import { InputField } from "@components/hook-form";

type PainType = {
	painAssessment: Pain | null;
	intensity: number;
};

function AssessmentCard({ title, children }: { title: string; children: React.ReactNode }) {
	return (
		<div className="bg-white rounded-lg shadow p-4">
			<h3 className="text-lg font-semibold text-gray-800 mb-3">{title}</h3>
			{children}
		</div>
	);
}

function CheckboxItem({ label, checked }: { label: string; checked: boolean }) {
	return (
		<label className="flex items-center space-x-2 text-gray-700 hover:bg-gray-50 p-1.5 rounded transition-colors">
			<input
				type="checkbox"
				checked={checked}
				disabled={true}
				className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
			/>
			<span className="text-sm">{label}</span>
		</label>
	);
}

const PainAssessment = (props: PainType) => {
	const [mood, setMood] = useState<string | null>(null);

	const marks = [
		{
			value: 0,
			label: "",
		},
		{
			value: 0.5,
			label: "no pain",
		},
		{
			value: 1,
			label: "",
		},
		{
			value: 2,
			label: "mild",
		},
		{
			value: 3,
			label: "",
		},
		{
			value: 4,
			label: "moderate",
		},
		{
			value: 5,
			label: "",
		},
		{
			value: 6,
			label: "severe",
		},
		{
			value: 7,
			label: "",
		},
		{
			value: 8,
			label: "very severe",
		},
		{
			value: 9,
			label: "",
		},
		{
			value: 9.5,
			label: "worst",
		},
		{
			value: 10,
			label: "",
		},
	];

	function setMoodForIntensity(intensity: any) {
		if (props.intensity <= 1) {
			setMood("😃");
		} else if (props.intensity > 1 && props.intensity <= 3) {
			setMood("🙂");
		} else if (props.intensity > 3 && props.intensity <= 5) {
			setMood("😑");
		} else if (props.intensity > 5 && props.intensity <= 7) {
			setMood("🙁");
		} else if (props.intensity > 7 && props.intensity <= 9) {
			setMood("😣");
		} else if (props.intensity > 9) {
			setMood("😢");
		}
	}

	useEffect(() => {
		setMoodForIntensity(props.intensity);
	}, []);

	return (
		<div>
			{props.painAssessment !== null ? (
				<div className="">
					<h3 className="mb-4 font-bold text-tertiary-600">Pain Assessment</h3>
					<div className="flex flex-col">
						<table className="table-auto  text-left text-md border-collapse border border-tertiary-500 w-full">
							<tbody>
								{props.painAssessment?.progression === "" ? (
									<tr></tr>
								) : (
									<tr className="border  border-2 border-tertiary-500">
										<td className="border border-r-2 border-tertiary-500 px-6 py-4 font-medium">Progression</td>
										<td className="flex justify-center px-6 py-4 ">
											<p>{props.painAssessment.progression}</p>
										</td>
									</tr>
								)}
							</tbody>
						</table>

						<div className="flex flex-col mt-6 border border-2 border-tertiary-500">
							<div className="border px-2 py-4 w-full font-medium">Pain Intensity</div>
							<div className="flex justify-center px-2 py-8 border-t-2 border-tertiary-500 items-center">
								<Box sx={{ width: 500 }}>
									<Slider
										aria-label="Custom marks"
										value={props.intensity}
										step={1}
										min={0}
										max={10}
										marks={marks}
										disabled={true}
										valueLabelDisplay="on"
										sx={{
											color: "transparent",
											height: 14,
											"& .MuiSlider-track": {
												height: 14,
												background: "transparent",
											},
											"& .MuiSlider-rail": {
												height: 14,
												background: "linear-gradient(to right, green, red)",
												maxHeight: 14,
											},
											"& .MuiSlider-thumb": {
												height: 24,
												width: 24,
												backgroundColor: "white",
												border: "2px solid green",
											},
										}}
									/>
								</Box>
								<div className="flex flex-row w-14 ml-6">
									<p style={{ fontSize: "35px" }}>{mood}</p>
								</div>
							</div>
						</div>

						<div className="flex flex-col">
							{props.painAssessment.headPainLeft !== true &&
							props.painAssessment.headPainRight !== true &&
							props.painAssessment.neckPainLeft !== true &&
							props.painAssessment.neckPainRight !== true &&
							props.painAssessment.axillaPainLeft !== true &&
							props.painAssessment.axillaPainRight !== true &&
							props.painAssessment.scapulaPainLeft !== true &&
							props.painAssessment.scapulaPainRight !== true &&
							props.painAssessment.shoulderPainLeft !== true &&
							props.painAssessment.shoulderPainRight !== true &&
							props.painAssessment.armPainLeft !== true &&
							props.painAssessment.armPainRight !== true &&
							props.painAssessment.elbowPainLeft !== true &&
							props.painAssessment.elbowPainRight !== true &&
							props.painAssessment.forearmPainLeft !== true &&
							props.painAssessment.forearmPainRight !== true &&
							props.painAssessment.wristPainLeft !== true &&
							props.painAssessment.wristPainRight !== true &&
							props.painAssessment.thumbPainLeft !== true &&
							props.painAssessment.thumbPainRight !== true &&
							props.painAssessment.palmPainLeft !== true &&
							props.painAssessment.palmPainRight !== true &&
							props.painAssessment.handPainLeft !== true &&
							props.painAssessment.handPainRight !== true &&
							props.painAssessment.chestPainLeft !== true &&
							props.painAssessment.chestPainRight !== true &&
							props.painAssessment.backPainLeft !== true &&
							props.painAssessment.backPainRight !== true &&
							props.painAssessment.sacrolliacPainLeft !== true &&
							props.painAssessment.sacrolliacPainRight !== true &&
							props.painAssessment.hipPainLeft !== true &&
							props.painAssessment.hipPainRight !== true &&
							props.painAssessment.thighPainLeft !== true &&
							props.painAssessment.thighPainRight !== true &&
							props.painAssessment.iTBandPainLeft !== true &&
							props.painAssessment.iTBandPainRight !== true &&
							props.painAssessment.kneePainLeft !== true &&
							props.painAssessment.kneePainRight !== true &&
							props.painAssessment.shinPainLeft !== true &&
							props.painAssessment.shinPainRight !== true &&
							props.painAssessment.anklePainLeft !== true &&
							props.painAssessment.anklePainRight !== true &&
							props.painAssessment.heelPainLeft !== true &&
							props.painAssessment.heelPainRight !== true &&
							props.painAssessment.greatToePainLeft !== true &&
							props.painAssessment.greatToePainRight !== true ? (
								<div></div>
							) : (
								<table className="table-auto text-left text-md border-collapse border border-2 border-tertiary-500 w-full mt-5">
									<thead className="bg-gray-50 bg-tertiary-100">
										<tr>
											<th className="border lg:px-6 px-2 py-4 border-tertiary-500 text-left">Pain Area</th>
											<th className="border lg:px-6 px-2 py-4 border-tertiary-500 text-left">Left</th>
											<th className="border lg:px-6 px-2 py-4 border-tertiary-500 text-left">Right</th>
										</tr>
									</thead>
									<tbody>
										{props.painAssessment.headPainLeft === true || props.painAssessment.headPainRight === true ? (
											<tr key={"Head"} className="border border-tertiary-500">
												<td className="px-2 py-4 border border-right border-tertiary-500">Head</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.headPainLeft === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500 ">
													<span>
														{props.painAssessment.headPainRight === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
											</tr>
										) : (
											<tr></tr>
										)}

										{props.painAssessment.neckPainLeft === true || props.painAssessment.neckPainRight === true ? (
											<tr key={"Neck"} className="border border-tertiary-500">
												<td className="px-2 py-4 border border-right border-tertiary-500">Neck</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.neckPainLeft === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.neckPainRight === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
											</tr>
										) : (
											<tr></tr>
										)}

										{props.painAssessment.axillaPainLeft === true || props.painAssessment.axillaPainRight === true ? (
											<tr key={"Axilla"} className="border border-tertiary-500">
												<td className="px-2 py-4 border border-right border-tertiary-500">Axilla</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.axillaPainLeft === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.axillaPainRight === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
											</tr>
										) : (
											<tr></tr>
										)}

										{props.painAssessment.scapulaPainLeft === true || props.painAssessment.scapulaPainRight === true ? (
											<tr key={"Scapula"} className="border border-tertiary-500">
												<td className="px-2 py-4 border border-right border-tertiary-500">Scapula</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.scapulaPainLeft === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.scapulaPainRight === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
											</tr>
										) : (
											<tr></tr>
										)}

										{props.painAssessment.shoulderPainLeft === true ||
										props.painAssessment.shoulderPainRight === true ? (
											<tr key={"Shoulder"} className="border border-tertiary-500">
												<td className="px-2 py-4 border border-right border-tertiary-500">Shoulder</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.shoulderPainLeft === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.shoulderPainRight === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
											</tr>
										) : (
											<tr></tr>
										)}

										{props.painAssessment.armPainLeft === true || props.painAssessment.armPainRight === true ? (
											<tr key={"Arm"} className="border border-tertiary-500">
												<td className="px-2 py-4 border border-right border-tertiary-500">Arm</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.armPainLeft === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.armPainRight === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
											</tr>
										) : (
											<tr></tr>
										)}

										{props.painAssessment.elbowPainLeft === true || props.painAssessment.elbowPainRight === true ? (
											<tr key={"Elbow"} className="border border-tertiary-500">
												<td className="px-2 py-4 border border-right border-tertiary-500">Elbow</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.elbowPainLeft === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.elbowPainRight === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
											</tr>
										) : (
											<tr></tr>
										)}

										{props.painAssessment.forearmPainLeft === true || props.painAssessment.forearmPainRight === true ? (
											<tr key={"Forearm"} className="border border-tertiary-500">
												<td className="px-2 py-4 border border-right border-tertiary-500">Forearm</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.forearmPainLeft === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.forearmPainRight === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
											</tr>
										) : (
											<tr></tr>
										)}

										{props.painAssessment.wristPainLeft === true || props.painAssessment.wristPainRight === true ? (
											<tr key={"Wrist"} className="border border-tertiary-500">
												<td className="px-2 py-4 border border-right border-tertiary-500">Wrist</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.wristPainLeft === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.wristPainRight === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
											</tr>
										) : (
											<tr></tr>
										)}

										{props.painAssessment.thumbPainLeft === true || props.painAssessment.thumbPainRight === true ? (
											<tr key={"Thumb"} className="border border-tertiary-500">
												<td className="px-2 py-4 border border-right border-tertiary-500">Thumb</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.thumbPainLeft === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.thumbPainRight === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
											</tr>
										) : (
											<tr></tr>
										)}

										{props.painAssessment.palmPainLeft === true || props.painAssessment.palmPainRight === true ? (
											<tr key={"Palm"} className="border border-tertiary-500">
												<td className="px-2 py-4 border border-right border-tertiary-500">Palm</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.palmPainLeft === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.palmPainRight === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
											</tr>
										) : (
											<tr></tr>
										)}

										{props.painAssessment.handPainLeft === true || props.painAssessment.handPainRight === true ? (
											<tr key={"Hand"} className="border border-tertiary-500">
												<td className="px-2 py-4 border border-right border-tertiary-500">Hand</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.handPainLeft === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.handPainRight === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
											</tr>
										) : (
											<tr></tr>
										)}

										{props.painAssessment.chestPainLeft === true || props.painAssessment.chestPainRight === true ? (
											<tr key={"Chest"} className="border border-tertiary-500">
												<td className="px-2 py-4 border border-right border-tertiary-500">Chest</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.chestPainLeft === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.chestPainRight === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
											</tr>
										) : (
											<tr></tr>
										)}

										{props.painAssessment.upperBackPainLeft === true ||
										props.painAssessment.upperBackPainRight === true ? (
											<tr key={"Upper Back"} className="border border-tertiary-500">
												<td className="px-2 py-4 border border-right border-tertiary-500">Upper Back</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.upperBackPainLeft === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.upperBackPainRight === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
											</tr>
										) : (
											<tr></tr>
										)}

										{props.painAssessment.backPainLeft === true || props.painAssessment.backPainRight === true ? (
											<tr key={"Back"} className="border border-tertiary-500">
												<td className="px-2 py-4 border border-right border-tertiary-500">Mid Back</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.backPainLeft === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.backPainRight === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
											</tr>
										) : (
											<tr></tr>
										)}

										{props.painAssessment.lowerBackPainLeft === true ||
										props.painAssessment.lowerBackPainRight === true ? (
											<tr key={"Upper Back"} className="border border-tertiary-500">
												<td className="px-2 py-4 border border-right border-tertiary-500">Lower Back</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.lowerBackPainLeft === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.lowerBackPainRight === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
											</tr>
										) : (
											<tr></tr>
										)}

										{props.painAssessment.sacrolliacPainLeft === true ||
										props.painAssessment.sacrolliacPainRight === true ? (
											<tr key={"Sacrolliac"} className="border border-tertiary-500">
												<td className="px-2 py-4 border border-right border-tertiary-500">Sacrolliac</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.sacrolliacPainLeft === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.sacrolliacPainRight === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
											</tr>
										) : (
											<tr></tr>
										)}

										{props.painAssessment.hipPainLeft === true || props.painAssessment.hipPainRight === true ? (
											<tr key={"Hip"} className="border border-tertiary-500">
												<td className="px-2 py-4 border border-right border-tertiary-500">Hip</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.hipPainLeft === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.hipPainRight === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
											</tr>
										) : (
											<tr></tr>
										)}

										{props.painAssessment.thighPainLeft === true || props.painAssessment.thighPainRight === true ? (
											<tr key={"Thigh"} className="border border-tertiary-500">
												<td className="px-2 py-4 border border-right border-tertiary-500">Thigh</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.thighPainLeft === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.thighPainRight === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
											</tr>
										) : (
											<tr></tr>
										)}

										{props.painAssessment.iTBandPainLeft === true || props.painAssessment.iTBandPainRight === true ? (
											<tr key={"I-T-Band"} className="border border-tertiary-500">
												<td className="px-2 py-4 border border-right border-tertiary-500">I-T Band</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.iTBandPainLeft === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.iTBandPainRight === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
											</tr>
										) : (
											<tr></tr>
										)}

										{props.painAssessment.kneePainLeft === true || props.painAssessment.kneePainRight === true ? (
											<tr key={"Knee"} className="border border-tertiary-500">
												<td className="px-2 py-4 border border-right border-tertiary-500">Knee</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.kneePainLeft === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.kneePainRight === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
											</tr>
										) : (
											<tr></tr>
										)}

										{props.painAssessment.shinPainLeft === true || props.painAssessment.shinPainRight === true ? (
											<tr key={"Shin"} className="border border-tertiary-500">
												<td className="px-2 py-4 border border-right border-tertiary-500">Shin</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.shinPainLeft === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.shinPainRight === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
											</tr>
										) : (
											<tr></tr>
										)}

										{props.painAssessment.anklePainLeft === true || props.painAssessment.anklePainRight === true ? (
											<tr key={"Ankle"} className="border border-tertiary-500">
												<td className="px-2 py-4 border border-right border-tertiary-500">Ankle</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.anklePainLeft === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.anklePainRight === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
											</tr>
										) : (
											<tr></tr>
										)}

										{props.painAssessment.heelPainLeft === true || props.painAssessment.heelPainRight === true ? (
											<tr key={"Heel"} className="border border-tertiary-500">
												<td className="px-2 py-4 border border-right border-tertiary-500">Heel</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.heelPainLeft === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.heelPainRight === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
											</tr>
										) : (
											<tr></tr>
										)}

										{props.painAssessment.greatToePainLeft === true ||
										props.painAssessment.greatToePainRight === true ? (
											<tr key={"GreatToe"} className="border border-tertiary-500">
												<td className="px-2 py-4 border border-right border-tertiary-500">Great Toe</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.greatToePainLeft === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.greatToePainRight === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
											</tr>
										) : (
											<tr></tr>
										)}

										{props.painAssessment.footPainLeft === true || props.painAssessment.footPainRight === true ? (
											<tr key={"Foot"} className="border border-tertiary-500">
												<td className="px-2 py-4 border border-right border-tertiary-500">Foot</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.footPainLeft === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
												<td className="border px-8 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.footPainRight === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
											</tr>
										) : (
											<tr></tr>
										)}
									</tbody>
								</table>
							)}

							{/* <table className="table-auto text-left text-md border-collapse border border-2 border-tertiary-500 w-full mt-5">
                <thead className="bg-gray-50 bg-tertiary-100">
                  <tr>
                    <th className="border lg:px-6 px-2 py-4 border-tertiary-500 text-center">
                      Pain Area
                    </th>
                    <th className="border lg:px-6 px-2 py-4 border-tertiary-500 text-center">
                      Left
                    </th>
                    <th className="border lg:px-6 px-2 py-4 border-tertiary-500 text-center">
                      Right
                    </th>
                  </tr>
                </thead>
              </table> */}
						</div>

						<div className="py-6">
							<div className="max-w-4xl mx-auto">
								<div className="grid grid-cols-1 md:grid-cols-3 gap-6">
									{/* Stiffness Section */}
									{(props.painAssessment.stiffnessMorning ||
										props.painAssessment.stiffnessEvening ||
										props.painAssessment.stiffnessNight ||
										props.painAssessment.stiffnessWholeDay) && (
										<AssessmentCard title="Stiffness">
											<div className="space-y-2">
												<CheckboxItem label="Morning" checked={props.painAssessment.stiffnessMorning} />
												<CheckboxItem label="Evening" checked={props.painAssessment.stiffnessEvening} />
												<CheckboxItem label="Night" checked={props.painAssessment.stiffnessNight} />
												<CheckboxItem label="Whole Day" checked={props.painAssessment.stiffnessWholeDay} />
											</div>
										</AssessmentCard>
									)}

									{/* Sleep Section */}
									{(props.painAssessment.sleepDisturbed || props.painAssessment.sleepNormal) && (
										<AssessmentCard title="Sleep">
											<div className="space-y-2">
												<CheckboxItem label="Disturbed" checked={props.painAssessment.sleepDisturbed} />
												<CheckboxItem label="Normal" checked={props.painAssessment.sleepNormal} />
											</div>
										</AssessmentCard>
									)}

									{/* Travelling Section */}
									{(props.painAssessment.travellingTwoWheeler ||
										props.painAssessment.travellingFourWheeler ||
										props.painAssessment.travellingPublicTransport) && (
										<AssessmentCard title="Travelling">
											<div className="space-y-2">
												<CheckboxItem label="2 Wheeler" checked={props.painAssessment.travellingTwoWheeler} />
												<CheckboxItem label="4 Wheeler" checked={props.painAssessment.travellingFourWheeler} />
												<CheckboxItem
													label="Public Transport"
													checked={props.painAssessment.travellingPublicTransport}
												/>
											</div>
										</AssessmentCard>
									)}
								</div>
							</div>
						</div>

						{props.painAssessment.dailyTravelKm !== 0 ? (
							<div className="flex flex-row items-center mt-4">
								<p className="text-md mr-2 font-medium">Daily Kilometers Travelled - </p>
								<p>{props.painAssessment.dailyTravelKm ?? "0"}</p>
							</div>
						) : (
							<div></div>
						)}

						<div className="flex flex-col">
							{props.painAssessment.constantIntermittentLeft !== true &&
							props.painAssessment.constantIntermittentRight !== true &&
							props.painAssessment.intermittentLeft !== true &&
							props.painAssessment.intermittentRight !== true &&
							props.painAssessment.sharpLeft !== true &&
							props.painAssessment.sharpRight !== true &&
							props.painAssessment.shootingLeft !== true &&
							props.painAssessment.shootingRight !== true &&
							props.painAssessment.burningLeft !== true &&
							props.painAssessment.burningRight !== true &&
							props.painAssessment.throbbingLeft !== true &&
							props.painAssessment.throbbingRight !== true &&
							props.painAssessment.numbnessLeft !== true &&
							props.painAssessment.numbnessRight !== true &&
							props.painAssessment.pinsAndNeedlesLeft !== true &&
							props.painAssessment.pinsAndNeedlesRight !== true &&
							props.painAssessment.dullAchingLeft !== true &&
							props.painAssessment.dullAchingRight !== true ? (
								<div></div>
							) : (
								<table className="table-auto text-left text-md border-collapse border border-2 border-tertiary-500 w-full mt-5">
									<thead className="bg-gray-50 bg-tertiary-100">
										<tr>
											<th className="border lg:px-6 px-2 py-4 border-tertiary-500 text-left">Pain Type</th>
											<th className="border lg:px-6 px-2 py-4 border-tertiary-500 text-left">Left</th>
											<th className="border lg:px-6 px-2 py-4 border-tertiary-500 text-left">Right</th>
										</tr>
									</thead>
									<tbody>
										{props.painAssessment.constantIntermittentLeft === true ||
										props.painAssessment.constantIntermittentRight === true ? (
											<tr key={"constantIntermittent"} className="border border-tertiary-500">
												<td className="px-2 py-4 border border-right border-tertiary-500">Constant</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.constantIntermittentLeft === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500 ">
													<span>
														{props.painAssessment.constantIntermittentRight === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
											</tr>
										) : (
											<tr></tr>
										)}

										{props.painAssessment.intermittentLeft === true ||
										props.painAssessment.intermittentRight === true ? (
											<tr key={"intermittent"} className="border border-tertiary-500">
												<td className="px-2 py-4 border border-right border-tertiary-500">Intermittent</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.intermittentLeft === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.intermittentRight === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
											</tr>
										) : (
											<tr></tr>
										)}

										{props.painAssessment.sharpLeft === true || props.painAssessment.sharpRight === true ? (
											<tr key={"Sharp"} className="border border-tertiary-500">
												<td className="px-2 py-4 border border-right border-tertiary-500">Sharp</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.sharpLeft === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.sharpRight === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
											</tr>
										) : (
											<tr></tr>
										)}

										{props.painAssessment.shootingLeft === true || props.painAssessment.shootingRight === true ? (
											<tr key={"Shooting"} className="border border-tertiary-500">
												<td className="px-2 py-4 border border-right border-tertiary-500">Shooting</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.shootingLeft === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.shootingRight === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
											</tr>
										) : (
											<tr></tr>
										)}

										{props.painAssessment.burningLeft === true || props.painAssessment.burningRight === true ? (
											<tr key={"Burning"} className="border border-tertiary-500">
												<td className="px-2 py-4 border border-right border-tertiary-500">Burning</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.burningLeft === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.burningRight === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
											</tr>
										) : (
											<tr></tr>
										)}

										{props.painAssessment.throbbingLeft === true || props.painAssessment.throbbingRight === true ? (
											<tr key={"Throbbing"} className="border border-tertiary-500">
												<td className="px-2 py-4 border border-right border-tertiary-500">Throbbing</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.throbbingLeft === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.throbbingRight === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
											</tr>
										) : (
											<tr></tr>
										)}

										{props.painAssessment.numbnessLeft === true || props.painAssessment.numbnessRight === true ? (
											<tr key={"Numbness"} className="border border-tertiary-500">
												<td className="px-2 py-4 border border-right border-tertiary-500">Numbness</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.numbnessLeft === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.numbnessRight === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
											</tr>
										) : (
											<tr></tr>
										)}

										{props.painAssessment.pinsAndNeedlesLeft === true ||
										props.painAssessment.pinsAndNeedlesRight === true ? (
											<tr key={"Pinsandneedles"} className="border border-tertiary-500">
												<td className="px-2 py-4 border border-right border-tertiary-500">Pins And Needles</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.pinsAndNeedlesLeft === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.pinsAndNeedlesRight === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
											</tr>
										) : (
											<tr></tr>
										)}

										{props.painAssessment.dullAchingLeft === true || props.painAssessment.dullAchingRight === true ? (
											<tr key={"DullAching"} className="border border-tertiary-500">
												<td className="px-2 py-4 border border-right border-tertiary-500">Dull Aching</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.dullAchingLeft === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
												<td className="border px-6 py-4 border-2 text-center border-tertiary-500">
													<span>
														{props.painAssessment.dullAchingRight === true ? (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																alt="check-mark-emoji"
															/>
														) : (
															<img
																width="28"
																height="28"
																src="https://img.icons8.com/fluency/48/delete-sign.png"
																alt="delete-sign"
															/>
														)}
													</span>
												</td>
											</tr>
										) : (
											<tr></tr>
										)}
									</tbody>
								</table>
							)}
						</div>

						<div className="mt-4">
							{props.painAssessment.activityLimitation !== "" ? (
								<div>
									<div className="flex flex-row justify-between w-full mt-4 bg-tertiary-100 px-2 py-1 font-medium">
										Activity Limitation
									</div>
									<article className="text-wrap">
										<p className="px-2 py-1 ">{props.painAssessment.activityLimitation}</p>
									</article>
								</div>
							) : (
								<div></div>
							)}

							{props.painAssessment.aggravatingFactors !== "" ? (
								<div className="mt-2">
									<div className="flex flex-row justify-between w-full mt-4 bg-tertiary-100 px-2 py-1 font-medium">
										Aggravating Factors
									</div>
									<p className="px-2 py-1 text-wrap">{props.painAssessment.aggravatingFactors}</p>
								</div>
							) : (
								<div></div>
							)}

							{props.painAssessment.relievingFactors !== "" ? (
								<div className="mt-2">
									<div className="flex flex-row justify-between w-full mt-4 bg-tertiary-100 px-2 py-1 font-medium">
										Relieving Factors
									</div>
									<p className="px-2 py-1 flex text-wrap">{props.painAssessment.relievingFactors}</p>
								</div>
							) : (
								<div></div>
							)}
						</div>
					</div>
					<hr className=" my-12 border-tertiary-500"></hr>
				</div>
			) : (
				<div></div>
			)}
		</div>
	);
};

export default PainAssessment;
