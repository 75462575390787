import React, { useState, useEffect } from "react";
import { NewMasterAssessmentData } from "../types/NewMasterAssessment";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import { endpoints, replaceParams } from "@utils/axios";
import axios from "axios";
import {
  Convert,
  TreatmentProtocolData,
  TreatmentProtocolDetail,
} from "../types/TreatmentProtocol";
import RightOffcanvas from "@components/right-offcanvas";
import { Button, OutlinedButton } from "@components/button";
import { ButtonVariant } from "@components/button/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import AddStaticData from "../components/AddStaticData";

interface ModalityProps {
  patientId: string;
  masterAssessmentData: NewMasterAssessmentData | null;
  getMasterAssessmentLedger: () => Promise<void>;
}

export default function TreatmentProtocol(props: ModalityProps) {
  const [treatmentProtocol, setTreatmentProtocol] =
    React.useState<TreatmentProtocolData>();
  const [array, setArray] = useState<TreatmentProtocolDetail[]>([]);
  const [isRightOffcanvasOpenToFeedback, setIsRightOffcanvasOpenToFeedback] =
    React.useState(false);

  const userData = getUserPersistedOnLocalStorage();

  const [addStaticData, setAddStaticData] = React.useState(false);
  const [staticProtocolIndex, setStaticProtocolIndex] = React.useState(0);

  const getTreatmentProtocolData = React.useCallback(async () => {
    if (
      props.masterAssessmentData?.treatmentProtocolId ===
        "000000000000000000000000" ||
      props.masterAssessmentData?.treatmentProtocolId === null
    ) {
      let data;
      try {
        const paramsMap = new Map<string, string>([
          ["entityid", props.masterAssessmentData?.entityId ?? ""],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.treatmentProtocol.fetchEntityTreatmentProtocolSetup,
          paramsMap
        );
        const response = await axios.get(filteredEndpoint);
        data = response.data["data"].treatmentProtocolDetails;

        setTreatmentProtocol(response.data["data"]);
        if (data) {
          setArray(data);
        } else {
          setArray([]);
        }
      } catch (error) {
        console.error("Error getting treatment protocol data:", error);
        setArray([]);
      }
    } else {
      try {
        const paramsMap = new Map<string, string>([
          ["id", props.masterAssessmentData?.treatmentProtocolId ?? ""],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.treatmentProtocol.fetch,
          paramsMap
        );

        let data, response;
        response = await axios.get(filteredEndpoint);
        data = response.data["data"].treatmentProtocolDetails;

        if (data === null) {
          const paramsMap = new Map<string, string>([
            ["entityid", props.masterAssessmentData?.entityId ?? ""],
          ]);
          const filteredEndpoint = replaceParams(
            endpoints.treatmentProtocol.fetchEntityTreatmentProtocolSetup,
            paramsMap
          );
          const response = await axios.get(filteredEndpoint);
          data = response.data["data"].treatmentProtocolDetails;
        }

        setTreatmentProtocol(response.data["data"]);

        if (data) {
          setArray(data);
        } else {
          setArray([]);
        }
      } catch (error) {
        console.error("Error getting treatment protocol data:", error);
        setArray([]);
      }
    }
  }, [
    props.masterAssessmentData?.treatmentProtocolId,
    props.masterAssessmentData?.entityId,
  ]);

  useEffect(() => {
    const _init = async () => {
      await getTreatmentProtocolData();
    };
    _init();
  }, [getTreatmentProtocolData, props.masterAssessmentData]);

  const onSubmit = async () => {
    if (
      props.masterAssessmentData?.treatmentProtocolId ===
        "000000000000000000000000" ||
      props.masterAssessmentData?.treatmentProtocolId === null
    ) {
      try {
        const paramsMap = new Map<string, string>([
          ["mid", props.masterAssessmentData?.id!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.treatmentProtocol.add,
          paramsMap
        );

        const response = await axios.post(filteredEndpoint, {
          entityId: props.masterAssessmentData?.entityId,
          entityBranchId: userData?.branch_id,
          clientId: props.masterAssessmentData?.clientId,
          masterAssessmentId: props.masterAssessmentData?.id,
          treatmentProtocolDetails: array,
        });

        props.getMasterAssessmentLedger();
        getTreatmentProtocolData();
        toast.success("Treatment protocol saved successfully");
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    } else {
      try {
        const paramsMap = new Map<string, string>([
          ["id", props.masterAssessmentData?.treatmentProtocolId!],
        ]);
        const filteredEndpoint = replaceParams(
          endpoints.treatmentProtocol.update,
          paramsMap
        );

        const response = await axios.put(filteredEndpoint, {
          entityId: props.masterAssessmentData?.entityId,
          entityBranchId: userData?.branch_id,
          clientId: props.masterAssessmentData?.clientId,
          masterAssessmentId: props.masterAssessmentData?.id,
          treatmentProtocolDetails: array,
        });

        getTreatmentProtocolData();
        toast.success("Treatment protocol saved successfully");
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const handleChange = (
    protocolIndex: number,
    rowIndex: number,
    colIndex: number,
    value: string | boolean
  ) => {
    const newData = [...array];
    const currentSpreadsheet = newData[protocolIndex];

    if (currentSpreadsheet.type === "spreadsheet") {
      currentSpreadsheet.rows[rowIndex][colIndex] = value;
    } else if (currentSpreadsheet.type === "spreadsheetextareat") {
      currentSpreadsheet.textAreaField = value as string;
    }

    setArray(newData);
  };

  const handleCloseRightOffCanvas = () => {
    setIsRightOffcanvasOpenToFeedback(false);
  };

  const addRow = (protocolIndex: number) => {
    const newData = [...array];
    const currentSpreadsheet = newData[protocolIndex];

    const newRow: (string | boolean)[] = currentSpreadsheet.columns.map(
      (column) => {
        switch (column.type) {
          case "input-text":
          case "input-textarea":
          case "static":
            return "";
          case "input-checkbox":
            return false;
          default:
            return "";
        }
      }
    ) as (string | boolean)[];

    currentSpreadsheet.rows.push(newRow);

    setArray(newData);
    toast.info(`Row added for ${currentSpreadsheet.name}`);
  };

  const deleteRow = (protocolIndex: number, rowIndex: number) => {
    const newData = [...array];
    const currentSpreadsheet = newData[protocolIndex];

    if (rowIndex >= 0 && rowIndex < currentSpreadsheet.rows.length) {
      currentSpreadsheet.rows.splice(rowIndex, 1);

      setArray(newData);
      toast.info(`Row deleted for ${currentSpreadsheet.name}`);
    }
  };

  return (
    <>
      {array.length === 0 ? (
        <>No protocols found</>
      ) : (
        <button
          type="button"
          onClick={() => setIsRightOffcanvasOpenToFeedback(true)}
          className="m-4 w-24 cursor-pointer text-center text-secondary-500 border border-gray-400 py-1 px-4 text-sm rounded justify-center "
        >
          Open Protocols
        </button>
      )}

      <RightOffcanvas
        isOpen={isRightOffcanvasOpenToFeedback}
        onClose={handleCloseRightOffCanvas}
        sizeClasses="w-full"
      >
        {isRightOffcanvasOpenToFeedback && (
          <div className="flex h-full w-full flex-col">
            <div className="sticky top-0 z-50 flex items-center justify-between bg-white bg-opacity-75">
              <div className="min-w-0 flex-1 mb-2">
                <h2 className="text-xl font-bold leading-7 sm:truncate sm:text-3xl sm:tracking-tight">
                  Treatment Protocols
                </h2>
              </div>
              <div className="flex mt-4 flex-shrink-0 md:ml-4 md:mt-0 justify-end">
                <Button
                  type="button"
                  onClick={handleCloseRightOffCanvas}
                  className="mr-2"
                  variant={ButtonVariant.PRIMARY}
                >
                  Close
                </Button>
                <Button
                  type="button"
                  onClick={onSubmit}
                  className=""
                  variant={ButtonVariant.PRIMARY}
                >
                  Save
                </Button>
              </div>
            </div>

            <div className="flex flex-col w-full space-y-4 ">
              {array.map((spreadsheet, protocolIndex) => (
                <>
                  <h2 className="text-lg font-semibold mt-2">
                    {spreadsheet.name}
                  </h2>
                  {spreadsheet.type === "spreadsheet" && (
                    <table className="overflow-hidden">
                      <thead>
                        <tr className="bg-primary-100">
                          {spreadsheet.columns.map((col, index) => (
                            <th
                              key={index}
                              className="text-base px-2 py-2 font-semibold text-gray-800"
                            >
                              {col.header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {spreadsheet.rows.map((row, rowIndex) => (
                          <tr key={rowIndex}>
                            {row.map((cell, colIndex) => (
                              <td
                                className="border-b-2 border-gray-100 p-2 text-center"
                                key={colIndex}
                              >
                                {spreadsheet.columns[colIndex].type ===
                                "static" ? (
                                  <span>{cell}</span>
                                ) : spreadsheet.columns[colIndex].type ===
                                  "input-text" ? (
                                  <input
                                    type="text"
                                    value={cell as string}
                                    onChange={(e) =>
                                      handleChange(
                                        protocolIndex,
                                        rowIndex,
                                        colIndex,
                                        e.target.value
                                      )
                                    }
                                    disabled={
                                      !spreadsheet.columns[colIndex].isEditable
                                    }
                                    className="w-28 text-center rounded-md border-0 ring-1 ring-inset ring-gray-300"
                                  />
                                ) : spreadsheet.columns[colIndex].type ===
                                  "input-checkbox" ? (
                                  <input
                                    type="checkbox"
                                    checked={cell as boolean}
                                    onChange={(e) =>
                                      handleChange(
                                        protocolIndex,
                                        rowIndex,
                                        colIndex,
                                        e.target.checked
                                      )
                                    }
                                    disabled={
                                      !spreadsheet.columns[colIndex].isEditable
                                    }
                                    className="h-5 w-5 mx-auto"
                                  />
                                ) : spreadsheet.columns[colIndex].type ===
                                  "input-textarea" ? (
                                  <textarea
                                    value={cell as string}
                                    onChange={(e) =>
                                      handleChange(
                                        protocolIndex,
                                        rowIndex,
                                        colIndex,
                                        e.target.value
                                      )
                                    }
                                    disabled={
                                      !spreadsheet.columns[colIndex].isEditable
                                    }
                                    className="w-full text-center rounded-md border-0 ring-1 ring-inset ring-gray-300"
                                  />
                                ) : null}
                              </td>
                            ))}
                            <td className="border-b-2 border-gray-100 p-2 text-center">
                              <button
                                className="custom-button h-full w-full text-gray-300 hover:text-red-500 flex flex-row"
                                type="button"
                                onClick={() =>
                                  deleteRow(protocolIndex, rowIndex)
                                }
                              >
                                <span className="icon-container justify-items-center">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    role="img"
                                    className="component-iconify MuiBox-root css-1t9pz9x iconify iconify--solar"
                                    width="1.5em"
                                    height="1.5em"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M3 6.386c0-.484.345-.877.771-.877h2.665c.529-.016.996-.399 1.176-.965l.03-.1l.115-.391c.07-.24.131-.45.217-.637c.338-.739.964-1.252 1.687-1.383c.184-.033.378-.033.6-.033h3.478c.223 0 .417 0 .6.033c.723.131 1.35.644 1.687 1.383c.086.187.147.396.218.637l.114.391l.03.1c.18.566.74.95 1.27.965h2.57c.427 0 .772.393.772.877s-.345.877-.771.877H3.77c-.425 0-.77-.393-.77-.877"
                                    ></path>
                                    <path
                                      fill="currentColor"
                                      fill-rule="evenodd"
                                      d="M11.596 22h.808c2.783 0 4.174 0 5.08-.886c.904-.886.996-2.339 1.181-5.245l.267-4.188c.1-1.577.15-2.366-.303-2.865c-.454-.5-1.22-.5-2.753-.5H8.124c-1.533 0-2.3 0-2.753.5c-.454.5-.404 1.288-.303 2.865l.267 4.188c.185 2.906.277 4.36 1.182 5.245c.905.886 2.296.886 5.079.886m-1.35-9.811c-.04-.434-.408-.75-.82-.707c-.413.043-.713.43-.672.864l.5 5.263c.04.434.408.75.82.707c.413-.043.713-.43.672-.864zm4.329-.707c.412.043.713.43.671.864l-.5 5.263c-.04.434-.409.75-.82.707c-.413-.043-.713-.43-.672-.864l.5-5.263c.04-.434.409-.75.82-.707"
                                      clip-rule="evenodd"
                                    ></path>
                                  </svg>
                                </span>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                  {spreadsheet.type === "spreadsheetextareat" && (
                    <textarea
                      value={spreadsheet.textAreaField}
                      onChange={(e) =>
                        handleChange(protocolIndex, 0, 0, e.target.value)
                      }
                      className="w-full h-32 border px-4 py-2 rounded-lg"
                    />
                  )}
                  {spreadsheet.type === "spreadsheet" && (
                    <div className="flex flex-row justify-end mt-2 items-center">
                      {spreadsheet.columns.some(
                        (column) => column.type === "static"
                      ) ? (
                        <>
                          <Button
                            variant={ButtonVariant.SECONDARY}
                            type="button"
                            className="bg-primary-500 hover:bg-primary-500"
                            onClick={() => {
                              setStaticProtocolIndex(protocolIndex);
                              setAddStaticData(true);
                            }}
                            children={
                              <div className="flex flex-row items-center">
                                <FontAwesomeIcon
                                  icon={faPlus}
                                  className="h-4 w-4 mr-2 "
                                  aria-hidden="true"
                                />
                                <span className="text-sm ">Add row</span>
                              </div>
                            }
                          />
                        </>
                      ) : (
                        <>
                          <Button
                            variant={ButtonVariant.SECONDARY}
                            type="button"
                            className="bg-primary-500 hover:bg-primary-500"
                            onClick={() => addRow(protocolIndex)}
                            children={
                              <div className="flex flex-row items-center">
                                <FontAwesomeIcon
                                  icon={faPlus}
                                  className="h-4 w-4 mr-2 "
                                  aria-hidden="true"
                                />
                                <span className="text-sm ">Add row</span>
                              </div>
                            }
                          />
                        </>
                      )}
                    </div>
                  )}
                </>
              ))}
            </div>

            {addStaticData && (
              <AddStaticData
                addStaticData={addStaticData}
                setAddStaticData={setAddStaticData}
                staticProtocolIndex={staticProtocolIndex}
                headers={array[staticProtocolIndex].columns}
                setArray={setArray}
                array={array}
              />
            )}
          </div>
        )}
      </RightOffcanvas>
    </>
  );
}
