import React, { useState, useRef, useEffect } from "react";
import { PlusCircle, FileText, Receipt, Printer, CheckCircle, AlertCircle, BookOpenText } from "lucide-react";

import { v4 as uuidv4 } from "uuid";

import LineItemRow from "./LineItemRow";
import SummaryCard from "./SummaryCard";
import { endpoints, replaceParams } from "@utils/axios";
import axios, { CancelTokenSource } from "axios";
import InvoiceHeader from "./InvoiceHeader";
import {
	ConvertBilling,
	InvoiceDetails,
	LineItem,
	PaymentDetails,
	TaxItem,
	AppointmentDetail,
} from "../types/InvoiceDetails";
import { CURRENCY_SYMBOL } from "../../../config/currencyConfig";
import moment from "moment";
import PaymentSection from "./PaymentSection";
import { toast } from "react-toastify";
import { Patient } from "@common/types/Patient";
import EditPaymentModal from "@components/EditPaymentModal";
import { Appointment, Convert as AppointmentConvert } from "@pages/appointments/types/Appointment";
import Button, { ButtonVariant } from "@components/button/outline-button";
import { OutlinedButton } from "@components/button";
import UnbilledAppointments from "./UnbilledAppointments";
import { Service } from "@/src/common/types/ServiceType";

import { ConvertStaff, Staff } from "@pages/appointments/book-appointment/types/StaffType";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import { Line } from "react-chartjs-2";
import { Plan } from "@pages/branches/types/Plan";
interface InvoiceProps {
	invoiceDetails: InvoiceDetails;
	clientName: string;
	onClose: () => void;
	isAdvancePayment?: boolean;
}

const InvoicePage: React.FC<InvoiceProps> = ({ onClose, invoiceDetails, clientName, isAdvancePayment, ...otherProps }) => {
	const [invoice, setInvoice] = useState<InvoiceDetails>({
		...invoiceDetails,
		items: invoiceDetails?.items || [],
		taxes: invoiceDetails?.taxes || [],
		status: invoiceDetails?.status || "pending",
	});
	const [paymentDetails, setPaymentDetails] = useState<PaymentDetails>({
		amountPaid: 0,
		paymentDate: new Date(),
		paymentMode: "",
		note: "",
		transactionID: "",
		audit: {
			createdBy: "",
			lastUpdatedBy: "",
			createdAt: new Date(),
			lastUpdatedAt: new Date(),
		},
	});
	const [isCreating, setIsCreating] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const printRef = useRef<HTMLDivElement>(null);
	const [showError, setShowError] = useState(true);
	const [originalStatus, setOriginalStatus] = useState(invoiceDetails?.status);
	const [resetPaymentFields, setResetPaymentFields] = useState<boolean>(false);
	const [readOnly, setReadOnly] = useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [currentPayment, setCurrentPayment] = useState<PaymentDetails | null>(null);
	const [updatePayment, setUpdatePayment] = useState<boolean>(false);
	const [selectedAppointments, setSelectedAppointments] = useState<Appointment[]>([]);
	const [isApptModelOpen, setIsApptModelOpen] = useState(false);

	const addLineItem = () => {
		const newItem: LineItem = {
			itemId: uuidv4(),
			type: "service",
			description: "",
			quantity: 1,
			amount: 0,
			total: 0,
		};
		setInvoice((prev) => ({
			...prev,
			items: [...prev.items, newItem],
		}));
	};

	const addNewLineItem = (newItem: LineItem) => {
		setInvoice((prev) => ({
			...prev,
			items: [...prev.items, newItem],
		}));
	};

	const updateLineItem = (id: string, field: keyof LineItem, value: any) => {
		setInvoice((prev) => ({
			...prev,
			items: prev.items.map((item) => (item.itemId === id ? { ...item, [field]: value } : item)),
		}));
	};

	const deleteLineItem = (id: string) => {
		setInvoice((prev) => ({
			...prev,
			items: prev.items.filter((item) => item.itemId !== id),
		}));
	};

	const handleDiscountChange = (value: number) => {
		setInvoice((prev) => ({
			...prev,
			discount: value,
		}));
	};

	const handleTaxesChange = (taxes: TaxItem[]) => {
		setInvoice((prev) => ({
			...prev,
			taxes,
		}));
	};

	const validateInvoice = () => {
		if (!invoice.clientId) {
			throw new Error("Please select a patient");
		}
		if (invoice.items.length === 0) {
			throw new Error("Please add at least one item to the invoice");
		}

		if (invoice.items.some((item) => !item.description)) {
			throw new Error("Please provide a description for all items");
		}

		if (invoice.items.some((item) => item.quantity !== undefined && item.quantity <= 0)) {
			throw new Error("Quantity must be greater than 0 for all items");
		}

		if (invoice.items.some((item) => item.amount !== undefined && item.amount < 0)) {
			throw new Error("Price cannot be negative");
		}

		if (invoice.discount < 0) {
			throw new Error("Discount cannot be negative");
		}

		if (invoice.status === "partlyPaid" && invoice.totalPaid >= invoice.totalAmount) {
			throw new Error("Partially paid amount cannot be greater than or equal to the total amount");
		}

		if (invoice.taxes.some((tax) => tax.rate < 0 || tax.rate > 100)) {
			throw new Error("Tax rate must be between 0 and 100");
		}
	};
	const [postInProgress, setPostInProgress] = React.useState<boolean>(false);
	const cancelToken = React.useRef<CancelTokenSource | null>(null);

	const handlePrint = async () => {
		try {
			if (invoice.id) {
				const paramsMap = new Map<string, string>([["id", invoice.id]]);
				const endpoint = replaceParams(endpoints.invoice.printBillDetails, paramsMap);
				const response = await axios.get(endpoint);
				let htmlContent = response.data;
				const blob = new Blob([htmlContent], { type: "text/html" });
				const url = URL.createObjectURL(blob);
				const anchor = document.createElement("a");
				anchor.href = url;
				anchor.target = "_blank";
				anchor.style.display = "none";
				document.body.appendChild(anchor);
				anchor.click();
				URL.revokeObjectURL(url);
				document.body.removeChild(anchor);
			}
		} catch (error) {
			console.error("Error in printing", error);
		}
	};

	const handleSaveInvoice = async () => {
		if (postInProgress) return;

		if (invoice.status === "pending") {
			invoice.outStandingAmount = invoice.totalAmount - invoice.totalPaid;
		}

		if (cancelToken.current) {
			cancelToken.current.cancel("Request already in progress!");
		}
		cancelToken.current = axios.CancelToken.source();
		try {
			setPostInProgress(true);

			setIsCreating(true);
			setError(null);
			// Validate invoice data
			validateInvoice();

			// Make API call
			let endpoint = endpoints.invoice.addBilling;
			let response;
			if (invoice.id && invoice.id !== "") {
				/** Update Invoice/Biling Details */
				const paramsMap = new Map<string, string>([["id", invoice.id]]);
				endpoint = replaceParams(endpoints.invoice.updateBilling, paramsMap);
				response = await axios.put(endpoint, invoice, {
					cancelToken: cancelToken.current.token,
				});
			} else {
				/** Create Invoice/Biling Details */
				endpoint = endpoints.invoice.addBilling;
				response = await axios.post(endpoint, invoice, {
					cancelToken: cancelToken.current.token,
				});
				invoice.id = response.data.data;
			}

			if (response && response.data.status === 200) {
				if (invoice && invoice.id && invoice.status !== "pending" && updatePayment) {
					const paramsMap = new Map<string, string>([["id", invoice.id]]);
					const filteredEndpoint = replaceParams(endpoints.invoice.payBill, paramsMap);

					await axios.post(
						filteredEndpoint,
						{
							amountPaid: Number(paymentDetails.amountPaid),
							paymentMode: paymentDetails.paymentMode,
							note: paymentDetails.note,
							paymentDate: paymentDetails.paymentDate,
							transactionId: uuidv4(),
						},
						{
							cancelToken: cancelToken.current.token,
						}
					);
				}
			}

			toast.success("Invoice saved successfully");
			setResetPaymentFields(true);
			setPostInProgress(false);
			setOriginalStatus(invoice.status);
			getBillDetails();
			setUpdatePayment(false); //Reset updatePayment flag
		} catch (err) {
			setPostInProgress(false);
			setError(err instanceof Error ? err.message : "An unexpected error occurred");
		} finally {
			setIsCreating(false);
		}
	};

	const getBillDetails = async () => {
		try {
			if (invoice.id) {
				const paramsMap = new Map<string, string>([["id", invoice.id]]);
				const endpoint = replaceParams(endpoints.invoice.getBillDetails, paramsMap);
				const response = await axios.get(endpoint);
				const data = response.data["data"];
				if (data !== null) {
					var billRecord = ConvertBilling.toBilling(JSON.stringify(data));
					setInvoice(billRecord);
					/*            updateSubTotal(billRecord.items);
           setItemsList(billRecord.items); */
				}
			}
		} catch (error) {
			console.error("Error fetching expenses details:", error);
		}
	};

	const userData = getUserPersistedOnLocalStorage();

	const [staffList, setStaffList] = React.useState<Staff[]>([]);
	const [serviceData, setServiceData] = useState<Service[]>([]); // Add state for service data
	const [planData, setPlanData] = useState<Plan[]>([]);

	const [descriptionOptions, setDescriptionOptions] = useState<{
		[key: string]: string;
	}>({});
	// Fetch description options based on selected type
	const fetchServices = async () => {
		const paramsMap = new Map<string, string>([
			["entityId", userData?.entity_id!],
			["branchId", userData?.branch_id ?? ""],
		]);
		let endpoint = replaceParams(endpoints.services.getActiveServicesForBranch, paramsMap);
		if (userData?.user_role === "ENTITY_OWNER") {
			endpoint = replaceParams(endpoints.services.getActiveServicesForEntity, paramsMap);
		}

		const response = await axios.get(endpoint);

		if (response.status === 200) {
			const data = response.data.data; // Get the data from the response
			if (Array.isArray(data)) {
				// Check if data is an array
				setServiceData(data); // Store fetched data in state
				/** set services in description by default */
				const options = data.reduce(
					(acc, entity) => {
						acc[entity.id] = entity.name;
						return acc;
					},
					{} as { [key: string]: string }
				);
				setDescriptionOptions(options);
			} else {
				console.error("Expected data to be an array, but got:", data);
			}
		}
	};

	const fetchPlans = async () => {
		const paramsMap = new Map<string, string>([
			["entityId", userData?.entity_id!],
			["branchId", userData?.branch_id ?? ""],
		]);
		let endpoint = replaceParams(endpoints.services.getActivePlansForBranch, paramsMap);
		if (userData?.user_role === "ENTITY_OWNER") {
			endpoint = replaceParams(endpoints.services.getActivePlansForEntity, paramsMap);
		}

		const response = await axios.get(endpoint);

		if (response.status === 200) {
			const data = response.data.data; // Get the data from the response

			if (Array.isArray(data)) {
				// Check if data is an array
				setPlanData(data); // Store fetched data in state
			} else {
				console.error("Expected data to be an array, but got:", data);
			}
		}
	};

	const fetchStaffList = async () => {
		try {
			let response;
			if (userData?.branch_id && userData?.user_role !== "ENTITY_OWNER") {
				response = await axios.get(endpoints.staff.list, {
					params: {
						branchId: userData?.branch_id,
					},
				});
			} else {
				response = await axios.get(endpoints.staff.list);
			}

			const data = response.data.data;
			const convertedStaffList = data.map((staff: any) => ConvertStaff.toStaff(JSON.stringify(staff)));
			setStaffList(convertedStaffList);
		} catch (error) {
			console.error("Error fetching staff list:", error);
		}
	};

	// Calculate totals whenever items, discount, or taxes change
	React.useEffect(() => {
		if (invoice.items.length === 0) {
			addLineItem();
		}

		const subtotal = Array.isArray(invoice.items)
			? invoice.items.reduce((sum, { quantity = 0, amount = 0, discount = 0 }) => sum + quantity * amount - discount, 0)
			: 0;

		const totalTaxAmount = Array.isArray(invoice.taxes)
			? invoice.taxes.reduce((sum, tax) => sum + (subtotal - (invoice.discount || 0)) * (tax.rate / 100), 0)
			: 0;

		const total = subtotal - (invoice.discount || 0) + (totalTaxAmount || 0);

		setInvoice((prev) => ({
			...prev,
			subtotal: subtotal,
			totalAmount: total,
		}));
	}, [invoice.items, invoice.discount, invoice.taxes]);

	React.useEffect(() => {
		setReadOnly(originalStatus === "paid");
	}, [originalStatus]);

	const [unbilledPatientAppointments, setUnbilledPatientAppointments] = React.useState<Appointment[]>([]);
	const [totalUnbilledAppointments, setTotalUnbilledAppointments] = React.useState(0);

	const handleEditPayment = (payment: PaymentDetails) => {
		setCurrentPayment(payment);
		setIsModalOpen(true);
	};

	useEffect(() => {
		fetchServices();
		fetchPlans();
		fetchStaffList();
	}, []);

	// This effect will run whenever serviceData changes
	useEffect(() => {
		if (serviceData.length > 0) {
			getUnbilledAppointments(); // Call this only if serviceData has data
		}
	}, [serviceData]);

	const getUnbilledAppointments = async () => {
		if (invoice && invoice.clientId) {
			const paramsMap = new Map<string, string>([["id", invoice.clientId]]);
			const filteredEndpoint = replaceParams(endpoints.patient.getUnbilledAppointments, paramsMap);
			const response = await axios.get(filteredEndpoint);
			const data = response.data["data"];
			const convertedAppointmentList = data.map((appointment: any) => {
				const service = serviceData.find((service) => service.name === appointment.appointmentType);
				const charges = service ? service.defaultCost : 0;
				appointment.billAmount = charges;
				return AppointmentConvert.toAppointment(JSON.stringify(appointment));
			});

			setTotalUnbilledAppointments(convertedAppointmentList.length);
			setUnbilledPatientAppointments(convertedAppointmentList);
		}
	};

	return (
		<>
			<div className="min-h-screen max-w-full py-2 no-print ">
				<div className="max-w-full mx-auto space-y-4">
					{/* Header Card */}
					{/*           <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-3">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <div className="h-8 w-8 bg-blue-600 rounded-lg flex items-center justify-center">
                  <FileText className="h-4 w-4 text-white" />
                </div>
                <div>
                  <h1 className="text-lg font-bold text-gray-900">
                    New Invoice
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-3">
                <span
                  className={`px-2.5 py-1 rounded-full text-xs font-semibold ${
                    invoice.status === "paid"
                      ? "bg-emerald-100 text-emerald-800"
                      : invoice.status === "partlyPaid"
                      ? "bg-amber-100 text-amber-800"
                      : "bg-rose-100 text-rose-800"
                  }`}
                >
                  {invoice.status
                    ? invoice.status.replace("_", " ").toUpperCase()
                    : "UNKNOWN STATUS"}
                </span>
                <button
                  onClick={handlePrint}
                  className="flex items-center gap-1.5 text-blue-600 hover:text-blue-700 font-medium px-2.5 py-1.5 rounded-lg hover:bg-blue-50 transition-colors duration-200 text-sm border border-blue-200"
                >
                  <Printer className="h-4 w-4" />
                  Print Invoice
                </button>
              </div>
            </div>
          </div> */}

					{/* Error Message */}
					{error && (
						<div className="bg-red-50 border border-red-200 rounded-lg p-3 flex items-start gap-2">
							<AlertCircle className="h-5 w-5 text-red-500 flex-shrink-0 mt-0.5" />
							<div className="flex-1">
								<h3 className="text-sm font-medium text-red-800">Error</h3>
								<p className="text-sm text-red-700 mt-0.5">{error}</p>
							</div>
							<button onClick={() => setError(null)} className="text-red-500 text-lg">
								&times;
							</button>
						</div>
					)}

					{/* Main Content */}
					{!isApptModelOpen && (
						<>
							{/* Invoice Header */}
							<InvoiceHeader
								invoice={invoice}
								clientName={clientName}
								readOnly={readOnly}
								makeEditable={() => setReadOnly(false)}
								onDateChange={() => {}}
								onPatientChange={(patient: Patient | null) => {
									if (patient) {
										invoice.clientId = patient._id;
										let name = patient?.firstName;
										if (patient?.salutation) {
											name = patient.salutation + " " + name;
										}
										if (patient?.middleName) {
											name = name + " " + patient.middleName;
										}
										if (patient?.lastName) {
											name = name + " " + patient.lastName;
										}
										invoice.clientName = name;
									}
								}}
								onClose={onClose}
								resetPaymentFields={resetPaymentFields}
								handleSaveInvoice={handleSaveInvoice}
								handlePrint={handlePrint}
							/>
							<div className="flex flex-col gap-4">
								{/* Line Items Section */}
								<div className="px-4">
									<div className="p-3">
										<div className="flex items-center justify-between mb-3">
											<h2 className="text-base font-semibold text-gray-900 flex items-center gap-2">
												<Receipt className="h-4 w-4 text-secondary-600" />
												Line Items
											</h2>
											{!readOnly && invoice.items.length > 0 && (
												<>
													{/* 	<span className="text-sm text-gray-500">
														Select service and add session credits to the client's account.
													</span> */}
													{!isAdvancePayment ? (
														<button
															onClick={() => {
																setIsApptModelOpen(true);
															}}
															disabled={totalUnbilledAppointments <= 0}
															className="flex items-center gap-1.5 text-blue-600 hover:text-blue-700 font-medium px-2.5 py-1 rounded-lg bg-blue-50 hover:bg-blue-100 transition-colors duration-200 text-sm border border-blue-200"
														>
															<PlusCircle className="h-4 w-4" />
															Add Unbilled Appointments: ({totalUnbilledAppointments})
														</button>
													) : (
														<span className="text-sm text-gray-500">
															Select service and add session credits to the client's account.
														</span>
													)}
													<button
														onClick={addLineItem}
														className="flex items-center gap-1.5 text-blue-600 hover:text-blue-700 font-medium px-2.5 py-1 rounded-lg hover:bg-blue-50 transition-colors duration-200 text-sm"
													>
														<PlusCircle className="h-4 w-4" />
														Add Item
													</button>
												</>
											)}
										</div>

										<div className="space-y-1">
											<LineItemRow
												isHeader
												item={{
													itemId: "",
													type: "service",
													description: "",
													quantity: 0,
													amount: 0,
													total: 0,
													discount: 0,
												}}
												onUpdate={() => {}}
												onDelete={() => {}}
												serviceData={serviceData}
												planData={planData}
												staffList={staffList}
												descriptionOptionsInp={descriptionOptions}
												isAdvancePayment={isAdvancePayment}
											/>
											{invoice.items.map((item) => (
												<LineItemRow
													key={item.itemId}
													item={item}
													onUpdate={updateLineItem}
													readOnly={readOnly}
													onDelete={deleteLineItem}
													serviceData={serviceData}
													planData={planData}
													staffList={staffList}
													descriptionOptionsInp={descriptionOptions}
													isAdvancePayment={isAdvancePayment}
												/>
											))}
											{invoice.items.length === 0 && (
												<div className="flex flex-row text-center items-center justify-center py-4 text-gray-400 text-sm gap-2">
													No items added yet. Add session credits to the client's account.
												</div>
											)}
										</div>
									</div>
								</div>
								<hr />
								{/* Summary and Payment Sections */}
								<div className="grid grid-cols-1 px-4">
									<SummaryCard
										subtotal={invoice.subtotal}
										discount={invoice.discount}
										taxes={invoice.taxes}
										total={invoice.totalAmount}
										onDiscountChange={handleDiscountChange}
										onTaxesChange={handleTaxesChange}
										readOnly={readOnly}
									/>
									<hr className="border-t-2 " />

									<PaymentSection
										total={invoice.totalAmount}
										paidAmount={invoice.totalPaid}
										outStandingAmount={invoice.outStandingAmount}
										status={invoice.status}
										originalStatus={originalStatus}
										resetPaymentFields={resetPaymentFields}
										paymentMode={
											invoice.paymentDetails && invoice.paymentDetails.length > 0
												? invoice.paymentDetails[0].paymentMode
												: ""
										}
										notes={
											invoice.paymentDetails && invoice.paymentDetails.length > 0 ? invoice.paymentDetails[0].note : ""
										}
										onPaidAmountChange={(amount) => {
											setPaymentDetails((prev) => ({
												...prev,
												amountPaid: amount,
											}));
											setUpdatePayment(true);
										}}
										onStatusChange={async (status) => {
											setPaymentDetails((prev) => ({ ...prev, status }));
											setInvoice((prev) => ({ ...prev, status }));
											setUpdatePayment(true);
										}}
										onPaymentModeChange={(mode) => {
											setPaymentDetails((prev) => ({ ...prev, paymentMode: mode }));
											setUpdatePayment(true);
										}}
										onNotesChange={(note) => {
											setPaymentDetails((prev) => ({ ...prev, note: note }));
											setUpdatePayment(true);
										}}
										onPaymentDateChange={(date) => {
											setPaymentDetails((prev) => ({ ...prev, paymentDate: date }));
											setUpdatePayment(true);
										}}
									/>
								</div>
								<hr className="border-t-2 " />

								{/* Create Invoice Button */}
								{/*             <div className="flex justify-end gap-2 px-4">
								<button
									onClick={onClose}
									className="flex items-center gap-1.5 font-medium px-2.5 py-1.5 rounded-lg hover:bg-blue-50 transition-colors duration-200 text-sm border bg-secondary-50 border-secondary-500"
								>
									Close
								</button>
								<button
									onClick={handleSaveInvoice}
									disabled={isCreating || invoice.items.length === 0}
									className={`flex items-center gap-2 px-6 py-2.5 rounded-lg font-medium text-white transition-all duration-200 ${
										isCreating || invoice.items.length === 0
											? "bg-gray-400 cursor-not-allowed"
											: "bg-blue-600 hover:bg-blue-700 active:bg-blue-800"
									}`}
								>
									{isCreating ? (
										<div className="h-5 w-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
									) : showSuccess ? (
										<CheckCircle className="h-5 w-5" />
									) : (
										<FileText className="h-5 w-5" />
									)}
									{showSuccess
										? "Invoice Created!"
										: isCreating
										? "Creating..."
										: "Create Invoice"}
								</button>
							</div> */}

								<div className="flex flex-row p-4">
									<fieldset className="m-2 gap-2">
										<h2 className="text-base font-semibold text-gray-900 flex items-center gap-2">
											<BookOpenText className="h-4 w-4 text-secondary-600" />
											Payment History
										</h2>
										<hr className="my-2 border-t border-gray-200" />
										<table className="min-w-full divide-y divide-gray-200">
											<thead>
												<tr>
													<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
														Amount Paid
													</th>
													<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
														Mode
													</th>
													<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
														Date
													</th>
													<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
														Note
													</th>
													<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
														Actions
													</th>
												</tr>
											</thead>
											<tbody className="bg-white divide-y divide-gray-200">
												{invoice.paymentDetails &&
													invoice.paymentDetails.map((payment, index) => (
														<tr key={index}>
															<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
																{CURRENCY_SYMBOL}
																{payment.amountPaid}
															</td>
															<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
																{payment.paymentMode}
															</td>
															<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
																{moment(payment.paymentDate).format("DD-MM-YYYY").toString()}
															</td>
															<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{payment.note}</td>
															<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
																<button
																	onClick={() => handleEditPayment(payment)}
																	className="text-blue-600 hover:text-blue-700"
																>
																	Edit
																</button>
															</td>
														</tr>
													))}
											</tbody>
										</table>
									</fieldset>
								</div>
							</div>
						</>
					)}
					{isApptModelOpen && (
						<div className="flex flex-col w-full pl-2">
							<UnbilledAppointments
								patientId={invoice?.clientId ?? ""}
								patientAppointments={unbilledPatientAppointments}
								totalAppointemnts={totalUnbilledAppointments}
								handleBack={() => setIsApptModelOpen(false)}
								services={serviceData}
								plans={planData}
								onAddSelected={(selectedAppointments) => {
									setSelectedAppointments(selectedAppointments);
									setIsApptModelOpen(false);
									/** Remove blank line item added by default in useEffect when unbilled appointments are added */
									if (selectedAppointments.length > 0 && invoice.totalAmount === 0) {
										invoice.items = [];
									}
									selectedAppointments.forEach((appointment) => {
										var itemId = uuidv4();
										var appointmentDetail = {
											_id: appointment.id,
											type: appointment.appointmentType,
											date: moment(appointment.apptdate).format("YYYY-MM-DD"),
											billedAmount: appointment.billAmount,
										};

										/** Remove  */

										addNewLineItem({
											itemId: itemId,
											type: "service",
											description: appointment.appointmentType,
											quantity: 1,
											amount: appointment.billAmount,
											total: appointment.billAmount,
											staffId: appointment.entityUserId,
											staffName: appointment.entityUserName,
											discount: 0,
											appointments: [appointmentDetail],
										});

										/** Remove selected appointments from unbilled appointment list */
										setUnbilledPatientAppointments((prevAppointments) =>
											prevAppointments.filter((appointment) => !selectedAppointments.includes(appointment))
										);
									});

									setTotalUnbilledAppointments(totalUnbilledAppointments - selectedAppointments.length);
								}}
							/>
						</div>
					)}
				</div>
			</div>

			{/* Print Layout */}
			<div ref={printRef}>{/*    <PrintInvoice invoice={invoice} /> */}</div>

			{/* Modal for editing payment */}
			{currentPayment && (
				<EditPaymentModal
					isOpen={isModalOpen}
					onClose={() => {
						setIsModalOpen(false);
						getBillDetails();
					}}
					payment={currentPayment}
					invoice={invoice}
				/>
			)}
		</>
	);
};

export default InvoicePage;
