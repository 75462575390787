import React, { Fragment, Suspense, useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, Cog6ToothIcon, UserIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { AuthGuard } from "@authentication/guard";
import LoadingScreen from "@components/loading";
import logo from "./../../../public/logo.png";
import { useAuthContext } from "@authentication/hooks/use-auth-context";
import { navigation, getAccessibleNavItems } from "./nav-items";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import { usePathname } from "@routes/hooks";
import { getCurrentRouteMetadata, getPageTitleByPath, isRouteAccessible, paths } from "@routes/paths";
import Button, { ButtonVariant } from "@components/button/button";
import { endpoints, replaceParams } from "@utils/axios";
import axios from "axios";
import { toast } from "react-toastify";
import createInitials from "@utils/createInitials";
import SetupGuard from "@authentication/guard/setup-guard";
import { STORAGE_KEYS } from "@constants/storage-keys";
import tzmoment from "moment-timezone";
import { Breadcrumb } from "./breadcrumb";
import DashboardNavigation from "./dashboardNavigation";
import { Building2, LogOutIcon } from "lucide-react";

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(" ");
}

export type InitialData = {
	EntityBranchName?: string;
	EntityName?: string;
	isEntityDetailsCompleted: boolean;
	isTherapiesConfigurationCompleted: boolean;
	entityTimeZone: string;
	isWorkingHoursConfigurationCompleted: boolean;
	isConsentCompleted: boolean;
	isEntityWorkingHoursConfigCompleted: boolean;
	isEntityBranchWorkingHoursConfigCompleted: boolean;
	RoleName?: string;
	entityLogoUrl?: string;
	entityLogoId?: string;
	uiVersion?: string;
};

// Converts JSON strings to/from your types
export class Convert {
	public static toInitialData(json: string): InitialData {
		return JSON.parse(json);
	}

	public static initialDataToJson(value: InitialData): string {
		return JSON.stringify(value);
	}
}

export default function DashboardLayout() {
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const [initialData, setInitialData] = useState<InitialData>();
	const [initials, setInitials] = useState<string>("");

	const activeTabColorClasses: string = "bg-primary-100 text-primary-500";
	const inActiveTabColorClasses: string = "text-gray-700 hover:text-primary-600 hover:bg-gray-50";

	const { logout } = useAuthContext();
	const userData = getUserPersistedOnLocalStorage();
	let [isOpen, setIsOpen] = useState(true);
	const [isLargeScreen, setIsLargeScreen] = useState(true);

	const currentPath = usePathname();
	const naviagtor = useNavigate();

	const userNavigation = [
		{
			name: "Your profile",
			icon: <UserIcon className="h-5 w-5 mr-2" />,
			callBack: () => {
				navigateViewStaff();
			},
		},

		{
			name: "Manage Entity",
			icon: <Building2 className="h-5 w-5 mr-2" />,
			role: "ENTITY_OWNER",
			callBack: () => {
				navigateStaffEntityManage();
			},
		},
		{
			name: "Manage Branch",
			icon: <Building2 className="h-5 w-5 mr-2" />,
			role: "BRANCH_ADMIN",
			callBack: () => {
				navigateStaffBranchManage();
			},
		},
		{
			name: "Sign out",
			icon: <LogOutIcon className="h-5 w-5 mr-2" />,
			callBack: logout,
		},
	];

	function closeModal() {
		setIsOpen(false);
	}

	const getEntityData = async () => {
		try {
			const paramsMap = new Map<string, string>([["userid", userData?.user_id ?? ""]]);
			const filteredEndpoint = replaceParams(endpoints.dashboardInitialData.initialData, paramsMap);
			const response = await axios.get(filteredEndpoint);
			const data = response.data["data"];
			if (data != null) {
				const convertedData = Convert.toInitialData(JSON.stringify(data));
				const initials = createInitials(convertedData.EntityName ?? "");
				setInitials(initials);
				setInitialData(convertedData);
				sessionStorage.setItem(STORAGE_KEYS.ENTITY_TIMEZONE, convertedData.entityTimeZone);
				sessionStorage.setItem(STORAGE_KEYS.UI_VERSION, convertedData.uiVersion ?? "");
			}
		} catch (error) {
			// toast.error(error.message);

			console.error("Error fetching staff data:", error);
		}
	};

	useEffect(() => {
		if (userData !== null) getEntityData();

		window.addEventListener("resize", updateScreenSize);

		return () => {
			window.removeEventListener("resize", updateScreenSize);
		};
	}, []);

	const navigateViewStaff = () => {
		const paramsMap = new Map<string, string>([["staffId", userData?.user_id ?? ""]]);
		closeModal();
		naviagtor(replaceParams(paths.staff.profileView.route, paramsMap));
	};
	const navigateStaffEntityManage = () => {
		const paramsMap = new Map<string, string>([
			["userId", userData?.user_id ?? ""],
			["entityId", userData?.entity_id ?? ""],
			["entityName", initialData?.EntityName ?? ""],
		]);
		const queryParams = new Map<string, string>([["name", initialData?.EntityName ?? ""]]);
		closeModal();
		naviagtor(replaceParams(paths.staff.entityManage.route, paramsMap, queryParams));
	};
	const navigateStaffBranchManage = () => {
		const paramsMap = new Map<string, string>([
			["userId", userData?.user_id ?? ""],
			["branchId", userData?.branch_id ?? ""],
			["branchName", initialData?.EntityBranchName ?? ""],
		]);
		const queryParams = new Map<string, string>([["name", initialData?.EntityBranchName ?? ""]]);
		closeModal();
		naviagtor(replaceParams(paths.staff.branchManage.route, paramsMap, queryParams));
	};

	function formatRoleName(role: string): string {
		return role
			.replace(/_/g, " ")
			.split(" ")
			.map((word) => word.charAt(0) + word.slice(1))
			.join(" ");
	}

	const updateScreenSize = () => {
		setIsLargeScreen(window.innerWidth >= 1024);
	};

	return (
		<>
			<AuthGuard>
				<SetupGuard value={initialData}>
					<Suspense fallback={<LoadingScreen />}>
						<>
							<Transition.Root show={sidebarOpen} as={Fragment}>
								<Dialog as="div" className="relative z-50 xl:hidden" onClose={setSidebarOpen}>
									<Transition.Child
										as={Fragment}
										enter="transition-opacity ease-linear duration-300"
										enterFrom="opacity-0"
										enterTo="opacity-100"
										leave="transition-opacity ease-linear duration-300"
										leaveFrom="opacity-100"
										leaveTo="opacity-0"
									>
										<div className="fixed inset-0 bg-gray-900/80" />
									</Transition.Child>

									<div className="fixed inset-0 flex">
										<Transition.Child
											as={Fragment}
											enter="transition ease-in-out duration-300 transform"
											enterFrom="-translate-x-full"
											enterTo="translate-x-0"
											leave="transition ease-in-out duration-300 transform"
											leaveFrom="translate-x-0"
											leaveTo="-translate-x-full"
										>
											<Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
												<Transition.Child
													as={Fragment}
													enter="ease-in-out duration-300"
													enterFrom="opacity-0"
													enterTo="opacity-100"
													leave="ease-in-out duration-300"
													leaveFrom="opacity-100"
													leaveTo="opacity-0"
												>
													<div className="absolute left-full top-0 flex w-16 justify-center pt-5">
														<button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
															<span className="sr-only">Close sidebar</span>
															<XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
														</button>
													</div>
												</Transition.Child>
												{/* Sidebar component, swap this element with another sidebar if you like */}
												<DashboardNavigation
													initialData={initialData ?? {}}
													logo={logo}
													navigation={navigation}
													isRouteAccessible={isRouteAccessible}
													activeTabColorClasses={activeTabColorClasses}
													inActiveTabColorClasses={inActiveTabColorClasses}
													initials={initials}
													formatRoleName={formatRoleName}
													navigateStaffEntityManage={navigateStaffEntityManage}
													navigateStaffBranchManage={navigateStaffBranchManage}
													navigateViewStaff={navigateViewStaff}
													logout={logout}
													onClick={() => setSidebarOpen(false)}
												/>
												{/*   <nav className="flex flex-1 flex-col px-4 border-r">
                          <ul className="flex flex-1 flex-col gap-y-7 ">
                            {userNavigation.map(
                              (item) =>
                                (!item.role ||
                                  item.role === initialData?.RoleName) && (
                                  <li key={item.name}>
                                    <button
                                      onClick={item.callBack}
                                      className={`group flex gap-x-3 rounded-xl p-3 text-sm leading-6 font-semibold ${inActiveTabColorClasses}`}
                                    >
                                      {item.icon}
                                      {item.name}
                                    </button>
                                  </li>
                                )
                            )}
                          </ul>
                        </nav> */}
											</Dialog.Panel>
										</Transition.Child>
									</div>
								</Dialog>
							</Transition.Root>

							{/* Static sidebar for desktop */}
							<div className="hidden xl:fixed xl:inset-y-0 xl:z-50 xl:flex xl:w-64 xl:flex-col">
								{/* Sidebar component, swap this element with another sidebar if you like */}
								<DashboardNavigation
									initialData={initialData ?? {}}
									logo={logo}
									navigation={getAccessibleNavItems()}
									isRouteAccessible={isRouteAccessible}
									activeTabColorClasses={activeTabColorClasses}
									inActiveTabColorClasses={inActiveTabColorClasses}
									initials={initials}
									formatRoleName={formatRoleName}
									navigateStaffEntityManage={navigateStaffEntityManage}
									navigateStaffBranchManage={navigateStaffBranchManage}
									navigateViewStaff={navigateViewStaff}
									logout={logout}
								/>
								<div className="flex h-24">
									{/* Profile dropdown */}
									<Menu as="div" className="relative w-full">
										<Menu.Button className=" flex w-full items-center pb-2">
											<div
												className={classNames(
													"flex flex-col justify-between w-full overflow-clip border border-primary-200 rounded-xl m-2",
													"text-primary-800"
												)}
											>
												{/* ...content of the staff card... */}
												<div
													className="flex flex-row bg-white-300 items-center text-sm font-semibold p-2 space-x-2 w-full
                          justify-between overflow-hidden whitespace-nowrap overflow-ellipsis"
												>
													{userData?.name ?? ""}
												</div>
												<hr className="border-t border-primary-200" />
												<div className="bg-primary-100 items-center text-primary-500">
													<div className="flex flex-row bg-white-300 items-center justify-between text-center text-xs font-semibold p-2 space-x-2  w-full">
														{formatRoleName(initialData?.RoleName ?? "")}
														<Cog6ToothIcon className="ml-2 h-6 w-6 font-bold text-gray-900" aria-hidden="true" />
													</div>
												</div>
											</div>
										</Menu.Button>
										<Transition
											as={Fragment}
											enter="transition ease-out duration-100"
											enterFrom="transform opacity-0 scale-95"
											enterTo="transform opacity-100 scale-100"
											leave="transition ease-in duration-75"
											leaveFrom="transform opacity-100 scale-100"
											leaveTo="transform opacity-0 scale-95"
										>
											<Menu.Items className="absolute left-0 bottom-full z-10 w-60 origin-bottom-left rounded-lg bg-primary-50 shadow-lg ring-1 ring-gray-900/50 focus:outline-none m-2 p-2">
												<div className="mb-1">
													<Menu.Item key={"Current Timezone"}>
														<span className={"xl:hidden px-3 py-1 text-xs leading-6 text-gray-600"}>
															{tzmoment.tz.guess()} (UTC
															{tzmoment.tz(tzmoment.tz.guess()).format("Z")})
														</span>
													</Menu.Item>
												</div>
												<div className="mb-1">
													<Menu.Item key={"Username"}>
														<span className={"xl:hidden px-3 py-1 text-base leading-6 text-gray-900"}>
															{userData?.name ?? ""}
														</span>
													</Menu.Item>
												</div>
												{userNavigation.map(
													(item) =>
														(!item.role || item.role === initialData?.RoleName) && (
															<Menu.Item key={item.name}>
																{({ active }) => (
																	<button
																		onClick={item.callBack}
																		className={classNames(
																			active ? "bg-gray-50" : "",
																			"w-full text-left px-3 py-1 text-sm font-medium leading-6 text-gray-900 flex items-center"
																		)}
																	>
																		{item.icon}
																		{item.name}
																	</button>
																)}
															</Menu.Item>
														)
												)}
												<div className="flex flex-col justify-between w-full overflow-clip mt-4 pt-2 border rounded-xl">
													{/* ...content of the staff card... */}
													<div className="flex flex-row bg-white-300 items-center text-xs font-semibold p-2 space-x-2  w-full">
														<div className="flex flex-col w-full ">
															<span className="whitespace-nowrap overflow-hidden truncate">
																{initialData?.EntityName}
															</span>
															<span className="whitespace-nowrap overflow-hidden truncate">
																{initialData?.EntityBranchName}
															</span>
														</div>
													</div>

													{/*                           <div className="flex flex-row rounded-bl-xl rounded-br-xl bg-tertiary-500 items-center px-4 py-2 mt-2">
                            <div className="flex flex-row w-full justify-center font-semibold text-white text-sm">
                              {formatRoleName(initialData?.RoleName ?? "")}
                            </div>
                          </div> */}
												</div>
											</Menu.Items>
										</Transition>
									</Menu>
								</div>
							</div>

							<div className="h-full\">
								<div className="sticky xl:hidden top-0 left-0 z-40 flex pt-6 shrink-0 items-center gap-x-4 ml-4 w-10">
									<button
										type="button"
										className="-m-2.5 p-2.5 text-gray-700 xl:hidden"
										onClick={() => setSidebarOpen(true)}
									>
										<span className="sr-only">Open sidebar</span>
										<Bars3Icon className="h-6 w-6 font-bold" aria-hidden="true" />
									</button>
								</div>
								{/*  hide top bar    *           <div className="fixed top-0 right-0 left-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
                  <button
                    type="button"
                    className="-m-2.5 p-2.5 text-gray-700 xl:hidden"
                    onClick={() => setSidebarOpen(true)}
                  >
                    <span className="sr-only">Open sidebar</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                  </button>

                               <div
                    className="h-6 w-px bg-gray-200 lg:hidden"
                    aria-hidden="true"
                  /> 

                  <div className="relative flex flex-1 gap-x-4  lg:gap-x-6">
                    <div className="flex shrink-0 items-center border-b w-64">
                      {initialData?.entityLogoUrl ? (
                        <img
                          src={initialData?.entityLogoUrl}
                          alt="Entity Logo"
                          className=" object-center object-cover h-16"
                        />
                      ) : (
                        <img
                          src={logo}
                          alt="Default Logo"
                          className="h-20 object-center object-cover"
                        />
                      )}
                    </div>
                    <div className="flex flex-1 ">
                      <h1 className="flex items-center text-lg font-bold leading-7 text-gray-900 sm:text-xl sm:truncate">
                        {isLargeScreen && <Breadcrumb></Breadcrumb>}
                        {!isLargeScreen && (
                          <>
                            {getCurrentRouteMetadata(currentPath.toString())
                              .title ?? "Gritup"}
                          </>
                        )}
                      </h1>
                    </div>
                  </div>
                </div>
 */}
								<main className="py-4 h-[calc(100vh-8px)] xl:pl-64 w-full mt-2 ">
									<div className="px-4 sm:px-6 lg:px-8 w-full h-full">
										<Outlet />
									</div>
								</main>
							</div>
						</>
					</Suspense>
				</SetupGuard>
			</AuthGuard>
		</>
	);
}
