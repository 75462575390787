import { Dialog, Transition } from "@headlessui/react";
import { endpoints, replaceParams } from "@utils/axios";
import axios from "axios";
import { Fragment, useEffect, useState, useRef } from "react";
import React from "react";
import { PersonalInformationDetails } from "../types/PatientPersonalDetails";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";
import { NewMasterAssessmentData } from "../types/NewMasterAssessment";
import moment from "moment";
import { ConvertMedicalHistory, MedicalHistoryDetails } from "./type/MedicalHistoryDetails";
import { ConvertDiagnosis, Diagnosis } from "./type/DiagnosisDetails";
import { Convert, PrescriptionDetails } from "./type/PrescriptionDetails";
import { AssignedExercises, ConvertAssignedExercises } from "./type/AssignedExercises";
import SolidButton, { ButtonVariant } from "@components/button/button";
import { ConvertExerciseMedia, ExerciseMedia } from "./type/ExerciseMedia";
import logo from "./../../../../public/logo.png";
import { Assessments } from "./PdfCheckboxDialog";

import { toast } from "react-toastify";
import { ConvertVitals, Vitals } from "./type/VitalAssessment";
import { ConvertPain, Pain } from "./type/PainAssessment";
import { ConvertROM, RangeOfMotionAssessment } from "./type/RangeOfMotion";
import ADLAssessment from "./pdfcomponents/ADLAssessment";
import RangeOfMotion from "./pdfcomponents/RangeOfMotion";
import { ADLQuestions, ConvertADLQuestions } from "./type/ADLtypes/ADLQuestions";
import { ADLCategory } from "./type/ADLtypes/ADLcategory";
import PainAssessment from "./pdfcomponents/PainAssessment";
import VitalsAssessment from "./pdfcomponents/VitalsAssessment";
import { ConvertCustom, Custom } from "./type/CustomAssessment";
import CustomAssessment from "./pdfcomponents/CustomAssessment";
import PostureAssessment from "./pdfcomponents/PostureAssessment";
import { ConvertPosture, Posture } from "./type/Posture";
import GaitAssessment from "./pdfcomponents/GaitAssessment";
import MuscletoneAssessment from "./pdfcomponents/MuscletoneAssessment";
import { ConvertGait, Gait } from "./type/Gait";
import { ConvertMuscleTone, MuscleToneAssessment } from "./type/MuscleTone";
import { ConvertReflex, Reflex } from "./type/Reflex";
import ReflexAssessment from "./pdfcomponents/ReflexAssessment";
import { ConvertOdema, Oedema } from "./type/Oedema";
import OedemaAssessment from "./pdfcomponents/OedemaAssessment";
import { ConvertLimbLength, LimbLength } from "./type/LimbLengthType";
import { ConvertLimbGirth, LimbGirth } from "./type/LimbGirthTypes";
import { ConvertMuscleWasting, MuscleWasting } from "./type/MuscleWastingTypes";
import LimbLengthAssessment from "./pdfcomponents/LimbLengthAssessment";
import MuscleWastingAssessment from "./pdfcomponents/MuscleWastingAssessment";
import LimbGirthAssessment from "./pdfcomponents/LimbGirthAssessment";
import { ConvertSensory, SensoryDetails } from "./type/SensoryDetails";
import { Balance, ConvertBalance } from "./type/Balance";
import { ConvertMuscleTightness, MuscleTightness } from "./type/MuscleTightness";

import { ConvertMuscleStrength, MuscleStrength } from "./type/MuscleStrength";
import { ConvertScar, ScarDetails } from "./type/ScarDetails";
import { ConvertSpecialTest, SpecialTest } from "./type/SpecialTest";
import SpecialTestDetails from "@pages/assesment-all/special-test-assessment/type/SpecialTestDetails";
import { ConvertNerveTest, NerveTest } from "./type/NerveTest";
import { ConvertSTD, StructuralDeformitiesType } from "./type/StructuralDeformitiesType";
import SensoryAssessment from "./pdfcomponents/SensoryAssessment";
import BalanceAssessment from "./pdfcomponents/BalanceAssessment";
import MuscleTightnessAssessment from "./pdfcomponents/MuscleTightnessAssessment";
import MuscleStrengthAssessment from "./pdfcomponents/MuscleStrength";
import ScarAssessment from "./pdfcomponents/ScarAssessment";
import SpecialTestAssessment from "./pdfcomponents/SpecialTestAssessment";
import NerveTestAssessment from "./pdfcomponents/NerveTestAssessment";
import StructuralDeformitiesAssessment from "./pdfcomponents/StructuralDeformitiesAssessment";
import Applabel from "@components/hook-form/applabel";
import { ContentState, Editor, EditorState, convertFromHTML } from "draft-js";

interface InitialData {
	EntityBranchAddress: EntityBranchAddress;
	EntityBranchName?: string;
	EntityBranchPhone: string;
	EntityName?: string;
	isEntityDetailsCompleted: boolean;
	isTherapiesConfigurationCompleted: boolean;
	entityTimeZone: string;
	entityLogoId: string;
	entityLogoUrl: string;
	isWorkingHoursConfigurationCompleted: boolean;
	isConsentCompleted: boolean;
	isEntityWorkingHoursConfigCompleted: boolean;
	isEntityBranchWorkingHoursConfigCompleted: boolean;
	RoleName?: string;
	EntityBranchHeaderEnabled: boolean;
	EntityBranchFooterEnabled: boolean;
	EntityBranchLetterFooter: string;
	EntityBranchLetterHeader: string;
	EntityBranchHeaderLogoEnabled: boolean;
	EntityBranchHideHeader: boolean;
}

interface EntityBranchAddress {
	address: string;
	city: string;
	state: string;
	country: string;
	postalCode: number;
}

type PDFProps = {
	isOpen: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	patientId: string;
	masterAssessmentData: NewMasterAssessmentData | null;
	assessmentData: Assessments | null;
	setIsCheckboxDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setAssessmentData: React.Dispatch<React.SetStateAction<Assessments | null>>;
	setSelectAll: React.Dispatch<React.SetStateAction<boolean>>;
};

const PdfExportDialog = (props: PDFProps) => {
	const userData = getUserPersistedOnLocalStorage();
	const [initialData, setInitialData] = useState<InitialData>();
	const printRef = useRef<HTMLDivElement>(null);
	const [loading, setLoading] = useState<boolean>(true); // Loading state
	const [patientPersonalInformation, setPatientPersonalInformation] = useState<PersonalInformationDetails | null>(null); //patient info state
	const [history, setHistory] = useState<MedicalHistoryDetails | null>(null); // medical history state
	const [diagnosis, setDiagnosis] = useState<Diagnosis | null>(null); //diagnosis state
	const [treatmentPlanData, setTreatmentPlanData] = useState<PrescriptionDetails>(); // treatment plan states
	const [radioValue, setRadioValue] = useState("Home");
	const [selectedTherapy, setSelectedTherapy] = useState<string[]>();
	const [activeExerciseProgram, setActiveExerciseProgram] = useState<AssignedExercises[] | null>(null);
	const [mediaFiles, setMediaFiles] = useState<ExerciseMedia[]>([]);
	const imagesPerRow = 2;
	const [selectedOutReferralName, setSelectedOutReferralName] = useState("");
	const [isLoading, setIsLoading] = useState(true);
	const [vitalAssessment, setVitalAssessment] = React.useState<Vitals | null>(null); //vitals states
	const [painAssessment, setPainAssessment] = useState<Pain | null>(null); //pain states
	const [intensity, setIntensity] = useState(0);
	const [romAssessment, setRomAssessment] = useState<RangeOfMotionAssessment | null>(null); //ROM states
	const [adlQuestions, setAdlQuestions] = useState<ADLQuestions | null>(null); //ADL states
	const [adlCategories, setADLCategories] = useState<ADLCategory[]>([]);
	const [customAssessment, setCustomAssessment] = useState<Custom[] | null>(null); //Custom Assessment
	const [postureAssessment, setPostureAssessment] = useState<Posture | null>(null); //Posture state
	const [gaitAssessment, setGaitAssessment] = useState<Gait | null>(null); //Gait
	const [muscleToneAssessment, setMuscleToneAssessment] = useState<MuscleToneAssessment | null>(null); //Muscle TOne
	const [reflexAssessment, setReflexAssessment] = useState<Reflex | null>(null); //reflex
	const [odemaAssessment, setOdemaAssessment] = useState<Oedema | null>(null); //Odema
	const [limbLengthAssessment, setLimbLengthAssessment] = useState<LimbLength | null>(null); //limblength
	const [limbGirthAssessment, setLimbGirthAssessment] = useState<LimbGirth | null>(null); //limbgirth
	const [muscleWastingAssessment, setMuscleWastingAssessment] = useState<MuscleWasting | null>(null); //muscleWasting
	const [sensoryAssessment, setSensoryAssessment] = useState<SensoryDetails | null>(null); //sensory
	const [balanceAssessment, setBalanceAssessment] = useState<Balance | null>(null); //balance
	const [muscleTightnessAssessment, setmuscleTightnessAssessment] = useState<MuscleTightness | null>(null); //muscle tightness
	const [muscleStrengthAssessment, setMuscleStrengthAssessment] = useState<MuscleStrength | null>(null); //muscle strength
	const [scarAssessment, setScarAssessment] = useState<ScarDetails | null>(null); //scar
	const [specialTestAssessment, setSpecialTestAssessment] = useState<SpecialTest | null>(null); //special test
	const [nerveTestAssessment, setNerveTestAssessment] = useState<NerveTest | null>(null); //nerve test
	const [structuralDeformitiesAssessment, setStructuralDeformitiesAssessment] =
		useState<StructuralDeformitiesType | null>(null); //structural deformities
	const [headerEditorState, setHeaderEditorState] = React.useState(EditorState.createEmpty());
	const [signatureEditorState, setSignatureEditorState] = React.useState(EditorState.createEmpty());

	var checklistData = {
		medicalHistory: [
			{ name: "Chief Complaint", isChecked: false },
			{ name: "History of Present Illness", isChecked: false },
			{ name: "History of Past Illness", isChecked: false },
			{ name: "Pre Existing Health Conditions", isChecked: false },
			{ name: "Past Treatment", isChecked: false },
			{ name: "Surgeries", isChecked: false },
			{ name: "Family Medical History", isChecked: false },
		],
		physicalAssessments: [
			{ name: "Vitals", isChecked: false },
			{ name: "Pain", isChecked: false },
			{ name: "Range of Motion", isChecked: false },
			{ name: "ADL", isChecked: false },
			{ name: "Custom", isChecked: false },
			{ name: "Posture", isChecked: false },
			{ name: "Gait", isChecked: false },
			{ name: "Muscle Tone", isChecked: false },
			{ name: "Reflex", isChecked: false },
			{ name: "Oedema", isChecked: false },
			{ name: "Limb Length", isChecked: false },
			{ name: "Limb Girth", isChecked: false },
			{ name: "Muscle Wasting", isChecked: false },
			{ name: "Sensory", isChecked: false },
			{ name: "Balance", isChecked: false },
			{ name: "Muscle Tightness", isChecked: false },
			{ name: "Muscle Strength", isChecked: false },
			{ name: "Scar", isChecked: false },
			{ name: "Special Test", isChecked: false },
			{ name: "Nerve Test", isChecked: false },
			{ name: "Structural Deformities", isChecked: false },
		],
		diagnosis: [
			// { name: "Chief Complaint", isChecked: false },
			{ name: "Provisional Diagnosis", isChecked: false },
			{ name: "Differential Diagnosis", isChecked: false },
			{ name: "Final Diagnosis", isChecked: false },
			{ name: "Radiological Findings", isChecked: false },
			{ name: "Medial Findings", isChecked: false },
		],
		treatmentPlan: [
			{ name: "Prescription", isChecked: false },
			{ name: "Exercise Library", isChecked: false },
			{ name: "Medical Prescription", isChecked: false },
			{ name: "Goals", isChecked: false },
		],
	};

	const getAssignedExercises = async () => {
		try {
			const paramsMap = new Map<string, string>([["id", props.masterAssessmentData?.treatmentPlanId!]]);
			const response = await axios.get(replaceParams(endpoints.managePatient.getAssignedExercises, paramsMap));
			const data = response.data["data"]["homeExerciseProgram"];

			const toSet: AssignedExercises[] = [];
			if (data !== null) {
				for (const exercise of data) {
					const converted = ConvertAssignedExercises.toAssignedExercises(JSON.stringify(exercise));
					toSet.push(converted);
				}
			}
			setActiveExerciseProgram(toSet);
		} catch (error) {
			console.error("Error fetching active exercise program:", error);
		}
	};

	const getTreatmentPlanData = async () => {
		try {
			const paramsMap = new Map<string, string>([["id", props.masterAssessmentData?.treatmentPlanId ?? ""]]);
			const filteredEndpoint = replaceParams(endpoints.treatmentPlan.view, paramsMap);
			const response = await axios.get(filteredEndpoint);
			const data = response.data["data"];
			const convertedTreatmentPlanData = Convert.toAddNewPrescriptionDetails(JSON.stringify(data));

			if (data.outReferral != "") {
				setSelectedOutReferralName(data.outReferral);
			}

			if (convertedTreatmentPlanData.prescribedThrapeuticProcedures === null) {
				convertedTreatmentPlanData.prescribedThrapeuticProcedures = [];
			}

			if (convertedTreatmentPlanData.type === "") {
				convertedTreatmentPlanData.type = "Home";
			}

			setSelectedTherapy(convertedTreatmentPlanData.prescribedThrapeuticProcedures);
			setRadioValue(convertedTreatmentPlanData.type);
			setTreatmentPlanData(convertedTreatmentPlanData);
		} catch (error) {
			setRadioValue("Home");
			setSelectedTherapy([]);

			console.error("Error fetching data:", error);
		}
	};

	const getDiagnosisData = async () => {
		try {
			const paramsMap = new Map<string, string>([["id", props.masterAssessmentData?.diagnosisId!]]);
			const filteredEndpoint = replaceParams(endpoints.diagnosis.fetch, paramsMap);
			const response = await axios.get(filteredEndpoint);
			const data = response.data["data"];
			const convertedDiagnosis = ConvertDiagnosis.toDiagnosis(JSON.stringify(data));
			setDiagnosis(convertedDiagnosis);
		} catch (error) {
			console.error("Error getting diagnosis data:", error);
		}
	};

	const getEntityData = async () => {
		try {
			const paramsMap = new Map<string, string>([["userid", userData?.user_id ?? ""]]);
			const filteredEndpoint = replaceParams(endpoints.dashboardInitialData.initialData, paramsMap);
			const response = await axios.get(filteredEndpoint);
			const data = response.data["data"];
			if (data != null) {
				setInitialData(data);
			}
		} catch (error) {
			console.error("Error fetching staff data:", error);
		}
	};

	const getPatientData = async () => {
		try {
			const paramsMap = new Map<string, string>([["patientId", props.patientId]]);
			const filteredEndpoint = replaceParams(endpoints.patient.view, paramsMap);
			const response = await axios.get(filteredEndpoint);
			const data = response.data["data"];
			setPatientPersonalInformation(data);
		} catch (error) {
			console.error("Error getting patient information", error);
		}
	};

	const getMedicalData = async () => {
		try {
			const paramsMap = new Map<string, string>([["id", props.masterAssessmentData?.medicalHistoryId!]]);
			const filteredEndpoint = replaceParams(endpoints.medicalHistory.view, paramsMap);
			const response = await axios.get(filteredEndpoint);
			const data = response.data["data"];

			const convertedMedicalHistory = ConvertMedicalHistory.toMedicalHistoryDetails(JSON.stringify(data));
			setHistory(convertedMedicalHistory);
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	const fetchMediaFiles = async (exerciseId: string) => {
		try {
			const paramsMap = new Map<string, string>([["id", exerciseId]]);
			const filteredEndpoint = replaceParams(endpoints.exercise.fetchMedia, paramsMap);
			const response = await axios.get(filteredEndpoint);
			const data = response.data["data"];
			const toSet: ExerciseMedia[] = [];
			if (data !== null) {
				for (let media of data) {
					const converted: any = ConvertExerciseMedia.toExerciseMedia(JSON.stringify(media));
					toSet.push(converted);
				}
			}
			return toSet;
		} catch (error) {
			console.error("Error fetching media data:", error);
			return [];
		}
	};

	const initializeVitalAssessment = async () => {
		if (props.masterAssessmentData?.id)
			if (props.masterAssessmentData?.vitalAssessmentId !== "000000000000000000000000") {
				try {
					const paramsMap = new Map<string, string>([["masterAssessmentId", props.masterAssessmentData?.id]]);
					const filteredEndpoint = replaceParams(endpoints.assessment.getVitalAssessment, paramsMap);
					const response = await axios.get(filteredEndpoint);
					const data = response.data["data"];
					const convertedVitalAssessment = ConvertVitals.toVitals(JSON.stringify(data));
					setVitalAssessment(convertedVitalAssessment);

					// setIsDisabled(true);
					// setLoading(false);
				} catch (error) {
					// toast.error(error.message);

					console.error("Error fetching vital assessment:", error);
					// setError(error.message);
					// setLoading(false);
				}
			}
	};

	const initializePainAssessment = async () => {
		if (props.masterAssessmentData?.id)
			if (props.masterAssessmentData?.painAssessmentId !== "000000000000000000000000")
				try {
					const paramsMap = new Map<string, string>([["mid", props.masterAssessmentData.id!]]);
					const filteredEndpoint = replaceParams(endpoints.assessment.getPainAssessment, paramsMap);
					const response = await axios.get(filteredEndpoint);
					const data = response.data["data"];

					const convertedPainAssessment = ConvertPain.toPain(JSON.stringify(data));
					setPainAssessment(convertedPainAssessment);

					setIntensity(convertedPainAssessment.painIntensity ?? 0);
					// setIsDisabled(true);
					// setLoading(false);
				} catch (error) {
					console.error("Error fetching pain assessment:", error);
					// setError(error.message);
					// setLoading(false);
				}
	};

	const initializeROMAssessment = async () => {
		if (props.masterAssessmentData?.id)
			if (props.masterAssessmentData?.rangeOfMotionAssessmentId !== "000000000000000000000000")
				try {
					const paramsMap = new Map<string, string>([["mid", props.masterAssessmentData.id!]]);
					const filteredEndpoint = replaceParams(endpoints.assessment.getRomAssessment, paramsMap);
					const response = await axios.get(filteredEndpoint);
					const data = response.data["data"];
					const convertedRomAssessment = ConvertROM.toRangeOfMotionAssessment(JSON.stringify(data));

					setRomAssessment(convertedRomAssessment);

					//  methods.reset({ ...convertedRomAssessment });
					//  setIsDisabled(true);
					//  setLoading(false);
				} catch (error) {
					// toast.error(error.message);

					console.error("Error fetching ROM assessment:", error.message);
					//  setError(error.message);
					//  setLoading(false);
				}
	};

	const initializeADLAssessment = async () => {
		if (props.masterAssessmentData?.id)
			if (props.masterAssessmentData?.adlAssessmentId !== "000000000000000000000000") {
				try {
					const paramsMap = new Map<string, string>([["mid", props.masterAssessmentData.id!]]);
					const filteredEndpoint = replaceParams(endpoints.assessment.getADLAssessment, paramsMap);
					const response = await axios.get(filteredEndpoint);
					const data = response.data["data"];
					if (data.categoryScoreDetails === null) setAdlQuestions(null);
					else {
						const converted = ConvertADLQuestions.toADLQuestions(JSON.stringify(data));

						if (converted.categoryScoreDetails) {
							const categoriesMap: ADLCategory[] = converted.categoryScoreDetails
								.filter((val) => typeof val.categoryCode === "string") // Filter out undefined values
								.map((val) => {
									return {
										category: val.category!,
										categoryCode: val.categoryCode!,
									};
								});
							setADLCategories(categoriesMap);
							//console.log(categoriesMap);
						}

						//console.log(converted);

						setAdlQuestions(converted);

						//reset(convertedBranchList);

						// setLoading(false);
					}
				} catch (error) {
					// toast.error(error.message);

					console.error("Error fetching Muscle Wasting assessment:", error);

					// setLoading(false);
				}
			} else {
				try {
					const paramsMap = new Map<string, string>([["id", userData?.entity_id!]]);
					const filteredEndpoint = replaceParams(endpoints.assessment.getADLQuestions, paramsMap);
					const response = await axios.get(filteredEndpoint);
					const data = response.data["data"];

					const converted = ConvertADLQuestions.toADLQuestions(JSON.stringify(data));
					if (converted.categoryScoreDetails) {
						const categoriesMap: ADLCategory[] = converted.categoryScoreDetails
							.filter((val) => typeof val.categoryCode === "string") // Filter out undefined values
							.map((val) => {
								return {
									category: val.category!,
									categoryCode: val.categoryCode!,
								};
							});
						setADLCategories(categoriesMap);
						//console.log(categoriesMap);
					}

					setAdlQuestions(converted);

					// reset(convertedBranchList);
					// //console.log("form reset");
					// setLoading(false);
				} catch (error) {
					// toast.error(error.message);

					console.error("Error fetching Muscle Wasting assessment:", error);

					// setLoading(false);
				}
			}
	};

	const initializeCustomAssessment = async () => {
		if (props.masterAssessmentData?.id)
			if (props.masterAssessmentData?.customAssessmentId !== "000000000000000000000000") {
				try {
					const paramsMap = new Map<string, string>([["mid", props.masterAssessmentData.id!]]);
					const filteredEndpoint = replaceParams(endpoints.assessment.getCustomAssessment, paramsMap);
					const response = await axios.get(filteredEndpoint);
					const data = response.data["data"].customAssessmentDetails;

					let converted: Custom[] = [];
					if (data) {
						for (var d of data) {
							converted.push(d);
						}
					}

					setCustomAssessment(converted);
				} catch (error) {
					// toast.error(error.message);

					console.error("Error fetching Assessments:", error);
				}
			}
	};

	const initializePostureAssessment = async () => {
		if (props.masterAssessmentData?.id)
			if (props.masterAssessmentData?.postureAssessmentId !== "000000000000000000000000") {
				try {
					const paramsMap = new Map<string, string>([["mid", props.masterAssessmentData.id!]]);
					const filteredEndpoint = replaceParams(endpoints.assessment.getPostureAssessment, paramsMap);
					const response = await axios.get(filteredEndpoint);
					const data = response.data["data"];
					const convertedPostureAssessment = ConvertPosture.toPosture(JSON.stringify(data));
					setPostureAssessment(convertedPostureAssessment);

					// setIsDisabled(true);
					// setLoading(false);
				} catch (error) {
					console.error("Error fetching Posture assessment:", error);
					// toast.error(error.message);

					// setError(error.message);
					// setLoading(false);
				}
			}
	};

	const initializeGaitAssessment = async () => {
		if (props.masterAssessmentData?.id)
			if (props.masterAssessmentData?.gaitAssessmentId !== "000000000000000000000000") {
				try {
					const paramsMap = new Map<string, string>([["mid", props.masterAssessmentData.id!]]);
					const filteredEndpoint = replaceParams(endpoints.assessment.getGaitAssessment, paramsMap);
					const response = await axios.get(filteredEndpoint);
					const data = response.data["data"];
					const convertedAssessment = ConvertGait.toGait(JSON.stringify(data));
					setGaitAssessment(convertedAssessment);
				} catch (error) {
					// toast.error(error.message);

					console.error("Error fetching Gait assessment:", error);
				}
			}
	};

	const initializeMuscleToneAssessment = async () => {
		if (props.masterAssessmentData?.id)
			if (props.masterAssessmentData?.gaitAssessmentId !== "000000000000000000000000") {
				try {
					const paramsMap = new Map<string, string>([["mid", props.masterAssessmentData?.id!]]);
					const filteredEndpoint = replaceParams(endpoints.assessment.getMuscleToneAssessment, paramsMap);
					const response = await axios.get(filteredEndpoint);
					const data = response.data["data"];
					const convertedMuscleToneAssessment = ConvertMuscleTone.toMuscleToneAssessment(JSON.stringify(data));
					setMuscleToneAssessment(convertedMuscleToneAssessment);
				} catch (error) {
					// toast.error(error.message);

					console.error("Error fetching MuscleTone assessment:", error);
				}
			}
	};

	const initializeReflexAssessment = async () => {
		if (props.masterAssessmentData?.id)
			if (props.masterAssessmentData?.reflexAssessmentId !== "000000000000000000000000") {
				try {
					const paramsMap = new Map<string, string>([["mid", props.masterAssessmentData?.id!]]);
					const filteredEndpoint = replaceParams(endpoints.assessment.getReflexAssessment, paramsMap);
					const response = await axios.get(filteredEndpoint);
					const data = response.data["data"];
					const convertedReflexAssessment = ConvertReflex.toReflex(JSON.stringify(data));
					setReflexAssessment(convertedReflexAssessment);
				} catch (error) {
					// toast.error(error.message);

					console.error("Error fetching Reflex assessment:", error);
				}
			}
	};

	const initializeOedemaAssessment = async () => {
		if (props.masterAssessmentData?.id!)
			if (props.masterAssessmentData?.oedemaAssessmentId !== "000000000000000000000000") {
				try {
					const paramsMap = new Map<string, string>([["mid", props.masterAssessmentData?.id!]]);
					const filteredEndpoint = replaceParams(endpoints.assessment.getOedemaAssessment, paramsMap);
					const response = await axios.get(filteredEndpoint);
					const data = response.data["data"];
					const convertedOedemaAssessment = ConvertOdema.toOedema(JSON.stringify(data));

					setOdemaAssessment(convertedOedemaAssessment);
				} catch (error) {
					// toast.error(error.message);

					console.error("Error fetching Reflex assessment:", error);
				}
			}
	};

	const initializeLimbLengthAssessment = async () => {
		if (props.masterAssessmentData?.id!)
			if (props.masterAssessmentData?.limbLengthAssessmentId !== "000000000000000000000000") {
				try {
					const paramsMap = new Map<string, string>([["mid", props.masterAssessmentData.id!]]);
					const filteredEndpoint = replaceParams(endpoints.assessment.getLimbLengthAssessment, paramsMap);
					const response = await axios.get(filteredEndpoint);
					const data = response.data["data"];
					const convertedLimbLength = ConvertLimbLength.toLimbLength(JSON.stringify(data));
					setLimbLengthAssessment(convertedLimbLength);
				} catch (error) {
					// toast.error(error.message);
				}
			}
	};

	const initializeLimbGirthAssessment = async () => {
		if (props.masterAssessmentData?.id!)
			if (props.masterAssessmentData?.limbGirthAssessmentId !== "000000000000000000000000") {
				try {
					const paramsMap = new Map<string, string>([["mid", props.masterAssessmentData.id!]]);
					const filteredEndpoint = replaceParams(endpoints.assessment.getLimbGirthAssessment, paramsMap);
					const response = await axios.get(filteredEndpoint);
					const data = response.data["data"];
					const convertedLimbGirthAssessment = ConvertLimbGirth.toLimbGirth(JSON.stringify(data));
					setLimbGirthAssessment(convertedLimbGirthAssessment);
				} catch (error) {
					toast.error(error.message);

					console.error("Error fetching Limb Girth assessment:", error);
				}
			}
	};

	const initializeAssessmentMuscleWasting = async () => {
		if (props.masterAssessmentData?.id!)
			if (props.masterAssessmentData?.muscleWastingAssessmentId !== "000000000000000000000000") {
				try {
					const paramsMap = new Map<string, string>([["mid", props.masterAssessmentData.id!]]);
					const filteredEndpoint = replaceParams(endpoints.assessment.getMuscleWastingAssessment, paramsMap);
					const response = await axios.get(filteredEndpoint);
					const data = response.data["data"];
					const convertedMuscleWastingAssessment = ConvertMuscleWasting.toMuscleWasting(JSON.stringify(data));

					setMuscleWastingAssessment(convertedMuscleWastingAssessment);
				} catch (error) {
					// toast.error(error.message);

					console.error("Error fetching Muscle Wasting assessment:", error);
				}
			}
	};

	const initializeSensoryAssessment = async () => {
		if (props.masterAssessmentData?.id!)
			if (props.masterAssessmentData?.sensoryAssessmentId !== "000000000000000000000000") {
				try {
					const paramsMap = new Map<string, string>([["mid", props.masterAssessmentData.id!]]);
					const filteredEndpoint = replaceParams(endpoints.assessment.getSensoryAssessment, paramsMap);
					const response = await axios.get(filteredEndpoint);
					const data = response.data["data"];
					const converted = ConvertSensory.toSensoryDetails(JSON.stringify(data));
					setSensoryAssessment(converted);
				} catch (error) {
					// toast.error(error.message);

					console.error("Error fetching Balance assessment:", error);
				}
			}
	};

	const initializeBalanceAssessment = async () => {
		if (props.masterAssessmentData?.id!)
			if (props.masterAssessmentData?.balanceAssessmentId !== "000000000000000000000000") {
				try {
					const paramsMap = new Map<string, string>([["mid", props.masterAssessmentData.id!]]);
					const filteredEndpoint = replaceParams(endpoints.assessment.getBalanceAssessment, paramsMap);
					const response = await axios.get(filteredEndpoint);
					const data = response.data["data"];
					const converted = ConvertBalance.toBalance(JSON.stringify(data));
					setBalanceAssessment(converted);
				} catch (error) {
					// toast.error(error.message);

					console.error("Error fetching Balance assessment:", error);
				}
			}
	};

	const initializeMuscleTightnessAssessment = async () => {
		if (props.masterAssessmentData?.id!)
			if (props.masterAssessmentData?.muscleTightnessAssessmentId !== "000000000000000000000000") {
				try {
					const paramsMap = new Map<string, string>([["mid", props.masterAssessmentData?.id!]]);
					const filteredEndpoint = replaceParams(endpoints.assessment.getMuscleTightnessAssessment, paramsMap);
					const response = await axios.get(filteredEndpoint);
					const data = response.data["data"];
					const converted = ConvertMuscleTightness.toMuscleTightness(JSON.stringify(data));
					setmuscleTightnessAssessment(converted);
				} catch (error) {
					// toast.error(error.message);
					console.error("Error fetching Muscle Tightness:", error);
				}
			}
	};

	const initializeMuscleStrengthAssessment = async () => {
		if (props.masterAssessmentData?.id!)
			if (props.masterAssessmentData?.muscleStrengthAssessmentId !== "000000000000000000000000") {
				try {
					const paramsMap = new Map<string, string>([["mid", props.masterAssessmentData?.id!]]);
					const filteredEndpoint = replaceParams(endpoints.assessment.getMuscleStrengthAssessment, paramsMap);
					const response = await axios.get(filteredEndpoint);
					const data = response.data["data"];
					const converted = ConvertMuscleStrength.toMuscleStrength(JSON.stringify(data));
					setMuscleStrengthAssessment(converted);
				} catch (error) {
					// toast.error(error.message);
					console.error("Error fetching Muscle Strength:", error);
				}
			}
	};

	const initializeScarAssessment = async () => {
		if (props.masterAssessmentData?.id!)
			if (props.masterAssessmentData?.scarAssessmentId !== "000000000000000000000000") {
				try {
					const paramsMap = new Map<string, string>([["mid", props.masterAssessmentData.id!]]);
					const filteredEndpoint = replaceParams(endpoints.assessment.getScarAssessment, paramsMap);
					const response = await axios.get(filteredEndpoint);
					const data = response.data["data"];
					const converted = ConvertScar.toScarDetails(JSON.stringify(data));
					setScarAssessment(converted);
				} catch (error) {
					// toast.error(error.message);
					console.error("Error fetching Scar assessment:", error);
				}
			}
	};

	const initializeSpecialTestAssessment = async () => {
		if (props.masterAssessmentData?.id!)
			if (props.masterAssessmentData?.specialTestsAssessmentId !== "000000000000000000000000") {
				try {
					const paramsMap = new Map<string, string>([["mid", props.masterAssessmentData.id!]]);
					const filteredEndpoint = replaceParams(endpoints.assessment.getSpecialTestAssessment, paramsMap);
					const response = await axios.get(filteredEndpoint);
					const data = response.data["data"];
					const converted = ConvertSpecialTest.toSpecialTest(JSON.stringify(data));
					setSpecialTestAssessment(converted);
				} catch (error) {
					// toast.error(error.message);
					console.error("Error fetching Special Test:", error);
				}
			}
	};

	const initializeNerveTestAssessment = async () => {
		if (props.masterAssessmentData?.id!)
			if (props.masterAssessmentData?.nerveTestAssessmentId !== "000000000000000000000000") {
				try {
					const paramsMap = new Map<string, string>([["mid", props.masterAssessmentData.id!]]);
					const filteredEndpoint = replaceParams(endpoints.assessment.getNerveTestAssessment, paramsMap);
					const response = await axios.get(filteredEndpoint);
					const data = response.data["data"];

					const converted = ConvertNerveTest.toNerveTest(JSON.stringify(data));
					setNerveTestAssessment(converted);
				} catch (error) {
					// toast.error(error.message);
					console.error("Error fetching Nerve Test Details:", error);
				}
			}
	};

	const initializeStructuralDeformitiesAssessment = async () => {
		if (props.masterAssessmentData?.id!)
			if (props.masterAssessmentData?.structuralDeformitiesAssessmentId !== "000000000000000000000000") {
				try {
					const paramsMap = new Map<string, string>([["mid", props.masterAssessmentData.id!]]);
					const filteredEndpoint = replaceParams(endpoints.assessment.getStructuralDeformitiesAssessment, paramsMap);
					const response = await axios.get(filteredEndpoint);
					const data = response.data["data"];
					const convertedStructuralDeformitiesAssessment = ConvertSTD.toStructuralDeformitiesType(JSON.stringify(data));
					setStructuralDeformitiesAssessment(convertedStructuralDeformitiesAssessment);
				} catch (error) {
					// toast.error(error.message);

					console.error("Error fetching Structural Deformities assessment:", error);

					setLoading(false);
				}
			}
	};

	const getBranchLetterHead = async () => {
		try {
			const paramsMap = new Map<string, string>([["id", userData?.branch_id ?? ""]]);
			const filteredEndpoint = replaceParams(endpoints.branch.letterHead, paramsMap);
			const response = await axios.get(filteredEndpoint);
			const data = response.data["data"];

			const headerBlocksFromHTML = convertFromHTML(data.header);
			const initialContentState = ContentState.createFromBlockArray(
				headerBlocksFromHTML.contentBlocks,
				headerBlocksFromHTML.entityMap
			);
			const signatureBlocksFromHTML = convertFromHTML(data.footer);
			const initialSContentState = ContentState.createFromBlockArray(
				signatureBlocksFromHTML.contentBlocks,
				signatureBlocksFromHTML.entityMap
			);

			setHeaderEditorState(EditorState.createWithContent(initialContentState));
			setSignatureEditorState(EditorState.createWithContent(initialSContentState));
		} catch (error) {
			console.error("Error fetching branch letterhead:", error);
			toast.error("Failed to fetch branch letterhead.");
		}
	};

	const handlePrint = useReactToPrint({
		content: () => printRef.current,
	});

	useEffect(() => {
		const fetchData = async () => {
			try {
				await Promise.all([
					getEntityData(),
					getBranchLetterHead(),
					getPatientData(),
					getMedicalData(),
					getDiagnosisData(),
					getTreatmentPlanData(),
					getAssignedExercises(),
					initializeVitalAssessment(),
					initializePainAssessment(),
					initializeROMAssessment(),
					initializeADLAssessment(),
					initializeCustomAssessment(),
					initializePostureAssessment(),
					initializeGaitAssessment(),
					initializeMuscleToneAssessment(),
					initializeReflexAssessment(),
					initializeOedemaAssessment(),
					initializeLimbLengthAssessment(),
					initializeLimbGirthAssessment(),
					initializeAssessmentMuscleWasting(),
					initializeSensoryAssessment(),
					initializeBalanceAssessment(),
					initializeMuscleTightnessAssessment(),
					initializeMuscleStrengthAssessment(),
					initializeScarAssessment(),
					initializeSpecialTestAssessment(),
					initializeSpecialTestAssessment(),
					initializeNerveTestAssessment(),
					initializeStructuralDeformitiesAssessment(),
				]);
			} catch (error) {
				console.error("Error fetching data:", error);
			} finally {
				setLoading(false); // Set loading to false when all promises are resolved (or rejected)
			}
		};
		if (props.isOpen) {
			setLoading(true); // Set loading to true when dialog is opened
			fetchData();
		}
	}, [props.isOpen]);

	useEffect(() => {
		const updateExerciseMedia = async () => {
			if (activeExerciseProgram) {
				const mediaPromises = activeExerciseProgram.map(async (exercise) => {
					const exerciseMedia = await fetchMediaFiles(exercise.id);
					return exerciseMedia;
				});
				// Wait for all media promises to resolve
				const allMediaFiles = await Promise.all(mediaPromises);

				// Flatten the array of arrays into a single array
				const flattenedMediaFiles = allMediaFiles.flat();

				// Set the mediaFiles state after all promises have resolved
				setMediaFiles(flattenedMediaFiles);
				setIsLoading(false);
			}
		};

		updateExerciseMedia();
	}, [activeExerciseProgram]);

	function closeModal(value: boolean): void {}

	const chiefComplaint = props.assessmentData?.medicalHistory?.find((item) => item.name === "Chief Complaint");

	const historyOfPresentIllness = props.assessmentData?.medicalHistory?.find(
		(item) => item.name === "History of Present Illness"
	);

	const historyOfPastIllness = props.assessmentData?.medicalHistory?.find(
		(item) => item.name === "History of Past Illness"
	);

	const preExistingHealthCondition = props.assessmentData?.medicalHistory?.find(
		(item) => item.name === "Pre Existing Health Conditions"
	);
	const pastTreatments = props.assessmentData?.medicalHistory?.find((item) => item.name === "Past Treatment");
	const surgeries = props.assessmentData?.medicalHistory?.find((item) => item.name === "Surgeries");
	const famMedicalHistory = props.assessmentData?.medicalHistory?.find(
		(item) => item.name === "Family Medical History"
	);

	const vitals = props.assessmentData?.physicalAssessments?.find((item) => item.name === "Vitals");
	const pain = props.assessmentData?.physicalAssessments?.find((item) => item.name === "Pain");
	const ROM = props.assessmentData?.physicalAssessments?.find((item) => item.name === "Range of Motion");
	const adlAssessment = props.assessmentData?.physicalAssessments?.find((item) => item.name === "ADL");

	const custom = props.assessmentData?.physicalAssessments?.find((item) => item.name === "Custom");

	const posture = props.assessmentData?.physicalAssessments?.find((item) => item.name === "Posture");

	const gait = props.assessmentData?.physicalAssessments?.find((item) => item.name === "Gait");

	const muscleTone = props.assessmentData?.physicalAssessments?.find((item) => item.name === "Muscle Tone");

	const reflex = props.assessmentData?.physicalAssessments?.find((item) => item.name === "Reflex");

	const oedema = props.assessmentData?.physicalAssessments?.find((item) => item.name === "Oedema");

	const limbLength = props.assessmentData?.physicalAssessments?.find((item) => item.name === "Limb Length");
	const limbGirth = props.assessmentData?.physicalAssessments?.find((item) => item.name === "Limb Girth");
	const muscleWasting = props.assessmentData?.physicalAssessments?.find((item) => item.name === "Muscle Wasting");
	const sensory = props.assessmentData?.physicalAssessments?.find((item) => item.name === "Sensory");
	const balance = props.assessmentData?.physicalAssessments?.find((item) => item.name === "Balance");
	const muscleTightness = props.assessmentData?.physicalAssessments?.find((item) => item.name === "Muscle Tightness");

	const muscleStrength = props.assessmentData?.physicalAssessments?.find((item) => item.name === "Muscle Strength");

	const scar = props.assessmentData?.physicalAssessments?.find((item) => item.name === "Scar");

	const specialTest = props.assessmentData?.physicalAssessments?.find((item) => item.name === "Special Test");

	const nerveTest = props.assessmentData?.physicalAssessments?.find((item) => item.name === "Nerve Test");

	const structuralDeformities = props.assessmentData?.physicalAssessments?.find(
		(item) => item.name === "Structural Deformities"
	);

	// const chiefComplaint = props.assessmentData?.diagnosis?.find(
	//   (item) => item.name === "Chief Complaint"
	// );

	const provisionalDiagnosis = props.assessmentData?.diagnosis?.find((item) => item.name === "Provisional Diagnosis");

	const differentailDiagnosis = props.assessmentData?.diagnosis?.find((item) => item.name === "Differential Diagnosis");

	const finalDiagnosis = props.assessmentData?.diagnosis?.find((item) => item.name === "Final Diagnosis");

	const radiologicalFindings = props.assessmentData?.diagnosis?.find((item) => item.name === "Radiological Findings");

	const medicalFindings = props.assessmentData?.diagnosis?.find((item) => item.name === "Medial Findings");

	const prescription = props.assessmentData?.treatmentPlan?.find((item) => item.name === "Prescription");
	const exerciseLibrary = props.assessmentData?.treatmentPlan?.find((item) => item.name === "Exercise Library");
	const goals = props.assessmentData?.treatmentPlan?.find((item) => item.name === "Goals");

	// const chief = diagnosis?.diagnosisDetails?.find(
	//   (e) => e.code === "chiefcomplaint"
	// );
	const prov = diagnosis?.diagnosisDetails?.find((e) => e.code === "provisionaldiagnosis");
	const diff = diagnosis?.diagnosisDetails?.find((e) => e.code === "differentialdiagnosis");
	const final = diagnosis?.diagnosisDetails?.find((e) => e.code === "finaldiagnosis");
	const radiological = diagnosis?.diagnosisDetails?.find((e) => e.code === "radiologicalfindings");
	const medial = diagnosis?.diagnosisDetails?.find((e) => e.code === "medicalfindings");

	// Function to check if EditorState is empty
	const isEditorEmpty = () => {
		const currentContent = headerEditorState.getCurrentContent();
		return (
			currentContent.getBlockMap().size === 1 &&
			currentContent.getFirstBlock().getType() === "unstyled" &&
			currentContent.getFirstBlock().getText().length === 0
		);
	};

	return (
		<>
			<Transition appear show={props.isOpen} as={Fragment}>
				<Dialog as="div" className="relative z-50" onClose={closeModal}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black/25" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel className="w-full sm:w-full md:w-full lg:w-full xl:w-7/12 2xl:w-7/12 transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
									<Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-1100 px-8"></Dialog.Title>
									<div ref={printRef} className="px-8 py-8">
										<header className="mb-8">
											{!initialData?.EntityBranchHideHeader && (
												<>
													<div className="flex flex-col sm:flex-row md:flex-row ">
														{(!initialData?.EntityBranchHeaderEnabled ||
															initialData?.EntityBranchHeaderLogoEnabled) && (
															<div className="flex-shrink-0 sm:w-1/2 p-4 ">
																<div className="flex shrink-0 items-center h-20 w-30">
																	<img
																		src={
																			initialData?.entityLogoUrl === "" ||
																			initialData?.entityLogoUrl === null ||
																			initialData?.entityLogoUrl === undefined
																				? logo
																				: initialData?.entityLogoUrl
																		}
																		className={initialData?.entityLogoUrl ? "object-fill h-20" : "object-fill h-12"}
																	/>
																</div>
															</div>
														)}
														{!initialData?.EntityBranchHeaderEnabled ? (
															<div>
																<span>{initialData?.EntityName || "N/A"}</span> <br></br>
																<span>{initialData?.EntityBranchPhone || "N/A"}</span> <br></br>
																<span>
																	{initialData?.EntityBranchAddress.address || "N/A"} ,{" "}
																	{initialData?.EntityBranchAddress.city || "N/A"} ,{" "}
																	{initialData?.EntityBranchAddress.state || "N/A"} ,{" "}
																	{initialData?.EntityBranchAddress.country || "N/A"} -{" "}
																	{initialData?.EntityBranchAddress.postalCode || "N/A"}
																</span>
															</div>
														) : (
															<div
																className="ql-editor w-full"
																dangerouslySetInnerHTML={{
																	__html: initialData?.EntityBranchLetterHeader || "",
																}}
															/>
														)}
													</div>
													<hr className="my-2 border-tertiary-500"></hr>
												</>
											)}
											<div className="flex flex-row justify-between">
												<div className="">
													<span className="font-bold text-sm"> Patient Details </span>
													<br></br>
													<span>
														{patientPersonalInformation?.salutation || ""}{" "}
														{patientPersonalInformation?.firstName || "N/A"}{" "}
														{patientPersonalInformation?.lastName || "N/A"}{" "}
													</span>
													<br></br>
													<span>
														{(patientPersonalInformation?.age || patientPersonalInformation?.age !== 0) && (
															<>{patientPersonalInformation?.age} Years</>
														)}
													</span>
													<br></br>
													<span>
														{[
															patientPersonalInformation?.address?.address,
															patientPersonalInformation?.address?.city,
															patientPersonalInformation?.address?.state,
															patientPersonalInformation?.address?.postalCode,
														]
															.filter(Boolean)
															.join(", ")}
													</span>
												</div>
												<span className="w-1/3">
													<span className="font-bold text-sm"> Assessment Details </span>
													<br></br>
													<span>
														{moment.utc(props.masterAssessmentData?.assessmentDate).format("DD-MM-YYYY") || "N/A"}{" "}
													</span>
													<br></br>
													<p>{props.masterAssessmentData?.entityUserName || "NA"} </p>
												</span>
											</div>
										</header>
										{loading && <>Loading...</>}
										{!loading && (
											<div>
												{/* Medical History */}
												{preExistingHealthCondition?.isChecked === false &&
												chiefComplaint?.isChecked === false &&
												historyOfPresentIllness?.isChecked === false &&
												historyOfPastIllness?.isChecked === false &&
												pastTreatments?.isChecked === false &&
												surgeries?.isChecked === false &&
												famMedicalHistory?.isChecked === false ? (
													<div></div>
												) : history?.healthConditionDetails === "" &&
												  history.pastTreatmentDetails === "" &&
												  history.chiefComplaintDetails === "" &&
												  history.historyOfPresentIllness === "" &&
												  history.historyOfPastIllness === "" &&
												  history.surgeryDetails === "" &&
												  history.familyHistoryDetails === "" ? (
													<div></div>
												) : (
													<div className="grid-cols-1 gap-x-8 gap-y-8 pt-2 md:grid-cols-3 bg-grey-200 diagnosis">
														<div>
															<h3 className="my-4 font-bold text-tertiary-500">Medical History</h3>

															{chiefComplaint?.isChecked ? (
																history?.chiefComplaintDetails !== "" ? (
																	<div>
																		<div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4 bg-gray-100 px-2 py-1 font-medium">
																			Chief Complaint
																		</div>

																		<div className="mx-2 my-2">
																			<p>{history?.chiefComplaintDetails}</p>
																		</div>
																	</div>
																) : (
																	<div></div>
																)
															) : (
																<div></div>
															)}
															{historyOfPresentIllness?.isChecked ? (
																history?.historyOfPresentIllness !== "" ? (
																	<div>
																		<div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4 bg-gray-100 px-2 py-1 font-medium">
																			History of Present Illness
																		</div>

																		<div className="mx-2 my-2">
																			<p>{history?.historyOfPresentIllness}</p>
																		</div>
																	</div>
																) : (
																	<div></div>
																)
															) : (
																<div></div>
															)}
															{historyOfPastIllness?.isChecked ? (
																history?.historyOfPastIllness !== "" ? (
																	<div>
																		<div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4 bg-gray-100 px-2 py-1 font-medium">
																			History of Past Illness
																		</div>

																		<div className="mx-2 my-2">
																			<p>{history?.historyOfPastIllness}</p>
																		</div>
																	</div>
																) : (
																	<div></div>
																)
															) : (
																<div></div>
															)}

															{preExistingHealthCondition?.isChecked ? (
																history?.healthConditionDetails !== "" ? (
																	<div>
																		<div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4 bg-gray-100 px-2 py-1 font-medium">
																			Pre-existing Health Conditions
																		</div>

																		<div className="mx-2 my-2">
																			<p>{history?.healthConditionDetails}</p>
																		</div>
																	</div>
																) : (
																	<div></div>
																)
															) : (
																<div></div>
															)}

															{pastTreatments?.isChecked ? (
																history?.pastTreatmentDetails !== "" ? (
																	<div>
																		<div className="flex flex-col md:flex-row w-full mt-4 md:space-x-4 bg-gray-100 px-2 py-1 font-medium">
																			Past Treatments
																		</div>

																		<div className="mx-2 my-2">
																			<p>{history?.pastTreatmentDetails}</p>
																		</div>
																	</div>
																) : (
																	<div></div>
																)
															) : (
																<div></div>
															)}

															{surgeries?.isChecked ? (
																history?.surgeryDetails !== "" ? (
																	<div>
																		<div className="flex flex-row md:flex-row w-full mt-4 md:space-x-4  bg-gray-100 px-2 py-1 font-medium">
																			Surgeries
																		</div>

																		<div className="mx-2 my-2">
																			<p>{history?.surgeryDetails}</p>
																		</div>
																	</div>
																) : (
																	<div></div>
																)
															) : (
																<div></div>
															)}

															{famMedicalHistory?.isChecked ? (
																history?.familyHistoryDetails !== "" ? (
																	<div>
																		<div className="flex flex-row md:flex-row w-full mt-4 md:space-x-4  bg-gray-100 px-2 py-1 font-medium">
																			Family Medical History
																		</div>

																		<div className="mx-2 my-2">
																			<p>{history?.familyHistoryDetails}</p>
																		</div>
																	</div>
																) : (
																	<div></div>
																)
															) : (
																<div></div>
															)}
														</div>
														<hr className=" my-12 border-tertiary-500"></hr>
													</div>
												)}

												{/* physical assessments  */}
												<div>
													{/* Vitals Assessment */}
													{vitals?.isChecked && <VitalsAssessment vitalAssessment={vitalAssessment}></VitalsAssessment>}
													{/* Pain assessment */}
													{pain?.isChecked && (
														<PainAssessment intensity={intensity} painAssessment={painAssessment}></PainAssessment>
													)}
													{/* ROM Assessment */}
													{ROM?.isChecked && <RangeOfMotion romAssessment={romAssessment}></RangeOfMotion>}
													{/* ADL Assessment */}
													{adlAssessment?.isChecked && (
														<ADLAssessment adlCategories={adlCategories} adlQuestions={adlQuestions}></ADLAssessment>
													)}
													{/* Custom Assessment */}
													{custom?.isChecked && (
														<CustomAssessment customAssessment={customAssessment}></CustomAssessment>
													)}
													{/* Posture */}
													{posture?.isChecked && (
														<PostureAssessment postureAssessment={postureAssessment}></PostureAssessment>
													)}
													{/* Gait  */}
													{gait?.isChecked && <GaitAssessment gait={gaitAssessment}></GaitAssessment>}
													{/* Muscle Tone  */}
													{muscleTone?.isChecked && (
														<MuscletoneAssessment muscleTone={muscleToneAssessment}></MuscletoneAssessment>
													)}
													{/* Reflex  */}
													{reflex?.isChecked && (
														<ReflexAssessment refleAssessment={reflexAssessment}></ReflexAssessment>
													)}
													{/* Oedema  */}
													{oedema?.isChecked && (
														<OedemaAssessment oedemaAssessment={odemaAssessment}></OedemaAssessment>
													)}
													{/* LimbLength  */}
													{limbLength?.isChecked && (
														<LimbLengthAssessment limbLength={limbLengthAssessment}></LimbLengthAssessment>
													)}
													{/* Limb Girth  */}
													{limbGirth?.isChecked && (
														<LimbGirthAssessment limbGirth={limbGirthAssessment}></LimbGirthAssessment>
													)}
													{/* Muscle Wasting  */}
													{muscleWasting?.isChecked && (
														<MuscleWastingAssessment muscleWasting={muscleWastingAssessment}></MuscleWastingAssessment>
													)}
													{/* sensory  */}
													{sensory?.isChecked && <SensoryAssessment sensory={sensoryAssessment}></SensoryAssessment>}
													{balance?.isChecked && <BalanceAssessment balance={balanceAssessment}></BalanceAssessment>}
													{muscleTightness?.isChecked && (
														<MuscleTightnessAssessment
															muscleTightness={muscleTightnessAssessment}
														></MuscleTightnessAssessment>
													)}
													{muscleStrength?.isChecked && (
														<MuscleStrengthAssessment
															muscleStrength={muscleStrengthAssessment}
														></MuscleStrengthAssessment>
													)}
													{scar?.isChecked && <ScarAssessment scar={scarAssessment}></ScarAssessment>}
													{specialTest?.isChecked && (
														<SpecialTestAssessment specialTest={specialTestAssessment}></SpecialTestAssessment>
													)}
													{nerveTest?.isChecked && (
														<NerveTestAssessment nerveTest={nerveTestAssessment}></NerveTestAssessment>
													)}
													{structuralDeformities?.isChecked && (
														<StructuralDeformitiesAssessment
															structuralDeformities={structuralDeformitiesAssessment}
														></StructuralDeformitiesAssessment>
													)}
												</div>

												{/* Diagnosis */}
												{
													// chiefComplaint?.isChecked === false &&
													provisionalDiagnosis?.isChecked === false &&
													differentailDiagnosis?.isChecked === false &&
													finalDiagnosis?.isChecked === false &&
													radiologicalFindings?.isChecked === false &&
													medicalFindings?.isChecked === false ? (
														<div></div>
													) : (
														<div>
															{
																//     (chiefComplaint?.isChecked &&
																// (chief?.value || chief?.isChecked)) ||
																((provisionalDiagnosis?.isChecked && (prov?.value || prov?.isChecked)) ||
																	(differentailDiagnosis?.isChecked && (diff?.value || diff?.isChecked)) ||
																	(finalDiagnosis?.isChecked && (final?.value || final?.isChecked)) ||
																	(radiologicalFindings?.isChecked &&
																		(radiological?.value || radiological?.isChecked)) ||
																	(medicalFindings?.isChecked && (medial?.value || medial?.isChecked))) && (
																	<div className="flex flex-col">
																		<h3 className="mb-4 font-bold text-tertiary-500">Diagnosis</h3>
																		<div className="flex flex-row justify-between w-full bg-gray-100 px-2 py-1 font-medium">
																			<span className="w-1/3">Name</span>
																			<span className="w-1/3">Yes / No</span>
																			<span className="w-1/3">Details</span>
																		</div>
																		{/* {chiefComplaint?.isChecked &&
                                  (chief?.value || chief?.isChecked) && (
                                    <div className="flex flex-row md:flex-row w-full mt-4 justify-between items-center border-b-2 border-grey-500 px-2 pb-4">
                                      <span className="w-1/3">
                                        {chief?.name}
                                      </span>
                                      <span className="w-1/3">
                                        {chief?.isChecked === true ? (
                                          <img
                                            width="28"
                                            height="28"
                                            src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
                                            alt="check-mark-emoji"
                                          />
                                        ) : (
                                          <img
                                            width="28"
                                            height="28"
                                            src="https://img.icons8.com/fluency/48/delete-sign.png"
                                            alt="delete-sign"
                                          />
                                        )}
                                      </span>
                                      <span className="w-1/3">
                                        {chief?.value}
                                      </span>
                                    </div>
                                  )} */}
																		{provisionalDiagnosis?.isChecked && (prov?.value || prov?.isChecked) && (
																			<div className="flex flex-row md:flex-row w-full mt-4 justify-between items-center border-b-2 border-grey-500 px-2 pb-4">
																				<span className="w-1/3">{prov?.name}</span>
																				<span className="w-1/3">
																					{prov?.isChecked === true ? (
																						<img
																							width="28"
																							height="28"
																							src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																							alt="check-mark-emoji"
																						/>
																					) : (
																						<img
																							width="28"
																							height="28"
																							src="https://img.icons8.com/fluency/48/delete-sign.png"
																							alt="delete-sign"
																						/>
																					)}
																				</span>
																				<span className="w-1/3">{prov?.value}</span>
																			</div>
																		)}
																		{differentailDiagnosis?.isChecked && (diff?.value || diff?.isChecked) && (
																			<div className="flex flex-row md:flex-row w-full mt-4 justify-between items-center border-b-2 border-grey-500 px-2 pb-4">
																				<span className="w-1/3">{diff?.name}</span>
																				<span className="w-1/3">
																					{diff?.isChecked === true ? (
																						<img
																							width="28"
																							height="28"
																							src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																							alt="check-mark-emoji"
																						/>
																					) : (
																						<img
																							width="28"
																							height="28"
																							src="https://img.icons8.com/fluency/48/delete-sign.png"
																							alt="delete-sign"
																						/>
																					)}
																				</span>
																				<span className="w-1/3">{diff?.value}</span>
																			</div>
																		)}
																		{finalDiagnosis?.isChecked && (final?.value || final?.isChecked) && (
																			<div className="flex flex-row md:flex-row w-full mt-4 justify-between items-center border-b-2 border-grey-500 px-2 pb-4">
																				<span className="w-1/3">{final?.name}</span>
																				<span className="w-1/3">
																					{final?.isChecked === true ? (
																						<img
																							width="28"
																							height="28"
																							src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																							alt="check-mark-emoji"
																						/>
																					) : (
																						<img
																							width="28"
																							height="28"
																							src="https://img.icons8.com/fluency/48/delete-sign.png"
																							alt="delete-sign"
																						/>
																					)}
																				</span>
																				<span className="w-1/3">{final?.value}</span>
																			</div>
																		)}
																		{radiologicalFindings?.isChecked &&
																			(radiological?.value || radiological?.isChecked) && (
																				<div className="flex flex-row md:flex-row w-full mt-4 justify-between items-center border-b-2 border-grey-500 px-2 pb-4">
																					<span className="w-1/3">{radiological?.name}</span>
																					<span className="w-1/3">
																						{radiological?.isChecked === true ? (
																							<img
																								width="28"
																								height="28"
																								src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																								alt="check-mark-emoji"
																							/>
																						) : (
																							<img
																								width="28"
																								height="28"
																								src="https://img.icons8.com/fluency/48/delete-sign.png"
																								alt="delete-sign"
																							/>
																						)}
																					</span>
																					<span className="w-1/3">{radiological?.value}</span>
																				</div>
																			)}
																		{medicalFindings?.isChecked && (medial?.value || medial?.isChecked) && (
																			<div className="flex flex-row md:flex-row w-full mt-4 justify-between items-center border-b-2 border-grey-500 px-2 pb-4">
																				<span className="w-1/3">{medial?.name}</span>
																				<span className="w-1/3">
																					{medial?.isChecked === true ? (
																						<img
																							width="28"
																							height="28"
																							src="https://img.icons8.com/emoji/48/check-mark-emoji.png"
																							alt="check-mark-emoji"
																						/>
																					) : (
																						<img
																							width="28"
																							height="28"
																							src="https://img.icons8.com/fluency/48/delete-sign.png"
																							alt="delete-sign"
																						/>
																					)}
																				</span>
																				<span className="w-1/3">{medial?.value}</span>
																			</div>
																		)}
																		<hr className="my-12 border-tertiary-500"></hr>
																	</div>
																)
															}
														</div>
													)
												}

												{/* Prescription */}
												{prescription?.isChecked ? (
													selectedTherapy?.length === 0 &&
													treatmentPlanData?.advice === "" &&
													treatmentPlanData?.precautions === "" &&
													treatmentPlanData?.testadviced === "" &&
													treatmentPlanData?.sessionFrequency === 0 ? (
														<div></div>
													) : (
														<div className="grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3 bg-grey-200 diagnosis">
															<h3 className="mb-4 font-bold text-tertiary-500">Prescription</h3>
															<div>
																{selectedTherapy?.length === 0 ? (
																	<div></div>
																) : (
																	<div>
																		<div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4 bg-gray-100 px-2 py-1 font-medium">
																			Modalities Name
																		</div>
																		<p className="mx-2 my-8">
																			{selectedTherapy &&
																				selectedTherapy.map((t) => (
																					<span className="my-8 mr-4 bg-[#E5EEFB] px-2 rounded-md">{t}</span>
																				))}
																		</p>
																	</div>
																)}
																{treatmentPlanData?.sessionFrequency === 0 ? (
																	<div></div>
																) : (
																	<div>
																		<div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4 bg-gray-100 px-2 py-1 font-medium">
																			Session
																		</div>
																		<p className="mx-2 my-2">
																			Recommended : {treatmentPlanData?.sessionFrequency} Sessions{" "}
																			{treatmentPlanData?.sessionCount} Per {treatmentPlanData?.sessionFrequencyUnit}
																		</p>
																		{radioValue === "" ? (
																			<div></div>
																		) : (
																			<p className="mx-2 my-2">Session Type: {radioValue}</p>
																		)}
																	</div>
																)}

																{/* Advice */}
																{treatmentPlanData?.advice === "" ? (
																	<div></div>
																) : (
																	<div>
																		<div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4 bg-gray-100 px-2 py-1 font-medium">
																			Advice
																		</div>

																		<div className="mx-2 my-2">{treatmentPlanData?.advice}</div>
																	</div>
																)}

																{/* Precaution */}
																{treatmentPlanData?.precautions === "" ? (
																	<div></div>
																) : (
																	<div>
																		<div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4 bg-gray-100 px-2 py-1 font-medium">
																			Precaution
																		</div>

																		<div className="mx-2 my-2">{treatmentPlanData?.precautions}</div>
																	</div>
																)}

																{/* Test Adviced */}
																{treatmentPlanData?.testadviced === "" ? (
																	<div></div>
																) : (
																	<div>
																		<div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4 bg-gray-100 px-2 py-1 font-medium">
																			Test Adviced
																		</div>
																		<div className="mx-2 my-2">{treatmentPlanData?.testadviced}</div>
																	</div>
																)}
															</div>
															<hr className=" my-12 border-tertiary-500"></hr>
														</div>
													)
												) : (
													<div></div>
												)}

												{/* Exercise Program */}
												{exerciseLibrary?.isChecked ? (
													activeExerciseProgram?.length === 0 ? (
														<div></div>
													) : (
														<div>
															<h3 className="mb-4 font-bold text-tertiary-500">Exercise Program</h3>
															{isLoading ? (
																<div>Loading...</div>
															) : (
																activeExerciseProgram &&
																activeExerciseProgram.map((exercise, index) => {
																	let sequenceNumber = 1; // Initialize sequence number for each exercise
																	return (
																		<div key={exercise.id}>
																			<div className="flex flex-row md:flex-row w-full mt-4 md:space-x-4 bg-gray-100 px-2 py-1 font-medium">
																				{index + 1}. {exercise.title}
																			</div>
																			<p className="mx-2 mt-2">{exercise.instructions}</p>
																			<div className="mx-2 my-2 font-medium">Configurations</div>
																			<div className="relative overflow-x-auto">
																				<table className="w-full text-sm text-left rtl:text-right text-gray-500">
																					<thead className="text-xs text-gray-700 uppercase bg-gray-50">
																						<tr className="">
																							<th scope="col" className="px-6 py-3">
																								Sets
																							</th>
																							<th scope="col" className="px-6 py-3">
																								Reps
																							</th>
																							<th scope="col" className="px-6 py-3">
																								Hold
																							</th>
																							<th scope="col" className="px-6 py-3">
																								Interval
																							</th>
																							<th scope="col" className="px-6 py-3">
																								Side
																							</th>
																							<th scope="col" className="px-6 py-3">
																								Weights
																							</th>
																						</tr>
																					</thead>
																					<tbody>
																						<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
																							<td
																								scope="row"
																								className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
																							>
																								{exercise.config.sets}
																							</td>
																							<td
																								scope="row"
																								className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
																							>
																								{exercise.config.repetitions}
																							</td>
																							<td
																								scope="row"
																								className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
																							>
																								{exercise.config.hold} {exercise.config.holdUnit}
																							</td>
																							<td
																								scope="row"
																								className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
																							>
																								{exercise.config.interval} {exercise.config.intervalUnit}
																							</td>
																							<td
																								scope="row"
																								className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
																							>
																								{exercise.config.side || "NA"}
																							</td>
																							<td
																								scope="row"
																								className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
																							>
																								{exercise.config.weights} {exercise.config.weightsUnit}
																							</td>
																						</tr>
																					</tbody>
																				</table>
																			</div>
																			<br />
																			<div className="mx-2 my-2 font-medium">Sequence</div>
																			{mediaFiles &&
																				mediaFiles.map((media) =>
																					media.ownerId === exercise.id && media.fileCategory === "EXERCISE_IMAGE" ? (
																						<React.Fragment key={media.id}>
																							{/* Display and increment the sequence number */}

																							<img
																								src={`${media.url}`}
																								alt="exercise"
																								height={120}
																								width={`${90 / imagesPerRow}%`}
																								className="inline-block mr-8 mb-8"
																							/>
																							{sequenceNumber % 3 === 0 && <br />}
																						</React.Fragment>
																					) : (
																						<div key={media.id}></div>
																					)
																				)}
																		</div>
																	);
																})
															)}
															<hr className=" my-12 border-tertiary-500"></hr>
														</div>
													)
												) : (
													<div></div>
												)}

												{/* Goals */}
												{goals?.isChecked ? (
													treatmentPlanData?.shortTermGoals === "" &&
													treatmentPlanData?.midTermGoals === "" &&
													treatmentPlanData?.longTermGoals === "" ? (
														<div></div>
													) : (
														<div className="grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3 bg-grey-200 diagnosis">
															<h3 className="mb-4 font-bold text-tertiary-500">Goals</h3>

															<div>
																{treatmentPlanData?.shortTermGoals === "" ? (
																	<div></div>
																) : (
																	<div>
																		<div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4 bg-gray-100 px-2 py-1 font-medium">
																			Short - Term Goals
																		</div>

																		<div className="mx-2 my-2">{treatmentPlanData?.shortTermGoals}</div>
																	</div>
																)}

																{treatmentPlanData?.midTermGoals === "" ? (
																	<div></div>
																) : (
																	<div>
																		<div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4 bg-gray-100 px-2 py-1 font-medium">
																			Mid - Term Goals
																		</div>

																		<div className="mx-2 my-2">{treatmentPlanData?.midTermGoals}</div>
																	</div>
																)}

																{treatmentPlanData?.longTermGoals === "" ? (
																	<div></div>
																) : (
																	<div>
																		<div className="flex flex-col md:flex-row w-full mt-0 md:space-x-4 bg-gray-100 px-2 py-1 font-medium">
																			Long - Term Goals
																		</div>
																		<div className="mx-2 my-2">{treatmentPlanData?.longTermGoals}</div>
																	</div>
																)}
															</div>
															<hr className=" my-12 border-tertiary-500"></hr>
														</div>
													)
												) : (
													<div></div>
												)}
											</div>
										)}
										<div
											className="ql-editor"
											dangerouslySetInnerHTML={{
												__html: initialData?.EntityBranchLetterFooter || "",
											}}
										/>
									</div>

									<div className="mt-4 flex flex-row justify-end">
										<SolidButton
											variant={ButtonVariant.PRIMARY}
											className="mr-2"
											onClick={() => {
												props.setIsOpen(false);
											}}
										>
											Close
										</SolidButton>
										<SolidButton
											variant={ButtonVariant.PRIMARY}
											className="mr-2"
											onClick={() => {
												handlePrint();
												props.setIsOpen(false);
												props.setIsCheckboxDialogOpen(false);
												props.setAssessmentData(checklistData);
												props.setSelectAll(false);
											}}
										>
											Download / Print Examination Report
										</SolidButton>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	);
};

export default PdfExportDialog;
