import { Button, OutlinedButton } from "@components/button";
import { ButtonVariant } from "@components/button/button";
import Modal from "@components/modal";
import { Dialog, Transition } from "@headlessui/react";
import useAxios from "@routes/hooks/use-axios";
import { endpoints, replaceParams } from "@utils/axios";
import dayjs from "dayjs";
import React, { Fragment, useState } from "react";
import { toast } from "react-toastify";

export function ConfirmationDialog(props: {
	onClose: () => void;
	onConfirm: () => void;
	cancelText: string;
	confirmationText: string;
	confirmationButtonText: string;
}) {
	let [isOpen, setIsOpen] = useState(true);
	function closeModal() {
		setIsOpen(false);
		props.onClose();
	}
	return (
		<>
			<Modal isOpen={isOpen} onClose={closeModal}>
				<div className="w-[500px] gap-4 ">
					<div className="flex flex-row  justify-between mx-4 my-2">
						<h3 className="text-base leading-6 font-bold text-gray-900">Confirmation</h3>
					</div>
					<hr className="w-full" />
					<div className="flex flex-col w-full h-full px-4 py-2 space-y-2">
						<label>{props.confirmationText}</label>
					</div>
					<div className="flex flex-row w-full mt-4 p-4 bg-gray-100 justify-end">
						<Button
							variant={ButtonVariant.PRIMARY}
							type="button"
							onClick={closeModal}
							children={props.cancelText}
							className="mr-2"
						/>

						<Button
							variant={ButtonVariant.PRIMARY}
							type="button"
							onClick={props.onConfirm}
							children={props.confirmationButtonText}
							className="mr-2"
						/>
					</div>
				</div>
			</Modal>
		</>
	);
}
