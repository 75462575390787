import React from "react";
import { NewMasterAssessmentData } from "../types/NewMasterAssessment";

import { CheckCircleIcon } from "@heroicons/react/24/solid";

import axios from "axios";
import { endpoints, replaceParams } from "@utils/axios";
import { toast } from "react-toastify";

import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import {
  MasterAssessmentLedger,
  MasterAssessmentLedgerConvert,
} from "../types/MasterAssessmentLedger";
import { useNavigate, useParams } from "react-router-dom";
import Button from "@components/button/outline-button";
import SolidButton, { ButtonVariant } from "@components/button/button";
import { paths } from "@routes/paths";
import Modal from "@components/modal";
import NewMasterAssessment from "./NewMasterAssessment";
import moment from "moment";
import PatientInfoCard from "./PatientInfoCard";
import { motion } from "framer-motion";
import { OutlinedButton } from "@components/button";
function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function PhysicalExamination({
  inputPatientId,
}: {
  inputPatientId?: string;
}) {
  const [masterAssessmentLedger, setMasterAssessmentLedger] = React.useState<
    MasterAssessmentLedger[] | null
  >(null);
  const userData = getUserPersistedOnLocalStorage();
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  let { patientId } = useParams();
  const navigator = useNavigate();
  const getPatientId = (): string => {
    return inputPatientId ?? patientId ?? "";
  };
  const getMasterAssessmentLedger = React.useCallback(async () => {
    try {
      const paramsMap = new Map<string, string>([
        ["patientId", getPatientId()],
      ]);
      const filteredEndpoint = replaceParams(
        endpoints.assessment.masterAssessmentList,
        paramsMap
      );
      const response = await axios.get(filteredEndpoint);
      const data = response.data["data"];

      if (data != null) {
        const convertedMasterAssessmentLedger = data.map((assessment: any) =>
          MasterAssessmentLedgerConvert.toMasterAssessmentLedger(
            JSON.stringify(assessment)
          )
        );
        setMasterAssessmentLedger(convertedMasterAssessmentLedger);
      } else {
        setMasterAssessmentLedger([]);
      }
    } catch (error) {
      console.error("Error fetching master assessment list:", error);
    }
  }, [patientId]);

  React.useEffect(() => {
    getMasterAssessmentLedger();
  }, []);

  const addNotes = async () => {};
  const viewMasterAssessment = (assessmentId: string) => {
    const paramsMap = new Map<string, string>([
      ["masterAssessmentId", assessmentId],
      ["patientId", getPatientId()],
    ]);
    navigator(
      replaceParams(paths.patient.physicalExamination.route, paramsMap)
    );
  };

  const formatDateDifference = (dateString: string): string => {
    const currentDate = new Date();
    const createdDate = new Date(dateString);
    const timeDifference = currentDate.getTime() - createdDate.getTime();
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    if (daysDifference === 0) {
      return "Today";
    } else if (daysDifference === 1) {
      return "1d";
    } else {
      return `${daysDifference}d`;
    }
  };

  const handleCloseModal = () => setIsModalOpen(false);
  const handleOpenModal = () => setIsModalOpen(true);

  return (
    <>
      <PatientInfoCard patientId={getPatientId()} />
      <div className="flex flex-col w-full">
        <div className="flex flex-col justify-between sm:flex-row">
          <div className="flex flex-row flex-grow flex-1 items-center justify-center sm:justify-start">
            <span className="font-bold">
              Physical Examination & Treatment Plan
            </span>
          </div>
          <div className="flex flex-row justify-center sm:justify-end mt-2 sm:mt-0">
            <Button
              variant={ButtonVariant.TERTIARY}
              className="ml-2"
              onClick={() => handleOpenModal()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>

              <span className="pl-1 text-sm"> Create New </span>
            </Button>
            <SolidButton
              variant={ButtonVariant.PRIMARY}
              className="ml-2"
              onClick={() => {
                navigator("/patient");
              }}
            >
              <span className="pl-1 text-sm"> Back </span>
            </SolidButton>
          </div>
        </div>
      </div>
      <hr className="my-3" />

      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <NewMasterAssessment
          handleCloseModal={handleCloseModal}
          handleOnSubmit={async () => {
            await getMasterAssessmentLedger();
          }}
          masterAssessmentLedgerLength={masterAssessmentLedger?.length ?? 0}
          patientId={getPatientId()}
        />
      </Modal>

      <ul
        role="list"
        className="space-y-6 overflow-auto max-h-[80vh] w-full p-2 mt-2 "
      >
        {(masterAssessmentLedger === null ||
          masterAssessmentLedger.length === 0) && (
          <div className="text-center text-gray-500">
            <p className="text-lg font-semibold mb-2">No Assessments found.</p>
            <p className="text-sm">
              Add Assessments to keep track of your sessions.
            </p>
          </div>
        )}
        {masterAssessmentLedger &&
          masterAssessmentLedger.map((activityItem, activityItemIdx) => (
            <li
              key={activityItem.id}
              className="relative flex gap-x-4 "
              onClick={() => {
                viewMasterAssessment(activityItem.id);
              }}
            >
              <div
                className={classNames(
                  activityItemIdx === masterAssessmentLedger.length - 1
                    ? "h-6"
                    : "-bottom-6",
                  "absolute left-0 top-0 flex w-6 justify-center"
                )}
              >
                <div className="w-px bg-gray-200" />
              </div>

              <>
                <svg
                  className="relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-50"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <circle cx="12" cy="12" r="8" strokeWidth="2" />
                </svg>
                <div
                  className={`cursor-pointer flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200 hover:border-2  border-primary-200 ${
                    activityItemIdx == 0 ? "border-2 border-success-400" : ""
                  }`}
                >
                  <div className="flex justify-between gap-x-4">
                    <div className="py-0.5 text-sm leading-5 text-gray-500">
                      Assessed by{" : "}
                      <span className="font-medium text-gray-900">
                        {activityItem.entityUserName}
                      </span>
                    </div>
                    {activityItemIdx == 0 && (
                      <div className="bg-success-400 text-success-700 rounded-full py-1 px-4 ml-2 text-xs">
                        current
                      </div>
                    )}
                  </div>
                  <p className="text-sm leading-6 text-gray-500">
                    Assessed on{" : "}
                    <span className="font-medium text-gray-900">
                      {moment(activityItem.audit.createdAt).format(
                        "DD MMMM YYYY"
                      )}
                    </span>
                  </p>
                </div>
              </>
            </li>
          ))}
      </ul>
      {/* <div className="mt-6 flex gap-x-3">
        <form action="#" className="relative flex-auto">
          <div className="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
            <label htmlFor="comment" className="sr-only">
              Add your notes
            </label>
            <textarea
              rows={2}
              name="notes"
              id="notes"
              className="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
              placeholder="Add your notes..."
            />
          </div>

          <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
            <button
              type="button"
              className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={addNotes}
            >
              Add Notes
            </button>
          </div>
        </form>
      </div> */}
    </>
  );
}
