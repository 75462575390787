import { useEffect, useMemo, useState, useCallback } from "react";

import React from "react";

import Button, { ButtonVariant } from "@components/button/outline-button";

import { endpoints, replaceParams } from "@utils/axios";
import useAxios from "@routes/hooks/use-axios";
import { useNavigate } from "react-router-dom";
import { Pagination } from "@pages/appointments/appointment-index-table/Pagination";
import { filterRows, paginateRows, sortRows } from "@pages/appointments/appointment-index-table/Helpers";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import moment from "moment";
import { InvoiceDetails, ConvertBilling, LineItem } from "../types/InvoiceDetails";
import RightOffcanvas from "@components/right-offcanvas";
import { PersonalInformationDetails } from "@pages/manage-patient/types/PatientPersonalDetails";
import { paths } from "@routes/paths";
import { ScrollArea, ScrollBar } from "@components/ui/scroll-area";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@components/ui/table";
import InvoicePage from "./InvoicePage";
import { BanknoteIcon, CalendarIcon, FileSpreadsheet } from "lucide-react";
import { OutlinedButton } from "@components/button";
import * as XLSX from "xlsx"; // Import the xlsx library
import { PageHeader } from "@components/ui/PageHeader";
import { SquaresPlusIcon } from "@heroicons/react/24/outline";

export default function BillingIndexClientPage(props: {
	patientId?: string;
	patientInfo?: PersonalInformationDetails;
	getPatientBillingDetails?: () => Promise<void>;
}) {
	const [billing, setBilling] = React.useState<InvoiceDetails[] | undefined>([]);
	const [loading, setLoading] = React.useState(true);

	const axios = useAxios();
	const [rows, setRows] = useState<InvoiceDetails[]>([]);
	const [isRightOffcanvasBilling, setIsRightOffcanvasBilling] = React.useState(false);

	const [selectedInvoice, setSelectedInvoice] = React.useState("");

	const columns = [
		{ accessor: "invoiceNumber", label: "Invoice Number" },
		{ accessor: "invoiceDate", label: "Date" },
		{ accessor: "totalAmount", label: "Total" },
		{ accessor: "totalPaid", label: "Total Paid" },
		{ accessor: "outStandingAmount", label: "Outstanding" },
		{ accessor: "status", label: "Status" },
		{ accessor: "actions", label: "Actions" },
	];

	const [activePage, setActivePage] = useState<number>(1);
	const [filters, setFilters] = useState<{ [key: string]: any }>({});
	const [sort, setSort] = useState<{ order: string; orderBy: string }>({
		order: "",
		orderBy: "",
	});
	const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
	const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

	// Function to determine the border color based on the selected status
	const getTextColor = (selectedStatus: string) => {
		if (selectedStatus === "partlyPaid") {
			return "text-warning-500"; // Red border for 'pending' status
		} else if (selectedStatus === "paid") {
			return "text-success-500"; // Green border for 'paid' status
		} else {
			return "text-red-500"; // Default gray border
		}
	};
	const rowsPerPage: number = 10;
	const filteredRows = useMemo(() => filterRows(rows, filters), [rows, filters]);
	const sortedRows = useMemo(() => sortRows(filteredRows, sort), [filteredRows, sort]);

	const calculatedRows = useMemo(
		() => paginateRows(sortedRows, activePage, rowsPerPage),
		[sortedRows, activePage, rowsPerPage]
	);

	const count: number = filteredRows.length;
	const totalPages: number = Math.ceil(count / rowsPerPage);
	const userData = getUserPersistedOnLocalStorage();
	const naviagtor = useNavigate();

	const navigateToReferralBilling = () => {
		naviagtor(paths.referralBilling.index.route);
	};

	const [newBill, setNewBill] = React.useState(false);
	function createNewBill(): void {
		setNewBill(true);
	}

	const getBilling = useCallback(async () => {
		try {
			let endpoint = "";
			if (props.patientId) {
				const paramsMap = new Map<string, string>([["clientId", props.patientId]]);
				endpoint = replaceParams(endpoints.invoice.getBillsForClient, paramsMap);
			} else {
				const paramsMap = new Map<string, string>([
					["entityId", userData?.entity_id!],
					["branchId", userData?.branch_id ?? ""],
				]);
				/* 				const queryMap = new Map<string, string>([
					["requestedFromDate", moment(startDate).format("DD-MM-YYYY")],
					["requestedToDate", moment(endDate).format("DD-MM-YYYY")],
				]); */
				if (userData?.user_role === "ENTITY_OWNER") {
					endpoint = replaceParams(endpoints.invoice.getBillsForEntity, paramsMap, queryMap);
				} else {
					endpoint = replaceParams(endpoints.invoice.getBillsForBranch, paramsMap, queryMap);
				}
			}

			const response = await axios.get(endpoint);
			const data = response.data["data"];
			if (data !== null) {
				const convertedList = data.map((expense: any) => {
					if (expense.status === "pending") {
						expense.outStandingAmount = expense.totalAmount - expense.totalPaid;
					}
					return ConvertBilling.toBilling(JSON.stringify(expense));
				});

				setBilling(convertedList);
				setRows(convertedList);
			} else {
				setBilling([]);
				setRows([]);
			}

			setLoading(false);
		} catch (error) {
			console.error("Error fetching expenses details:", error);

			setLoading(false);
		}
	}, [props.patientId, startDate, endDate, userData]);

	useEffect(() => {
		getBilling();
	}, []);

	// Effect to call getBilling when startDate or endDate changes
	useEffect(() => {
		getBilling(); // Call getBilling whenever startDate or endDate changes
	}, [startDate, endDate]);

	const [isLoading, setIsLoading] = useState(false); // Add loading state

	function handleSearch(value: string) {
		setIsLoading(true); // Set loading to true
		let filteredRows = billing || [];
		filteredRows = filteredRows.filter((row: any) => {
			return (
				row.invoiceNumber.toLowerCase().includes(value.toLowerCase()) ||
				row.clientName?.toLowerCase().includes(value.toLowerCase()) ||
				row.totalAmount.toString().toLowerCase().includes(value.toLowerCase()) ||
				row.status.toLowerCase().includes(value.toLowerCase()) ||
				moment(row.dueDate).format("DD-MM-YYYY").toString().toLowerCase().includes(value.toLowerCase())
			);
		});
		setRows(filteredRows);
		setIsLoading(false); // Set loading to false after processing
	}

	const calculateSubtotal = (items: LineItem[]) => {
		return items
			? items.reduce((sum: number, item: { amount: number; quantity: number }) => sum + item.amount * item.quantity, 0)
			: 0;
	};

	const calculateDiscount = (items: LineItem[]) => {
		return items ? items.reduce((sum: number, item: { discount?: number }) => sum + (item.discount || 0), 0) : 0;
	};

	const calculateTotalPaid = (billing: InvoiceDetails[]) => {
		return billing.reduce((sum: number, row) => sum + (row.totalPaid || 0), 0);
	};

	// Function to download the search results as an Excel file
	const downloadExcel = () => {
		// Flatten the data to include LineItem and tax details with human-readable headers
		const flattenedData = rows.map((row) => ({
			"Invoice Number": row.invoiceNumber,
			"Invoice Date": moment(row.invoiceDate).format("DD-MM-YYYY"),
			"Client Name": row.clientName,
			"Staff/Consultant": row.items ? row.items.map((item) => item.staffName).join(", ") : "N/A",
			"Service Names": row.items ? row.items.map((item) => item.description).join(", ") : "N/A",
			"Tax Rates": row.taxes ? row.taxes.map((tax) => tax.rate).join(", ") : "N/A",
			"Sub total": calculateSubtotal(row.items),
			Discount: calculateDiscount(row.items),
			Total: row.totalAmount || 0,
			"Total Paid": row.totalPaid || 0,
			"Outstanding Amount": row.outStandingAmount || 0,
			Mode:
				Array.isArray(row.paymentDetails) && row.paymentDetails.length > 0
					? row.paymentDetails.map((paymentDetail) => paymentDetail.paymentMode).join(", ")
					: "",
			Notes:
				Array.isArray(row.paymentDetails) && row.paymentDetails.length > 0
					? row.paymentDetails.map((paymentDetail) => paymentDetail.note).join(", ")
					: "",
			Status: row.status || "Unknown",
		}));

		// Calculate sums for the relevant columns
		const totalBillAmount = flattenedData.reduce((sum, row) => sum + (row["Total"] || 0), 0);
		const totalPaid = flattenedData.reduce((sum, row) => sum + (row["Total Paid"] || 0), 0);
		const totalOutstanding = flattenedData.reduce((sum, row) => sum + (row["Outstanding Amount"] || 0), 0);
		const subTotal = flattenedData.reduce((sum, row) => sum + (row["Sub total"] || 0), 0);
		const totalDiscount = flattenedData.reduce((sum, row) => sum + (row.Discount || 0), 0);

		// Add a summary row
		flattenedData.push({
			"Invoice Number": "Total",
			"Invoice Date": "",
			"Client Name": "",
			"Staff/Consultant": "",
			"Service Names": "",
			"Tax Rates": "",
			"Sub total": subTotal || 0,
			Discount: totalDiscount || 0,
			Total: totalBillAmount || 0,
			"Total Paid": totalPaid || 0,
			"Outstanding Amount": totalOutstanding || 0,
			Status: "",
		});

		// Convert the flattened data to a worksheet
		const worksheet = XLSX.utils.json_to_sheet(flattenedData);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, "Billing Data");

		// Apply styles to the first and last columns
		if (worksheet["!ref"]) {
			// Check if '!ref' is defined
			const range = XLSX.utils.decode_range(worksheet["!ref"]); // Get the range of the worksheet
			for (let row = range.s.r; row <= range.e.r; row++) {
				const firstCell = worksheet[XLSX.utils.encode_cell({ r: row, c: 0 })]; // First column
				const lastCell = worksheet[XLSX.utils.encode_cell({ r: row, c: range.e.c })]; // Last column

				if (firstCell) {
					firstCell.s = {
						fill: { fgColor: { rgb: "D3D3D3" } }, // Light gray background
						font: { bold: true }, // Bold text
					};
				}
				if (lastCell) {
					lastCell.s = {
						fill: { fgColor: { rgb: "D3D3D3" } }, // Light gray background
						font: { bold: true }, // Bold text
					};
				}
			}
		}

		// Write the file
		XLSX.writeFile(workbook, "billing-data-" + moment().format("DD-MM-YYYY") + ".xlsx");
	};

	return (
		<>
			{/* For Widescreen layout */}
			<div className=" flex flex-row">
				<div className="flex flex-col w-full">
					{/* 					<div className="flex flex-row lg:flex-row mb-4 lg:justify-between ">
						<div className="flex flex-col lg:flex-row gap-2 ">
						 						 <div className="flex flex-row items-center gap-2 ">
								<div className="flex flex-col md:flex-row items-center gap-1">
									<label className="text-sm text-center">Start Date:</label>
									<input
										type="date"
										value={startDate ? moment(startDate).format("YYYY-MM-DD") : ""}
										onChange={(e) =>
											setStartDate(e.target.value ? moment(new Date(e.target.value)).format("YYYY-MM-DD") : "")
										}
										className="h-8 rounded-md border border-gray-300"
									/>
								</div>
								<div className="flex flex-col md:flex-row items-center gap-1">
									<label className="text-sm text-center">End Date:</label>
									<input
										type="date"
										value={endDate ? moment(endDate).format("YYYY-MM-DD") : ""}
										onChange={(e) =>
											setEndDate(e.target.value ? moment(new Date(e.target.value)).format("YYYY-MM-DD") : "")
										}
										className="h-8  rounded-md border border-gray-300"
									/>
								</div>
							</div> 
							<div className="flex flex-row w-full gap-2 justify-end">
															<Button
									variant={ButtonVariant.PRIMARY}
									className="mx-1"
									isDisabled={isLoading} // Change 'disabled' to 'isDisabled'
									onClick={() => {
										setStartDate(moment().format("YYYY-MM-DD"));
										setEndDate(moment().format("YYYY-MM-DD"));
										// No need to call getBilling here, it will be called in the useEffect
									}}
								>
									<CalendarIcon className="w-5 h-5 text-primary-500" />
									<span className="pl-1 text-xs"> Today </span>
								</Button> 
							</div>
						</div>
					</div>
					<hr className="my-2 border-gray-100 shadow-sm"></hr> */}
					<div className="flex flex-row justify-end">
						<input
							type="text"
							onChange={(e) => {
								handleSearch(e.target.value);
							}}
							placeholder="Search for billings by Invoice Number, Date, Patient Name, Total and Status"
							className="w-1/4 sm:w-1/2 h-min px-4 py-2 mb-4 text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-0 focus:border-primary-500"
						/>
						{/* 						<Button variant={ButtonVariant.PRIMARY} onClick={downloadExcel}>
							<FileSpreadsheet className="w-5 h-5 text-primary-500" />
							<span className="pl-1 text-xs"> Download data </span>
						</Button> */}
					</div>
					<div className="overflow-x-auto w-full">
						{/* Billing table start */}
						<Table className="w-max">
							<TableHeader>
								<TableRow>
									{columns.map((column) => {
										return (
											<TableHead
												key={column.accessor}
												className="px-4 py-2 border-b border-gray-300 text-sm min-w-[150px]"
											>
												<span>{column.label}</span>
											</TableHead>
										);
									})}
								</TableRow>
							</TableHeader>
							<TableBody className="bg-white">
								{calculatedRows.map((row, index) => {
									return (
										<TableRow
											key={row.id}
											className={index !== calculatedRows.length - 1 ? "border-b border-gray-200" : ""}
										>
											<TableCell className={`px-4 py-2`}>{row["invoiceNumber"]}</TableCell>
											<TableCell className={`px-4 py-2`}>
												{moment(row["invoiceDate"]).format("DD-MM-YYYY").toString()}
											</TableCell>
											<TableCell className={`px-4 py-2`}>₹{row["totalAmount"]}</TableCell>
											<TableCell className={`px-4 py-2`}>₹{row["totalPaid"]}</TableCell>
											<TableCell className={`px-4 py-2`}>₹{row["outStandingAmount"]}</TableCell>
											{/* 											<TableCell className={`px-2 py-3 text-gray-700 text-xs`}>
												{row.paymentDetails && row.paymentDetails.length > 0 ? row.paymentDetails[0].paymentMode : ""}
											</TableCell> */}
											<TableCell className={`px-4 py-2`}>
												<span className={`font-semibold ${getTextColor(row["status"])}`}>
													{(() => {
														switch (row["status"]) {
															case "partlyPaid":
																return "Partly Paid";
															case "paid":
																return "Paid";
															case "pending":
																return "Pending";
														}
													})()}
												</span>
											</TableCell>
											<TableCell className={`px-4 py-2`}>
												<div className="flex flex-row">
													<div className="flex-shrink mr-2">
														<OutlinedButton
															variant={ButtonVariant.SECONDARY}
															type="button"
															onClick={() => {
																setIsRightOffcanvasBilling(true);
																setSelectedInvoice(row["invoiceNumber"]);
															}}
															children={row["status"] === "paid" ? "View" : "Open"}
														/>
													</div>
												</div>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
						{/* Billing table end  */}
					</div>
					{/*             <ScrollBar orientation="horizontal" />
					 */}
					{/*           </ScrollArea>
					 */}{" "}
					{count > 0 ? (
						<Pagination
							activePage={activePage}
							count={count}
							rowsPerPage={rowsPerPage}
							totalPages={totalPages}
							setActivePage={setActivePage}
						/>
					) : (
						<p className="text-center text-gray-500 text-xs p-4 ">No data present</p>
					)}
				</div>
				<hr className="my-4" />
			</div>
			<RightOffcanvas
				isOpen={isRightOffcanvasBilling}
				customMaxWidthClass="xl:max-w-[70vw]"
				onClose={() => {
					setIsRightOffcanvasBilling(false);
					getBilling();
				}}
			>
				{isRightOffcanvasBilling && billing && (
					/*           <BillDetailsPage
            onClose={() => {
              setIsRightOffcanvasBilling(false);
              getBilling();
            }}
            bill={
              billing.find((item) => item.invoiceNumber === selectedInvoice) ||
              ConvertBilling.toBilling("{}")
            }
            getPatientBillingDetails={props.getPatientBillingDetails}
          /> */
					<InvoicePage
						clientName=""
						invoiceDetails={
							billing.find((item) => item.invoiceNumber === selectedInvoice) || ConvertBilling.toBilling("{}")
						}
						onClose={() => {
							setIsRightOffcanvasBilling(false);
							getBilling();
						}}
					/>
				)}
			</RightOffcanvas>
			<RightOffcanvas
				isOpen={newBill}
				customMaxWidthClass="xl:max-w-[70vw]"
				onClose={() => {
					setNewBill(false);
				}}
			>
				{newBill && (
					<>
						<InvoicePage
							clientName=""
							invoiceDetails={{
								invoiceNumber: "",
								invoiceDate: new Date(),
								dueDate: new Date(),
								entityId: userData?.entity_id,
								entityBranchId: userData?.branch_id,
								clientName: "",
								clientId: "",
								items: [],
								totalAmount: 0,
								status: "pending",
								billForAppointments: false,
								totalPaid: 0,
								tax: 0,
								outStandingAmount: 0,
								paymentDetails: [],
								discount: 0,
								subtotal: 0,
								paymentMode: "",
								notes: "",
								taxes: [],
							}}
							onClose={() => {
								setNewBill(false);
								getBilling();
							}}
						/>
					</>
				)}
			</RightOffcanvas>
		</>
	);
}
