import React, { useEffect } from "react";

import PatientInfoCardV2 from "./PatientInfoCardV2";
import ExaminationAndPlan from "./ExaminationAndPlan";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@components/ui/tabs";
import Billing from "./Billing";
import { useParams } from "react-router-dom";
import { ScrollArea, ScrollBar } from "@components/ui/scroll-area";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";

export default function PatientRecord({
	inputPatientId,
	defaultTab,
}: {
	inputPatientId?: string;
	defaultTab?: string;
}) {
	const [isModalOpen, setIsModalOpen] = React.useState(false);

	let { patientId } = useParams();
	const getPatientId = (): string => {
		return inputPatientId ?? patientId ?? "";
	};
	const defaultValue = defaultTab && defaultTab !== null ? defaultTab : "patientInfo";

	const handleCloseModal = () => setIsModalOpen(false);
	const userData = getUserPersistedOnLocalStorage();

	return (
		<>
			{/* <PatientInfoCardV2 patientId={getPatientId()} />
			 */}
			<span></span>
			<Tabs defaultValue={defaultValue} className="">
				<TabsList
					className={`text-sm grid w-full ${userData?.user_role === "PRACTITIONER" ? "grid-cols-2" : "grid-cols-3"}`}
				>
					<TabsTrigger value="patientInfo">
						<span className="hidden lg:flex text-sm">Patient Information & Acvitites</span>
						<span className="flex lg:hidden text-sm">Patient Info</span>
					</TabsTrigger>
					<TabsTrigger value="pe">
						<span className="hidden lg:flex text-sm">Physical Examination & Treatment Plan</span>
						<span className="flex lg:hidden text-sm">History</span>
					</TabsTrigger>
					{userData?.user_role !== "PRACTITIONER" && (
						<TabsTrigger value="billing">
							<span className="hidden lg:flex text-sm">Billing & Payments</span>
							<span className="flex lg:hidden text-sm">Billing</span>
						</TabsTrigger>
					)}
				</TabsList>
				<TabsContent value="patientInfo">
					<div className="mt-8 mx-2">
						<PatientInfoCardV2 patientId={getPatientId()} />
					</div>
				</TabsContent>
				<TabsContent value="pe">
					<ExaminationAndPlan inputPatientId={inputPatientId} />
				</TabsContent>
				<TabsContent value="billing">
					<Billing patientId={getPatientId()} onClose={handleCloseModal} patientPersonalInformation={null} />
				</TabsContent>
			</Tabs>
		</>
	);
}
