import React from "react";
import { NewMasterAssessmentData } from "@pages/manage-patient-new/types/NewMasterAssessment";

import { CheckCircleIcon } from "@heroicons/react/24/solid";

import axios from "axios";
import { endpoints, replaceParams } from "@utils/axios";
import { toast } from "react-toastify";
import {
  Convert,
  SessionNotesDetails,
} from "@pages/manage-patient-new/types/SessionNotesDetails";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}
export default function SessionNotesV2(props: {
  patientId: string;
  masterAssessmentData: NewMasterAssessmentData | null;
  getMasterAssessmentLedger: () => Promise<void>;
}) {
  const [notes, setNotes] = React.useState("");
  const [notesList, setNotesList] = React.useState<
    SessionNotesDetails[] | null
  >(null);
  const userData = getUserPersistedOnLocalStorage();

  const getSessionNotes = async () => {
    setNotes("");
    try {
      const paramsMap = new Map<string, string>([
        ["id", props.masterAssessmentData?.id ?? ""],
      ]);
      const filteredEndpoint = replaceParams(
        endpoints.sessionNotes.view,
        paramsMap
      );
      const response = await axios.get(filteredEndpoint);
      const data = response.data["data"];
      if (data) {
        const convertedNotesList = data.map((note: any) =>
          Convert.toSessionNotesDetails(JSON.stringify(note))
        );

        setNotesList(convertedNotesList);
      } else {
        setNotesList([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  React.useEffect(() => {
    getSessionNotes();
  }, [props.masterAssessmentData]);

  const addNotes = async () => {
    try {
      const response = await axios.post(
        endpoints.sessionNotes.addSessionNotes,
        {
          clientId: props.patientId,
          entityId: props.masterAssessmentData?.entityId,
          entityUserId: userData?.user_id,
          entityUserName: userData?.name,
          assessmentId: props.masterAssessmentData?.id,
          createdDate: new Date(),
          notes: notes,
          createdBy: props.masterAssessmentData?.entityUserId,
        }
      );

      toast.success("Session Note added successfully");

      getSessionNotes();
    } catch (error) {
      toast.error("Something went wrong. Please try again!");
    }
  };

  const formatDateDifference = (dateString: string): string => {
    const currentDate = new Date();
    const createdDate = new Date(dateString);
    const timeDifference = currentDate.getTime() - createdDate.getTime();
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    if (daysDifference === 0) {
      return "Today";
    } else if (daysDifference === 1) {
      return "1d";
    } else {
      return `${daysDifference}d`;
    }
  };

  return (
    <>
      <ul role="list" className="space-y-6 overflow-auto max-h-[50vh] p-2 mt-2">
        {(notesList === null || notesList.length === 0) && (
          <div className="text-center text-gray-500">
            <p className="text-lg font-semibold mb-2">
              No session notes found.
            </p>
            <p className="text-sm">Add notes to keep track of your sessions.</p>
          </div>
        )}
        {notesList &&
          notesList.map((activityItem, activityItemIdx) => (
            <li key={activityItem.id} className="relative flex gap-x-4">
              <div
                className={classNames(
                  activityItemIdx === notesList.length - 1
                    ? "h-6"
                    : "-bottom-6",
                  "absolute left-0 top-0 flex w-6 justify-center"
                )}
              >
                <div className="w-px bg-gray-200" />
              </div>

              <>
                <svg
                  className="relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-50"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <circle cx="12" cy="12" r="8" strokeWidth="2" />
                </svg>
                <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
                  <div className="flex justify-between gap-x-4">
                    <div className="py-0.5 text-xs leading-5 text-gray-500">
                      <span className="font-medium text-gray-900">
                        {activityItem.entityUserName}
                      </span>{" "}
                      noted
                    </div>
                    <time className="flex-none py-0.5 text-xs leading-5 text-gray-500">
                      {formatDateDifference(
                        activityItem.createdDate.toString()
                      )}
                    </time>
                  </div>
                  <p className="text-sm leading-6 text-gray-500">
                    {activityItem.notes}
                  </p>
                </div>
              </>
            </li>
          ))}
      </ul>

      <div className="mt-6 flex gap-x-3">
        <form action="#" className="relative flex-auto">
          <div className="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
            <label htmlFor="comment" className="sr-only">
              Add your notes
            </label>
            <textarea
              rows={2}
              name="notes"
              id="notes"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              className="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
              placeholder="Add your notes..."
            />
          </div>

          <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
            <button
              type="button"
              className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={notes === "" ? () => {} : addNotes}
            >
              Add Notes
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
