import React, { useEffect, useState } from "react";

import Modal from "@components/modal";
import Applabel from "@components/hook-form/applabel";
import { Button, OutlinedButton } from "@components/button";
import { ButtonVariant } from "@components/button/button";
import { endpoints, replaceParams } from "@utils/axios";
import axios, { CancelTokenSource } from "axios";
import { InvoiceDetails, ConvertBilling } from "@pages/finances/types/InvoiceDetails";
import moment from "moment";
import { z } from "zod";
import { FieldValues, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { getUserPersistedOnLocalStorage } from "@authentication/context/jwt/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { ValidationError } from "@components/hook-form";
import { toast } from "react-toastify";

export const RefundDetailsSchema = z.object({
	amount: z.string().min(1, "Please add amount"),
	invoiceNo: z.string().default(""),
	notes: z.string().default(""),
});
export type RefundDetailsSchemaType = z.infer<typeof RefundDetailsSchema>;

export default function RefundDetailsDialog(props: { onClose: () => void; onConfirm: () => void; patientId: string }) {
	let [isOpen, setIsOpen] = useState(true);
	const [invoices, setInvoices] = React.useState<InvoiceDetails[]>([]);
	const [selectedDate, setSelectedDate] = React.useState<Date>(new Date());

	const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value != "") {
			setSelectedDate(new Date(e.target.value));
		}
	};

	const getInvoices = async () => {
		const paramsMap = new Map<string, string>([["clientId", props.patientId]]);
		const endpoint = replaceParams(endpoints.invoice.getBillsForClient, paramsMap);

		const response = await axios.get(endpoint);
		const data = response.data["data"];
		if (data !== null) {
			const convertedList = data.map((expense: any) => {
				return ConvertBilling.toBilling(JSON.stringify(expense));
			});

			setInvoices(convertedList);
		} else {
			setInvoices([]);
		}
	};

	useEffect(() => {
		const _init = async () => {
			await getInvoices();
		};

		_init();
	}, []);

	function closeModal() {
		setIsOpen(false);
		props.onClose();
	}

	const methods = useForm({
		resolver: zodResolver(RefundDetailsSchema),
	});

	const [postInProgress, setPostInProgress] = React.useState<boolean>(false);
	const cancelToken = React.useRef<CancelTokenSource | null>(null);
	const [formData, setFormData] = useState<RefundDetailsSchemaType>({
		amount: "",
		invoiceNo: "",
		notes: "",
	});
	const onSubmit: SubmitHandler<FieldValues> = async (data) => {
		if (postInProgress) return;
		methods.trigger();

		if (cancelToken.current) {
			cancelToken.current.cancel("Request already in progress!");
		}

		cancelToken.current = axios.CancelToken.source();

		try {
			setPostInProgress(true);
			const combinedData = { ...formData, ...data };

			RefundDetailsSchema.parse(combinedData);
			const userData = getUserPersistedOnLocalStorage();

			await axios.post(
				`${endpoints.payment.refund}`,
				{
					entityId: userData?.entity_id,
					entityBranchId: userData?.branch_id,
					clientId: props.patientId,
					amount: Number(combinedData.amount),
					invoiceNo: combinedData.invoiceNo,
					notes: combinedData.notes,
					refundDate: selectedDate,
				},
				{
					cancelToken: cancelToken.current.token,
				}
			);
			setPostInProgress(false);
			closeModal();
			props.onConfirm();
			toast.success("Refund processed");
		} catch (error) {
			console.error("Error submitting form:", error);
			setPostInProgress(false);
		}
	};

	return (
		<>
			<Modal isOpen={isOpen} onClose={closeModal}>
				<div className="flex flex-row w-full justify-between mx-4 my-4">
					<h3 className="text-md leading-6 font-bold text-gray-900">Refund Details</h3>
				</div>
				<hr className="w-full" />
				<FormProvider {...methods}>
					<form onSubmit={methods.handleSubmit(onSubmit)}>
						<div className="flex flex-col py-2 px-4">
							<div className="flex flex-col w-2/3 sm:w-full mr-2">
								<Applabel label="Date" />
								<input
									disabled
									className="flex lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
									type="date"
									value={moment(selectedDate).format("YYYY-MM-DD")}
									onChange={handleDateChange}
								/>
							</div>
							<div className="flex flex-col w-2/3">
								<Applabel label="Refund amount" mandatory />
								<input
									{...methods.register("amount")}
									name="amount"
									placeholder="Enter refund amount"
									type="number"
									onChange={(e) => {
										if (e.target.value === "") {
											methods.setError("amount", {
												message: "Please enter amount",
											});
										} else if (Number(e.target.value) < 0) {
											methods.setError("amount", {
												message: "amount should be positive",
											});
											methods.setValue("amount", "");
										} else {
											methods.clearErrors("amount");
											methods.setValue("amount", e.target.value);
										}
									}}
									className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
								/>
								{methods.formState.errors.amount && (
									<ValidationError message={methods.formState.errors.amount?.message?.toString() ?? ""} />
								)}
							</div>
							<div className="flex flex-col w-2/3">
								<Applabel label="Select invoice" />
								<select
									className={`lock w-full rounded-lg border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-grey-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
									onChange={(e) => {
										methods.setValue("invoiceNo", e.target.value);
									}}
								>
									<option value="">Select Invoice</option>
									{invoices!.map((inv) => (
										<option key={inv.invoiceNumber} value={inv.invoiceNumber}>
											{inv.invoiceNumber} ({moment(new Date(inv.invoiceDate)).format("DD-MM-YYYY")})
										</option>
									))}
								</select>
							</div>
							<div className="flex flex-col mt-2">
								<Applabel label="Notes" />

								<textarea
									{...methods.register("notes")}
									id="message"
									rows={3}
									className="block w-full rounded-lg border border-gray-300 focus:outline-none focus:border-primary-600 placeholder-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm"
									placeholder="Enter additional notes..."
								></textarea>
							</div>
						</div>
						<div className="flex flex-row w-full mt-4 p-4 bg-gray-100 justify-end">
							<OutlinedButton variant={ButtonVariant.PRIMARY} onClick={closeModal} className="mr-2" type="button">
								Cancel
							</OutlinedButton>
							<Button
								variant={ButtonVariant.PRIMARY}
								onClick={() => {
									setFormData({
										...formData,
										...methods.getValues(),
									});
								}}
								type="submit"
							>
								Refund
							</Button>
						</div>
					</form>
				</FormProvider>
			</Modal>
		</>
	);
}
